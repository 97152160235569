import { store } from '../../app/store'
import { usersApiSlice } from '../users/usersApiSlice'
import { centersApiSlice } from '../centers/centersApiSlice'
import { assessortraineeassignmentApiSlice } from '../assessortraineeassignment/assessortraineeassignmentApiSlice'
import { centerapplicationApiSlice } from '../centerapplication/centerapplicationApiSlice'
import { rolesApiSlice } from '../roles/rolesApiSlice'
import { qualificationsApiSlice } from '../qualifications/qualificationsApiSlice'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

const Prefetch = () => {

    useEffect(() => {
        store.dispatch(usersApiSlice.util.prefetch('getUsers', 'usersList', { force: true }))
        store.dispatch(centersApiSlice.util.prefetch('getCenters', 'centersList', { force: true }))
        store.dispatch(assessortraineeassignmentApiSlice.util.prefetch('getAssessorTraineeAssignments', 'assessorTraineeAssignmentsList', { force: true }))
        store.dispatch(centerapplicationApiSlice.util.prefetch('getCenterApplications', 'centerapplicationsList', { force: true }))
        store.dispatch(rolesApiSlice.util.prefetch('getRoles', 'rolesList', { force: true }))
        store.dispatch(qualificationsApiSlice.util.prefetch('getQualifications', 'qualificationsList', { force: true }))
    }, [])

    return <Outlet />
}
export default Prefetch
