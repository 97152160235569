import { useParams } from 'react-router-dom'
import EditRoleForm from './EditRoleForm'
import { useGetRolesQuery } from './rolesApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'

const EditRole = () => {
    useTitle('NIOB Skill CaMP: Edit Role')

    const { id } = useParams()

    const { role } = useGetRolesQuery("rolesList", {
        selectFromResult: ({ data }) => ({
            role: data?.entities[id]
        }),
    })

    if (!role) return <PulseLoader color={"#0b1c08"} className="center" />

    const content = <EditRoleForm role={role} />

    return content
}

export default EditRole
