import { useParams } from 'react-router-dom'
import CenterQualityCheck from './CenterQualityCheck'
import { useGetCenterApplicationsQuery } from './centerapplicationApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'

const CenterQuality = () => {
    useTitle('NIOB Skill CaMP: Center Quality Check')

    const { id } = useParams()

    const { centerapplication } = useGetCenterApplicationsQuery("centerapplicationsList", {
        selectFromResult: ({ data }) => ({
            centerapplication: data?.entities[id]
        }),
    })

    if (!centerapplication) return <PulseLoader color={"#0b1c08"} className="center" />

    const content = <CenterQualityCheck centerapplication={centerapplication} />

    return content
}

export default CenterQuality
