import { useParams } from 'react-router-dom'
import EditCenterApplicationForm from './EditCenterApplicationForm'
import { useGetCenterApplicationsQuery } from './centerapplicationApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'

const EditCenterApplication = () => {
    useTitle('NIOB Skill CaMP: Edit Center Application')

    const { id } = useParams()

    const { centerapplication } = useGetCenterApplicationsQuery("centerapplicationsList", {
        selectFromResult: ({ data }) => ({
            centerapplication: data?.entities[id]
        }),
    })

    if (!centerapplication) return <PulseLoader color={"#0b1c08"} className="center" />

    const content = <EditCenterApplicationForm centerapplication={centerapplication} />

    return content
}

export default EditCenterApplication
