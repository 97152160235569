import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetCentersQuery } from './centersApiSlice'
import useAuth from "../../hooks/useAuth"
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Box, Button, Stack, IconButton, Tooltip, Paper, Switch, FormControlLabel, useMediaQuery } from "@mui/material"
import { Link } from "react-router-dom"
import Header from "../../components/Header"
import AddIcon from '@mui/icons-material/Add'
import HomeIcon from '@mui/icons-material/Home'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { styled } from '@mui/material/styles'

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .no-rows-primary': {
    fill: theme.palette.mode === 'light' ? '#AEB8C2' : '#3D4751',
  },
  '& .no-rows-secondary': {
    fill: theme.palette.mode === 'light' ? '#E8EAED' : '#1D2126',
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width={96}
        viewBox="0 0 452 257"
        aria-hidden
        focusable="false"
      >
        <path
          className="no-rows-primary"
          d="M348 69c-46.392 0-84 37.608-84 84s37.608 84 84 84 84-37.608 84-84-37.608-84-84-84Zm-104 84c0-57.438 46.562-104 104-104s104 46.562 104 104-46.562 104-104 104-104-46.562-104-104Z"
        />
        <path
          className="no-rows-primary"
          d="M308.929 113.929c3.905-3.905 10.237-3.905 14.142 0l63.64 63.64c3.905 3.905 3.905 10.236 0 14.142-3.906 3.905-10.237 3.905-14.142 0l-63.64-63.64c-3.905-3.905-3.905-10.237 0-14.142Z"
        />
        <path
          className="no-rows-primary"
          d="M308.929 191.711c-3.905-3.906-3.905-10.237 0-14.142l63.64-63.64c3.905-3.905 10.236-3.905 14.142 0 3.905 3.905 3.905 10.237 0 14.142l-63.64 63.64c-3.905 3.905-10.237 3.905-14.142 0Z"
        />
        <path
          className="no-rows-secondary"
          d="M0 10C0 4.477 4.477 0 10 0h380c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 20 0 15.523 0 10ZM0 59c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 69 0 64.523 0 59ZM0 106c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 153c0-5.523 4.477-10 10-10h195.5c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 200c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 247c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10Z"
        />
      </svg>
      <Box sx={{ mt: 2, color: '#0b1c08' }}>No record</Box>
    </StyledGridOverlay>
  );
}

const CentersList = () => {
    useTitle('NIOB Skill CaMP: All Centres')
    const isMobile = useMediaQuery("(max-width:600px)")
    const { isSuperAdmin } = useAuth()
    const navigate = useNavigate()

    const { data: centers, isLoading, isSuccess, isError, error } = useGetCentersQuery('centersList', {
        pollingInterval: 30000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const [pageSize, setPageSize] = useState(5)
    const [density, setDensity] = useState(true)

    const handleEdit = (id) => navigate(`/dash/centers/${id}`)

    const rows = useMemo(() => {
        if (!centers || !centers.entities) return []
        return Object.values(centers.entities).map(center => ({
            ...center,
            id: center.id
        }))
    }, [centers])

    const columns = [
        { field: 'centerCode', headerName: 'CENTRE CODE', width: 150 },
        { field: 'centerName', headerName: 'CENTRE NAME', width: 200 },
        { field: 'centerManager', headerName: 'CENTRE MANAGER', width: 200 },
        { field: 'centerType', headerName: 'CENTRE TYPE', width: 150 },
        { field: 'stateOfRez', headerName: 'STATE', width: 150 },
        { field: 'qualifications', headerName: 'QUALIFICATIONS', width: 250, renderCell: (params) => params.value.join(", ") },
        {
            field: 'actions',
            headerName: 'ACTIONS',
            width: 150,
            renderCell: (params) => (
                <Tooltip title="Edit Center">
                    <IconButton onClick={() => handleEdit(params.row.id)} sx={{color:"#0b1c08"}}>
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </IconButton>
                </Tooltip>
            )
        }
    ]

    let content

    if (isLoading) content = <PulseLoader color={"#0b1c08"} className="center" />

    if (isError) content = <p className='errmsg'>{error?.data?.message}</p>

    //if (isSuccess) {
    return content = (
            <>
                <Header title="CENTRES LIST" subtitle="Record of All Centres" />
                <Box sx={{
                  width: '100%', mb: '150px', mt: isMobile ? "10%" : "",
                  "& .MuiPaper-root": { backgroundColor: '#030702' },
                  "& .MuiDataGrid-row:nth-of-type(odd)": {
                      backgroundColor: '#fff'
                  },
                  "& .MuiDataGrid-row:nth-of-type(odd):hover": {
                      backgroundColor: '#dfdfdf',
                  },
                  "& .MuiDataGrid-row:nth-of-type(even)": {
                      backgroundColor: '#efefef'
                  },
                  "& .MuiDataGrid-row:nth-of-type(even):hover": {
                      backgroundColor: '#dfdfdf',
                  },
                  "& .MuiDataGrid-footerContainer": {
                      backgroundColor: '#0b1c08'
                  },
                  "& .MuiDataGrid-filterForm": {
                      backgroundColor: '#9CD08F'
                  },
                  "& .MuiDataGrid-cell": { color: '#0b1c08' },
                  "& .MuiDataGrid-toolbarContainer": {backgroundColor: '#fff !important'},
                  "& .MuiDataGrid-scrollbar--horizontal": {height: '5px !important', zIndex:1},
                  "& .MuiDataGrid-scrollbar--vertical": {zIndex:1},
                  p: "2%"
                }}>
                    <Stack direction="row" gap={1} sx={{ mb: 2 }}>
                        <Link to="/dash">
                            <Button variant="outlined"  sx={{color:"#0b1c08"}} size="small" startIcon={<HomeIcon />}>
                                Home
                            </Button>
                        </Link>
                        <Link to="/dash/centers/new">
                            <Button variant="outlined"  sx={{color:"#0b1c08"}} size="small" startIcon={<AddIcon />}>
                                Add New Centre
                            </Button>
                        </Link>
                    </Stack>
                    <div style={{ height: 450, width: '100%' }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={pageSize}
                            rowsPerPageOptions={[5, 10, 25]}
                            disableSelectionOnClick
                            disableColumnFilter
                            disableColumnSelector
                            slots={{
                              toolbar: GridToolbar,
                              noRowsOverlay: CustomNoRowsOverlay
                            }}
                            slotProps={{
                              toolbar: {
                                showQuickFilter: true,
                              },
                            }}
                            components={{
                                Toolbar: () => (
                                    <GridToolbarContainer>
                                        <GridToolbarQuickFilter />
                                        <GridToolbarExport />
                                    </GridToolbarContainer>
                                )
                            }}
                            sx={{ '--DataGrid-overlayHeight': '450px' }}
                        />
                    </div>
                </Box>
            </>
        )
    //}

    //return content
}

export default CentersList
