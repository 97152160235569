import { useAddNewCenterApplicationMutation } from "./centerapplicationApiSlice"
import { Link, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { STATES } from '../../config/states'
import { CENTERTYPES } from '../../config/centertypes'
import { QUALIFICATIONS } from '../../config/qualifications'
import useTitle from '../../hooks/useTitle'
import dayjs from 'dayjs'
import axios from 'axios'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import LoadingButton from '@mui/lab/LoadingButton'
import Header from '../../components/Header'
import DashHeader from '../../components/DashHeader2'
import DashFooter from '../../components/DashFooter'
import { Typography, Box, Card, Divider, InputAdornment, MenuItem, IconButton, CardContent, Grid, FormControl, Button, Tabs, Tab } from "@mui/material"
import { useTheme, TextField, Stack, InputLabel, Select, CardHeader, CardActions, Radio, RadioGroup, FormLabel, FormControlLabel } from "@mui/material"
import { FormGroup, Checkbox, List, ListItem, ListItemText, Alert, Collapse } from "@mui/material"
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import useMediaQuery from "@mui/material/useMediaQuery"
import React, { useState, useEffect } from "react"
import { VisibilityOff, Visibility } from "@mui/icons-material"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { tokens } from "../../theme"

const CAC_REGEX = /^[0-9]{5,7}$/
const CENTER_REGEX = /^[A-z ]{8,50}$/
const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
const PHONE_REGEX = /^[0-9]{11}$/
const WEBSITE_REGEX = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, position: "relative" }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const CenterApplication = () => {
    const isMobile = useMediaQuery("(max-width:600px)")
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const maxYear = new Date().getFullYear().toString()
    const today = new Date().toString()

    const [addNewCenterApplication, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewCenterApplicationMutation()

    const navigate = useNavigate()

    //INITIALIZE UPLOAD VARIABLES
    const [centerCredentials, setCenterCredentials] = useState(null)
    const [centerCredentialsURL, setCenterCredentialsURL] = useState("")
    const [previewCredentials, setPreviewCredentials] = useState()

    //CENTER DETAILS INITIALIZE
    const [centerName, setCenterName] = useState('')
    const [validCenterName, setValidCenterName] = useState(false)
    const [yearOfInc, setYearOfInc] = useState()
    const [cacNumber, setCACNumber] = useState('')
    const [validCACNumber, setValidCACNumber] = useState(false)
    const [permanentCenterAddress, setPermanentCenterAddress] = useState('')
    const [trainingCenterAddress, setTrainingCenterAddress] = useState('')
    const [phone, setPhone] = useState('')
    const [validPhone, setValidPhone] = useState(false)
    const [email, setEmail] = useState('')
    const [validEmail, setValidEmail] = useState(false)
    const [website, setWebsite] = useState('')
    const [validWebsite, setValidWebsite] = useState(false)
    const [centerHead, setCenterHead] = useState('')
    const [validCenterHead, setValidCenterHead] = useState(false)
    const [centerHeadPhone, setCenterHeadPhone] = useState('')
    const [validCenterHeadPhone, setValidCenterHeadPhone] = useState(false)
    const [centerHeadEmail, setCenterHeadEmail] = useState('')
    const [validCenterHeadEmail, setValidCenterHeadEmail] = useState(false)
    const [qualifications, setQualifications] = useState([])
    const [stateOfRez, setStateOfRez] = useState('')


    //CENTER ORGANISATION INITIALIZE
    const [centerType, setCenterType] = useState('')
    const [satelliteCenterName1, setSatelliteCenterName1] = useState('')
    const [validSatelliteCenterName1, setValidSatelliteCenterName1] = useState(false)
    const [satelliteCenterAddress1, setSatelliteCenterAddress1] = useState('')
    const [satelliteCenterName2, setSatelliteCenterName2] = useState('')
    const [validSatelliteCenterName2, setValidSatelliteCenterName2] = useState(false)
    const [satelliteCenterAddress2, setSatelliteCenterAddress2] = useState('')
    const [otherApprovals, setOtherApprovals] = React.useState({
      itp: false,
      naddc: false,
      nabteb: false,
      cityGuild: false,
    })
    const [awardingBody1, setAwardingBody1] = useState('')
    const [awardingBody1Qualification, setAwardingBody1Qualification] = useState('')
    const [awardingBody1Level, setAwardingBody1Level] = useState(1)
    const [awardingBody1DateApproved, setAwardingBody1DateApproved] = useState()
    const [awardingBody1ApprovalStatus, setAwardingBody1ApprovalStatus] = useState('')
    const [awardingBody2, setAwardingBody2] = useState('')
    const [awardingBody2Qualification, setAwardingBody2Qualification] = useState('')
    const [awardingBody2Level, setAwardingBody2Level] = useState(1)
    const [awardingBody2DateApproved, setAwardingBody2DateApproved] = useState()
    const [awardingBody2ApprovalStatus, setAwardingBody2ApprovalStatus] = useState('')


    //HUMAN & FINANCIAL RESOURCES INITIALIZE
    const [nsqCertifiedAssessors, setNsqCertifiedAssessors] = useState('')
    const [assessorsCount, setAssessorsCount] = useState(0)
    const [nsqCertifiedVerifiers, setNsqCertifiedVerifiers] = useState('')
    const [verifiersCount, setVerifiersCount] = useState(0)
    const [nsqCertifiedCenterManager, setNsqCertifiedCenterManager] = useState('')
    const [liaisonOfficerPresent, setLiaisonOfficerPresent] = useState('')
    const [resourceOfficerPresent, setResourceOfficerPresent] = useState('')
    const [siteBankPresent, setSiteBankPresent] = useState('')


    //PHYSICAL INFRASTRUCTURE INITIALIZE
    const [trainingRoomAdequate, setTrainingRoomAdequate] = useState('')
    const [dataRoomAdequate, setDataRoomAdequate] = useState('')
    const [workshopAdequate, setWorkshopAdequate] = useState('')
    const [practicalSpaceAdequate, setPracticalSpaceAdequate] = useState('')
    const [techLabAdequate, setTechLabAdequate] = useState('')
    const [staffRoomAdequate, setStaffRoomAdequate] = useState('')
    const [toiletAdequate, setToiletAdequate] = useState('')
    const [firstAidFireAdequate, setFirstAidFireAdequate] = useState('')
    const [hostelsAdequate, setHostelsAdequate] = useState('')
    const [tradeToolsAdequate, setTradeToolsAdequate] = useState('')
    const [auditoriumAvailable, setAuditoriumAvailable] = useState('')
    const [libraryAdequate, setLibraryAdequate] = useState('')
    const [waterAndPowerAdequate, setWaterAndPowerAdequate] = useState('')
    const [mgtOfficesAdequate, setMgtOfficesAdequate] = useState('')


    //CODE OF PRACTICE INITIALIZE
    const [nosAvailable, setNosAvailable] = useState('')
    const [trainingManualAvailable, setTrainingManualAvailable] = useState('')
    const [nosAndManualAlignment, setNosAndManualAlignment] = useState('')
    const [otherTrainingMaterialsAvailable, setOtherTrainingMaterialsAvailable] = useState('')
    const [nsqGuidelineAvailable, setNsqGuidelineAvailable] = useState('')
    const [staffCompliantWithStandards, setStaffCompliantWithStandards] = useState('')
    const [objectivesClear, setObjectivesClear] = useState('')
    const [classParticipation, setClassParticipation] = useState('')


    //ORGANISATION STAFFING INITIALIZE
    const [centerManagerName, setCenterManagerName] = useState('')
    const [centerManagerQualification, setCenterManagerQualification] = useState('')
    const [assessor1Name, setAssessor1Name] = useState('')
    const [assessor1Qualification, setAssessor1Qualification] = useState('')
    const [assessor2Name, setAssessor2Name] = useState('')
    const [assessor2Qualification, setAssessor2Qualification] = useState('')
    const [internalVerifierName, setInternalVerifierName] = useState('')
    const [internalVerifierQualification, setInternalVerifierQualification] = useState('')
    const [liaisonOfficerName, setLiaisonOfficerName] = useState('')
    const [liaisonOfficerQualification, setLiaisonOfficerQualification] = useState('')
    const [resourceOfficerName, setResourceOfficerName] = useState('')
    const [resourceOfficerQualification, setResourceOfficerQualification] = useState('')
    const [otherStaffName, setOtherStaffName] = useState('')
    const [otherStaffQualification, setOtherStaffQualification] = useState('')
    const [otherStaffDesignation, setOtherStaffDesignation] = useState('')


    //PHYSICAL FACILITIES INITIALIZE
    const [trainingRoomQuantity, setTrainingRoomQuantity] = useState(0)
    const [trainingRoomFloorArea, setTrainingRoomFloorArea] = useState(0)
    const [workshopQuantity, setWorkshopQuantity] = useState(0)
    const [workshopFloorArea, setWorkshopFloorArea] = useState(0)
    const [practicalTrainingSpaceQuantity, setPracticalTrainingSpaceQuantity] = useState(0)
    const [practicalTrainingSpaceFloorArea, setPracticalTrainingSpaceFloorArea] = useState(0)
    const [technicalLabQuantity, setTechnicalLabQuantity] = useState(0)
    const [technicalLabFloorArea, setTechnicalLabFloorArea] = useState(0)
    const [recordsRoomQuantity, setRecordsRoomQuantity] = useState(0)
    const [recordsRoomFloorArea, setRecordsRoomFloorArea] = useState(0)
    const [auditoriumQuantity, setAuditoriumQuantity] = useState(0)
    const [auditoriumFloorArea, setAuditoriumFloorArea] = useState(0)
    const [libraryQuantity, setLibraryQuantity] = useState(0)
    const [libraryFloorArea, setLibraryFloorArea] = useState(0)
    const [utilityQuantity, setUtilityQuantity] = useState(0)
    const [utilityFloorArea, setUtilityFloorArea] = useState(0)
    const [mgtOfficesQuantity, setMgtOfficesQuantity] = useState(0)
    const [mgtOfficesFloorArea, setMgtOfficesFloorArea] = useState(0)
    const [staffRoomQuantity, setStaffRoomQuantity] = useState(0)
    const [staffRoomFloorArea, setStaffRoomFloorArea] = useState(0)
    const [toiletQuantity, setToiletQuantity] = useState(0)
    const [toiletFloorArea, setToiletFloorArea] = useState(0)
    const [firstAidFireFacilityQuantity, setFirstAidFireFacilityQuantity] = useState(0)
    const [firstAidFireFacilityFloorArea, setFirstAidFireFacilityFloorArea] = useState(0)
    const [hostelQuantity, setHostelQuantity] = useState(0)
    const [hostelFloorArea, setHostelFloorArea] = useState(0)
    const [tradeToolsQuantity, setTradeToolsQuantity] = useState(0)
    const [tradeToolsFloorArea, setTradeToolsFloorArea] = useState(0)
    const [otherFacilityName, setOtherFacilityName] = useState('')
    const [otherFacilityQuantity, setOtherFacilityQuantity] = useState(0)
    const [otherFacilityFloorArea, setOtherFacilityFloorArea] = useState(0)


    //TRAINING STAFF INITIALIZE
    const [trainingStaff1Name, setTrainingStaff1Name] = useState('')
    const [trainingStaff1Gender, setTrainingStaff1Gender] = useState('')
    const [trainingStaff1Qualification, setTrainingStaff1Qualification] = useState('')
    const [trainingStaff1Experience, setTrainingStaff1Experience] = useState(0)
    const [trainingStaff1Specialization, setTrainingStaff1Specialization] = useState('')
    const [trainingStaff2Name, setTrainingStaff2Name] = useState('')
    const [trainingStaff2Gender, setTrainingStaff2Gender] = useState('')
    const [trainingStaff2Qualification, setTrainingStaff2Qualification] = useState('')
    const [trainingStaff2Experience, setTrainingStaff2Experience] = useState(0)
    const [trainingStaff2Specialization, setTrainingStaff2Specialization] = useState('')
    const [trainingStaff3Name, setTrainingStaff3Name] = useState('')
    const [trainingStaff3Gender, setTrainingStaff3Gender] = useState('')
    const [trainingStaff3Qualification, setTrainingStaff3Qualification] = useState('')
    const [trainingStaff3Experience, setTrainingStaff3Experience] = useState(0)
    const [trainingStaff3Specialization, setTrainingStaff3Specialization] = useState('')


    //SUPPORTING DOCS & DECLARATION INITIALIZE
    const [applicantsName, setApplicantsName] = useState('')
    const [validApplicantsName, setValidApplicantsName] = useState(false)
    const [applicantsPosition, setApplicantsPosition] = useState('')
    const [validApplicantsPosition, setValidApplicantsPosition] = useState(false)


    const [alert, setAlert] = useState(null)


    useEffect(() => {
        setValidCenterName(CENTER_REGEX.test(centerName))
    }, [centerName])

    useEffect(() => {
        setValidCACNumber(CAC_REGEX.test(cacNumber))
    }, [cacNumber])

    useEffect(() => {
        setValidPhone(PHONE_REGEX.test(phone))
    }, [phone])

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email))
    }, [email])

    useEffect(() => {
        setValidWebsite(WEBSITE_REGEX.test(website))
    }, [website])

    useEffect(() => {
        setValidCenterHead(CENTER_REGEX.test(centerHead))
    }, [centerHead])

    useEffect(() => {
        setValidCenterHeadPhone(PHONE_REGEX.test(centerHeadPhone))
    }, [centerHeadPhone])

    useEffect(() => {
        setValidCenterHeadEmail(EMAIL_REGEX.test(centerHeadEmail))
    }, [centerHeadEmail])

    useEffect(() => {
        setValidApplicantsName(CENTER_REGEX.test(applicantsName))
    }, [applicantsName])

    useEffect(() => {
        setValidApplicantsPosition(CENTER_REGEX.test(applicantsPosition))
    }, [applicantsPosition])

    useEffect(() => {
        setValidSatelliteCenterName1(CENTER_REGEX.test(satelliteCenterName1))
    }, [satelliteCenterName1])

    useEffect(() => {
        setValidSatelliteCenterName2(CENTER_REGEX.test(satelliteCenterName2))
    }, [satelliteCenterName2])

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!centerCredentials) {
            setPreviewCredentials(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(centerCredentials)
        setPreviewCredentials(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [centerCredentials])

    useEffect(() => {
        if (isSuccess) {
            setAlert("Center Application successfully submitted!")
            setTimeout(() => navigate("/", { replace: true }), 7000)
        }
    }, [isSuccess, navigate])

    //CENTER DETAILS HANDLE CHANGE
    const onCenterNameChanged = e => setCenterName(e.target.value)
    const onCACChanged = e => setCACNumber(e.target.value)
    const onPermanentCenterAddressChanged = e => setPermanentCenterAddress(e.target.value)
    const onTrainingCenterAddressChanged = e => setTrainingCenterAddress(e.target.value)
    const onPhoneChanged = e => setPhone(e.target.value)
    const onEmailChanged = e => setEmail(e.target.value)
    const onWebsiteChanged = e => setWebsite(e.target.value)
    const onCenterHeadChanged = e => setCenterHead(e.target.value)
    const onCenterHeadPhoneChanged = e => setCenterHeadPhone(e.target.value)
    const onCenterHeadEmailChanged = e => setCenterHeadEmail(e.target.value)

    //CENTER ORGANIZATION HANDLE CHANGE
    const onCenterTypeChanged = e => setCenterType(e.target.value)
    const onSatelliteCenterName1Changed = e => setSatelliteCenterName1(e.target.value)
    const onSatelliteCenterAddress1Changed = e => setSatelliteCenterAddress1(e.target.value)
    const onSatelliteCenterName2Changed = e => setSatelliteCenterName2(e.target.value)
    const onSatelliteCenterAddress2Changed = e => setSatelliteCenterAddress2(e.target.value)
    const onOtherApprovalsChange = (event) => {
      setOtherApprovals({ ...otherApprovals, [event.target.name]: event.target.checked })
    }
    const { itp, naddc, nabteb, cityGuild } = otherApprovals
    const onAwardingBody1Changed = e => setAwardingBody1(e.target.value)
    const onAwardingBody1LevelChanged = e => setAwardingBody1Level(e.target.value)
    const onAwardingBody2Changed = e => setAwardingBody2(e.target.value)
    const onAwardingBody2LevelChanged = e => setAwardingBody2Level(e.target.value)

    //HUMAN & FINANCIAL RESOURCES HANDLE CHANGE
    const onAssessorsCountChanged = e => setAssessorsCount(e.target.value)
    const onVerifiersCountChanged = e => setVerifiersCount(e.target.value)

    //ORGANIZATIONAL STAFFING HANDLE CHANGE
    const onCenterManagerNameChanged = e => setCenterManagerName(e.target.value)
    const onCenterManagerQualificationChanged = e => setCenterManagerQualification(e.target.value)
    const onAssessor1NameChanged = e => setAssessor1Name(e.target.value)
    const onAssessor1QualificationChanged = e => setAssessor1Qualification(e.target.value)
    const onAssessor2NameChanged = e => setAssessor2Name(e.target.value)
    const onAssessor2QualificationChanged = e => setAssessor2Qualification(e.target.value)
    const onInternalVerifierNameChanged = e => setInternalVerifierName(e.target.value)
    const onInternalVerifierQualificationChanged = e => setInternalVerifierQualification(e.target.value)
    const onLiaisonOfficerNameChanged = e => setLiaisonOfficerName(e.target.value)
    const onLiaisonOfficerQualificationChanged = e => setLiaisonOfficerQualification(e.target.value)
    const onResourceOfficerNameChanged = e => setResourceOfficerName(e.target.value)
    const onResourceOfficerQualificationChanged = e => setResourceOfficerQualification(e.target.value)
    const onOtherStaffNameChanged = e => setOtherStaffName(e.target.value)
    const onOtherStaffQualificationChanged = e => setOtherStaffQualification(e.target.value)
    const onOtherStaffDesignationChanged = e => setOtherStaffDesignation(e.target.value)

    //PHYSICAL FACILITIES HANDLE CHANGE
    const onTrainingRoomQuantityChanged = e => setTrainingRoomQuantity(e.target.value)
    const onTrainingRoomFloorAreaChanged = e => setTrainingRoomFloorArea(e.target.value)
    const onWorkshopQuantityChanged = e => setWorkshopQuantity(e.target.value)
    const onWorkshopFloorAreaChanged = e => setWorkshopFloorArea(e.target.value)
    const onPracticalTrainingSpaceQuantityChanged = e => setPracticalTrainingSpaceQuantity(e.target.value)
    const onPracticalTrainingSpaceFloorAreaChanged = e => setPracticalTrainingSpaceFloorArea(e.target.value)
    const onTechnicalLabQuantityChanged = e => setTechnicalLabQuantity(e.target.value)
    const onTechnicalLabFloorAreaChanged = e => setTechnicalLabFloorArea(e.target.value)
    const onRecordsRoomQuantityChanged = e => setRecordsRoomQuantity(e.target.value)
    const onRecordsRoomFloorAreaChanged = e => setRecordsRoomFloorArea(e.target.value)
    const onAuditoriumQuantityChanged = e => setAuditoriumQuantity(e.target.value)
    const onAuditoriumFloorAreaChanged = e => setAuditoriumFloorArea(e.target.value)
    const onLibraryQuantityChanged = e => setLibraryQuantity(e.target.value)
    const onLibraryFloorAreaChanged = e => setLibraryFloorArea(e.target.value)
    const onUtilityQuantityChanged = e => setUtilityQuantity(e.target.value)
    const onUtilityFloorAreaChanged = e => setUtilityFloorArea(e.target.value)
    const onMgtOfficesQuantityChanged = e => setMgtOfficesQuantity(e.target.value)
    const onMgtOfficesFloorAreaChanged = e => setMgtOfficesFloorArea(e.target.value)
    const onStaffRoomQuantityChanged = e => setStaffRoomQuantity(e.target.value)
    const onStaffRoomFloorAreaChanged = e => setStaffRoomFloorArea(e.target.value)
    const onToiletQuantityChanged = e => setToiletQuantity(e.target.value)
    const onToiletFloorAreaChanged = e => setToiletFloorArea(e.target.value)
    const onFirstAidFireFacilityQuantityChanged = e => setFirstAidFireFacilityQuantity(e.target.value)
    const onFirstAidFireFacilityFloorAreaChanged = e => setFirstAidFireFacilityFloorArea(e.target.value)
    const onHostelQuantityChanged = e => setHostelQuantity(e.target.value)
    const onHostelFloorAreaChanged = e => setHostelFloorArea(e.target.value)
    const onTradeToolsQuantityChanged = e => setTradeToolsQuantity(e.target.value)
    const onTradeToolsFloorAreaChanged = e => setTradeToolsFloorArea(e.target.value)
    const onOtherFacilityNameChanged = e => setOtherFacilityName(e.target.value)
    const onOtherFacilityQuantityChanged = e => setOtherFacilityQuantity(e.target.value)
    const onOtherFacilityFloorAreaChanged = e => setOtherFacilityFloorArea(e.target.value)

    //TRAINING STAFF HANDLE CHANGE
    const onTrainingStaff1NameChanged = e => setTrainingStaff1Name(e.target.value)
    const onTrainingStaff1QualificationChanged = e => setTrainingStaff1Qualification(e.target.value)
    const onTrainingStaff1ExperienceChanged = e => setTrainingStaff1Experience(e.target.value)
    const onTrainingStaff2NameChanged = e => setTrainingStaff2Name(e.target.value)
    const onTrainingStaff2QualificationChanged = e => setTrainingStaff2Qualification(e.target.value)
    const onTrainingStaff2ExperienceChanged = e => setTrainingStaff2Experience(e.target.value)
    const onTrainingStaff3NameChanged = e => setTrainingStaff3Name(e.target.value)
    const onTrainingStaff3QualificationChanged = e => setTrainingStaff3Qualification(e.target.value)
    const onTrainingStaff3ExperienceChanged = e => setTrainingStaff3Experience(e.target.value)

    //SUPPORTING DOCS & DECLARATION HANDLE CHANGE
    const onApplicantsNameChanged = e => setApplicantsName(e.target.value)
    const onApplicantsPositionChanged = e => setApplicantsPosition(e.target.value)
    const onSelectCredentials = e => {
        if (e.target.files && e.target.files.length > 0) {
            setCenterCredentials(e.target.files[0])
        }
    }


    const canSave = [
        centerType,
        nsqCertifiedAssessors,
        nsqCertifiedVerifiers,
        nsqCertifiedCenterManager,
        liaisonOfficerPresent,
        resourceOfficerPresent,
        siteBankPresent,
        trainingRoomAdequate,
        dataRoomAdequate,
        workshopAdequate,
        practicalSpaceAdequate,
        techLabAdequate,
        staffRoomAdequate,
        toiletAdequate,
        firstAidFireAdequate,
        hostelsAdequate,
        tradeToolsAdequate,
        auditoriumAvailable,
        libraryAdequate,
        waterAndPowerAdequate,
        mgtOfficesAdequate,
        nosAvailable,
        trainingManualAvailable,
        nosAndManualAlignment,
        otherTrainingMaterialsAvailable,
        nsqGuidelineAvailable,
        staffCompliantWithStandards,
        objectivesClear,
        classParticipation,
        stateOfRez,
        qualifications.length,
        validCenterName,
        validCACNumber,
        validEmail,
        validCenterHead,
        validCenterHeadPhone,
        validCenterHeadEmail,
        validApplicantsName,
        validApplicantsPosition,
        centerCredentials
    ].every(Boolean) && !isLoading

    const onSaveCenterApplicationClicked = async e => {
        e.preventDefault()

        // Use axios to send and store Center Credentials and return filePath of stored credentials
        const formData = new FormData()
        formData.append('centerCredentials', centerCredentials)

        try {
            //const response = await axios.post('http://localhost:3500/upload', formData, {
            const response = await axios.post('https://niob-skill-camp-api.onrender.com/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            if (response.status === 200) {
                //console.log('File uploaded successfully. File path:', response.data.filePath)

                const applicationSubmitted = await addNewCenterApplication({
                  centerName,
                  'yearOfInc': yearOfInc.$y,
                  cacNumber,
                  permanentCenterAddress,
                  trainingCenterAddress,
                  phone,
                  email,
                  website,
                  centerHead,
                  centerHeadPhone,
                  centerHeadEmail,
                  qualifications,
                  stateOfRez,
                  centerType,
                  satelliteCenterName1,
                  satelliteCenterAddress1,
                  satelliteCenterName2,
                  satelliteCenterAddress2,
                  otherApprovals,
                  awardingBody1,
                  awardingBody1Qualification,
                  awardingBody1Level,
                  awardingBody1DateApproved,
                  awardingBody1ApprovalStatus,
                  awardingBody2,
                  awardingBody2Qualification,
                  awardingBody2Level,
                  awardingBody2DateApproved,
                  awardingBody2ApprovalStatus,
                  nsqCertifiedAssessors,
                  assessorsCount,
                  nsqCertifiedVerifiers,
                  verifiersCount,
                  nsqCertifiedCenterManager,
                  liaisonOfficerPresent,
                  resourceOfficerPresent,
                  siteBankPresent,
                  trainingRoomAdequate,
                  dataRoomAdequate,
                  workshopAdequate,
                  practicalSpaceAdequate,
                  techLabAdequate,
                  staffRoomAdequate,
                  toiletAdequate,
                  firstAidFireAdequate,
                  hostelsAdequate,
                  tradeToolsAdequate,
                  auditoriumAvailable,
                  libraryAdequate,
                  waterAndPowerAdequate,
                  mgtOfficesAdequate,
                  nosAvailable,
                  trainingManualAvailable,
                  nosAndManualAlignment,
                  otherTrainingMaterialsAvailable,
                  nsqGuidelineAvailable,
                  staffCompliantWithStandards,
                  objectivesClear,
                  classParticipation,
                  centerManagerName,
                  centerManagerQualification,
                  assessor1Name,
                  assessor1Qualification,
                  assessor2Name,
                  assessor2Qualification,
                  internalVerifierName,
                  internalVerifierQualification,
                  liaisonOfficerName,
                  liaisonOfficerQualification,
                  resourceOfficerName,
                  resourceOfficerQualification,
                  otherStaffName,
                  otherStaffQualification,
                  otherStaffDesignation,
                  trainingRoomQuantity,
                  trainingRoomFloorArea,
                  workshopQuantity,
                  workshopFloorArea,
                  practicalTrainingSpaceQuantity,
                  practicalTrainingSpaceFloorArea,
                  technicalLabQuantity,
                  technicalLabFloorArea,
                  recordsRoomQuantity,
                  recordsRoomFloorArea,
                  auditoriumQuantity,
                  auditoriumFloorArea,
                  libraryQuantity,
                  libraryFloorArea,
                  utilityQuantity,
                  utilityFloorArea,
                  mgtOfficesQuantity,
                  mgtOfficesFloorArea,
                  staffRoomQuantity,
                  staffRoomFloorArea,
                  toiletQuantity,
                  toiletFloorArea,
                  firstAidFireFacilityQuantity,
                  firstAidFireFacilityFloorArea,
                  hostelQuantity,
                  hostelFloorArea,
                  tradeToolsQuantity,
                  tradeToolsFloorArea,
                  otherFacilityName,
                  otherFacilityQuantity,
                  otherFacilityFloorArea,
                  trainingStaff1Name,
                  trainingStaff1Gender,
                  trainingStaff1Qualification,
                  trainingStaff1Experience,
                  trainingStaff1Specialization,
                  trainingStaff2Name,
                  trainingStaff2Gender,
                  trainingStaff2Qualification,
                  trainingStaff2Experience,
                  trainingStaff2Specialization,
                  trainingStaff3Name,
                  trainingStaff3Gender,
                  trainingStaff3Qualification,
                  trainingStaff3Experience,
                  trainingStaff3Specialization,
                  applicantsName,
                  applicantsPosition,
                  "centerCredentialsURL": response.data.filePath
              })
            }
        } catch (error) {
            console.error('Error uploading file:', error)
            setAlert(`Error uploading file: ${error.message}`)
        }
    }


    const options1 = Object.values(STATES).map(_state => (
        <MenuItem key={_state} value={_state}>{_state}</MenuItem>
    ))

    const options2 = Object.values(CENTERTYPES).map(centerType => (
        <MenuItem key={centerType} value={centerType}>
            {centerType}
        </MenuItem>
    ))

    const options3 = Object.entries(QUALIFICATIONS).map(([key, value]) => (
        <MenuItem key={key} value={key}>{value}</MenuItem>
    ))

    const options4 = Object.entries(QUALIFICATIONS).map(([key, value]) => (
        <MenuItem key={key} value={value}>{value}</MenuItem>
    ))

    const errContent = error?.data?.message ?? ''

    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
      setValue(newValue)
    }


    const content = (
        <>
            <DashHeader />
            <Header title="Centre Application" subtitle="Application/Approval Form for Training Providers under the NSQS for Construction Sector" />
            <Box sx={{
              m: isMobile ? "10% 8% 150px 8%" : "2% 2% 10% 2%"
            }}>

                <Box
                  sx={{
                    flexGrow: 1,
                    bgcolor: '#F1F3F1',
                    display: isMobile ? 'block' : 'flex',
                    "& .MuiTab-root.Mui-selected": {
                        color: '#0b1c08',
                        fontWeight: 700
                    },
                    "& .MuiTab-root": {
                        alignItems: 'flex-start',
                        color: '#295C21',
                        borderBottom: '1px solid #0b1c081a'
                    },
                    "& .MuiTabs-indicator": {
                        backgroundColor: '#49a139'
                    },
                    "& .MuiFormHelperText-root": {
                        color: '#49a139'
                    }
                  }}
                >
                    <Tabs
                      orientation={isMobile ? "horizontal" : "vertical"}
                      variant="scrollable"
                      allowScrollButtonsMobile
                      value={value}
                      onChange={handleChange}
                      aria-label="Vertical tabs"
                      sx={{ borderRight: "1px solid #D7D9D7", minWidth: isMobile ? '100%' : '280px', color:"#49a139" }}
                    >
                      <Tab label="Instructions" {...a11yProps(0)} />
                      <Tab label="A. CENTRE DETAILS" {...a11yProps(1)} />
                      <Tab label="B. YOUR ORGANISATION" {...a11yProps(2)} />
                      <Tab label="C. HUMAN & FINANCIAL RESOURCES" {...a11yProps(3)} />
                      <Tab label="D. PHYSICAL INFRASTRUCTURE" {...a11yProps(4)} />
                      <Tab label="E. CODE OF PRACTICE" {...a11yProps(5)} />
                      <Tab label="F. ORGANISATIONAL STAFFING" {...a11yProps(6)} />
                      <Tab label="G. PHYSICAL FACILITIES" {...a11yProps(7)} />
                      <Tab label="H. TRAINING STAFF" {...a11yProps(8)} />
                      <Tab label="I. SUPPORTING DOCUMENTS" {...a11yProps(9)} />
                    </Tabs>

                    <form className="form" onSubmit={onSaveCenterApplicationClicked}>
                      {/*Instructions*/}
                      <TabPanel value={value} index={0} sx={{ width: isMobile ? "100%" : "90%", paddingBottom:isMobile ? "20px" : "" }}>
                          <Box
                            sx={{
                              width: "100%",
                              height: isMobile ? "" : "470px",
                              overflowY: isMobile ? "none" : "scroll",
                              pr: 2
                            }}
                          >
                              <Typography sx={{color:'#295C21', fontSize:17, mb:3}}>
                                  APPLICATION / APPROVAL FORM INSTRUCTIONS
                              </Typography>

                              <Box sx={{maxWidth: isMobile ? "90%" : "78%"}}>
                                  <Typography sx={{color:'#0b1c08', fontSize:16, wordWrap: "break-word", mb:3}}>
                                      Please use this form to request approval as Training/Assessment Centre for NSQs
                                      Complete all sections in BLOCK LETTERS and give your details as you want them to
                                      appear in NIOB records.
                                  </Typography>
                                  <Typography sx={{color:'#49a139', fontSize:16, wordWrap: "break-word"}}>
                                      Read the following conditions which apply to this approval request, before you complete
                                      this form:
                                  </Typography>
                                  <List sx={{fontSize:14, wordWrap: "break-word"}}>
                                      <ListItem>
                                          1. You will be required to pay an inspection fee which will be invoiced to your Account.
                                      </ListItem>
                                      <ListItem>
                                          2. Payment for an inspection visit fee is required per venue/satellite Centre.
                                      </ListItem>
                                      <ListItem>
                                          3. Your Centre and Qualification(s) approval will lapse if there is no activity within one year
                                          of approval being granted.
                                      </ListItem>
                                      <ListItem>
                                          4. Failure to provide accurate information may result in withdrawal of any Approval granted earlier.
                                      </ListItem>
                                      <ListItem sx={{pb:0}}>
                                          5. You'll need the following documents to complete your application:
                                      </ListItem>
                                      <List sx={{ml:"20px"}}>
                                          <ListItem sx={{pt:0, pb:0}}>
                                              - Evidence of Legal Registration
                                          </ListItem>
                                          <ListItem sx={{pt:0, pb:0}}>
                                              - Centre Profile
                                          </ListItem>
                                          <ListItem sx={{pt:0, pb:0}}>
                                              - Proof of Payment (NGN 150,000.00)
                                          </ListItem>
                                          <ListItem sx={{ pt: 0, pb: 0 }}>
                                            - Completed Centre Manager Declaration Form
                                            (<a
                                              href="/2024-CENTRE APPLICATION Declaration FORM.pdf" // Adjusted path
                                              style={{ color: "#49a139", textDecoration: "underline" }}
                                              target="_blank"
                                              download="2024-CENTRE_APPLICATION_Declaration_FORM.pdf" // Added download attribute
                                            >
                                              <u>download the form here</u>
                                            </a>)
                                          </ListItem>
                                          <ListItem sx={{pt:0, pb:0}}>
                                              - MOU (Site Banks / Facility Usage)
                                          </ListItem>
                                          <ListItem sx={{pt:0, pb:0}}>
                                              - Training Staff CVs
                                          </ListItem>
                                          <ListItem sx={{pt:0, pb:0}}>
                                              - List of Equipment
                                          </ListItem>
                                      </List>
                                  </List>
                              </Box>

                              <Button
                                  onClick={() => {setValue(1)}}
                                  sx={{color:"#fff"}}
                                  variant="contained"
                              >Proceed to Apply</Button>

                          </Box>
                      </TabPanel>

                      {/*CENTER DETAILS*/}
                      <TabPanel value={value} index={1} sx={{ width: isMobile ? "100%" : "90%", paddingBottom:isMobile ? "20px" : "" }}>
                          <Box
                            sx={{
                              width: "100%",
                              height: isMobile ? "" : "470px",
                              overflowY: isMobile ? "none" : "scroll",
                              pr: 2
                            }}
                          >
                              <Typography sx={{color:'#295C21', fontSize:12, mb:3}}>CENTRE DETAILS</Typography>
                              <TextField
                                variant="outlined"
                                type="text"
                                label={<span>Centre Name <span style={{ color: 'red' }}>*</span></span>}
                                onChange={onCenterNameChanged}
                                value={centerName}
                                name="centerName"
                                sx={{ width: isMobile ? "100%" : "32%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  variant="outlined"
                                  label={<span>Year of Incorporation <span style={{ color: 'red' }}>*</span></span>}
                                  openTo="year"
                                  onChange={(newValue) => setYearOfInc(newValue)}
                                  value={yearOfInc ? yearOfInc : dayjs("")}
                                  views={['year']}
                                  maxDate={dayjs(maxYear)}
                                  minDate={dayjs("1960")}
                                  name="yearOfInc"
                                  sx={{ width: isMobile ? "100%" : "32%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                                />
                              </LocalizationProvider>

                              <TextField
                                variant="outlined"
                                type="text"
                                label={<span>CAC Number <span style={{ color: 'red' }}>*</span></span>}
                                onChange={onCACChanged}
                                value={cacNumber}
                                name="cacNumber"
                                sx={{ width: isMobile ? "100%" : "32%", mb:"30px" }}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">RC</InputAdornment>,
                                }}
                              />

                              <TextField
                                variant="outlined"
                                multiline
                                rows={3}
                                type="text"
                                label={<span>Permanent Centre Address <span style={{ color: 'red' }}>*</span></span>}
                                onChange={onPermanentCenterAddressChanged}
                                value={permanentCenterAddress}
                                name="permanentCenterAddress"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                multiline
                                rows={3}
                                type="text"
                                label="Training Centre Address"
                                onChange={onTrainingCenterAddressChanged}
                                value={trainingCenterAddress}
                                name="trainingCenterAddress"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"30px" }}
                              />

                              <TextField
                                variant="outlined"
                                type="text"
                                label={<span>Phone Number <span style={{ color: 'red' }}>*</span></span>}
                                onChange={onPhoneChanged}
                                value={phone}
                                name="phone"
                                sx={{ width: isMobile ? "100%" : "32%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">+234</InputAdornment>,
                                }}
                              />

                              <TextField
                                variant="outlined"
                                type="email"
                                label={<span>Email <span style={{ color: 'red' }}>*</span></span>}
                                onChange={onEmailChanged}
                                value={email}
                                name="email"
                                sx={{ width: isMobile ? "100%" : "32%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                type="text"
                                label="Website"
                                onChange={onWebsiteChanged}
                                value={website}
                                name="website"
                                sx={{ width: isMobile ? "100%" : "32%", mb:"30px" }}
                              />

                              <TextField
                                variant="outlined"
                                type="text"
                                label={<span>Centre Head Fullname <span style={{ color: 'red' }}>*</span></span>}
                                onChange={onCenterHeadChanged}
                                value={centerHead}
                                name="centerHead"
                                sx={{ width: isMobile ? "100%" : "32%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                type="text"
                                label={<span>Centre Head Phone Number <span style={{ color: 'red' }}>*</span></span>}
                                onChange={onCenterHeadPhoneChanged}
                                value={centerHeadPhone}
                                name="centerHeadPhone"
                                sx={{ width: isMobile ? "100%" : "32%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">+234</InputAdornment>,
                                }}
                              />

                              <TextField
                                variant="outlined"
                                type="email"
                                label="Centre Head Email"
                                onChange={onCenterHeadEmailChanged}
                                value={centerHeadEmail}
                                name="centerHeadEmail"
                                sx={{ width: isMobile ? "100%" : "32%", mb:"30px" }}
                              />

                              <FormControl sx={{ width: isMobile ? "100%" : "49%", mr: isMobile ? "0%" : "2%" }}>
                                <InputLabel id="qualifications-id">Qualifications <span style={{ color: 'red' }}>*</span></InputLabel>
                                <Select
                                  labelId="qualifications-id"
                                  variant="outlined"
                                  name="qualifications"
                                  value={qualifications}
                                  onChange={(e) => setQualifications(e.target.value)}
                                  multiple
                                >
                                  {options3}
                                </Select>
                              </FormControl>

                              <FormControl sx={{ width: isMobile ? "100%" : "49%" }}>
                                <InputLabel id="state-id">State <span style={{ color: 'red' }}>*</span></InputLabel>
                                <Select
                                  labelId="state-id"
                                  variant="outlined"
                                  name="stateOfRez"
                                  value={stateOfRez}
                                  onChange={(e) => setStateOfRez(e.target.value)}
                                >
                                  {options1}
                                </Select>
                              </FormControl>

                              <Stack direction="row" gap={2}>
                                  <IconButton
                                      sx={{bottom:"40px", color:"#0b1c08", top:"30px"}}
                                      onClick={() => {setValue(0)}}
                                      variant="contained"
                                  ><ArrowBackIosIcon /></IconButton>
                                  <IconButton
                                      sx={{bottom:"40px", right:"30px", color:"#0b1c08", top:"30px"}}
                                      onClick={() => {setValue(2)}}
                                      variant="contained"
                                  ><ArrowForwardIosIcon /></IconButton>
                              </Stack>
                          </Box>

                      </TabPanel>

                      {/*YOUR ORGANISATION*/}
                      <TabPanel value={value} index={2} sx={{ width: isMobile ? "100%" : "90%", paddingBottom:isMobile ? "20px" : "" }}>
                          <Box
                            sx={{
                              width: "100%",
                              height: isMobile ? "" : "470px",
                              overflowY: isMobile ? "none" : "scroll",
                              pr: 2
                            }}
                          >
                              <Typography sx={{color:'#295C21', fontSize:12, mb:3}}>YOUR ORGANISATION</Typography>

                              <FormControl sx={{width:"100%", mb:"30px"}}>
                                <InputLabel id="center-type-id">Centre Type <span style={{ color: 'red' }}>*</span></InputLabel>
                                <Select
                                  labelId="center-type-id"
                                  variant="outlined"
                                  value={centerType}
                                  name="centerType"
                                  onChange={(e) => setCenterType(e.target.value)}
                                >
                                  {options2}
                                </Select>
                              </FormControl>


                              <Typography sx={{width:"100%", mb:"10px"}}>Satellite Centres where Training is provided other than your Main Campus/Centre</Typography>
                              <TextField
                                variant="outlined"
                                type="text"
                                label="Satellite Centre Name 1"
                                onChange={onSatelliteCenterName1Changed}
                                value={satelliteCenterName1}
                                name="satelliteCenterName1"
                                sx={{ width: isMobile ? "100%" : "40%", mb:"15px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                multiline
                                type="text"
                                label="Satellite Centre Address 1"
                                onChange={onSatelliteCenterAddress1Changed}
                                value={satelliteCenterAddress1}
                                name="satelliteCenterAddress1"
                                sx={{ width: isMobile ? "100%" : "58%", mb:"15px" }}
                              />

                              <TextField
                                variant="outlined"
                                type="text"
                                label="Satellite Centre Name 2"
                                onChange={onSatelliteCenterName2Changed}
                                value={satelliteCenterName2}
                                name="satelliteCenterName2"
                                sx={{ width: isMobile ? "100%" : "40%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                multiline
                                type="text"
                                label="Satellite Centre Address 2"
                                onChange={onSatelliteCenterAddress2Changed}
                                value={satelliteCenterAddress2}
                                name="satelliteCenterAddress2"
                                sx={{ width: isMobile ? "100%" : "58%", mb:"30px" }}
                              />


                              <FormControl fullWidth sx={{ width: '100%', mb:"30px" }}>
                                <FormLabel component="legend" sx={{color:"#49a139"}}>Indicate if you are currently an approved Centre of another Awarding Body</FormLabel>
                                <FormGroup row>
                                  <FormControlLabel
                                    control={<Checkbox checked={itp} onChange={onOtherApprovalsChange} name="itp" sx={{color:"#49a139"}} />}
                                    label="ITP"
                                  />
                                  <FormControlLabel
                                    control={<Checkbox checked={naddc} onChange={onOtherApprovalsChange} name="naddc" sx={{color:"#49a139"}} />}
                                    label="NADDC"
                                  />
                                  <FormControlLabel
                                    control={<Checkbox checked={nabteb} onChange={onOtherApprovalsChange} name="nabteb" sx={{color:"#49a139"}} />}
                                    label="NABTEB"
                                  />
                                  <FormControlLabel
                                    control={<Checkbox checked={cityGuild} onChange={onOtherApprovalsChange} name="cityGuild" sx={{color:"#49a139"}} />}
                                    label="City & Guilds"
                                  />
                                </FormGroup>
                              </FormControl>


                              <Typography sx={{width:"100%", mb:"10px"}}>If your Centre is currently approved as a Centre for NSQ by another Awarding Body, please give details below:</Typography>
                              <TextField
                                variant="outlined"
                                type="text"
                                label="Awarding Body 1"
                                onChange={onAwardingBody1Changed}
                                value={awardingBody1}
                                name="awardingBody1"
                                sx={{ width: isMobile ? "100%" : "20%", mb:"15px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <FormControl sx={{ width: isMobile ? "100%" : "25%", mb:"15px", mr: isMobile ? "0%" : "2%" }}>
                                <InputLabel id="awardingBody1Qualification-id">Qualification 1</InputLabel>
                                <Select
                                  labelId="awardingBody1Qualification-id"
                                  variant="outlined"
                                  name="awardingBody1Qualification"
                                  value={awardingBody1Qualification}
                                  onChange={(e) => setAwardingBody1Qualification(e.target.value)}
                                >
                                  {options3}
                                </Select>
                              </FormControl>

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 1, max: 5 } }}
                                label="Level"
                                onChange={onAwardingBody1LevelChanged}
                                value={awardingBody1Level}
                                name="awardingBody1Level"
                                sx={{ width: isMobile ? "100%" : "10%", mb:"15px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  variant="outlined"
                                  label={'Date Approved'}
                                  onChange={(newValue) => setAwardingBody1DateApproved(newValue)}
                                  value={awardingBody1DateApproved}
                                  maxDate={dayjs(today)}
                                  name="awardingBody1DateApproved"
                                  sx={{ width: isMobile ? "100%" : "17%", mb:"15px", mr: isMobile ? "0%" : "2%" }}
                                />
                              </LocalizationProvider>

                              <FormControl sx={{ width: isMobile ? "100%" : "20%", mb:"15px"}}>
                                <InputLabel id="approvalStatus-id">Approval Status</InputLabel>
                                <Select
                                  labelId="approvalStatus-id"
                                  variant="outlined"
                                  name="awardingBody1ApprovalStatus"
                                  value={awardingBody1ApprovalStatus}
                                  onChange={(e) => setAwardingBody1ApprovalStatus(e.target.value)}
                                >
                                    <MenuItem value={"Done"}>Done</MenuItem>
                                    <MenuItem value={"Pending"}>Pending</MenuItem>
                                </Select>
                              </FormControl>

                              <TextField
                                variant="outlined"
                                type="text"
                                label="Awarding Body 2"
                                onChange={onAwardingBody2Changed}
                                value={awardingBody2}
                                name="awardingBody2"
                                sx={{ width: isMobile ? "100%" : "20%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <FormControl sx={{ width: isMobile ? "100%" : "25%", mb:"30px", mr: isMobile ? "0%" : "2%" }}>
                                <InputLabel id="awardingBody2Qualification-id">Qualification 2</InputLabel>
                                <Select
                                  labelId="awardingBody2Qualification-id"
                                  variant="outlined"
                                  name="awardingBody2Qualification"
                                  value={awardingBody2Qualification}
                                  onChange={(e) => setAwardingBody2Qualification(e.target.value)}
                                >
                                  {options3}
                                </Select>
                              </FormControl>

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 1, max: 5 } }}
                                label="Level"
                                onChange={onAwardingBody2LevelChanged}
                                value={awardingBody2Level}
                                name="awardingBody2Level"
                                sx={{ width: isMobile ? "100%" : "10%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  variant="outlined"
                                  label={'Date Approved'}
                                  onChange={(newValue) => setAwardingBody2DateApproved(newValue)}
                                  value={awardingBody2DateApproved}
                                  maxDate={dayjs(today)}
                                  name="awardingBody2DateApproved"
                                  sx={{ width: isMobile ? "100%" : "17%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                                />
                              </LocalizationProvider>

                              <FormControl sx={{ width: isMobile ? "100%" : "20%", mb:"30px"}}>
                                <InputLabel id="approvalStatus-id">Approval Status</InputLabel>
                                <Select
                                  labelId="approvalStatus-id"
                                  variant="outlined"
                                  name="awardingBody2ApprovalStatus"
                                  value={awardingBody2ApprovalStatus}
                                  onChange={(e) => setAwardingBody2ApprovalStatus(e.target.value)}
                                >
                                    <MenuItem value={"Done"}>Done</MenuItem>
                                    <MenuItem value={"Pending"}>Pending</MenuItem>
                                </Select>
                              </FormControl>

                              <Stack direction="row" gap={2}>
                                  <IconButton
                                      sx={{bottom:"40px", color:"#0b1c08", top:"30px"}}
                                      onClick={() => {setValue(1)}}
                                      variant="contained"
                                  ><ArrowBackIosIcon /></IconButton>
                                  <IconButton
                                      sx={{bottom:"40px", right:"30px", color:"#0b1c08", top:"30px"}}
                                      onClick={() => {setValue(3)}}
                                      variant="contained"
                                  ><ArrowForwardIosIcon /></IconButton>
                              </Stack>
                          </Box>
                      </TabPanel>

                      {/*HUMAN & FINANCIAL RESOURCES*/}
                      <TabPanel value={value} index={3} sx={{ width: isMobile ? "100%" : "90%", paddingBottom:isMobile ? "20px" : "" }}>
                          <Box
                            sx={{
                              width: "100%",
                              height: isMobile ? "" : "470px",
                              overflowY: isMobile ? "none" : "scroll",
                              pr: 2
                            }}
                          >
                              <Typography sx={{color:'#295C21', fontSize:12, mb:3}}>HUMAN & FINANCIAL RESOURCES</Typography>

                              <Box sx={{minWidth:isMobile ? "100%" : "960px"}}>
                                  <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                      <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                          i.{"\u00A0"} Are there NSQ certified Assessors at your Centre? <span style={{ color: 'red' }}>*</span>
                                      </Typography>
                                      <FormControl sx={{width:isMobile ? "60%" : "40%", mb:"30px"}}>
                                        <InputLabel id="nsqCertifiedAssessor-id">Select if in place</InputLabel>
                                        <Select
                                          labelId="nsqCertifiedAssessor-id"
                                          variant="outlined"
                                          value={nsqCertifiedAssessors}
                                          name="nsqCertifiedAssessors"
                                          onChange={(e) => {setNsqCertifiedAssessors(e.target.value)}}
                                        >
                                            <MenuItem value={"YES"}>YES</MenuItem>
                                            <MenuItem value={"NO"}>NO</MenuItem>
                                        </Select>
                                      </FormControl>
                                  </Stack>

                                  <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                      <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                          ii.{"\u00A0"} How many Assessors are available at Centre? <span style={{ color: 'red' }}>*</span>
                                      </Typography>
                                      <TextField
                                        variant="outlined"
                                        type="number"
                                        InputProps={{ inputProps: { min: 0 } }}
                                        label="Assessors Count"
                                        value={assessorsCount}
                                        name="assessorsCount"
                                        onChange={onAssessorsCountChanged}
                                        sx={{ width: isMobile ? "60%" : "40%", mb:"30px"}}
                                      />
                                  </Stack>

                                  <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                      <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                          iii.{"\u00A0"} Are there NSQ certified Verifiers at your Centre? <span style={{ color: 'red' }}>*</span>
                                      </Typography>
                                      <FormControl sx={{width:isMobile ? "60%" : "40%", mb:"30px"}}>
                                        <InputLabel id="nsqCertifiedVerifier-id">Select if in place</InputLabel>
                                        <Select
                                          labelId="nsqCertifiedVerifier-id"
                                          variant="outlined"
                                          value={nsqCertifiedVerifiers}
                                          name="nsqCertifiedVerifiers"
                                          onChange={(e) => {setNsqCertifiedVerifiers(e.target.value)}}
                                        >
                                            <MenuItem value={"YES"}>YES</MenuItem>
                                            <MenuItem value={"NO"}>NO</MenuItem>
                                        </Select>
                                      </FormControl>
                                  </Stack>

                                  <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                      <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                          iv.{"\u00A0"} How many Verifiers are available at Centre? <span style={{ color: 'red' }}>*</span>
                                      </Typography>
                                      <TextField
                                        variant="outlined"
                                        type="number"
                                        InputProps={{ inputProps: { min: 0 } }}
                                        label="Verifiers Count"
                                        value={verifiersCount}
                                        name="verifiersCount"
                                        onChange={onVerifiersCountChanged}
                                        sx={{ width: isMobile ? "60%" : "40%", mb:"30px"}}
                                      />
                                  </Stack>

                                  <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                      <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                          v.{"\u00A0"} Is the Centre Manager NSQ certified? <span style={{ color: 'red' }}>*</span>
                                      </Typography>
                                      <FormControl sx={{width:isMobile ? "60%" : "40%", mb:"30px"}}>
                                        <InputLabel id="nsqCertifiedCenterManager-id">Select if in place</InputLabel>
                                        <Select
                                          labelId="nsqCertifiedCenterManager-id"
                                          variant="outlined"
                                          value={nsqCertifiedCenterManager}
                                          name="nsqCertifiedCenterManager"
                                          onChange={(e) => {setNsqCertifiedCenterManager(e.target.value)}}
                                        >
                                            <MenuItem value={"YES"}>YES</MenuItem>
                                            <MenuItem value={"NO"}>NO</MenuItem>
                                        </Select>
                                      </FormControl>
                                  </Stack>

                                  <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                      <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                          vi.{"\u00A0"} Is there a Liaison Officer with the Industry at your Centre? <span style={{ color: 'red' }}>*</span>
                                      </Typography>
                                      <FormControl sx={{width:isMobile ? "60%" : "40%", mb:"30px"}}>
                                        <InputLabel id="liaisonOfficerPresent-id">Select if in place</InputLabel>
                                        <Select
                                          labelId="liaisonOfficerPresent-id"
                                          variant="outlined"
                                          value={liaisonOfficerPresent}
                                          name="liaisonOfficerPresent"
                                          onChange={(e) => {setLiaisonOfficerPresent(e.target.value)}}
                                        >
                                            <MenuItem value={"YES"}>YES</MenuItem>
                                            <MenuItem value={"NO"}>NO</MenuItem>
                                        </Select>
                                      </FormControl>
                                  </Stack>

                                  <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                      <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                          vii.{"\u00A0"} Is there a Resource/Data Officer at your Centre? <span style={{ color: 'red' }}>*</span>
                                      </Typography>
                                      <FormControl sx={{width:isMobile ? "60%" : "40%", mb:"30px"}}>
                                        <InputLabel id="resourceOfficerPresent-id">Select if in place</InputLabel>
                                        <Select
                                          labelId="resourceOfficerPresent-id"
                                          variant="outlined"
                                          value={resourceOfficerPresent}
                                          name="resourceOfficerPresent"
                                          onChange={(e) => {setResourceOfficerPresent(e.target.value)}}
                                        >
                                            <MenuItem value={"YES"}>YES</MenuItem>
                                            <MenuItem value={"NO"}>NO</MenuItem>
                                        </Select>
                                      </FormControl>
                                  </Stack>

                                  <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                      <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                          viii.{"\u00A0"} Are there evidences/records of site-banks for apprenticeship training at your Centre? <span style={{ color: 'red' }}>*</span>
                                      </Typography>
                                      <FormControl sx={{width:isMobile ? "60%" : "40%", mb:"30px"}}>
                                        <InputLabel id="siteBankPresent-id">Select if in place</InputLabel>
                                        <Select
                                          labelId="siteBankPresent-id"
                                          variant="outlined"
                                          value={siteBankPresent}
                                          name="siteBankPresent"
                                          onChange={(e) => {setSiteBankPresent(e.target.value)}}
                                        >
                                            <MenuItem value={"YES"}>YES</MenuItem>
                                            <MenuItem value={"NO"}>NO</MenuItem>
                                        </Select>
                                      </FormControl>
                                  </Stack>

                                  <Stack direction="row" gap={2}>
                                      <IconButton
                                          sx={{bottom:"40px", color:"#0b1c08", top:"30px"}}
                                          onClick={() => {setValue(2)}}
                                          variant="contained"
                                      ><ArrowBackIosIcon /></IconButton>
                                      <IconButton
                                          sx={{bottom:"40px", right:"30px", color:"#0b1c08", top:"30px"}}
                                          onClick={() => {setValue(4)}}
                                          variant="contained"
                                      ><ArrowForwardIosIcon /></IconButton>
                                  </Stack>
                              </Box>
                          </Box>
                      </TabPanel>

                      {/*PHYSICAL INFRASTRUCTURE*/}
                      <TabPanel value={value} index={4} sx={{ width: isMobile ? "100%" : "90%", paddingBottom:isMobile ? "20px" : "" }}>
                          <Box
                            sx={{
                              width: "100%",
                              height: isMobile ? "" : "470px",
                              overflowY: isMobile ? "none" : "scroll",
                              pr: 2
                            }}
                          >
                              <Typography sx={{color:'#295C21', fontSize:12, mb:3}}>PHYSICAL INFRASTRUCTURE</Typography>

                              <Box sx={{minWidth:isMobile ? "100%" : "960px"}}>
                                  <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                      <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                          ix.{"\u00A0"} Are the Classrooms/Training Rooms adequate in terms of space, lighting, ventilation & traffic? <span style={{ color: 'red' }}>*</span>
                                      </Typography>
                                      <FormControl sx={{width:isMobile ? "60%" : "40%", mb:"30px"}}>
                                        <InputLabel id="trainingRoomAdequate-id">Select if in place</InputLabel>
                                        <Select
                                          labelId="trainingRoomAdequate-id"
                                          variant="outlined"
                                          value={trainingRoomAdequate}
                                          name="trainingRoomAdequate"
                                          onChange={(e) => {setTrainingRoomAdequate(e.target.value)}}
                                        >
                                            <MenuItem value={"YES"}>YES</MenuItem>
                                            <MenuItem value={"NO"}>NO</MenuItem>
                                        </Select>
                                      </FormControl>
                                  </Stack>

                                  <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                      <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                          x.{"\u00A0"} Is there a Data/Records room available and adequate? <span style={{ color: 'red' }}>*</span>
                                      </Typography>
                                      <FormControl sx={{width:isMobile ? "60%" : "40%", mb:"30px"}}>
                                        <InputLabel id="dataRoomAdequate-id">Select if in place</InputLabel>
                                        <Select
                                          labelId="dataRoomAdequate-id"
                                          variant="outlined"
                                          value={dataRoomAdequate}
                                          name="dataRoomAdequate"
                                          onChange={(e) => {setDataRoomAdequate(e.target.value)}}
                                        >
                                            <MenuItem value={"YES"}>YES</MenuItem>
                                            <MenuItem value={"NO"}>NO</MenuItem>
                                        </Select>
                                      </FormControl>
                                  </Stack>

                                  <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                      <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                          xi.{"\u00A0"} Are Workshop spaces adequate and fit for purpose? <span style={{ color: 'red' }}>*</span>
                                      </Typography>
                                      <FormControl sx={{width:isMobile ? "60%" : "40%", mb:"30px"}}>
                                        <InputLabel id="workshopAdequate-id">Select if in place</InputLabel>
                                        <Select
                                          labelId="workshopAdequate-id"
                                          variant="outlined"
                                          value={workshopAdequate}
                                          name="workshopAdequate"
                                          onChange={(e) => {setWorkshopAdequate(e.target.value)}}
                                        >
                                            <MenuItem value={"YES"}>YES</MenuItem>
                                            <MenuItem value={"NO"}>NO</MenuItem>
                                        </Select>
                                      </FormControl>
                                  </Stack>

                                  <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                      <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                          xii.{"\u00A0"} Are Practical training spaces available and suitable for each trade? <span style={{ color: 'red' }}>*</span>
                                      </Typography>
                                      <FormControl sx={{width:isMobile ? "60%" : "40%", mb:"30px"}}>
                                        <InputLabel id="practicalSpaceAdequate-id">Select if in place</InputLabel>
                                        <Select
                                          labelId="practicalSpaceAdequate-id"
                                          variant="outlined"
                                          value={practicalSpaceAdequate}
                                          name="practicalSpaceAdequate"
                                          onChange={(e) => {setPracticalSpaceAdequate(e.target.value)}}
                                        >
                                            <MenuItem value={"YES"}>YES</MenuItem>
                                            <MenuItem value={"NO"}>NO</MenuItem>
                                        </Select>
                                      </FormControl>
                                  </Stack>

                                  <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                      <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                          xiii.{"\u00A0"} Are Technical laboratories AVAILABLE or HIRED? <span style={{ color: 'red' }}>*</span>
                                      </Typography>
                                      <FormControl sx={{width:isMobile ? "60%" : "40%", mb:"30px"}}>
                                        <InputLabel id="techLabAdequate-id">Select if in place</InputLabel>
                                        <Select
                                          labelId="techLabAdequate-id"
                                          variant="outlined"
                                          value={techLabAdequate}
                                          name="techLabAdequate"
                                          onChange={(e) => {setTechLabAdequate(e.target.value)}}
                                        >
                                            <MenuItem value={"AVAILABLE"}>AVAILABLE</MenuItem>
                                            <MenuItem value={"HIRED"}>HIRED</MenuItem>
                                        </Select>
                                      </FormControl>
                                  </Stack>

                                  <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                      <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                          xiv.{"\u00A0"} Are Staff rooms adequate and fit for purpose? <span style={{ color: 'red' }}>*</span>
                                      </Typography>
                                      <FormControl sx={{width:isMobile ? "60%" : "40%", mb:"30px"}}>
                                        <InputLabel id="staffRoomAdequate-id">Select if in place</InputLabel>
                                        <Select
                                          labelId="staffRoomAdequate-id"
                                          variant="outlined"
                                          value={staffRoomAdequate}
                                          name="staffRoomAdequate"
                                          onChange={(e) => {setStaffRoomAdequate(e.target.value)}}
                                        >
                                            <MenuItem value={"YES"}>YES</MenuItem>
                                            <MenuItem value={"NO"}>NO</MenuItem>
                                        </Select>
                                      </FormControl>
                                  </Stack>

                                  <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                      <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                          xv.{"\u00A0"} Are Toilets available and functional? <span style={{ color: 'red' }}>*</span>
                                      </Typography>
                                      <FormControl sx={{width:isMobile ? "60%" : "40%", mb:"30px"}}>
                                        <InputLabel id="toiletAdequate-id">Select if in place</InputLabel>
                                        <Select
                                          labelId="toiletAdequate-id"
                                          variant="outlined"
                                          value={toiletAdequate}
                                          name="toiletAdequate"
                                          onChange={(e) => {setToiletAdequate(e.target.value)}}
                                        >
                                            <MenuItem value={"YES"}>YES</MenuItem>
                                            <MenuItem value={"NO"}>NO</MenuItem>
                                        </Select>
                                      </FormControl>
                                  </Stack>

                                  <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                      <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                          xvi.{"\u00A0"} Are First Aid/Firefighting facilities available and functional? <span style={{ color: 'red' }}>*</span>
                                      </Typography>
                                      <FormControl sx={{width:isMobile ? "60%" : "40%", mb:"30px"}}>
                                        <InputLabel id="firstAidFireAdequate-id">Select if in place</InputLabel>
                                        <Select
                                          labelId="firstAidFireAdequate-id"
                                          variant="outlined"
                                          value={firstAidFireAdequate}
                                          name="firstAidFireAdequate"
                                          onChange={(e) => {setFirstAidFireAdequate(e.target.value)}}
                                        >
                                            <MenuItem value={"YES"}>YES</MenuItem>
                                            <MenuItem value={"NO"}>NO</MenuItem>
                                        </Select>
                                      </FormControl>
                                  </Stack>

                                  <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                      <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                          xvii.{"\u00A0"} Are Hostels available and fit for purpose? <span style={{ color: 'red' }}>*</span>
                                      </Typography>
                                      <FormControl sx={{width:isMobile ? "60%" : "40%", mb:"30px"}}>
                                        <InputLabel id="hostelsAdequate-id">Select if in place</InputLabel>
                                        <Select
                                          labelId="hostelsAdequate-id"
                                          variant="outlined"
                                          value={hostelsAdequate}
                                          name="hostelsAdequate"
                                          onChange={(e) => {setHostelsAdequate(e.target.value)}}
                                        >
                                            <MenuItem value={"YES"}>YES</MenuItem>
                                            <MenuItem value={"NO"}>NO</MenuItem>
                                        </Select>
                                      </FormControl>
                                  </Stack>

                                  <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                      <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                          xviii.{"\u00A0"} Are there Tools and Equipment for relevant trades at your Centre? <span style={{ color: 'red' }}>*</span>
                                      </Typography>
                                      <FormControl sx={{width:isMobile ? "60%" : "40%", mb:"30px"}}>
                                        <InputLabel id="tradeToolsAdequate-id">Select if in place</InputLabel>
                                        <Select
                                          labelId="tradeToolsAdequate-id"
                                          variant="outlined"
                                          value={tradeToolsAdequate}
                                          name="tradeToolsAdequate"
                                          onChange={(e) => {setTradeToolsAdequate(e.target.value)}}
                                        >
                                            <MenuItem value={"YES"}>YES</MenuItem>
                                            <MenuItem value={"NO"}>NO</MenuItem>
                                        </Select>
                                      </FormControl>
                                  </Stack>

                                  <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                      <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                          xix.{"\u00A0"} Is Auditorium AVAILABLE or HIRED? <span style={{ color: 'red' }}>*</span>
                                      </Typography>
                                      <FormControl sx={{width:isMobile ? "60%" : "40%", mb:"30px"}}>
                                        <InputLabel id="auditoriumAvailable-id">Select if in place</InputLabel>
                                        <Select
                                          labelId="auditoriumAvailable-id"
                                          variant="outlined"
                                          value={auditoriumAvailable}
                                          name="auditoriumAvailable"
                                          onChange={(e) => {setAuditoriumAvailable(e.target.value)}}
                                        >
                                            <MenuItem value={"AVAILABLE"}>AVAILABLE</MenuItem>
                                            <MenuItem value={"HIRED"}>HIRED</MenuItem>
                                        </Select>
                                      </FormControl>
                                  </Stack>

                                  <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                      <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                          xx.{"\u00A0"} Are Library and Study rooms adequate? <span style={{ color: 'red' }}>*</span>
                                      </Typography>
                                      <FormControl sx={{width:isMobile ? "60%" : "40%", mb:"30px"}}>
                                        <InputLabel id="libraryAdequate-id">Select if in place</InputLabel>
                                        <Select
                                          labelId="libraryAdequate-id"
                                          variant="outlined"
                                          value={libraryAdequate}
                                          name="libraryAdequate"
                                          onChange={(e) => {setLibraryAdequate(e.target.value)}}
                                        >
                                            <MenuItem value={"YES"}>YES</MenuItem>
                                            <MenuItem value={"NO"}>NO</MenuItem>
                                        </Select>
                                      </FormControl>
                                  </Stack>

                                  <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                      <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                          xxi.{"\u00A0"} Is Water and Power supply adequate? <span style={{ color: 'red' }}>*</span>
                                      </Typography>
                                      <FormControl sx={{width:isMobile ? "60%" : "40%", mb:"30px"}}>
                                        <InputLabel id="waterAndPowerAdequate-id">Select if in place</InputLabel>
                                        <Select
                                          labelId="waterAndPowerAdequate-id"
                                          variant="outlined"
                                          value={waterAndPowerAdequate}
                                          name="waterAndPowerAdequate"
                                          onChange={(e) => {setWaterAndPowerAdequate(e.target.value)}}
                                        >
                                            <MenuItem value={"YES"}>YES</MenuItem>
                                            <MenuItem value={"NO"}>NO</MenuItem>
                                        </Select>
                                      </FormControl>
                                  </Stack>

                                  <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                      <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                          xxii.{"\u00A0"} Are Management Offices adequate and fit for purpose? <span style={{ color: 'red' }}>*</span>
                                      </Typography>
                                      <FormControl sx={{width:isMobile ? "60%" : "40%", mb:"30px"}}>
                                        <InputLabel id="mgtOfficesAdequate-id">Select if in place</InputLabel>
                                        <Select
                                          labelId="mgtOfficesAdequate-id"
                                          variant="outlined"
                                          value={mgtOfficesAdequate}
                                          name="mgtOfficesAdequate"
                                          onChange={(e) => {setMgtOfficesAdequate(e.target.value)}}
                                        >
                                            <MenuItem value={"YES"}>YES</MenuItem>
                                            <MenuItem value={"NO"}>NO</MenuItem>
                                        </Select>
                                      </FormControl>
                                  </Stack>

                                  <Stack direction="row" gap={2}>
                                      <IconButton
                                          sx={{bottom:"40px", color:"#0b1c08", top:"30px"}}
                                          onClick={() => {setValue(3)}}
                                          variant="contained"
                                      ><ArrowBackIosIcon /></IconButton>
                                      <IconButton
                                          sx={{bottom:"40px", right:"30px", color:"#0b1c08", top:"30px"}}
                                          onClick={() => {setValue(5)}}
                                          variant="contained"
                                      ><ArrowForwardIosIcon /></IconButton>
                                  </Stack>
                              </Box>
                          </Box>
                      </TabPanel>

                      {/*CODE OF PRACTICE*/}
                      <TabPanel value={value} index={5} sx={{ width: isMobile ? "100%" : "90%", paddingBottom:isMobile ? "20px" : "" }}>
                          <Box
                            sx={{
                              width: "100%",
                              height: isMobile ? "" : "470px",
                              overflowY: isMobile ? "none" : "scroll",
                              pr: 2
                            }}
                          >
                              <Typography sx={{color:'#295C21', fontSize:12, mb:3}}>CODE OF PRACTICE</Typography>

                              <Box sx={{minWidth:isMobile ? "100%" : "960px"}}>
                                  <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                      <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                          xxiii.{"\u00A0"} Is NOS available at your Centre? <span style={{ color: 'red' }}>*</span>
                                      </Typography>
                                      <FormControl sx={{width:isMobile ? "60%" : "40%", mb:"30px"}}>
                                        <InputLabel id="nosAvailable-id">Select if in place</InputLabel>
                                        <Select
                                          labelId="nosAvailable-id"
                                          variant="outlined"
                                          value={nosAvailable}
                                          name="nosAvailable"
                                          onChange={(e) => {setNosAvailable(e.target.value)}}
                                        >
                                            <MenuItem value={"YES"}>YES</MenuItem>
                                            <MenuItem value={"NO"}>NO</MenuItem>
                                        </Select>
                                      </FormControl>
                                  </Stack>

                                  <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                      <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                          xxiv.{"\u00A0"} Are there Training Manuals at your Centre? <span style={{ color: 'red' }}>*</span>
                                      </Typography>
                                      <FormControl sx={{width:isMobile ? "60%" : "40%", mb:"30px"}}>
                                        <InputLabel id="trainingManualAvailable-id">Select if in place</InputLabel>
                                        <Select
                                          labelId="trainingManualAvailable-id"
                                          variant="outlined"
                                          value={trainingManualAvailable}
                                          name="trainingManualAvailable"
                                          onChange={(e) => {setTrainingManualAvailable(e.target.value)}}
                                        >
                                            <MenuItem value={"YES"}>YES</MenuItem>
                                            <MenuItem value={"NO"}>NO</MenuItem>
                                        </Select>
                                      </FormControl>
                                  </Stack>

                                  <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                      <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                          xxv.{"\u00A0"} Are the Manuals aligned to the NOS? <span style={{ color: 'red' }}>*</span>
                                      </Typography>
                                      <FormControl sx={{width:isMobile ? "60%" : "40%", mb:"30px"}}>
                                        <InputLabel id="nosAndManualAlignment-id">Select if in place</InputLabel>
                                        <Select
                                          labelId="nosAndManualAlignment-id"
                                          variant="outlined"
                                          value={nosAndManualAlignment}
                                          name="nosAndManualAlignment"
                                          onChange={(e) => {setNosAndManualAlignment(e.target.value)}}
                                        >
                                            <MenuItem value={"YES"}>YES</MenuItem>
                                            <MenuItem value={"NO"}>NO</MenuItem>
                                        </Select>
                                      </FormControl>
                                  </Stack>

                                  <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                      <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                          xxvi.{"\u00A0"} Are there other Training materials that are relevant to the training needs? <span style={{ color: 'red' }}>*</span>
                                      </Typography>
                                      <FormControl sx={{width:isMobile ? "60%" : "40%", mb:"30px"}}>
                                        <InputLabel id="otherTrainingMaterialsAvailable-id">Select if in place</InputLabel>
                                        <Select
                                          labelId="otherTrainingMaterialsAvailable-id"
                                          variant="outlined"
                                          value={otherTrainingMaterialsAvailable}
                                          name="otherTrainingMaterialsAvailable"
                                          onChange={(e) => {setOtherTrainingMaterialsAvailable(e.target.value)}}
                                        >
                                            <MenuItem value={"YES"}>YES</MenuItem>
                                            <MenuItem value={"NO"}>NO</MenuItem>
                                        </Select>
                                      </FormControl>
                                  </Stack>

                                  <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                      <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                          xxvii.{"\u00A0"} Is there an NSQ Code of Practice and Implementation Guideline at your Centre? <span style={{ color: 'red' }}>*</span>
                                      </Typography>
                                      <FormControl sx={{width:isMobile ? "60%" : "40%", mb:"30px"}}>
                                        <InputLabel id="nsqGuidelineAvailable-id">Select if in place</InputLabel>
                                        <Select
                                          labelId="nsqGuidelineAvailable-id"
                                          variant="outlined"
                                          value={nsqGuidelineAvailable}
                                          name="nsqGuidelineAvailable"
                                          onChange={(e) => {setNsqGuidelineAvailable(e.target.value)}}
                                        >
                                            <MenuItem value={"YES"}>YES</MenuItem>
                                            <MenuItem value={"NO"}>NO</MenuItem>
                                        </Select>
                                      </FormControl>
                                  </Stack>

                                  <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                      <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                          xxviii.{"\u00A0"} Are the Trainers and Staff conversant/comply with these documents? <span style={{ color: 'red' }}>*</span>
                                      </Typography>
                                      <FormControl sx={{width:isMobile ? "60%" : "40%", mb:"30px"}}>
                                        <InputLabel id="staffCompliantWithStandards-id">Select if in place</InputLabel>
                                        <Select
                                          labelId="staffCompliantWithStandards-id"
                                          variant="outlined"
                                          value={staffCompliantWithStandards}
                                          name="staffCompliantWithStandards"
                                          onChange={(e) => {setStaffCompliantWithStandards(e.target.value)}}
                                        >
                                            <MenuItem value={"YES"}>YES</MenuItem>
                                            <MenuItem value={"NO"}>NO</MenuItem>
                                        </Select>
                                      </FormControl>
                                  </Stack>

                                  <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                      <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                          xxix.{"\u00A0"} Are the Objectives of the training clearly defined? <span style={{ color: 'red' }}>*</span>
                                      </Typography>
                                      <FormControl sx={{width:isMobile ? "60%" : "40%", mb:"30px"}}>
                                        <InputLabel id="objectivesClear-id">Select if in place</InputLabel>
                                        <Select
                                          labelId="objectivesClear-id"
                                          variant="outlined"
                                          value={objectivesClear}
                                          name="objectivesClear"
                                          onChange={(e) => {setObjectivesClear(e.target.value)}}
                                        >
                                            <MenuItem value={"YES"}>YES</MenuItem>
                                            <MenuItem value={"NO"}>NO</MenuItem>
                                        </Select>
                                      </FormControl>
                                  </Stack>

                                  <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                      <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                          xxx.{"\u00A0"} Is Class participation through questioning encouraged? <span style={{ color: 'red' }}>*</span>
                                      </Typography>
                                      <FormControl sx={{width:isMobile ? "60%" : "40%", mb:"30px"}}>
                                        <InputLabel id="classParticipation-id">Select if in place</InputLabel>
                                        <Select
                                          labelId="classParticipation-id"
                                          variant="outlined"
                                          value={classParticipation}
                                          name="classParticipation"
                                          onChange={(e) => {setClassParticipation(e.target.value)}}
                                        >
                                            <MenuItem value={"YES"}>YES</MenuItem>
                                            <MenuItem value={"NO"}>NO</MenuItem>
                                        </Select>
                                      </FormControl>
                                  </Stack>

                                  <Stack direction="row" gap={2}>
                                      <IconButton
                                          sx={{bottom:"40px", color:"#0b1c08", top:"30px"}}
                                          onClick={() => {setValue(4)}}
                                          variant="contained"
                                      ><ArrowBackIosIcon /></IconButton>
                                      <IconButton
                                          sx={{bottom:"40px", right:"30px", color:"#0b1c08", top:"30px"}}
                                          onClick={() => {setValue(6)}}
                                          variant="contained"
                                      ><ArrowForwardIosIcon /></IconButton>
                                  </Stack>
                              </Box>
                          </Box>
                      </TabPanel>

                      {/*ORGANISATIONAL STAFFING*/}
                      <TabPanel value={value} index={6} sx={{ width: isMobile ? "100%" : "90%", paddingBottom:isMobile ? "20px" : "" }}>
                          <Box
                            sx={{
                              width: "100%",
                              height: isMobile ? "" : "470px",
                              overflowY: isMobile ? "none" : "scroll",
                              pr: 2
                            }}
                          >
                              <Typography sx={{color:'#295C21', fontSize:12, mb:3}}>ORGANISATIONAL STAFFING</Typography>

                              <TextField
                                variant="outlined"
                                type="text"
                                label={<span>Centre Manager's FullName <span style={{ color: 'red' }}>*</span></span>}
                                onChange={onCenterManagerNameChanged}
                                value={centerManagerName}
                                name="centerManagerName"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                type="text"
                                label={<span>Centre Manager's Qualification <span style={{ color: 'red' }}>*</span></span>}
                                onChange={onCenterManagerQualificationChanged}
                                value={centerManagerQualification}
                                name="centerManagerQualification"
                                helperText="e.g: BSc. Building, HND Marketing, etc."
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                              />

                              <TextField
                                variant="outlined"
                                type="text"
                                label={<span>Assessor 1's FullName <span style={{ color: 'red' }}>*</span></span>}
                                onChange={onAssessor1NameChanged}
                                value={assessor1Name}
                                name="assessor1Name"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                type="text"
                                label={<span>Assessor 1's Qualification <span style={{ color: 'red' }}>*</span></span>}
                                onChange={onAssessor1QualificationChanged}
                                value={assessor1Qualification}
                                name="assessor1Qualification"
                                helperText="e.g: BSc. Building, HND Marketing, etc."
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                              />

                              <TextField
                                variant="outlined"
                                type="text"
                                label="Assessor 2's FullName"
                                onChange={onAssessor2NameChanged}
                                value={assessor2Name}
                                name="assessor2Name"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                type="text"
                                label="Assessor 2's Qualification"
                                onChange={onAssessor2QualificationChanged}
                                value={assessor2Qualification}
                                name="assessor2Qualification"
                                helperText="e.g: BSc. Building, HND Marketing, etc."
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                              />

                              <TextField
                                variant="outlined"
                                type="text"
                                label={<span>Internal Verifier's FullName <span style={{ color: 'red' }}>*</span></span>}
                                onChange={onInternalVerifierNameChanged}
                                value={internalVerifierName}
                                name="internalVerifierName"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                type="text"
                                label={<span>Internal Verifier's Qualification <span style={{ color: 'red' }}>*</span></span>}
                                onChange={onInternalVerifierQualificationChanged}
                                value={internalVerifierQualification}
                                name="internalVerifierQualification"
                                helperText="e.g: BSc. Building, HND Marketing, etc."
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                              />

                              <TextField
                                variant="outlined"
                                type="text"
                                label="Industry/Workplace Liaison Officer's FullName"
                                onChange={onLiaisonOfficerNameChanged}
                                value={liaisonOfficerName}
                                name="liaisonOfficerName"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                type="text"
                                label="Industry/Workplace Liaison Officer's Qualification"
                                onChange={onLiaisonOfficerQualificationChanged}
                                value={liaisonOfficerQualification}
                                name="liaisonOfficerQualification"
                                helperText="e.g: BSc. Building, HND Marketing, etc."
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                              />

                              <TextField
                                variant="outlined"
                                type="text"
                                label="Resource/Data Officer's FullName"
                                onChange={onResourceOfficerNameChanged}
                                value={resourceOfficerName}
                                name="resourceOfficerName"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                type="text"
                                label="Resource/Data Officer's Qualification"
                                onChange={onResourceOfficerQualificationChanged}
                                value={resourceOfficerQualification}
                                name="resourceOfficerQualification"
                                helperText="e.g: BSc. Building, HND Marketing, etc."
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                              />

                              <TextField
                                variant="outlined"
                                type="text"
                                label="Other Staff's FullName"
                                onChange={onOtherStaffNameChanged}
                                value={otherStaffName}
                                name="otherStaffName"
                                sx={{ width: isMobile ? "100%" : "32%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                type="text"
                                label="Other Staff's Qualification"
                                onChange={onOtherStaffQualificationChanged}
                                value={otherStaffQualification}
                                name="otherStaffQualification"
                                helperText="e.g: BSc. Building, HND Marketing, etc."
                                sx={{ width: isMobile ? "100%" : "32%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                type="text"
                                label="Other Staff's Designation"
                                onChange={onOtherStaffDesignationChanged}
                                value={otherStaffDesignation}
                                name="otherStaffDesignation"
                                helperText="e.g: Program Officer, HOD Admin, etc."
                                sx={{ width: isMobile ? "100%" : "32%", mb:"10px" }}
                              />

                              <Stack direction="row" gap={2}>
                                  <IconButton
                                      sx={{bottom:"40px", color:"#0b1c08", top:"30px"}}
                                      onClick={() => {setValue(5)}}
                                      variant="contained"
                                  ><ArrowBackIosIcon /></IconButton>
                                  <IconButton
                                      sx={{bottom:"40px", right:"30px", color:"#0b1c08", top:"30px"}}
                                      onClick={() => {setValue(7)}}
                                      variant="contained"
                                  ><ArrowForwardIosIcon /></IconButton>
                              </Stack>
                          </Box>
                      </TabPanel>

                      {/*PHYSICAL FACILITIES*/}
                      <TabPanel value={value} index={7} sx={{ width: isMobile ? "100%" : "90%", paddingBottom:isMobile ? "20px" : "" }}>
                          <Box
                            sx={{
                              width: "100%",
                              height: isMobile ? "" : "470px",
                              overflowY: isMobile ? "none" : "scroll",
                              pr: 2
                            }}
                          >
                              <Typography sx={{color:'#295C21', fontSize:12, mb: 3}}>PHYSICAL FACILITIES</Typography>

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Classrooms/Training Rooms - Quantity"
                                onChange={onTrainingRoomQuantityChanged}
                                value={trainingRoomQuantity}
                                name="trainingRoomQuantity"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Classrooms/Training Rooms - Total Floor Area"
                                onChange={onTrainingRoomFloorAreaChanged}
                                value={trainingRoomFloorArea}
                                name="trainingRoomFloorArea"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                                }}
                              />

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Workshops - Quantity"
                                onChange={onWorkshopQuantityChanged}
                                value={workshopQuantity}
                                name="workshopQuantity"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Workshops - Total Floor Area"
                                onChange={onWorkshopFloorAreaChanged}
                                value={workshopFloorArea}
                                name="workshopFloorArea"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                                }}
                              />

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Practical Training Space for each Trade - Quantity"
                                onChange={onPracticalTrainingSpaceQuantityChanged}
                                value={practicalTrainingSpaceQuantity}
                                name="practicalTrainingSpaceQuantity"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Practical Training Space for each Trade - Total Floor Area"
                                onChange={onPracticalTrainingSpaceFloorAreaChanged}
                                value={practicalTrainingSpaceFloorArea}
                                name="practicalTrainingSpaceFloorArea"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                                }}
                              />

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Technical Laboratories - Quantity"
                                onChange={onTechnicalLabQuantityChanged}
                                value={technicalLabQuantity}
                                name="technicalLabQuantity"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Technical Laboratories - Total Floor Area"
                                onChange={onTechnicalLabFloorAreaChanged}
                                value={technicalLabFloorArea}
                                name="technicalLabFloorArea"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                                }}
                              />

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Data/Records Room - Quantity"
                                onChange={onRecordsRoomQuantityChanged}
                                value={recordsRoomQuantity}
                                name="recordsRoomQuantity"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Data/Records Room - Total Floor Area"
                                onChange={onRecordsRoomFloorAreaChanged}
                                value={recordsRoomFloorArea}
                                name="recordsRoomFloorArea"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                                }}
                              />

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Auditorium - Quantity"
                                onChange={onAuditoriumQuantityChanged}
                                value={auditoriumQuantity}
                                name="auditoriumQuantity"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Auditorium - Total Floor Area"
                                onChange={onAuditoriumFloorAreaChanged}
                                value={auditoriumFloorArea}
                                name="auditoriumFloorArea"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                                }}
                              />

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Library & Study Rooms - Quantity"
                                onChange={onLibraryQuantityChanged}
                                value={libraryQuantity}
                                name="libraryQuantity"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Library & Study Rooms - Total Floor Area"
                                onChange={onLibraryFloorAreaChanged}
                                value={libraryFloorArea}
                                name="libraryFloorArea"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                                }}
                              />

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Water & Power Supply (Public/Private) - Quantity"
                                onChange={onUtilityQuantityChanged}
                                value={utilityQuantity}
                                name="utilityQuantity"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Water & Power Supply (Public/Private) - Total Floor Area"
                                onChange={onUtilityFloorAreaChanged}
                                value={utilityFloorArea}
                                name="utilityFloorArea"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                                }}
                              />

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Management Offices - Quantity"
                                onChange={onMgtOfficesQuantityChanged}
                                value={mgtOfficesQuantity}
                                name="mgtOfficesQuantity"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Management Offices - Total Floor Area"
                                onChange={onMgtOfficesFloorAreaChanged}
                                value={mgtOfficesFloorArea}
                                name="mgtOfficesFloorArea"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                                }}
                              />

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Staff Rooms - Quantity"
                                onChange={onStaffRoomQuantityChanged}
                                value={staffRoomQuantity}
                                name="staffRoomQuantity"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Staff Rooms - Total Floor Area"
                                onChange={onStaffRoomFloorAreaChanged}
                                value={staffRoomFloorArea}
                                name="staffRoomFloorArea"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                                }}
                              />

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Toilets - Quantity"
                                onChange={onToiletQuantityChanged}
                                value={toiletQuantity}
                                name="toiletQuantity"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Toilets - Total Floor Area"
                                onChange={onToiletFloorAreaChanged}
                                value={toiletFloorArea}
                                name="toiletFloorArea"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                                }}
                              />

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="First Aid/Firefighting Facility - Quantity"
                                onChange={onFirstAidFireFacilityQuantityChanged}
                                value={firstAidFireFacilityQuantity}
                                name="firstAidFireFacilityQuantity"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="First Aid/Firefighting Facility - Total Floor Area"
                                onChange={onFirstAidFireFacilityFloorAreaChanged}
                                value={firstAidFireFacilityFloorArea}
                                name="firstAidFireFacilityFloorArea"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                                }}
                              />

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Hostel - Quantity"
                                onChange={onHostelQuantityChanged}
                                value={hostelQuantity}
                                name="hostelQuantity"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Hostel - Total Floor Area"
                                onChange={onHostelFloorAreaChanged}
                                value={hostelFloorArea}
                                name="hostelFloorArea"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                                }}
                              />

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Tools & Equipment for relevant Trades - Quantity"
                                onChange={onTradeToolsQuantityChanged}
                                value={tradeToolsQuantity}
                                name="tradeToolsQuantity"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Tools & Equipment for relevant Trades - Total Floor Area"
                                onChange={onTradeToolsFloorAreaChanged}
                                value={tradeToolsFloorArea}
                                name="tradeToolsFloorArea"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                                }}
                              />

                              <TextField
                                variant="outlined"
                                type="text"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Other Facility Name"
                                onChange={onOtherFacilityNameChanged}
                                value={otherFacilityName}
                                name="otherFacilityName"
                                sx={{ width: isMobile ? "100%" : "32%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Others - Quantity"
                                onChange={onOtherFacilityQuantityChanged}
                                value={otherFacilityQuantity}
                                name="otherFacilityQuantity"
                                sx={{ width: isMobile ? "100%" : "32%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Others - Total Floor Area"
                                onChange={onOtherFacilityFloorAreaChanged}
                                value={otherFacilityFloorArea}
                                name="otherFacilityFloorArea"
                                sx={{ width: isMobile ? "100%" : "32%", mb:"10px" }}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                                }}
                              />

                              <Stack direction="row" gap={2}>
                                  <IconButton
                                      sx={{bottom:"40px", color:"#0b1c08", top:"30px"}}
                                      onClick={() => {setValue(6)}}
                                      variant="contained"
                                  ><ArrowBackIosIcon /></IconButton>
                                  <IconButton
                                      sx={{bottom:"40px", right:"30px", color:"#0b1c08", top:"30px"}}
                                      onClick={() => {setValue(8)}}
                                      variant="contained"
                                  ><ArrowForwardIosIcon /></IconButton>
                              </Stack>
                          </Box>
                      </TabPanel>

                      {/*TRAINING STAFF*/}
                      <TabPanel value={value} index={8} sx={{ width: isMobile ? "100%" : "90%", paddingBottom:isMobile ? "20px" : "" }}>
                          <Box
                            sx={{
                              width: "100%",
                              height: isMobile ? "" : "470px",
                              overflowY: isMobile ? "none" : "scroll",
                              pr: 2
                            }}
                          >
                              <Typography sx={{color:'#295C21', fontSize:12, mb: 3}}>TRAINING STAFF</Typography>

                              <TextField
                                variant="outlined"
                                type="text"
                                label="Full Name of Staff 1"
                                onChange={onTrainingStaff1NameChanged}
                                value={trainingStaff1Name}
                                name="trainingStaff1Name"
                                sx={{ width: isMobile ? "100%" : "25%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                type="text"
                                label="Staff's Qualification"
                                onChange={onTrainingStaff1QualificationChanged}
                                value={trainingStaff1Qualification}
                                name="trainingStaff1Qualification"
                                helperText="e.g: BSc. Building, HND Marketing, etc."
                                sx={{ width: isMobile ? "100%" : "25%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <FormControl sx={{ width: isMobile ? "49%" : "10%", mb:"10px", mr: isMobile ? "2%" : "2%"}}>
                                <InputLabel id="approvalStatus-id">Gender</InputLabel>
                                <Select
                                  labelId="approvalStatus-id"
                                  variant="outlined"
                                  name="trainingStaff1Gender"
                                  value={trainingStaff1Gender}
                                  onChange={(e) => setTrainingStaff1Gender(e.target.value)}
                                >
                                    <MenuItem value={"Female"}>Female</MenuItem>
                                    <MenuItem value={"Male"}>Male</MenuItem>
                                </Select>
                              </FormControl>

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Practical Experience"
                                onChange={onTrainingStaff1ExperienceChanged}
                                value={trainingStaff1Experience}
                                name="trainingStaff1Experience"
                                sx={{ width: isMobile ? "49%" : "10%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">years</InputAdornment>,
                                }}
                              />

                              <FormControl sx={{ width: isMobile ? "100%" : "20%", mb: isMobile ? "50px" : "10px" }}>
                                <InputLabel id="qualifications-id">Trade Specialization</InputLabel>
                                <Select
                                  labelId="qualifications-id"
                                  variant="outlined"
                                  name="trainingStaff1Specialization"
                                  value={trainingStaff1Specialization}
                                  onChange={(e) => setTrainingStaff1Specialization(e.target.value)}
                                >
                                  {options4}
                                </Select>
                              </FormControl>


                              <TextField
                                variant="outlined"
                                type="text"
                                label="Full Name of Staff 2"
                                onChange={onTrainingStaff2NameChanged}
                                value={trainingStaff2Name}
                                name="trainingStaff2Name"
                                sx={{ width: isMobile ? "100%" : "25%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                type="text"
                                label="Staff's Qualification"
                                onChange={onTrainingStaff2QualificationChanged}
                                value={trainingStaff2Qualification}
                                name="trainingStaff2Qualification"
                                helperText="e.g: BSc. Building, HND Marketing, etc."
                                sx={{ width: isMobile ? "100%" : "25%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <FormControl sx={{ width: isMobile ? "49%" : "10%", mb:"10px", mr: isMobile ? "2%" : "2%"}}>
                                <InputLabel id="approvalStatus-id">Gender</InputLabel>
                                <Select
                                  labelId="approvalStatus-id"
                                  variant="outlined"
                                  name="trainingStaff2Gender"
                                  value={trainingStaff2Gender}
                                  onChange={(e) => setTrainingStaff2Gender(e.target.value)}
                                >
                                    <MenuItem value={"Female"}>Female</MenuItem>
                                    <MenuItem value={"Male"}>Male</MenuItem>
                                </Select>
                              </FormControl>

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Practical Experience"
                                onChange={onTrainingStaff2ExperienceChanged}
                                value={trainingStaff2Experience}
                                name="trainingStaff2Experience"
                                sx={{ width: isMobile ? "49%" : "10%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">years</InputAdornment>,
                                }}
                              />

                              <FormControl sx={{ width: isMobile ? "100%" : "20%", mb: isMobile ? "50px" : "10px" }}>
                                <InputLabel id="qualifications-id">Trade Specialization</InputLabel>
                                <Select
                                  labelId="qualifications-id"
                                  variant="outlined"
                                  name="trainingStaff2Specialization"
                                  value={trainingStaff2Specialization}
                                  onChange={(e) => setTrainingStaff2Specialization(e.target.value)}
                                >
                                  {options4}
                                </Select>
                              </FormControl>


                              <TextField
                                variant="outlined"
                                type="text"
                                label="Full Name of Staff 3"
                                onChange={onTrainingStaff3NameChanged}
                                value={trainingStaff3Name}
                                name="trainingStaff3Name"
                                sx={{ width: isMobile ? "100%" : "25%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <TextField
                                variant="outlined"
                                type="text"
                                label="Staff's Qualification"
                                onChange={onTrainingStaff3QualificationChanged}
                                value={trainingStaff3Qualification}
                                name="trainingStaff3Qualification"
                                helperText="e.g: BSc. Building, HND Marketing, etc."
                                sx={{ width: isMobile ? "100%" : "25%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                              />

                              <FormControl sx={{ width: isMobile ? "49%" : "10%", mb:"10px", mr: isMobile ? "2%" : "2%"}}>
                                <InputLabel id="approvalStatus-id">Gender</InputLabel>
                                <Select
                                  labelId="approvalStatus-id"
                                  variant="outlined"
                                  name="trainingStaff3Gender"
                                  value={trainingStaff3Gender}
                                  onChange={(e) => setTrainingStaff3Gender(e.target.value)}
                                >
                                    <MenuItem value={"Female"}>Female</MenuItem>
                                    <MenuItem value={"Male"}>Male</MenuItem>
                                </Select>
                              </FormControl>

                              <TextField
                                variant="outlined"
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Practical Experience"
                                onChange={onTrainingStaff3ExperienceChanged}
                                value={trainingStaff3Experience}
                                name="trainingStaff3Experience"
                                sx={{ width: isMobile ? "49%" : "10%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">years</InputAdornment>,
                                }}
                              />

                              <FormControl sx={{ width: isMobile ? "100%" : "20%", mb: isMobile ? "50px" : "10px" }}>
                                <InputLabel id="qualifications-id">Trade Specialization</InputLabel>
                                <Select
                                  labelId="qualifications-id"
                                  variant="outlined"
                                  name="trainingStaff3Specialization"
                                  value={trainingStaff3Specialization}
                                  onChange={(e) => setTrainingStaff3Specialization(e.target.value)}
                                >
                                  {options4}
                                </Select>
                              </FormControl>

                              <Stack direction="row" gap={2}>
                                  <IconButton
                                      sx={{bottom:"40px", color:"#0b1c08", top:"30px"}}
                                      onClick={() => {setValue(7)}}
                                      variant="contained"
                                  ><ArrowBackIosIcon /></IconButton>
                                  <IconButton
                                      sx={{bottom:"40px", right:"30px", color:"#0b1c08", top:"30px"}}
                                      onClick={() => {setValue(9)}}
                                      variant="contained"
                                  ><ArrowForwardIosIcon /></IconButton>
                              </Stack>
                          </Box>
                      </TabPanel>

                      {/*SUPPORTING DOCUMENTS*/}
                      <TabPanel value={value} index={9} sx={{ width: isMobile ? "100%" : "90%", paddingBottom:isMobile ? "20px" : "" }}>
                          <Box
                            sx={{
                              width: "100%",
                              height: isMobile ? "" : "470px",
                              overflowY: isMobile ? "none" : "scroll",
                              pr: 2,
                              justifyContent: "start"
                            }}
                          >
                              <Typography sx={{color:'#295C21', fontSize:12, mb:3}}>SUPPORTING DOCUMENTS <span style={{ color: 'red' }}>*</span></Typography>


                              <Stack direction="column" gap={2} sx={{width:isMobile ? "100%" : "960px"}}>
                                  <Stack direction="row" gap={1}>
                                      {centerCredentials &&  <Typography sx={{width:"50%"}}>{centerCredentials.name}</Typography> }
                                  </Stack>

                                  <Stack direction="row" gap={1} sx={{mb:3}}>
                                      <input
                                        accept=".zip, .pdf"
                                        style={{ display: 'none' }}
                                        id="credentials-file"
                                        type="file"
                                        name="centerCredentials"
                                        onChange={onSelectCredentials}
                                      />
                                      <label htmlFor="credentials-file" style={{width:"50%"}}>
                                          <Button
                                              variant="filled"
                                              component="span"
                                              startIcon={<CloudUploadIcon />}
                                              sx={{ backgroundColor: '#80B373', color: 'rgba(0, 0, 0, 0.87)', width:"100%", mb:7 }}
                                          >
                                            Upload Centre Credentials
                                          </Button>
                                      </label>
                                  </Stack>
                              </Stack>

                              <TextField
                                variant="outlined"
                                type="text"
                                label={<span>Full Name of Applicant <span style={{ color: 'red' }}>*</span></span>}
                                onChange={onApplicantsNameChanged}
                                value={applicantsName}
                                name="applicantsName"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"60px", mr: isMobile ? "0%" : "2%" }}
                              />
                              <TextField
                                variant="outlined"
                                type="text"
                                label={<span>Position/Designation of Applicant <span style={{ color: 'red' }}>*</span></span>}
                                onChange={onApplicantsPositionChanged}
                                value={applicantsPosition}
                                name="applicantsPosition"
                                sx={{ width: isMobile ? "100%" : "49%", mb:"60px" }}
                              />

                              <Collapse in={alert} sx={{ width: "100%" }}>
                                <Alert
                                  variant="outlined"
                                  sx={{ color: "#00ffff" }}
                                  severity="success"
                                  aria-live="assertive"
                                  onClose={() => {setAlert(null)}}
                                >
                                    {alert}
                                </Alert>
                              </Collapse>

                              <Box display="flex" mt="30px">
                                <LoadingButton
                                    type="submit"
                                    loading={isLoading}
                                    variant="contained"
                                    disabled={canSave ? false : true}
                                    sx={{width:isMobile ? "100%" : "70%", color:"#fff"}}
                                >
                                  Submit Application
                                </LoadingButton>
                              </Box>

                              <Stack direction="row" gap={2}>
                                  <IconButton
                                      sx={{bottom:"20px", color:"#0b1c08", top:"30px"}}
                                      onClick={() => {setValue(8)}}
                                      variant="contained"
                                  ><ArrowBackIosIcon /></IconButton>
                              </Stack>
                          </Box>
                      </TabPanel>
                    </form>
                </Box>
            </Box>
            <DashFooter />
        </>
    )

    return content
}

export default CenterApplication
