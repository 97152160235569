import { useRef, useState, useEffect } from "react"
import { useAddNewQualificationMutation } from "./qualificationsApiSlice"
import { useNavigate } from "react-router-dom"
import useTitle from '../../hooks/useTitle'

import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Stack, Button, TextField } from "@mui/material"
import { Link } from "react-router-dom"
import HomeIcon from '@mui/icons-material/Home'
import VisibilityIcon from '@mui/icons-material/Visibility'
import Alert from '@mui/material/Alert'
import useMediaQuery from "@mui/material/useMediaQuery"
import Header from "../../components/Header"

const QUALIFICATION_REGEX = /^[A-z ]{5,30}$/

const NewQualificationForm = () => {
    useTitle('NIOB Skill CaMP: New Qualification')

    const isMobile = useMediaQuery("(max-width:600px)")

    const [addNewQualification, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewQualificationMutation()

    const navigate = useNavigate()

    const [qualificationType, setQualificationType] = useState('')
    const [validQualificationType, setValidQualificationType] = useState(false)
    const errRef = useRef()
    const [errMsg, setErrMsg] = useState('')

    useEffect(() => {
        setValidQualificationType(QUALIFICATION_REGEX.test(qualificationType))
    }, [qualificationType])

    useEffect(() => {
        if (isSuccess) {
            setQualificationType('')
            navigate('/dash/qualifications')
        }

    }, [isSuccess, navigate])

    const onQualificationTypeChanged = e => setQualificationType(e.target.value)

    const canSave = [validQualificationType].every(Boolean) && !isLoading

    const onSaveQualificationTypeClicked = async (e) => {
        e.preventDefault()
        try {
            await addNewQualification({ qualificationType })
            navigate('/dash/qualifications')
        } catch (err) {
            if (!err.status || err.status === 500 || err.status === 501) {
                setErrMsg('No Server Response');
            } else if (err.status === 400) {
                setErrMsg('Missing Qualification Type');
            } else if (err.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg(err.data?.message);
            }
            errRef.current.focus();
        }
    }

    const errContent = error?.data?.message ?? ''

    return (
        <>
        <Header title="NEW QUALIFICATION" subtitle="Add a New Qualification" />
        <Box sx={{
            p: "2% 2% 5% 2%",
            "& .MuiStack-root .MuiButton-outlined": {color:'#0b1c08', border:'1px solid #0b1c08'},
            "& .MuiStack-root .MuiButton-outlined:hover": {backgroundColor:'#7fd47010'},
            height: "60vh"
          }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', width: "100%" }}>

              <Stack direction="row" gap={1} mt="20px" mb="20px">
                <Link to="/dash">
                  <Button variant="outlined" color="info" size="small" startIcon={<HomeIcon />} sx={{ mb: 0 }}>
                    Home
                  </Button>
                </Link>
                <Link to="/dash/qualifications">
                  <Button variant="outlined" color="info" size="small" startIcon={<VisibilityIcon />} sx={{ mb: 0 }}>
                    View All Qualifications
                  </Button>
                </Link>
              </Stack>
          </Box>
          <form onSubmit={onSaveQualificationTypeClicked}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isMobile ? "span 4" : undefined },
                  width: "60%"
                }}
              >
                {/*Alert Messages to be displayed here*/}
                {errMsg ?
                <Alert ref={errRef}
                  variant="outlined"
                  sx={{ gridColumn: "span 4" }}
                  severity="error"
                  aria-live="assertive">
                    {errMsg}
                </Alert> : ""}

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Qualification"
                  onChange={onQualificationTypeChanged}
                  value={qualificationType}
                  name="qualificationType"
                  sx={{ gridColumn: "span 4" }}
                />
              </Box>
              <Box display="flex" mt="30px">
                  <LoadingButton type="submit" loading={isLoading} sx={{color:"#fff", width:"30%"}} disabled={!canSave} fullWidth variant="contained">
                    <span>Add New Qualification</span>
                  </LoadingButton>
              </Box>
          </form>
        </Box>
        </>
    )
}

export default NewQualificationForm
