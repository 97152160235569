import { Box, useTheme, TextField, Typography, Stack, Button, MenuItem, Grid, Card, CardHeader, CardActions, CardContent, Rating } from "@mui/material"
import Avatar from "@mui/material/Avatar"
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera"
import Badge from "@mui/material/Badge"
import { tokens } from "../theme"
import useMediaQuery from "@mui/material/useMediaQuery"

const MyProfileAvatarBox = ({fullName, rating, roles, id}) => {
    const isMobile = useMediaQuery("(max-width:600px)")
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const content = (
      <Box sx={{
        width: isMobile ? "100%" : '30%',
        bgcolor: colors.primary[400],
        maxHeight: 300
      }}>
        <Grid item sx={{ p: "1.5rem 0rem", textAlign: "center"  }}>
          {/* PROFILE PHOTO */}
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <PhotoCameraIcon
                sx={{
                  border: "5px solid white",
                  backgroundColor: "#fff",
                  borderRadius: "50%",
                  color: "#030702",
                  padding: ".2rem",
                  width: 35,
                  height: 35
                }}
              ></PhotoCameraIcon>
            }
          >
            <Avatar
              sx={{ width: 150, height: 150, mb: 1.5 }}
              src="https://media.glamour.com/photos/5a425fd3b6bcee68da9f86f8/master/pass/best-face-oil.png"
            ></Avatar>
          </Badge>

          {/* DESCRIPTION */}
          <Typography variant="h2" sx={{ mb: 2 }}>{fullName}</Typography>
          <Typography variant="h5" color="text.secondary">{roles}</Typography>
          <Rating name="rating" value={rating} readOnly />
        </Grid>
      </Box>
    )

    return content
}


const MyProfileAvatarBoxDash = ({fullName, rating, roles, id}) => {
    const isMobile = useMediaQuery("(max-width:600px)")
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const content = (
      <Box sx={{
        width: isMobile ? "100%" : '100%',
        bgcolor: colors.primary[400],
      }}>
        <Grid item sx={{ p: "1rem 0rem", textAlign: "center", justifyContent: "center" }}>
          {/* PROFILE PHOTO */}
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <PhotoCameraIcon
                sx={{
                  border: "2px solid white",
                  backgroundColor: "#fff",
                  borderRadius: "50%",
                  color: "#030702",
                  padding: ".1rem",
                  width: 20,
                  height: 20
                }}
              ></PhotoCameraIcon>
            }
          >
            <Avatar
              sx={{ width: 100, height: 100, mb: 1.5 }}
              src="avatar.jpg"
            ></Avatar>
          </Badge>

          {/* DESCRIPTION */}
          <Typography variant="h4" sx={{ mb: 1, color: "#fff" }}>{fullName}</Typography>
          <Typography variant="h6" color="text.secondary">{roles}</Typography>
          <Rating name="rating" value={rating} readOnly />
        </Grid>
      </Box>
    )

    return content
}

export {
    MyProfileAvatarBox,
    MyProfileAvatarBoxDash
}
