import { useRef, useState, useEffect } from "react"
import { useUpdateRoleMutation } from "./rolesApiSlice"
import { useDeleteRoleMutation } from "./rolesApiSlice"
import { useNavigate } from "react-router-dom"
import useTitle from '../../hooks/useTitle'

import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Stack, Button, TextField } from "@mui/material"
import { Link } from "react-router-dom"
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import HomeIcon from '@mui/icons-material/Home'
import VisibilityIcon from '@mui/icons-material/Visibility'
import Alert from '@mui/material/Alert'
import useMediaQuery from "@mui/material/useMediaQuery"
import Header from "../../components/Header"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from "@fortawesome/free-solid-svg-icons"

const ROLE_REGEX = /^[A-z ]{5,30}$/

const EditRoleForm = ({ role }) => {
    useTitle('NIOB Skill CaMP: New Role')

    const isMobile = useMediaQuery("(max-width:600px)")

    const [updateRole, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateRoleMutation()

    const [deleteRole, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteRoleMutation()

    const navigate = useNavigate()

    const [roleType, setRoleType] = useState(role.roleType)
    const [validRoleType, setValidRoleType] = useState(false)
    const errRef = useRef()
    const [errMsg, setErrMsg] = useState('')

    useEffect(() => {
        setValidRoleType(ROLE_REGEX.test(roleType))
    }, [roleType])

    useEffect(() => {
        if (isSuccess) {
            setRoleType('')
            navigate('/dash/roles')
        }

    }, [isSuccess, isDelSuccess, navigate])

    const onRoleTypeChanged = e => setRoleType(e.target.value)

    const canSave = [validRoleType].every(Boolean) && !isLoading

    const onSaveRoleTypeClicked = async (e) => {
        e.preventDefault()
        try {
            await updateRole({ id: role.id, roleType })
            navigate('/dash/roles')
        } catch (err) {
            if (!err.status || err.status === 500 || err.status === 501) {
                setErrMsg('No Server Response');
            } else if (err.status === 400) {
                setErrMsg('Missing Role Type');
            } else if (err.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg(err.data?.message);
            }
            errRef.current.focus();
        }
    }

    const onDeleteRoleClicked = async () => {
        await deleteRole({ id: role.id })
    }

    const errContent = (error?.data?.message || delerror?.data?.message) ?? ''

    return (
      <>
      <Header title="EDIT ROLE" subtitle="Update Role record" />
      <Box sx={{
        p: "2% 2% 5% 2%",
        "& .MuiStack-root .MuiButton-outlined": {color:'#0b1c08', border:'1px solid #0b1c08'},
        "& .MuiStack-root .MuiButton-outlined:hover": {backgroundColor:'#7fd47010'},
        height:"60vh"
      }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>

              <Stack direction="row" gap={1} mt="20px" mb="20px">
                <Link to="/dash">
                  <Button variant="outlined" color="info" size="small" startIcon={<HomeIcon />} sx={{ mb: 0 }}>
                    Home
                  </Button>
                </Link>
                <Link to="/dash/roles/new">
                  <Button variant="outlined" color="info" size="small" startIcon={<AddIcon />} sx={{ mb: 0 }}>
                    Add New Role
                  </Button>
                </Link>
                <Link to="/dash/roles">
                  <Button variant="outlined" color="info" size="small" startIcon={<VisibilityIcon />} sx={{ mb: 0 }}>
                    View All Roles
                  </Button>
                </Link>
                <Button
                  onClick={() => {onDeleteRoleClicked()}}
                  variant="outlined"
                  color="info"
                  size="small"
                  startIcon={<DeleteIcon />}
                  sx={{ mb: 0 }}
                >
                  Delete this Role
                </Button>
              </Stack>
          </Box>
          <form onSubmit={onSaveRoleTypeClicked}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isMobile ? "span 4" : undefined },
                  width: "60%"
                }}
              >
                {/*Alert Messages to be displayed here*/}
                {errMsg ?
                <Alert ref={errRef}
                  variant="outlined"
                  sx={{ gridColumn: "span 4" }}
                  severity="error"
                  aria-live="assertive">
                    {errMsg}
                </Alert> : ""}
                {errContent ?
                <Alert
                  variant="outlined"
                  sx={{ gridColumn: "span 4" }}
                  severity="error"
                  aria-live="assertive">
                    {errContent}
                </Alert> : ""}

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Role"
                  onChange={onRoleTypeChanged}
                  value={roleType}
                  name="roleType"
                  sx={{ gridColumn: "span 4" }}
                />
              </Box>
              <Box display="flex" mt="30px">
                  <LoadingButton type="submit" loading={isLoading} sx={{color:"#fff", width:"30%"}} disabled={!canSave} fullWidth variant="contained">
                    <span>Update Role</span>
                  </LoadingButton>
              </Box>
          </form>
      </Box>
      </>
    )
}

export default EditRoleForm
