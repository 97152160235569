import {
    createSelector,
    createEntityAdapter
} from '@reduxjs/toolkit'
import { apiSlice } from  '../../app/api/apiSlice'

const assessortraineeassignmentsAdapter = createEntityAdapter({})

const initialState = assessortraineeassignmentsAdapter.getInitialState()

export const assessortraineeassignmentApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAssessorTraineeAssignments: builder.query({
            query: () => ({
                url: '/assessor-trainee-assignments',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
              const loadedAssessorTraineeAssignments = responseData.map(assessorTraineeAssignment => {
                  assessorTraineeAssignment.id = assessorTraineeAssignment._id
                  return assessorTraineeAssignment
              });
              return assessortraineeassignmentsAdapter.setAll(initialState, loadedAssessorTraineeAssignments)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'AssessorTraineeAssignment', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'AssessorTraineeAssignment', id }))
                    ]
                } else return [{ type: 'AssessorTraineeAssignment', id: 'LIST' }]
            }
        }),
        addNewAssessorTraineeAssignment: builder.mutation({
            query: initialAssessorTraineeAssignmentData => ({
                url: '/assessor-trainee-assignments',
                method: 'POST',
                body: {
                    ...initialAssessorTraineeAssignmentData,
                }
            }),
            invalidatesTags: [
                { type: 'AssessorTraineeAssignment', id: "LIST" }
            ]
        }),
        updateAssessorTraineeAssignment: builder.mutation({
            query: initialAssessorTraineeAssignmentData => ({
                url: '/assessor-trainee-assignments',
                method: 'PATCH',
                body: {
                    ...initialAssessorTraineeAssignmentData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'AssessorTraineeAssignment', id: arg.id }
            ]
        }),
        deleteAssessorTraineeAssignment: builder.mutation({
            query: ({ id }) => ({
                url: `/assessor-trainee-assignments`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'AssessorTraineeAssignment', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetAssessorTraineeAssignmentsQuery,
    useAddNewAssessorTraineeAssignmentMutation,
    useUpdateAssessorTraineeAssignmentMutation,
    useDeleteAssessorTraineeAssignmentMutation,
} = assessortraineeassignmentApiSlice

// return the query result object
export const selectAssessorTraineeAssignmentsResult = assessortraineeassignmentApiSlice.endpoints.getAssessorTraineeAssignments.select()

// creates memoized selector
const selectAssessorTraineeAssignmentsData = createSelector(
    selectAssessorTraineeAssignmentsResult,
    assessorTraineeAssignmentsResult => assessorTraineeAssignmentsResult.data // normalized state object with ids  & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllAssessorTraineeAssignments,
    selectById: selectAssessorTraineeAssignmentById,
    selectIds: selectAssessorTraineeAssignmentIds
    // Pass in a selector that returns the AssessorTraineeAssignments slice of state
} = assessortraineeassignmentsAdapter.getSelectors(state => selectAssessorTraineeAssignmentsData(state) ?? initialState)
