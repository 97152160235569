import * as React from 'react'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetUsersQuery } from './usersApiSlice'
import useAuth from "../../hooks/useAuth"
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'

import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid'
import { Box, Button, Stack, Typography, Paper } from "@mui/material"
import { Link } from "react-router-dom"
import useMediaQuery from "@mui/material/useMediaQuery"
import Header from "../../components/Header"
import AddIcon from '@mui/icons-material/Add'
import HomeIcon from '@mui/icons-material/Home'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { faPenToSquare, faSearch } from "@fortawesome/free-solid-svg-icons"
import { styled } from '@mui/material/styles'

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .no-rows-primary': {
    fill: theme.palette.mode === 'light' ? '#AEB8C2' : '#3D4751',
  },
  '& .no-rows-secondary': {
    fill: theme.palette.mode === 'light' ? '#E8EAED' : '#1D2126',
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width={96}
        viewBox="0 0 452 257"
        aria-hidden
        focusable="false"
      >
        <path
          className="no-rows-primary"
          d="M348 69c-46.392 0-84 37.608-84 84s37.608 84 84 84 84-37.608 84-84-37.608-84-84-84Zm-104 84c0-57.438 46.562-104 104-104s104 46.562 104 104-46.562 104-104 104-104-46.562-104-104Z"
        />
        <path
          className="no-rows-primary"
          d="M308.929 113.929c3.905-3.905 10.237-3.905 14.142 0l63.64 63.64c3.905 3.905 3.905 10.236 0 14.142-3.906 3.905-10.237 3.905-14.142 0l-63.64-63.64c-3.905-3.905-3.905-10.237 0-14.142Z"
        />
        <path
          className="no-rows-primary"
          d="M308.929 191.711c-3.905-3.906-3.905-10.237 0-14.142l63.64-63.64c3.905-3.905 10.236-3.905 14.142 0 3.905 3.905 3.905 10.237 0 14.142l-63.64 63.64c-3.905 3.905-10.237 3.905-14.142 0Z"
        />
        <path
          className="no-rows-secondary"
          d="M0 10C0 4.477 4.477 0 10 0h380c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 20 0 15.523 0 10ZM0 59c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 69 0 64.523 0 59ZM0 106c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 153c0-5.523 4.477-10 10-10h195.5c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 200c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 247c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10Z"
        />
      </svg>
      <Box sx={{ mt: 2, color: '#0b1c08' }}>No record</Box>
    </StyledGridOverlay>
  );
}

const UsersList = () => {
    useTitle('NIOB Skill CaMP: All Users')
    const isMobile = useMediaQuery("(max-width:600px)")

    const { isSuperAdmin, isCenterManager, isCenter } = useAuth()
    const navigate = useNavigate()

    const {
        data: users,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetUsersQuery('usersList', {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const handleEdit = (event, id) => navigate(`/dash/users/${id}`)
    const handleView = (event, id) => navigate(`/dash/users/user/${id}`)

    const rows = useMemo(() => {
        if (!users || !users.entities) return []

        if (isSuperAdmin) {
            return Object.values(users.entities)
        }
        return []
    }, [users, isSuperAdmin, isCenterManager])

    const columns = [
        { field: 'uln', headerName: 'ULN', width: 150 },
        { field: 'firstName', headerName: 'FIRSTNAME', width: 150 },
        { field: 'lastName', headerName: 'LASTNAME', width: 150 },
        { field: 'email', headerName: 'EMAIL', width: 150 },
        { field: 'roles', headerName: 'ROLES', width: 150 },
        { field: 'centers', headerName: 'CENTRES', width: 200 },
        { field: 'qualifications', headerName: 'QUALIFICATIONS', width: 250 },
        {
            field: 'actions',
            headerName: 'ACTIONS',
            width: 150,
            renderCell: (params) => (
                <Stack direction="row" sx={{display: "inline-flex", justifyContent: "center", alignItems: "center"}}>
                    <Button onClick={(event) => handleEdit(event, params.row.id)} sx={{color:"#0b1c08"}}>
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </Button>
                    {/*<Button onClick={(event) => handleView(event, params.row.id)} sx={{color:"#0b1c08"}}>
                        <FontAwesomeIcon icon={faSearch} />
                    </Button>*/}
                </Stack>
            )
        }
    ]

    let content

    if (isLoading) {
        content = <PulseLoader color={"#0b1c08"} className="center" />
    }

    if (isError) {
        content = <p className='errmsg'>{error?.data?.message}</p>
    }

    //if (isSuccess) {
    return content = (
            <>
                <Header title="USERS LIST" subtitle={isSuperAdmin ? "Record of All Users" : "Record of All Your Center Users"} />
                <Box sx={{
                  width: '100%', mb: '150px', mt: isMobile ? "10%" : "",
                  "& .MuiPaper-root": { backgroundColor: '#030702' },
                  "& .MuiDataGrid-row:nth-of-type(odd)": {
                      backgroundColor: '#fff'
                  },
                  "& .MuiDataGrid-row:nth-of-type(odd):hover": {
                      backgroundColor: '#dfdfdf',
                  },
                  "& .MuiDataGrid-row:nth-of-type(even)": {
                      backgroundColor: '#efefef'
                  },
                  "& .MuiDataGrid-row:nth-of-type(even):hover": {
                      backgroundColor: '#dfdfdf',
                  },
                  "& .MuiDataGrid-footerContainer": {
                      backgroundColor: '#0b1c08'
                  },
                  "& .MuiDataGrid-filterForm": {
                      backgroundColor: '#9CD08F'
                  },
                  "& .MuiDataGrid-cell": { color: '#0b1c08' },
                  "& .MuiDataGrid-toolbarContainer": {backgroundColor: '#fff !important'},
                  "& .MuiDataGrid-scrollbar--horizontal": {height: '5px !important', zIndex:1},
                  "& .MuiDataGrid-scrollbar--vertical": {zIndex:1},
                  p: "2%"
                }}>
                    <Stack direction="row" gap={1} sx={{ mb: 2 }}>
                        <Link to="/dash">
                            <Button variant="outlined" sx={{color:"#0b1c08"}} size="small" startIcon={<HomeIcon />}>
                                Home
                            </Button>
                        </Link>
                        <Link to="/dash/users/new">
                            <Button variant="outlined" sx={{color:"#0b1c08"}} size="small" startIcon={<AddIcon />}>
                                Add New User
                            </Button>
                        </Link>
                    </Stack>
                    <div style={{ height: 450, width: '100%' }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            disableSelectionOnClick
                            disableColumnFilter
                            disableColumnSelector
                            slots={{
                              toolbar: GridToolbar,
                              noRowsOverlay: CustomNoRowsOverlay
                            }}
                            slotProps={{
                              toolbar: {
                                showQuickFilter: true,
                              },
                            }}
                            components={{
                                Toolbar: () => (
                                    <GridToolbarContainer>
                                        <GridToolbarQuickFilter />
                                        <GridToolbarExport />
                                    </GridToolbarContainer>
                                )
                            }}
                            sx={{
                              "& .MuiDataGrid-columnHeaders": { backgroundColor: '#121212', color: '#fff' },
                              '--DataGrid-overlayHeight': '450px'
                            }}
                        />
                    </div>
                </Box>
            </>
        )
    //}

    //return content
}


const MyUsersList = ({ roletype, center }) => {
    useTitle('NIOB Skill CaMP: All Users in your Center')
    const isMobile = useMediaQuery("(max-width:600px)")

    const { isCenterManager, isCenter } = useAuth()
    const navigate = useNavigate()

    const {
        data: users,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetUsersQuery('usersList', {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const [pageSize, setPageSize] = useState(5)

    const handleEdit = (id) => navigate(`/dash/users/${id}`)
    const handleView = (id) => navigate(`/dash/users/user/${id}`)

    const columns = [
        { field: 'uln', headerName: 'ULN', flex: 1 },
        { field: 'firstName', headerName: 'FIRST NAME', flex: 1 },
        { field: 'lastName', headerName: 'LAST NAME', flex: 1 },
        { field: 'roles', headerName: 'ROLES', flex: 1, renderCell: (params) => params.row.roles.join(', ') },
        { field: 'centers', headerName: 'CENTRES', flex: 1, renderCell: (params) => params.row.centers.join(', ') },
        { field: 'qualifications', headerName: 'QUALIFICATIONS', flex: 1, renderCell: (params) => params.row.qualifications.join(', ') },
        {
            field: 'actions',
            headerName: 'ACTIONS',
            sortable: false,
            renderCell: (params) => (
                <Button
                    color="success"
                    onClick={() => handleEdit(params.id)}
                >
                    <FontAwesomeIcon icon={faPenToSquare} />
                </Button>
            ),
        },
    ]

    const visibleRows = useMemo(() => {
        if (!users) return []

        return users?.entities
            ? Object.values(users.entities).filter(user => (user.centers.includes(center) && user.roles.includes(roletype)))
            : []
    }, [users, center, roletype])

    let content

    if (isLoading) content = <PulseLoader color={"#0b1c08"} className="center" />
    if (isError) content = <p className='errmsg'>{error?.data?.message}</p>

    if (isSuccess && (isCenterManager || isCenter)) {
        const userCount = visibleRows.length

        content = (
            <Box sx={{
              width: '100%', mb: '30px',
              "& .MuiPaper-root": { backgroundColor: '#030702' },
              "& .MuiDataGrid-row:nth-of-type(odd)": {
                backgroundColor: '#0b1c08',
              },
              "& .MuiDataGrid-row:nth-of-type(odd):hover": {
                backgroundColor: '#121212',
              },
              "& .MuiDataGrid-row:nth-of-type(even)": {
                backgroundColor: '#091506',
              },
              "& .MuiDataGrid-row:nth-of-type(even):hover": {
                backgroundColor: '#121212',
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: '#0b1c08'
              },
              "& .MuiDataGrid-filterForm": {
                backgroundColor: '#9CD08F'
              }
            }}>
                {userCount > 0 ? (
                    <DataGrid
                        rows={visibleRows}
                        columns={columns}
                        pageSize={pageSize}
                        rowsPerPageOptions={[5, 10, 25]}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        autoHeight
                    />
                ) : (
                    <>
                        <CloudUploadIcon sx={{ fill: "0b1c08a9", fontSize: 120 }} />
                        <Typography variant="h3" sx={{ color: "efefef" }}>
                            No {roletype} Records.
                        </Typography>
                    </>
                )}
            </Box>
        )
    }

    return content
}

export {
  UsersList,
  MyUsersList
}
