// IMPORTS
import * as React from 'react'
import { Box, useTheme, TextField, Typography, Stack, Button, FormControl, InputLabel, Select, MenuItem, Grid, Card, CardHeader, CardActions, CardContent, Rating } from "@mui/material"
import Avatar from "@mui/material/Avatar"
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Divider from '@mui/material/Divider'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera"
import Badge from "@mui/material/Badge"
import { Link, useNavigate } from "react-router-dom"
import useTitle from '../../hooks/useTitle'
import { tokens } from "../../theme"
import useMediaQuery from "@mui/material/useMediaQuery"
import Header from "../../components/Header"
import { styled } from '@mui/material/styles'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#80B373' : '#80B373',
  },
}))

//APP
const Profile = () => {
    useTitle('NIOB Skill CaMP: User Profile')
    const isMobile = useMediaQuery("(max-width:600px)")
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

  const content = (
      <Box sx={{
        m: isMobile ? "10% 8% 2% 8%" : "2% 2% 50px 2%",
        "& .MuiStack-root .MuiButton-outlined": {color:'#fff', border:'1px solid #fff'},
        "& .MuiStack-root .MuiButton-outlined:hover": {backgroundColor:'#7fd47010'}
      }}>
          <Box mb="50px">
              <Header title="USER PROFILE" subtitle="More details about a User" />

              <Stack direction={isMobile ? "column" : "row"} gap={5}>
                  <Box sx={{
                    width: isMobile ? "100%" : '30%',
                    bgcolor: colors.primary[400],
                    maxHeight: 400
                  }}>
                      <Grid item sx={{ p: "1.5rem 1rem", textAlign: "center"  }}>
                        {/* PROFILE PHOTO */}
                        <Badge
                          overlap="circular"
                          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                          badgeContent={
                            <PhotoCameraIcon
                              sx={{
                                border: "5px solid white",
                                backgroundColor: "#fff",
                                borderRadius: "50%",
                                color: "#030702",
                                padding: ".2rem",
                                width: 35,
                                height: 35
                              }}
                            ></PhotoCameraIcon>
                          }
                        >
                          <Avatar
                            sx={{ width: 150, height: 150, mb: 1.5 }}
                            src="https://media.glamour.com/photos/5a425fd3b6bcee68da9f86f8/master/pass/best-face-oil.png"
                          ></Avatar>
                        </Badge>

                        {/* DESCRIPTION */}
                        <Typography variant="h2" sx={{ mb: 2 }}>{'Ugbede Akowe'}</Typography>
                        <Typography variant="h5" color="text.secondary">{'Assessor / Internal Verifier'}</Typography>
                        <Rating name="rating" value={4} readOnly />

                        <Stack direction="row" justifyContent="center" gap={1} mt="20px" mb="10px">
                          <Link to="/dash">
                            <Button variant="contained" color="#49a139" size="small" sx={{ mb: 0 }}>
                              Edit User
                            </Button>
                          </Link>
                          <Link to="/dash/users">
                            <Button variant="outlined" color="#49a139" size="small" sx={{ mb: 0 }}>
                              Delete User
                            </Button>
                          </Link>
                        </Stack>
                      </Grid>


                      {/* CARD HEADER END */}
                  </Box>

                  <Box sx={{ width: isMobile ? "100%" : "60%" }}>
                      <Stack direction="column" gap={5}>
                          <Grid sx={{ bgcolor: colors.primary[400] }} >
                              <List sx={{ width: '100%' }}>
                                <ListItem sx={{ mb:1, mt:1 }}>
                                  { isMobile ?
                                  <ListItemText
                                      sx={{ maxWidth: "300px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant="h6"
                                            sx={{ fontSize: "10px" }}
                                            color={colors.greenAccent[400]}
                                          >
                                            FULL NAME:
                                          </Typography>
                                          <Typography
                                            variant="h4"
                                          >
                                            Ugbede Akowe
                                          </Typography>
                                        </React.Fragment>
                                      }
                                  />  :
                                  <>
                                  <ListItemText
                                    sx={{ maxWidth: "250px" }}
                                    primary={
                                      <React.Fragment>
                                        <Typography
                                          variant={ isMobile ? "h6" : "h4" }
                                          color={colors.greenAccent[400]}
                                        >
                                          FULL NAME:
                                        </Typography>
                                      </React.Fragment>
                                    }
                                  />
                                  <ListItemText
                                    primary={
                                      <React.Fragment>
                                        <Typography
                                          variant={ isMobile ? "h5" : "h4" }
                                          align="left"
                                        >
                                          Ugbede Akowe
                                        </Typography>
                                      </React.Fragment>
                                    }
                                  /></> }
                                </ListItem>

                                <Divider variant="middle" component="li" />

                                <ListItem sx={{ mb:1, mt:1 }}>
                                  { isMobile ?
                                  <ListItemText
                                      sx={{ maxWidth: "300px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant="h6"
                                            sx={{ fontSize: "10px" }}
                                            color={colors.greenAccent[400]}
                                          >
                                            EMAIL:
                                          </Typography>
                                          <Typography
                                            variant="h4"
                                          >
                                            u.akowe@gmail.com
                                          </Typography>
                                        </React.Fragment>
                                      }
                                  />  :
                                  <>
                                  <ListItemText
                                    sx={{ maxWidth: "250px" }}
                                    primary={
                                      <React.Fragment>
                                        <Typography
                                          variant={ isMobile ? "h6" : "h4" }
                                          color={colors.greenAccent[400]}
                                        >
                                          EMAIL:
                                        </Typography>
                                      </React.Fragment>
                                    }
                                  />
                                  <ListItemText
                                    primary={
                                      <React.Fragment>
                                        <Typography
                                          variant={ isMobile ? "h6" : "h4" }
                                        >
                                          u.akowe@gmail.com
                                        </Typography>
                                      </React.Fragment>
                                    }
                                  />
                                  </> }
                                </ListItem>

                                <Divider variant="middle" component="li" />

                                <ListItem sx={{ mb:1, mt:1 }}>
                                  { isMobile ?
                                  <ListItemText
                                      sx={{ maxWidth: "300px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant="h6"
                                            sx={{ fontSize: "10px" }}
                                            color={colors.greenAccent[400]}
                                          >
                                            PHONE NUMBER:
                                          </Typography>
                                          <Typography
                                            variant="h4"
                                          >
                                            0801 234 5678
                                          </Typography>
                                        </React.Fragment>
                                      }
                                  />  :
                                  <>
                                  <ListItemText
                                    sx={{ maxWidth: "250px" }}
                                    primary={
                                      <React.Fragment>
                                        <Typography
                                          variant={ isMobile ? "h6" : "h4" }
                                          color={colors.greenAccent[400]}
                                        >
                                          PHONE NUMBER:
                                        </Typography>
                                      </React.Fragment>
                                    }
                                  />
                                  <ListItemText
                                    primary={
                                      <React.Fragment>
                                        <Typography
                                          variant={ isMobile ? "h6" : "h4" }
                                        >
                                          0801 234 5678
                                        </Typography>
                                      </React.Fragment>
                                    }
                                  />
                                  </> }
                                </ListItem>

                                <Divider variant="middle" component="li" />

                                <ListItem sx={{ mb:1, mt:1 }}>
                                  { isMobile ?
                                  <ListItemText
                                      sx={{ maxWidth: "300px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant="h6"
                                            sx={{ fontSize: "10px" }}
                                            color={colors.greenAccent[400]}
                                          >
                                            ROLE(s):
                                          </Typography>
                                          <Typography
                                            variant="h4"
                                          >
                                            Assessor / Internal Verifier
                                          </Typography>
                                        </React.Fragment>
                                      }
                                  />  :
                                  <>
                                  <ListItemText
                                    sx={{ maxWidth: "250px" }}
                                    primary={
                                      <React.Fragment>
                                        <Typography
                                          variant={ isMobile ? "h6" : "h4" }
                                          color={colors.greenAccent[400]}
                                        >
                                          ROLE(s):
                                        </Typography>
                                      </React.Fragment>
                                    }
                                  />
                                  <ListItemText
                                    primary={
                                      <React.Fragment>
                                        <Typography
                                          variant={ isMobile ? "h6" : "h4" }
                                        >
                                          Assessor / Internal Verifier
                                        </Typography>
                                      </React.Fragment>
                                    }
                                  />
                                  </> }
                                </ListItem>

                                <Divider variant="middle" component="li" />

                                <ListItem sx={{ mb:1, mt:1 }}>
                                  { isMobile ?
                                  <ListItemText
                                      sx={{ maxWidth: "300px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant="h6"
                                            sx={{ fontSize: "10px" }}
                                            color={colors.greenAccent[400]}
                                          >
                                            CENTER(s):
                                          </Typography>
                                          <Typography
                                            variant="h4"
                                          >
                                            NABACA Training Center, Kaduna
                                          </Typography>
                                        </React.Fragment>
                                      }
                                  />  :
                                  <>
                                  <ListItemText
                                    sx={{ maxWidth: "250px" }}
                                    primary={
                                      <React.Fragment>
                                        <Typography
                                          variant={ isMobile ? "h6" : "h4" }
                                          color={colors.greenAccent[400]}
                                        >
                                          CENTER(s):
                                        </Typography>
                                      </React.Fragment>
                                    }
                                  />
                                  <ListItemText
                                    primary={
                                      <React.Fragment>
                                        <Typography
                                          variant={ isMobile ? "h6" : "h4" }
                                        >
                                          NABACA Training Center, Kaduna
                                        </Typography>
                                      </React.Fragment>
                                    }
                                  />
                                  </> }
                                </ListItem>

                                <Divider variant="middle" component="li" />

                                <ListItem sx={{ mb:1, mt:1 }}>
                                  { isMobile ?
                                  <ListItemText
                                      sx={{ maxWidth: "300px" }}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            variant="h6"
                                            sx={{ fontSize: "10px" }}
                                            color={colors.greenAccent[400]}
                                          >
                                            QUALIFICATION(s):
                                          </Typography>
                                          <Typography
                                            variant="h4"
                                          >
                                            Masonry, Plumbing, Electrical, Tiling & Decoration
                                          </Typography>
                                        </React.Fragment>
                                      }
                                  />  :
                                  <>
                                  <ListItemText
                                    sx={{ maxWidth: "250px" }}
                                    primary={
                                      <React.Fragment>
                                        <Typography
                                          variant={ isMobile ? "h6" : "h4" }
                                          color={colors.greenAccent[400]}
                                        >
                                          QUALIFICATIONS(s):
                                        </Typography>
                                      </React.Fragment>
                                    }
                                  />
                                  <ListItemText
                                    primary={
                                      <React.Fragment>
                                        <Typography
                                          variant={ isMobile ? "h6" : "h4" }
                                        >
                                          Masonry, Plumbing, Electrical, Tiling & Decoration
                                        </Typography>
                                      </React.Fragment>
                                    }
                                  />
                                  </> }
                                </ListItem>
                              </List>
                          </Grid>

                          <Stack direction={ isMobile ? "column" : "row"} gap={5}>
                              <Grid sx={{ bgcolor: colors.primary[400], width: isMobile ? "100%" : "50%", p: 2 }} >
                                  <List sx={{ width: '100%' }}>
                                    <Typography sx={{ mb:1, mt:1 }}>
                                      Masonry
                                    </Typography>
                                    <BorderLinearProgress variant="determinate" value={80} />

                                    <Typography sx={{ mb:1, mt:4 }}>
                                      Plumbing
                                    </Typography>
                                    <BorderLinearProgress variant="determinate" value={100} />

                                    <Typography sx={{ mb:1, mt:4 }}>
                                      Electrical
                                    </Typography>
                                    <BorderLinearProgress variant="determinate" value={75} />

                                    <Typography sx={{ mb:1, mt:4 }}>
                                      Tiling & Decoration
                                    </Typography>
                                    <BorderLinearProgress variant="determinate" value={98} />
                                  </List>
                              </Grid>

                              <Grid sx={{ bgcolor: colors.primary[400], width: isMobile ? "100%" : "50%", p: 2 }} >
                                  <List sx={{ width: '100%' }}>
                                    <Typography sx={{ mb:1, mt:1 }}>
                                      Masonry
                                    </Typography>
                                    <BorderLinearProgress variant="determinate" value={80} />

                                    <Typography sx={{ mb:1, mt:4 }}>
                                      Plumbing
                                    </Typography>
                                    <BorderLinearProgress variant="determinate" value={100} />

                                    <Typography sx={{ mb:1, mt:4 }}>
                                      Electrical
                                    </Typography>
                                    <BorderLinearProgress variant="determinate" value={75} />

                                    <Typography sx={{ mb:1, mt:4 }}>
                                      Tiling & Decoration
                                    </Typography>
                                    <BorderLinearProgress variant="determinate" value={98} />
                                  </List>
                              </Grid>
                          </Stack>
                      </Stack>
                  </Box>
              </Stack>
          </Box>
      </Box>
  )

  return content

}

export default Profile
