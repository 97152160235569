import { useState, useEffect } from "react"
import { useAddNewUserMutation } from "./usersApiSlice"
import { Link, useNavigate } from "react-router-dom"
import { STATES } from '../../config/states'
import { useGetRolesQuery } from '../roles/rolesApiSlice'
import { useGetCentersQuery } from '../centers/centersApiSlice'
import { useGetQualificationsQuery } from '../qualifications/qualificationsApiSlice'
import { useSelector } from 'react-redux'
import useTitle from '../../hooks/useTitle'

import LoadingButton from '@mui/lab/LoadingButton'
import { Box, useTheme, TextField, Typography, Stack, Button, FormControl, InputLabel, Select, MenuItem, Grid, Card, CardHeader, CardActions, CardContent } from "@mui/material"
import Alert from '@mui/material/Alert'
import HomeIcon from '@mui/icons-material/Home'
import VisibilityIcon from '@mui/icons-material/Visibility'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { styled } from '@mui/material/styles'
import { tokens } from "../../theme"
import useMediaQuery from "@mui/material/useMediaQuery"
import Header from "../../components/Header"
import Papa from 'papaparse'

const USER_REGEX = /^[A-z]{3,20}$/
const EMAIL_REGEX = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/
const PHONE_REGEX = /^[0-9]{11}$/

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const NewUserForm = () => {
    useTitle('NIOB Skill CaMP: New User')
    const isMobile = useMediaQuery("(max-width:600px)")
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const navigate = useNavigate()
    const { data: rolesData } = useGetRolesQuery()
    const { data: centersData } = useGetCentersQuery()
    const { data: qualificationsData } = useGetQualificationsQuery()

    const [addNewUser, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewUserMutation()

    const [uln, setUln] = useState('0000')
    const [firstName, setFirstName] = useState('')
    const [validFirstName, setValidFirstName] = useState(false)
    const [lastName, setLastName] = useState('')
    const [validLastName, setValidLastName] = useState(false)
    const [email, setEmail] = useState('')
    const [validEmail, setValidEmail] = useState(false)
    const [phone, setPhone] = useState('')
    const [validPhone, setValidPhone] = useState(false)
    const [roles, setRoles] = useState([])
    const [stateOfRez, setStateOfRez] = useState('')
    const [centers, setCenters] = useState([])
    const [qualifications, setQualifications] = useState([])
    const [errMsg2, setErrMsg2] = useState("")
    const [errRef2, setErrRef2] = useState("")

    useEffect(() => {
        setValidFirstName(USER_REGEX.test(firstName))
    }, [firstName])

    useEffect(() => {
        setValidLastName(USER_REGEX.test(lastName))
    }, [lastName])

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email))
    }, [email])

    useEffect(() => {
        setValidPhone(PHONE_REGEX.test(phone))
    }, [phone])

    useEffect(() => {
        if (isSuccess) {
            setUln('')
            setFirstName('')
            setLastName('')
            setEmail('')
            setPhone('')
            setRoles([])
            navigate('/dash/users')
        }
    }, [isSuccess, navigate])

    const onFirstNameChanged = e => setFirstName(e.target.value)
    const onLastNameChanged = e => setLastName(e.target.value)
    const onEmailChanged = e => setEmail(e.target.value)
    const onPhoneChanged = e => setPhone(e.target.value)

    const onSaveUserClicked = async (e) => {
        e.preventDefault()

        // Check that if `uln` === '0000' then role should be only 'Trainee'
        if (uln === '0000') {
          roles.length = 0
          roles[0] = '6620fcc350e9ae7c7dd2f90e'
          await addNewUser({ uln, firstName, lastName, email, phone, roles, stateOfRez, centers, qualifications })
        } else {
          await addNewUser({ uln, firstName, lastName, email, phone, roles, stateOfRez, centers, qualifications })
        }

    }

    const [file, setFile] = useState(null)
    const [data, setData] = useState()

    const handleBulkUploadFileChange = (event) => {
      const selectedFile = event.target.files[0]
      setFile(selectedFile)
    }

    const handleBulkUpload = async (event) => {
      const file = event.target.files[0]
      if (!file) {
        console.log("No file selected")
      } else {
        Papa.parse(file, {
          header: true,
          worker: true,
          skipEmptyLines: true,
          complete: async function (result) {
            try {
              result.data.map( async (row) => {
                // Split roles string into an array
                const rolesArray = row.roles ? row.roles.split(',') : []

                // Split qualifications string into an array
                const qualificationsArray = row.qualifications ? row.qualifications.split(',') : []

                // Split centers string into an array
                const centersArray = row?.centers ? row.centers.split(',') : []

                // Get the id of each role
                const role_ids = rolesArray
                  .map(role => role.trim()) // Trim any extra whitespace
                  .map(role => {
                    // Find the corresponding role object in roleData.entities
                    const roleObj = Object.values(rolesData.entities).find(
                      entity => entity.roleType === role
                    )
                    return roleObj ? roleObj.id : null // Return the role id or null if not found
                  })
                  .filter(roleId => roleId !== null) // Remove any null values

                // Get the id of each qualification
                const qualification_ids = qualificationsArray
                  .map(qualification => qualification.trim()) // Trim any extra whitespace
                  .map(qualification => {
                    // Find the corresponding qualification object in qualificationData.entities
                    const qualificationObj = Object.values(qualificationsData.entities).find(
                      entity => entity.qualificationType === qualification
                    )
                    return qualificationObj ? qualificationObj.id : null // Return the qualification id or null if not found
                  })
                  .filter(qualificationId => qualificationId !== null) // Remove any null values

                // Get the id of each center
                const center_ids = centersArray
                  .map(center => center.trim()) // Trim any extra whitespace
                  .map(center => {
                    // Find the corresponding center object in centerData.entities
                    const centerObj = Object.values(centersData.entities).find(
                      entity => entity.centerName === center
                    )
                    return centerObj ? centerObj.id : null // Return the center id or null if not found
                  })
                  .filter(centerId => centerId !== null) // Remove any null values


                const response = await addNewUser({
                  "uln": row.uln,
                  "firstName": row.firstName,
                  "lastName": row.lastName,
                  "email": row.email,
                  "phone": row.phone,
                  "roles": role_ids,
                  "stateOfRez": row.stateOfRez,
                  "centers": center_ids,
                  "qualifications": qualification_ids
                })
              })

              // Make an HTTP POST request to upload the file using Axios
              /*const response = await axiosPrivate({
                method: 'post',
                url: '/upload-users',
                data: result.data,
                headers: {
                  Authorization: AuthStr
                  // Add any necessary headers here, such as authorization token
                }
              })

              // File upload successful, display any feedback or response from the server
              // console.log('File upload successfully:', response.data)
              setSuccessMsg2(response?.data)
              setLoading2(false)
              setTimeout(() => navigate("/dash", { replace: true }), 7000)*/
            } catch (err) {
              // Handle error response from the server
              console.error('File upload failed:', err.message)
              if (!err?.response) {
                setErrMsg2('No Server Response')
              } else if (err.response?.status === 400) {
                setErrMsg2(err.response?.data.data)
              } else if (err.response?.status === 401) {
                setErrMsg2(err.response?.data.data)
              } else {
                setErrMsg2(err.response?.data.data)
              }
              errRef2.current.focus()
            }
          }
        })
      }
    }


    const errContent = error?.data?.message ?? ''

    /*const handleMultiSelectChange = (event) => {
        const { value } = event.target
        setQualifications(value)
    }*/

    const options1 = Object.values(STATES).map(_state => (
        <MenuItem key={_state} value={_state}>{_state}</MenuItem>
    ))

    const options2 = centersData?.entities ? Object.entries(centersData.entities).map(([id, center]) => (
        <MenuItem key={id} value={id}>{center.centerName}</MenuItem>
    )) : null

    const options3 = qualificationsData?.entities ? Object.entries(qualificationsData.entities).map(([id, qualification]) => (
        <MenuItem key={id} value={id}>{qualification.qualificationType}</MenuItem>
    )) : null

    const options = rolesData?.entities ? Object.entries(rolesData.entities).map(([id, role]) => (
        <MenuItem key={id} value={id}>{role.roleType}</MenuItem>
    )) : null

    const canSave = [roles.length, centers.length, qualifications.length, validFirstName, validLastName, validEmail, validPhone].every(Boolean) && !isLoading

    return (
      <>
      <Header title="NEW USER" subtitle="Register a New User" />
      <Box sx={{
          p: "2% 2% 5% 2%",
          m: isMobile ? "10% 2% 150px 2%" : "2% 2% 10% 2%",
          "& .MuiStack-root .MuiButton-outlined": {color:'#0b1c08', border:'1px solid #0b1c08'},
          "& .MuiStack-root .MuiButton-outlined:hover": {backgroundColor:'#7fd47010'}
        }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: "95%" }}>

                <Stack direction="row" gap={1} mt="20px" mb="20px">
                  <Link to="/dash">
                    <Button variant="outlined" color="info" size="small" startIcon={<HomeIcon />} sx={{ mb: 0 }}>
                      Home
                    </Button>
                  </Link>
                  <Link to="/dash/users">
                    <Button variant="outlined" color="info" size="small" startIcon={<VisibilityIcon />} sx={{ mb: 0 }}>
                      View All Users
                    </Button>
                  </Link>
                </Stack>
            </Box>
            <Stack direction={isMobile ? "column" : "row"} gap={5}>
                <Box sx={{
                  width: isMobile ? "95%" : '60%'
                }}>
                    <form onSubmit={onSaveUserClicked}>
                        <Box
                          display="grid"
                          gap="30px"
                          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                          sx={{
                            "& > div": { gridColumn: isMobile ? "span 4" : undefined },
                          }}
                        >
                            {/*Alert Messages to be displayed here*/}
                            {/*errMsg ?
                              <Alert ref={errRef}
                                variant="outlined"
                                sx={{ gridColumn: "span 4" }}
                                severity="error"
                                aria-live="assertive">
                                {errMsg}
                              </Alert> : ""*/}
                            {/*successMsg ?
                              <Alert ref={successRef}
                                variant="outlined"
                                sx={{ gridColumn: "span 4" }}
                                severity="success"
                                aria-live="assertive">
                                  {successMsg}
                              </Alert> : ""*/}

                            <TextField
                              fullWidth
                              variant="outlined"
                              type="text"
                              label="Unique Leaner's Number"
                              onChange={(e) => setUln(e.target.value)}
                              value={uln}
                              name="uln"
                              sx={{ gridColumn: "span 4" }}
                            />

                            <TextField
                              fullWidth
                              variant="outlined"
                              type="text"
                              label="First Name"
                              onChange={onFirstNameChanged}
                              value={firstName}
                              name="firstName"
                              sx={{ gridColumn: "span 2" }}
                            />

                            <TextField
                              fullWidth
                              variant="outlined"
                              type="text"
                              label="Last Name"
                              onChange={onLastNameChanged}
                              value={lastName}
                              name="lastName"
                              sx={{ gridColumn: "span 2" }}
                            />

                            <TextField
                              fullWidth
                              variant="outlined"
                              type="email"
                              label="Email"
                              onChange={onEmailChanged}
                              value={email}
                              name="email"
                              sx={{ gridColumn: "span 4" }}
                            />

                            <TextField
                              fullWidth
                              variant="outlined"
                              type="text"
                              label="Phone Number"
                              onChange={onPhoneChanged}
                              value={phone}
                              name="phone"
                              sx={{ gridColumn: "span 4" }}
                            />

                            <FormControl sx={{ gridColumn: 'span 2' }}>
                              <InputLabel id="state-id">State</InputLabel>
                              <Select
                                fullWidth
                                labelId="state-id"
                                variant="outlined"
                                value={stateOfRez}
                                name="stateOfRez"
                                onChange={(e) => setStateOfRez(e.target.value)}
                              >
                                {options1}
                              </Select>
                            </FormControl>

                            <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
                              <InputLabel id="center-id">Centers</InputLabel>
                              <Select
                                labelId="center-id"
                                variant="outlined"
                                value={centers}
                                name="centers"
                                onChange={(e) => setCenters(e.target.value)}
                                multiple
                              >
                                {options2}
                              </Select>
                            </FormControl>

                            <FormControl fullWidth sx={{ gridColumn: 'span 4' }}>
                              <InputLabel id="qualifications-id">Qualifications</InputLabel>
                              <Select
                                labelId="qualifications-id"
                                variant="outlined"
                                name="qualifications"
                                value={qualifications}
                                onChange={(e) => setQualifications(e.target.value)}
                                multiple
                              >
                                {options3}
                              </Select>
                            </FormControl>

                            <FormControl fullWidth sx={{ gridColumn: 'span 4' }}>
                              <InputLabel id="roles-id">Roles</InputLabel>
                              <Select
                                labelId="roles-id"
                                variant="outlined"
                                name="roles"
                                value={roles}
                                onChange={(e) => setRoles(e.target.value)}
                                multiple
                              >
                                {options}
                              </Select>
                            </FormControl>
                        </Box>
                        <Box display="flex" mt="30px">
                          <LoadingButton type="submit" loading={isLoading} disabled={!canSave} sx={{color:"#fff", width:"50%"}} variant="contained">
                            Add New User
                          </LoadingButton>
                        </Box>
                    </form>
                </Box>

                <Grid gap={4} sx={{ width: isMobile ? "95%" : "40%" }}>
                  <Grid item xs={12} md={4}>
                    <Card sx={{ bgcolor: colors.primary[400] }} >
                      <CardHeader
                        title={"BULK REGISTER USERS"}
                        subheader={"UPLOAD .csv FILE"}
                      />
                      <CardContent>
                          <Typography variant="body2" color="text.secondary">
                            Register 2 or more Users (Trainee, Assessor, IQA, EQA) at once by filling and uploading <a
                              href="/bulk_users.csv" // Adjusted path
                              style={{ color: "#49a139", textDecoration: "underline" }}
                              target="_blank"
                              download="bulk_users.csv" // Added download attribute
                            >
                              <u>this .csv file</u>
                            </a>.
                          </Typography>
                      </CardContent>
                      <CardActions>
                          <LoadingButton component="label" variant="contained" color="success" sx={{p: "5px 40px"}} startIcon={<CloudUploadIcon />}>
                              Upload file
                              <VisuallyHiddenInput
                                type="file"
                                name="file"
                                accept=".csv"
                                onChange={(event) => {
                                  handleBulkUploadFileChange(event)
                                  handleBulkUpload(event)
                                }}
                              />
                          </LoadingButton>
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>
            </Stack>
        </Box>
        </>
    )
}
export default NewUserForm
