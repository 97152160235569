import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'
import { useGetRolesQuery } from './rolesApiSlice'
import { memo } from 'react'


import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#030702',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#0b1c08',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#091506',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const Role = ({ roleId }) => {

    const { role } = useGetRolesQuery("rolesList", {
        selectFromResult: ({ data }) => ({
            role: data?.entities[roleId]
        }),
    })

    const navigate = useNavigate()

    if (role) {
        const handleEdit = () => navigate(`/dash/roles/${roleId}`)

        //const roleRolesString = role.roles.toString().replaceAll(',', ', ')

        //const cellStatus = ''

        return (
            <StyledTableRow
                key={role.roleId}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <StyledTableCell component="th" scope="row">
                  {role.roleId}
                </StyledTableCell>
                <StyledTableCell>{role.roleType}</StyledTableCell>
                <StyledTableCell align="center">
                    <button
                        className="icon-button table__button"
                        onClick={handleEdit}
                    >
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                </StyledTableCell>
            </StyledTableRow>
        )

    } else return null
}

const memoizedRole = memo(Role)

export default memoizedRole
