import { Outlet } from 'react-router-dom'
import DashHeader from './DashHeader2'
import DashFooter from './DashFooter'

const DashLayout = () => {
    return (
        <>
            <DashHeader /> {/*could be switched to Sidebar*/}
            <div>
                <Outlet />
            </div>
            <DashFooter />
        </>
    )
}

export default DashLayout
