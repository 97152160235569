import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'
import { useGetAssessorTraineeAssignmentsQuery } from './assessortraineeassignmentApiSlice'
import { memo } from 'react'

import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#030702',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#0b1c08',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#091506',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const AssessorTraineeAssignment = ({ assessorTraineeAssignmentId }) => {

    const { assessorTraineeAssignment } = useGetAssessorTraineeAssignmentsQuery("assessorTraineeAssignmentsList", {
        selectFromResult: ({ data }) => ({
            assessorTraineeAssignment: data?.entities[assessorTraineeAssignmentId]
        }),
    })

    const navigate = useNavigate()

    if (assessorTraineeAssignment) {
        const handleEdit = () => navigate(`/dash/assessor-trainee-assignments/${assessorTraineeAssignmentId}`)

        return (
            <StyledTableRow
                key={assessorTraineeAssignment.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <StyledTableCell component="th" scope="row">
                  {assessorTraineeAssignment.id}
                </StyledTableCell>
                <StyledTableCell>{assessorTraineeAssignment.assessor}</StyledTableCell>
                <StyledTableCell>{assessorTraineeAssignment.trainee}</StyledTableCell>
                <StyledTableCell>{assessorTraineeAssignment.createdAt}</StyledTableCell>
                <StyledTableCell align="center">
                    <button
                        className="icon-button table__button"
                        onClick={handleEdit}
                    >
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                </StyledTableCell>
            </StyledTableRow>
        )

    } else return null
}

const memoizedAssessorTraineeAssignment = memo(AssessorTraineeAssignment)

export default memoizedAssessorTraineeAssignment
