import { useState, useEffect } from "react"
import { useUpdateUserMutation, useDeleteUserMutation } from "./usersApiSlice"
import { Link, useNavigate } from "react-router-dom"
import { STATES } from '../../config/states'
import { useGetRolesQuery } from '../roles/rolesApiSlice'
import { useGetCentersQuery } from '../centers/centersApiSlice'
import { useGetQualificationsQuery } from '../qualifications/qualificationsApiSlice'
import { useSelector } from 'react-redux'
import useTitle from '../../hooks/useTitle'
import useAuth from "../../hooks/useAuth"

import LoadingButton from '@mui/lab/LoadingButton'
import { Box, useTheme, TextField, Stack, Button, FormControl, InputLabel, Select, MenuItem } from "@mui/material"
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Alert from '@mui/material/Alert'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import HomeIcon from '@mui/icons-material/Home'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { styled } from '@mui/material/styles'
import { tokens } from "../../theme"
import useMediaQuery from "@mui/material/useMediaQuery"
import Header from "../../components/Header"

const USER_REGEX = /^[A-z]{3,20}$/
const EMAIL_REGEX = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/
const PHONE_REGEX = /^[0-9]{11}$/

const EditUserForm = ({ user }) => {
    useTitle('NIOB Skill CaMP: New User')
    const isMobile = useMediaQuery("(max-width:600px)")
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const navigate = useNavigate()
    const { data: rolesData } = useGetRolesQuery()
    const { data: centersData } = useGetCentersQuery()
    const { data: qualificationsData } = useGetQualificationsQuery()

    const { isSuperAdmin } = useAuth()

    const [updateUser, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateUserMutation()

    const [deleteUser, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteUserMutation()

    const [firstName, setFirstName] = useState(user.firstName)
    const [validFirstName, setValidFirstName] = useState(false)
    const [lastName, setLastName] = useState(user.lastName)
    const [validLastName, setValidLastName] = useState(false)
    const [email, setEmail] = useState(user.email)
    const [validEmail, setValidEmail] = useState(false)
    const [phone, setPhone] = useState(user.phone)
    const [validPhone, setValidPhone] = useState(false)
    const [roles, setRoles] = useState(user.roles)
    const [stateOfRez, setStateOfRez] = useState(user.stateOfRez)
    const [centers, setCenters] = useState(user.centers)
    const [qualifications, setQualifications] = useState(user.qualifications)
    const [verified, setVerified] = useState(user.verified)

    useEffect(() => {
        setValidFirstName(USER_REGEX.test(firstName))
    }, [firstName])

    useEffect(() => {
        setValidLastName(USER_REGEX.test(lastName))
    }, [lastName])

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email))
    }, [email])

    useEffect(() => {
        setValidPhone(PHONE_REGEX.test(phone))
    }, [phone])

    useEffect(() => {
        if (isSuccess || isDelSuccess) {
            setFirstName('')
            setLastName('')
            setEmail('')
            setPhone('')
            setRoles([])
            setQualifications([])
            setVerified(false)
            navigate('/dash/users')
        }
    }, [isSuccess, isDelSuccess, navigate])

    const onFirstNameChanged = e => setFirstName(e.target.value)
    const onLastNameChanged = e => setLastName(e.target.value)
    const onEmailChanged = e => setEmail(e.target.value)
    const onPhoneChanged = e => setPhone(e.target.value)
    const onVerifiedChanged = () => setVerified(prev => !prev)

    const onDeleteUserClicked = async () => {
        await deleteUser({ id: user.id })
    }

    const onSaveUserClicked = async (e) => {
        e.preventDefault()

        // Check that if `uln` === '0000' then role should be only 'Trainee'
        if (user.uln.includes('TRA')) {
            const filteredRoleIds = rolesData.ids
            .map(id => rolesData.entities[id]) // Map each id to its corresponding role object
            .filter(role => roles.includes('TRAINEE')) // Filter by roleType array
            .map(role => role.id)

            const filteredCenterIds = centersData.ids
            .map(id => centersData.entities[id]) // Map each id to its corresponding center object
            .filter(center => centers.includes(center.centerName)) // Filter by centerName array
            .map(center => center.id)

            const filteredQualificationIds = qualificationsData.ids
            .map(id => qualificationsData.entities[id]) // Map each id to its corresponding qualification object
            .filter(qualification => qualifications.includes(qualification.qualificationType)) // Filter by qualificationType array
            .map(qualification => qualification.id)

            setRoles([])
            setRoles(filteredRoleIds)

            await updateUser({ id: user.id, verified, firstName, lastName, email, phone, roles, stateOfRez, filteredCenterIds, filteredQualificationIds })
        } else {
            // get the corresponding ids of all role, center and qualification values
            const filteredRoleIds = rolesData.ids
            .map(id => rolesData.entities[id]) // Map each id to its corresponding role object
            .filter(role => roles.includes(role.roleType)) // Filter by roleType array
            .map(role => role.id)

            const filteredCenterIds = centersData.ids
            .map(id => centersData.entities[id]) // Map each id to its corresponding center object
            .filter(center => centers.includes(center.centerName)) // Filter by centerName array
            .map(center => center.id)

            const filteredQualificationIds = qualificationsData.ids
            .map(id => qualificationsData.entities[id]) // Map each id to its corresponding qualification object
            .filter(qualification => qualifications.includes(qualification.qualificationType)) // Filter by qualificationType array
            .map(qualification => qualification.id)

            await updateUser({ id: user.id, verified, firstName, lastName, email, phone, filteredRoleIds, stateOfRez, filteredCenterIds, filteredQualificationIds })
        }

    }

    const errContent = error?.data?.message ?? ''

    /*const handleMultiSelectChange = (event) => {
        const { value } = event.target
        setQualifications(value)
    }*/

    const options1 = Object.values(STATES).map(_state => (
        <MenuItem key={_state} value={_state}>{_state}</MenuItem>
    ))

    const options2 = centersData?.entities ? Object.entries(centersData.entities).map(([id, center]) => (
        <MenuItem key={id} value={center.centerName}>{center.centerName}</MenuItem>
    )) : null

    const options3 = qualificationsData?.entities ? Object.entries(qualificationsData.entities).map(([id, qualification]) => (
        <MenuItem key={id} value={qualification.qualificationType}>{qualification.qualificationType}</MenuItem>
    )) : null

    const options = rolesData?.entities ? Object.entries(rolesData.entities).map(([id, role]) => (
        <MenuItem key={id} value={role.roleType}>{role.roleType}</MenuItem>
    )) : null

    const canSave = [roles.length, centers.length, qualifications.length, validFirstName, validLastName, validEmail, validPhone].every(Boolean) && !isLoading

    return (
      <>
      <Header title="EDIT USER" subtitle="Change a User's record" />
      <Box sx={{
          p: "2% 2% 5% 2%",
          m: isMobile ? "10% 2% 150px 2%" : "2% 2% 10% 2%",
          "& .MuiStack-root .MuiButton-outlined": {color:'#0b1c08', border:'1px solid #0b1c08'},
          "& .MuiStack-root .MuiButton-outlined:hover": {backgroundColor:'#7fd47010'}
        }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: "60%" }}>

                <Stack direction={isMobile ? "column" : "row"} gap={1} mt="20px" mb="20px">
                  <Link to="/dash">
                    <Button variant={isMobile ? "contained" : "outlined"} color="info" size="small" startIcon={<HomeIcon />} sx={{ mb: 0, width:isMobile ? "100%" : null }}>
                      Home
                    </Button>
                  </Link>
                  <Link to="/dash/users">
                    <Button variant={isMobile ? "contained" : "outlined"} color="success" size="small" startIcon={<VisibilityIcon />} sx={{ mb: 0, width:isMobile ? "100%" : null }}>
                      View All Users
                    </Button>
                  </Link>
                  {isSuperAdmin && <Button
                    onClick={() => {onDeleteUserClicked()}}
                    variant={isMobile ? "contained" : "outlined"}
                    color="error"
                    size="small"
                    startIcon={<DeleteIcon />}
                    sx={{ mb: 0, width:isMobile ? "100%" : null }}
                  >
                    Delete this User
                  </Button>}
                </Stack>
            </Box>
            <Stack direction={isMobile ? "column" : "row"} gap={5}>
                <Box sx={{
                  width: isMobile ? "95%" : '60%'
                }}>
                    <form onSubmit={onSaveUserClicked}>
                        <Box
                          display="grid"
                          gap="30px"
                          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                          sx={{
                            "& > div": { gridColumn: isMobile ? "span 4" : undefined },
                          }}
                        >
                            {/*Alert Messages to be displayed here*/}
                            {/*errMsg ?
                              <Alert ref={errRef}
                                variant="outlined"
                                sx={{ gridColumn: "span 4" }}
                                severity="error"
                                aria-live="assertive">
                                {errMsg}
                              </Alert> : ""*/}
                            {/*successMsg ?
                              <Alert ref={successRef}
                                variant="outlined"
                                sx={{ gridColumn: "span 4" }}
                                severity="success"
                                aria-live="assertive">
                                  {successMsg}
                              </Alert> : ""*/}

                            <TextField
                              fullWidth
                              variant="outlined"
                              type="text"
                              label="First Name"
                              onChange={onFirstNameChanged}
                              value={firstName}
                              name="firstName"
                              sx={{ gridColumn: "span 2" }}
                            />

                            <TextField
                              fullWidth
                              variant="outlined"
                              type="text"
                              label="Last Name"
                              onChange={onLastNameChanged}
                              value={lastName}
                              name="lastName"
                              sx={{ gridColumn: "span 2" }}
                            />

                            <TextField
                              fullWidth
                              variant="outlined"
                              type="email"
                              label="Email"
                              onChange={onEmailChanged}
                              value={email}
                              name="email"
                              sx={{ gridColumn: "span 4" }}
                            />

                            <TextField
                              fullWidth
                              variant="outlined"
                              type="text"
                              label="Phone Number"
                              onChange={onPhoneChanged}
                              value={phone}
                              name="phone"
                              sx={{ gridColumn: "span 4" }}
                            />

                            <FormControl sx={{ gridColumn: 'span 2' }}>
                              <InputLabel id="state-id">State</InputLabel>
                              <Select
                                fullWidth
                                labelId="state-id"
                                variant="outlined"
                                value={stateOfRez}
                                name="stateOfRez"
                                onChange={(e) => setStateOfRez(e.target.value)}
                              >
                                {options1}
                              </Select>
                            </FormControl>

                            <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
                              <InputLabel id="center-id">Centres</InputLabel>
                              <Select
                                labelId="center-id"
                                variant="outlined"
                                value={centers}
                                selected={user.centers}
                                name="centers"
                                onChange={(e) => setCenters(e.target.value)}
                                multiple
                              >
                                {options2}
                              </Select>
                            </FormControl>

                            <FormGroup sx={{ gridColumn: 'span 2' }}>
                                <FormControlLabel onChange={onVerifiedChanged} checked={verified} control={<Checkbox sx={{color:"#49a139"}} />} label="Verified" />
                            </FormGroup>

                            <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
                              <InputLabel id="qualifications-id">Qualifications</InputLabel>
                              <Select
                                labelId="qualifications-id"
                                variant="outlined"
                                name="qualifications"
                                value={qualifications}
                                onChange={(e) => setQualifications(e.target.value)}
                                multiple
                              >
                                {options3}
                              </Select>
                            </FormControl>

                            <FormControl fullWidth sx={{ gridColumn: 'span 4' }}>
                              <InputLabel id="roles-id">Roles</InputLabel>
                              <Select
                                labelId="roles-id"
                                variant="outlined"
                                name="roles"
                                value={roles}
                                onChange={(e) => setRoles(e.target.value)}
                                multiple
                              >
                                {options}
                              </Select>
                            </FormControl>
                        </Box>
                        <Box display="flex" mt="30px">
                          <LoadingButton type="submit" loading={isLoading} sx={{color:"#fff", width:"50%"}} variant="contained">
                            Update User Record
                          </LoadingButton>
                        </Box>
                    </form>
                </Box>
            </Stack>
        </Box>
        </>
    )
}
export default EditUserForm
