import { useParams, useNavigate, Link } from 'react-router-dom'
import { useGetCenterApplicationsQuery, useUpdateCenterApplicationMutation } from './centerapplicationApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'
import Header from "../../components/Header"

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import LoadingButton from '@mui/lab/LoadingButton'
import DashHeader from '../../components/DashHeader2'
import { Typography, Box, Card, Divider, InputAdornment, MenuItem, IconButton, CardContent, Grid, FormControl, Button, Tabs, Tab } from "@mui/material"
import { useTheme, TextField, Stack, InputLabel, Select, CardHeader, CardActions, Radio, RadioGroup, FormLabel, FormControlLabel } from "@mui/material"
import { FormGroup, Checkbox, List, ListItem, Alert, Collapse } from "@mui/material"
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import useMediaQuery from "@mui/material/useMediaQuery"
import React, { useState, useEffect } from "react"
import { VisibilityOff, Visibility } from "@mui/icons-material"
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { tokens } from "../../theme"
import { STATES } from '../../config/states'
import { CENTERTYPES } from '../../config/centertypes'
import { QUALIFICATIONS } from '../../config/qualifications'
import { useGetQualificationsQuery } from '../qualifications/qualificationsApiSlice'
import dayjs from 'dayjs'
import axios from 'axios'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const CenterQualityCheck = ({centerapplication}) => {
    useTitle('NIOB Skill CaMP: Centre Quality Check')
    const isMobile = useMediaQuery("(max-width:600px)")
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const maxYear = new Date().getFullYear().toString()
    const today = new Date()

    const [updateCenterApplication, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateCenterApplicationMutation()

    const navigate = useNavigate()

    const { id } = useParams()
    const { data: qualificationsData } = useGetQualificationsQuery()


    //CENTER DETAILS INITIALIZE
    const [centerName] = useState(centerapplication?.centerName)
    const [yearOfInc] = useState(centerapplication?.yearOfInc)
    const [cacNumber] = useState(centerapplication?.cacNumber)
    const [permanentCenterAddress] = useState(centerapplication?.permanentCenterAddress)
    const [trainingCenterAddress] = useState(centerapplication?.trainingCenterAddress)
    const [phone] = useState(centerapplication?.phone)
    const [email] = useState(centerapplication?.email)
    const [website] = useState(centerapplication?.website)
    const [centerHead] = useState(centerapplication?.centerHead)
    const [centerHeadPhone] = useState(centerapplication?.centerHeadPhone)
    const [centerHeadEmail] = useState(centerapplication?.centerHeadEmail)
    const [qualifications] = useState(centerapplication?.qualifications)
    const [stateOfRez] = useState(centerapplication?.stateOfRez)
    const [value, setValue] = React.useState(0)

    //CENTER ORGANISATION INITIALIZE
    const [centerType] = useState(centerapplication?.centerType)
    const [satelliteCenterName1] = useState(centerapplication?.satelliteCenterName1)
    const [satelliteCenterAddress1] = useState(centerapplication?.satelliteCenterAddress1)
    const [satelliteCenterName2] = useState(centerapplication?.satelliteCenterName2)
    const [satelliteCenterAddress2] = useState(centerapplication?.satelliteCenterAddress2)
    const [otherApprovals] = React.useState(centerapplication?.otherApprovals[0])
    const [awardingBody1] = useState(centerapplication?.awardingBody1)
    const [awardingBody1Qualification] = useState(centerapplication?.awardingBody1Qualification)
    const [awardingBody1Level] = useState(centerapplication?.awardingBody1Level)
    const [awardingBody1DateApproved] = useState(centerapplication?.awardingBody1DateApproved)
    const [awardingBody1ApprovalStatus] = useState(centerapplication?.awardingBody1ApprovalStatus)
    const [awardingBody2] = useState(centerapplication?.awardingBody2)
    const [awardingBody2Qualification] = useState(centerapplication?.awardingBody2Qualification)
    const [awardingBody2Level] = useState(centerapplication?.awardingBody2Level)
    const [awardingBody2DateApproved] = useState(centerapplication?.awardingBody2DateApproved)
    const [awardingBody2ApprovalStatus] = useState(centerapplication?.awardingBody2ApprovalStatus)


    //ORGANISATION STAFFING INITIALIZE
    const [centerManagerName] = useState(centerapplication?.centerManagerName)
    const [centerManagerQualification] = useState(centerapplication?.centerManagerQualification)
    const [assessor1Name] = useState(centerapplication?.assessor1Name)
    const [assessor1Qualification] = useState(centerapplication?.assessor1Qualification)
    const [assessor2Name] = useState(centerapplication?.assessor2Name)
    const [assessor2Qualification] = useState(centerapplication?.assessor2Qualification)
    const [internalVerifierName] = useState(centerapplication?.internalVerifierName)
    const [internalVerifierQualification] = useState(centerapplication?.internalVerifierQualification)
    const [liaisonOfficerName] = useState(centerapplication?.liaisonOfficerName)
    const [liaisonOfficerQualification] = useState(centerapplication?.liaisonOfficerQualification)
    const [resourceOfficerName] = useState(centerapplication?.resourceOfficerName)
    const [resourceOfficerQualification] = useState(centerapplication?.resourceOfficerQualification)
    const [otherStaffName] = useState(centerapplication?.otherStaffName)
    const [otherStaffQualification] = useState(centerapplication?.otherStaffQualification)
    const [otherStaffDesignation] = useState(centerapplication?.otherStaffDesignation)


    //PHYSICAL FACILITIES INITIALIZE
    const [trainingRoomQuantity] = useState(centerapplication?.trainingRoomQuantity)
    const [trainingRoomFloorArea] = useState(centerapplication?.trainingRoomFloorArea)
    const [workshopQuantity] = useState(centerapplication?.workshopQuantity)
    const [workshopFloorArea] = useState(centerapplication?.workshopFloorArea)
    const [practicalTrainingSpaceQuantity] = useState(centerapplication?.practicalTrainingSpaceQuantity)
    const [practicalTrainingSpaceFloorArea] = useState(centerapplication?.practicalTrainingSpaceFloorArea)
    const [technicalLabQuantity] = useState(centerapplication?.technicalLabQuantity)
    const [technicalLabFloorArea] = useState(centerapplication?.technicalLabFloorArea)
    const [recordsRoomQuantity] = useState(centerapplication?.recordsRoomQuantity)
    const [recordsRoomFloorArea] = useState(centerapplication?.recordsRoomFloorArea)
    const [auditoriumQuantity] = useState(centerapplication?.auditoriumQuantity)
    const [auditoriumFloorArea] = useState(centerapplication?.auditoriumFloorArea)
    const [libraryQuantity] = useState(centerapplication?.libraryQuantity)
    const [libraryFloorArea] = useState(centerapplication?.libraryFloorArea)
    const [utilityQuantity] = useState(centerapplication?.utilityQuantity)
    const [utilityFloorArea] = useState(centerapplication?.utilityFloorArea)
    const [mgtOfficesQuantity] = useState(centerapplication?.mgtOfficesQuantity)
    const [mgtOfficesFloorArea] = useState(centerapplication?.mgtOfficesFloorArea)
    const [staffRoomQuantity] = useState(centerapplication?.staffRoomQuantity)
    const [staffRoomFloorArea] = useState(centerapplication?.staffRoomFloorArea)
    const [toiletQuantity] = useState(centerapplication?.toiletQuantity)
    const [toiletFloorArea] = useState(centerapplication?.toiletFloorArea)
    const [firstAidFireFacilityQuantity] = useState(centerapplication?.firstAidFireFacilityQuantity)
    const [firstAidFireFacilityFloorArea] = useState(centerapplication?.firstAidFireFacilityFloorArea)
    const [hostelQuantity] = useState(centerapplication?.hostelQuantity)
    const [hostelFloorArea] = useState(centerapplication?.hostelFloorArea)
    const [tradeToolsQuantity] = useState(centerapplication?.tradeToolsQuantity)
    const [tradeToolsFloorArea] = useState(centerapplication?.tradeToolsFloorArea)
    const [otherFacilityName] = useState(centerapplication?.otherFacilityName)
    const [otherFacilityQuantity] = useState(centerapplication?.otherFacilityQuantity)
    const [otherFacilityFloorArea] = useState(centerapplication?.otherFacilityFloorArea)


    //TRAINING STAFF INITIALIZE
    const [trainingStaff1Name] = useState(centerapplication?.trainingStaff1Name)
    const [trainingStaff1Gender] = useState(centerapplication?.trainingStaff1Gender)
    const [trainingStaff1Qualification] = useState(centerapplication?.trainingStaff1Qualification)
    const [trainingStaff1Experience] = useState(centerapplication?.trainingStaff1Experience)
    const [trainingStaff1Specialization] = useState(centerapplication?.trainingStaff1Specialization)
    const [trainingStaff2Name] = useState(centerapplication?.trainingStaff2Name)
    const [trainingStaff2Gender] = useState(centerapplication?.trainingStaff2Gender)
    const [trainingStaff2Qualification] = useState(centerapplication?.trainingStaff2Qualification)
    const [trainingStaff2Experience] = useState(centerapplication?.trainingStaff2Experience)
    const [trainingStaff2Specialization] = useState(centerapplication?.trainingStaff2Specialization)
    const [trainingStaff3Name] = useState(centerapplication?.trainingStaff3Name)
    const [trainingStaff3Gender] = useState(centerapplication?.trainingStaff3Gender)
    const [trainingStaff3Qualification] = useState(centerapplication?.trainingStaff3Qualification)
    const [trainingStaff3Experience] = useState(centerapplication?.trainingStaff3Experience)
    const [trainingStaff3Specialization] = useState(centerapplication?.trainingStaff3Specialization)


    //EQA FEEDBACK 1 INITIALIZE
    const [nsqCertifiedAssessors] = useState(centerapplication?.nsqCertifiedAssessors)
    const [assessorsCount] = useState(centerapplication?.assessorsCount)
    const [nsqCertifiedVerifiers] = useState(centerapplication?.nsqCertifiedVerifiers)
    const [verifiersCount] = useState(centerapplication?.verifiersCount)
    const [nsqCertifiedCenterManager] = useState(centerapplication?.nsqCertifiedCenterManager)
    const [liaisonOfficerPresent] = useState(centerapplication?.liaisonOfficerPresent)
    const [resourceOfficerPresent] = useState(centerapplication?.resourceOfficerPresent)
    const [siteBankPresent] = useState(centerapplication?.siteBankPresent)
    const [nsqCertifiedAssessorsEQAView, setNsqCertifiedAssessorsEQAView] = useState('')
    const [assessorsCountEQAView, setAssessorsCountEQAView] = useState('')
    const [nsqCertifiedVerifiersEQAView, setNsqCertifiedVerifiersEQAView] = useState('')
    const [verifiersCountEQAView, setVerifiersCountEQAView] = useState('')
    const [nsqCertifiedCenterManagerEQAView, setNsqCertifiedCenterManagerEQAView] = useState('')
    const [liaisonOfficerPresentEQAView, setLiaisonOfficerPresentEQAView] = useState('')
    const [resourceOfficerPresentEQAView, setResourceOfficerPresentEQAView] = useState('')
    const [siteBankPresentEQAView, setSiteBankPresentEQAView] = useState('')
    const [eqaFeedbackOnCenterHumanResource, setEqaFeedbackOnCenterHumanResource] = useState('')


    //EQA FEEDBACK 2 INITIALIZE
    const [trainingRoomAdequate] = useState(centerapplication?.trainingRoomAdequate)
    const [dataRoomAdequate] = useState(centerapplication?.dataRoomAdequate)
    const [workshopAdequate] = useState(centerapplication?.workshopAdequate)
    const [practicalSpaceAdequate] = useState(centerapplication?.practicalSpaceAdequate)
    const [techLabAdequate] = useState(centerapplication?.techLabAdequate)
    const [staffRoomAdequate] = useState(centerapplication?.staffRoomAdequate)
    const [toiletAdequate] = useState(centerapplication?.toiletAdequate)
    const [firstAidFireAdequate] = useState(centerapplication?.firstAidFireAdequate)
    const [hostelsAdequate] = useState(centerapplication?.hostelsAdequate)
    const [tradeToolsAdequate] = useState(centerapplication?.tradeToolsAdequate)
    const [auditoriumAvailable] = useState(centerapplication?.auditoriumAvailable)
    const [libraryAdequate] = useState(centerapplication?.libraryAdequate)
    const [waterAndPowerAdequate] = useState(centerapplication?.waterAndPowerAdequate)
    const [mgtOfficesAdequate] = useState(centerapplication?.mgtOfficesAdequate)
    const [trainingRoomAdequateEQAView, setTrainingRoomAdequateEQAView] = useState('')
    const [dataRoomAdequateEQAView, setDataRoomAdequateEQAView] = useState('')
    const [workshopAdequateEQAView, setWorkshopAdequateEQAView] = useState('')
    const [practicalSpaceAdequateEQAView, setPracticalSpaceAdequateEQAView] = useState('')
    const [techLabAdequateEQAView, setTechLabAdequateEQAView] = useState('')
    const [staffRoomAdequateEQAView, setStaffRoomAdequateEQAView] = useState('')
    const [toiletAdequateEQAView, setToiletAdequateEQAView] = useState('')
    const [firstAidFireAdequateEQAView, setFirstAidFireAdequateEQAView] = useState('')
    const [hostelsAdequateEQAView, setHostelsAdequateEQAView] = useState('')
    const [tradeToolsAdequateEQAView, setTradeToolsAdequateEQAView] = useState('')
    const [auditoriumAvailableEQAView, setAuditoriumAvailableEQAView] = useState('')
    const [libraryAdequateEQAView, setLibraryAdequateEQAView] = useState('')
    const [waterAndPowerAdequateEQAView, setWaterAndPowerAdequateEQAView] = useState('')
    const [mgtOfficesAdequateEQAView, setMgtOfficesAdequateEQAView] = useState('')
    const [eqaFeedbackOnCenterFacilities, setEqaFeedbackOnCenterFacilities] = useState('')


    //EQA FEEDBACK 3 INITIALIZE
    const [nosAvailable] = useState(centerapplication?.nosAvailable)
    const [trainingManualAvailable] = useState(centerapplication?.trainingManualAvailable)
    const [nosAndManualAlignment] = useState(centerapplication?.nosAndManualAlignment)
    const [otherTrainingMaterialsAvailable] = useState(centerapplication?.otherTrainingMaterialsAvailable)
    const [nsqGuidelineAvailable] = useState(centerapplication?.nsqGuidelineAvailable)
    const [staffCompliantWithStandards] = useState(centerapplication?.staffCompliantWithStandards)
    const [objectivesClear] = useState(centerapplication?.objectivesClear)
    const [classParticipation] = useState(centerapplication?.classParticipation)
    const [nosAvailableEQAView, setNosAvailableEQAView] = useState('')
    const [trainingManualAvailableEQAView, setTrainingManualAvailableEQAView] = useState('')
    const [nosAndManualAlignedEQAView, setNosAndManualAlignedEQAView] = useState('')
    const [otherTrainingMaterialsAvailableEQAView, setOtherTrainingMaterialsAvailableEQAView] = useState('')
    const [nsqGuidelineAvailableEQAView, setNsqGuidelineAvailableEQAView] = useState('')
    const [staffCompliantWithStandardsEQAView, setStaffCompliantWithStandardsEQAView] = useState('')
    const [objectivesClearEQAView, setObjectivesClearEQAView] = useState('')
    const [classParticipationEQAView, setClassParticipationEQAView] = useState('')
    const [eqaFeedbackOnCenterNOSNSQ, setEqaFeedbackOnCenterNOSNSQ] = useState('')


    //EQA FINAL REPORT
    const [conduciveLearningEnvironment, setConduciveLearningEnvironment] = useState('')
    const [policyAndProcedure, setPolicyAndProcedure] = useState('')
    const [practicalWorkPlacementArrangement, setPracticalWorkPlacementArrangement] = useState('')
    const [sufficientQualityAssuranceTeam, setSufficientQualityAssuranceTeam] = useState('')
    const [learnersSupportSystem, setLearnersSupportSystem] = useState('')
    const [eqaFinalFeedbackOnCenter, setEqaFinalFeedbackOnCenter] = useState('')


    //EQA SUPPORTING DOCUMENT
    const [eqaReports, setEqaReports] = useState(null)
    const [eqaReportsURL, setEqaReportsURL] = useState("")
    const [previewEqaReports, setPreviewEqaReports] = useState()


    const [alert, setAlert] = useState(null)



    //HANDLE CHANGE
    const onEqaFeedbackOnCenterHumanResourceChanged = e => setEqaFeedbackOnCenterHumanResource(e.target.value)
    const onEqaFeedbackOnCenterFacilitiesChanged = e => setEqaFeedbackOnCenterFacilities(e.target.value)
    const onEqaFeedbackOnCenterNOSNSQChanged = e => setEqaFeedbackOnCenterNOSNSQ(e.target.value)
    const onEqaFinalFeedbackOnCenterChanged = e => setEqaFinalFeedbackOnCenter(e.target.value)

    //SUPPORTING DOCS HANDLE CHANGE
    const onSelectEqaReports = e => {
        if (e.target.files && e.target.files.length > 0) {
            setEqaReports(e.target.files[0])
        }
    }


    const canSave = [
        nsqCertifiedAssessorsEQAView !== "",
        assessorsCountEQAView !== "",
        nsqCertifiedVerifiersEQAView !== "",
        verifiersCountEQAView !== "",
        nsqCertifiedCenterManagerEQAView !== "",
        liaisonOfficerPresentEQAView !== "",
        resourceOfficerPresentEQAView !== "",
        siteBankPresentEQAView !== "",
        eqaFeedbackOnCenterHumanResource !== "",
        trainingRoomAdequateEQAView !== "",
        dataRoomAdequateEQAView !== "",
        workshopAdequateEQAView !== "",
        practicalSpaceAdequateEQAView !== "",
        techLabAdequateEQAView !== "",
        staffRoomAdequateEQAView !== "",
        toiletAdequateEQAView !== "",
        firstAidFireAdequateEQAView !== "",
        hostelsAdequateEQAView !== "",
        tradeToolsAdequateEQAView !== "",
        auditoriumAvailableEQAView !== "",
        libraryAdequateEQAView !== "",
        waterAndPowerAdequateEQAView !== "",
        mgtOfficesAdequateEQAView !== "",
        eqaFeedbackOnCenterFacilities !== "",
        nosAvailableEQAView !== "",
        trainingManualAvailableEQAView !== "",
        nosAndManualAlignedEQAView !== "",
        otherTrainingMaterialsAvailableEQAView !== "",
        nsqGuidelineAvailableEQAView !== "",
        staffCompliantWithStandardsEQAView !== "",
        objectivesClearEQAView !== "",
        classParticipationEQAView !== "",
        eqaFeedbackOnCenterNOSNSQ !== "",
        conduciveLearningEnvironment !== "",
        policyAndProcedure !== "",
        practicalWorkPlacementArrangement !== "",
        sufficientQualityAssuranceTeam !== "",
        learnersSupportSystem !== "",
        eqaFinalFeedbackOnCenter !== "",
        eqaReports
    ].every(Boolean) && !isLoading


    const onSaveCenterApplicationClicked = async e => {
        e.preventDefault()

        // Use axios to send and store Center Credentials and return filePath of stored credentials
        const formData = new FormData()
        formData.append('eqaReports', eqaReports)

        try {
            //const response = await axios.post('http://localhost:3500/upload-eqareport', formData, {
            const response = await axios.post('https://niob-skill-camp-api.onrender.com/upload-eqareport', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            if (response.status === 200) {
                console.log('File uploaded successfully. File path:', response.data.filePath)

                await updateCenterApplication({
                    id: centerapplication.id,
                    nsqCertifiedAssessorsEQAView,
                    assessorsCountEQAView,
                    nsqCertifiedVerifiersEQAView,
                    verifiersCountEQAView,
                    nsqCertifiedCenterManagerEQAView,
                    liaisonOfficerPresentEQAView,
                    resourceOfficerPresentEQAView,
                    siteBankPresentEQAView,
                    eqaFeedbackOnCenterHumanResource,
                    trainingRoomAdequateEQAView,
                    dataRoomAdequateEQAView,
                    workshopAdequateEQAView,
                    practicalSpaceAdequateEQAView,
                    techLabAdequateEQAView,
                    staffRoomAdequateEQAView,
                    toiletAdequateEQAView,
                    firstAidFireAdequateEQAView,
                    hostelsAdequateEQAView,
                    tradeToolsAdequateEQAView,
                    auditoriumAvailableEQAView,
                    libraryAdequateEQAView,
                    waterAndPowerAdequateEQAView,
                    mgtOfficesAdequateEQAView,
                    eqaFeedbackOnCenterFacilities,
                    nosAvailableEQAView,
                    trainingManualAvailableEQAView,
                    nosAndManualAlignedEQAView,
                    otherTrainingMaterialsAvailableEQAView,
                    nsqGuidelineAvailableEQAView,
                    staffCompliantWithStandardsEQAView,
                    objectivesClearEQAView,
                    classParticipationEQAView,
                    eqaFeedbackOnCenterNOSNSQ,
                    conduciveLearningEnvironment,
                    policyAndProcedure,
                    practicalWorkPlacementArrangement,
                    sufficientQualityAssuranceTeam,
                    learnersSupportSystem,
                    eqaFinalFeedbackOnCenter,
                    "eqaReportsURL": response.data.filePath
                })
              }
          } catch (error) {
              console.error('Error uploading file:', error)
              setAlert(`Error uploading file: ${error.message}`)
          }
    }

    useEffect(() => {
        if (isSuccess) {
            setAlert("EQA Report successfully submitted!")
            setTimeout(() => navigate("/dash", { replace: true }), 7000)
        }
    }, [isSuccess, navigate])


    const options1 = Object.values(STATES).map(_state => (
        <MenuItem key={_state} value={_state}>{_state}</MenuItem>
    ))

    const options2 = Object.values(CENTERTYPES).map(centerType => (
        <MenuItem key={centerType} value={centerType}>
            {centerType}
        </MenuItem>
    ))

    const options3 = qualificationsData?.entities ? Object.entries(qualificationsData.entities).map(([id, qualification]) => (
        <MenuItem key={id} value={qualification.qualificationType}>{qualification.qualificationType}</MenuItem>
    )) : null

    const options4 = Object.entries(QUALIFICATIONS).map(([key, value]) => (
        <MenuItem key={key} value={key}>{value}</MenuItem>
    ))

    const errContent = error?.data?.message ?? ''


    const handleChange = (event, newValue) => {
      setValue(newValue)
    }


    const content = (
      <>
      <Header title="CENTRES QUALITY CHECK" subtitle={`Quality Assurance for ${centerapplication.centerName.toUpperCase()}`} />
      <Box sx={{ width: '100%', mb: '70px', "& .MuiPaper-root": {backgroundColor: '#030702'}, p: "2% 2%" }}>

          <Box
            sx={{
              flexGrow: 1,
              bgcolor: '#F1F3F1',
              display: isMobile ? 'block' : 'flex',
              "& .MuiTab-root.Mui-selected": {
                  color: '#0b1c08',
                  fontWeight: 700
              },
              "& .MuiTab-root": {
                  alignItems: 'flex-start',
                  color: '#295C21',
                  borderBottom: '1px solid #0b1c081a'
              },
              "& .MuiTabs-indicator": {
                  backgroundColor: '#49a139'
              },
              "& .MuiFormHelperText-root": {
                  color: '#49a139'
              }
            }}
          >
              <Tabs
                orientation={isMobile ? "horizontal" : "vertical"}
                variant="scrollable"
                allowScrollButtonsMobile
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs"
                sx={{ borderRight: "1px solid #D7D9D7", minWidth: isMobile ? '100%' : '280px', color:"#49a139" }}
              >
                    <Tab label="Instructions" {...a11yProps(0)} />
                    <Tab label="A. CENTRE DETAILS" {...a11yProps(1)} />
                    <Tab label="B. CENTRE ORGANISATION" {...a11yProps(2)} />
                    <Tab label="C. CENTRE ORGANISATIONAL STAFFING" {...a11yProps(3)} />
                    <Tab label="D. CENTRE PHYSICAL FACILITIES" {...a11yProps(4)} />
                    <Tab label="E. CENTRE TRAINING STAFF" {...a11yProps(5)} />
                    <Tab label="EQA FEEDBACK 1" {...a11yProps(6)} />
                    <Tab label="EQA FEEDBACK 2" {...a11yProps(7)} />
                    <Tab label="EQA FEEDBACK 3" {...a11yProps(8)} />
                    <Tab label="EQA FINAL FEEDBACK" {...a11yProps(9)} />
                    <Tab label="EQA SUPPORTING DOCUMENT(s)" {...a11yProps(10)} />
              </Tabs>

              <form className="form" onSubmit={onSaveCenterApplicationClicked}>
                {/*Instructions*/}
                <TabPanel value={value} index={0} sx={{ width: isMobile ? "100%" : "90%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: isMobile ? "" : "470px",
                        overflowY: isMobile ? "none" : "scroll",
                        pr: 2
                      }}
                    >
                        <Typography sx={{color:'#295C21', fontSize:17, mb:3}}>
                            EQA FORM INSTRUCTIONS
                        </Typography>

                        <Box sx={{maxWidth: isMobile ? "90%" : "78%", mb:5}}>
                            <Typography sx={{color:'#0b1c08', fontSize:16, wordWrap: "break-word", mb:3}}>
                                Please use this form to conduct your quality assurance for <span sx={{color:"#49a139"}}>"{centerName}" </span>
                                Your input begins from the <Link onClick={() => {setValue(6)}}><u>EQA FEEDBACK 1</u></Link> section.
                                Complete all sections in BLOCK LETTERS .
                            </Typography>
                            <Typography sx={{color:'#295C21', fontSize:16, wordWrap: "break-word"}}>
                                Read the following conditions which apply to this quality assurance check, before you complete
                                this form:
                            </Typography>
                            <List sx={{fontSize:14, wordWrap: "break-word"}}>
                                <ListItem>
                                    1. Download this &nbsp;<a
                                      href="/Centre Visit Status Report.pdf" // Adjusted path
                                      style={{ color: "#49a139" }}
                                      target="_blank"
                                      download="Centre_Visit_Status_Report.pdf" // Added download attribute
                                    >"<u>Centre Visit Status Report</u>"</a>
                                </ListItem>
                                <ListItem>
                                    2. Complete the downloaded report and upload it in the &nbsp; <Link onClick={() => {setValue(10)}}><u>EQA SUPPORTING DOCUMENT(s)</u></Link>&nbsp; section.
                                </ListItem>
                                <ListItem>
                                    3. Your Centre and Qualification(s) approval will lapse if there is no activity within one year
                                    of approval being granted.
                                </ListItem>
                                <ListItem>
                                    4. Failure to provide accurate information may result in withdrawal of any Approval granted earlier.
                                </ListItem>
                            </List>
                        </Box>

                        <Button
                            onClick={() => {setValue(1)}}
                            sx={{color:"#fff"}}
                            variant="contained"
                        >Proceed to See Application</Button>

                    </Box>
                </TabPanel>

                {/*CENTRE DETAILS*/}
                <TabPanel value={value} index={1} sx={{ width: isMobile ? "100%" : "90%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: isMobile ? "" : "470px",
                        overflowY: isMobile ? "none" : "scroll",
                        pr: 2
                      }}
                    >
                        <Typography sx={{color:'#295C21', fontSize:12, mb:3}}>CENTRE DETAILS</Typography>
                        <TextField
                          variant="outlined"
                          type="text"
                          label="Centre Name"
                          disabled
                          value={centerName}
                          name="centerName"
                          sx={{ width: isMobile ? "100%" : "32%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            variant="outlined"
                            label={'Year of Incorporation'}
                            openTo="year"
                            disabled
                            value={dayjs(yearOfInc)}
                            views={['year']}
                            name="yearOfInc"
                            sx={{ width: isMobile ? "100%" : "32%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                          />
                        </LocalizationProvider>

                        <TextField
                          variant="outlined"
                          type="text"
                          label="CAC Number"
                          disabled
                          value={cacNumber}
                          name="cacNumber"
                          sx={{ width: isMobile ? "100%" : "32%", mb:"30px" }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">RC</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          multiline
                          rows={3}
                          type="text"
                          label="Permanent Centre Address"
                          disabled
                          value={permanentCenterAddress}
                          name="permanentCenterAddress"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          multiline
                          rows={3}
                          type="text"
                          label="Training Centre Address"
                          disabled
                          value={(trainingCenterAddress !== "") ? trainingCenterAddress : permanentCenterAddress}
                          name="trainingCenterAddress"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"30px" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Phone Number"
                          disabled
                          value={phone}
                          name="phone"
                          sx={{ width: isMobile ? "100%" : "32%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">+234</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="email"
                          label="Email"
                          disabled
                          value={email}
                          name="email"
                          sx={{ width: isMobile ? "100%" : "32%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Website"
                          disabled
                          value={website}
                          name="website"
                          sx={{ width: isMobile ? "100%" : "32%", mb:"30px" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Centre Head Fullname"
                          disabled
                          value={centerHead}
                          name="centerHead"
                          sx={{ width: isMobile ? "100%" : "32%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Centre Head Phone Number"
                          disabled
                          value={centerHeadPhone}
                          name="centerHeadPhone"
                          sx={{ width: isMobile ? "100%" : "32%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">+234</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="email"
                          label="Centre Head Email"
                          disabled
                          value={centerHeadEmail}
                          name="centerHeadEmail"
                          sx={{ width: isMobile ? "100%" : "32%", mb:"30px" }}
                        />

                        <FormControl sx={{ width: isMobile ? "100%" : "49%", mr: isMobile ? "0%" : "2%" }}>
                          <InputLabel id="qualifications-id">Qualifications</InputLabel>
                          <Select
                            labelId="qualifications-id"
                            variant="outlined"
                            name="qualifications"
                            value={qualifications}
                            disabled
                            multiple
                          >
                            {options3}
                          </Select>
                        </FormControl>

                        <FormControl sx={{ width: isMobile ? "100%" : "49%" }}>
                          <InputLabel id="state-id">State</InputLabel>
                          <Select
                            labelId="state-id"
                            variant="outlined"
                            name="stateOfRez"
                            value={stateOfRez}
                            disabled
                          >
                            {options1}
                          </Select>
                        </FormControl>

                        <Stack direction="row" gap={2}>
                            <Button
                                sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                onClick={() => {setValue(0)}}
                                variant="contained"
                            >Previous</Button>
                            <Button
                                sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                onClick={() => {setValue(2)}}
                                variant="contained"
                            >Next</Button>
                        </Stack>
                    </Box>

                </TabPanel>

                {/*YOUR ORGANISATION*/}
                <TabPanel value={value} index={2} sx={{ width: isMobile ? "100%" : "90%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: isMobile ? "" : "470px",
                        overflowY: isMobile ? "none" : "scroll",
                        pr: 2
                      }}
                    >
                        <Typography sx={{color:'#295C21', fontSize:12, mb:3}}>CENTRE ORGANISATION</Typography>

                        <FormControl sx={{width:"100%", mb:"30px"}}>
                          <InputLabel id="center-type-id">Centre Type</InputLabel>
                          <Select
                            labelId="center-type-id"
                            variant="outlined"
                            value={centerType}
                            name="centerType"
                            disabled
                          >
                            {options2}
                          </Select>
                        </FormControl>


                        <Typography sx={{width:"100%", mb:"10px"}}>Satellite Centres where Training is provided other than your Main Campus/Centre</Typography>
                        <TextField
                          variant="outlined"
                          type="text"
                          label="Satellite Centre Name 1"
                          disabled
                          value={satelliteCenterName1}
                          name="satelliteCenterName1"
                          sx={{ width: isMobile ? "100%" : "40%", mb:"15px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          multiline
                          type="text"
                          label="Satellite Centre Address 1"
                          disabled
                          value={satelliteCenterAddress1}
                          name="satelliteCenterAddress1"
                          sx={{ width: isMobile ? "100%" : "58%", mb:"15px" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Satellite Centre Name 2"
                          disabled
                          value={satelliteCenterName2}
                          name="satelliteCenterName2"
                          sx={{ width: isMobile ? "100%" : "40%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          multiline
                          type="text"
                          label="Satellite Centre Address 2"
                          disabled
                          value={satelliteCenterAddress2}
                          name="satelliteCenterAddress2"
                          sx={{ width: isMobile ? "100%" : "58%", mb:"30px" }}
                        />


                        <FormControl fullWidth sx={{ width: '100%', mb:"30px" }}>
                          <FormLabel component="legend" sx={{color:"#49a139"}}>Indicate if you are currently an approved Centre of another Awarding Body</FormLabel>
                          <FormGroup row>
                            <FormControlLabel
                              control={<Checkbox checked={otherApprovals?.itp} disabled name="itp" sx={{color:"#49a139"}} />}
                              label="ITP"
                            />
                            <FormControlLabel
                              control={<Checkbox checked={otherApprovals?.naddc} disabled name="naddc" sx={{color:"#49a139"}} />}
                              label="NADDC"
                            />
                            <FormControlLabel
                              control={<Checkbox checked={otherApprovals?.nabteb} disabled name="nabteb" sx={{color:"#49a139"}} />}
                              label="NABTEB"
                            />
                            <FormControlLabel
                              control={<Checkbox checked={otherApprovals?.cityGuild} disabled name="cityGuild" sx={{color:"#49a139"}} />}
                              label="City & Guilds"
                            />
                          </FormGroup>
                        </FormControl>


                        <Typography sx={{width:"100%", mb:"10px"}}>If your Centre is currently approved as a Centre for NSQ by another Awarding Body, please give details below:</Typography>
                        <TextField
                          variant="outlined"
                          type="text"
                          label="Awarding Body 1"
                          disabled
                          value={awardingBody1}
                          name="awardingBody1"
                          sx={{ width: isMobile ? "100%" : "20%", mb:"15px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <FormControl sx={{ width: isMobile ? "100%" : "25%", mb:"15px", mr: isMobile ? "0%" : "2%" }}>
                          <InputLabel id="awardingBody1Qualification-id">Qualification 1</InputLabel>
                          <Select
                            labelId="awardingBody1Qualification-id"
                            variant="outlined"
                            name="awardingBody1Qualification"
                            value={awardingBody1Qualification}
                            disabled
                          >
                            {options3}
                          </Select>
                        </FormControl>

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 1, max: 5 } }}
                          label="Level"
                          disabled
                          value={awardingBody1Level}
                          name="awardingBody1Level"
                          sx={{ width: isMobile ? "100%" : "10%", mb:"15px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            variant="outlined"
                            label={'Date Approved'}
                            disabled
                            value={dayjs(awardingBody1DateApproved)}
                            name="awardingBody1DateApproved"
                            sx={{ width: isMobile ? "100%" : "17%", mb:"15px", mr: isMobile ? "0%" : "2%" }}
                          />
                        </LocalizationProvider>

                        <FormControl sx={{ width: isMobile ? "100%" : "20%", mb:"15px"}}>
                          <InputLabel id="approvalStatus-id">Approval Status</InputLabel>
                          <Select
                            labelId="approvalStatus-id"
                            variant="outlined"
                            name="awardingBody1ApprovalStatus"
                            value={awardingBody1ApprovalStatus}
                            disabled
                          >
                              <MenuItem value={"Done"}>Done</MenuItem>
                              <MenuItem value={"Pending"}>Pending</MenuItem>
                          </Select>
                        </FormControl>

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Awarding Body 2"
                          disabled
                          value={awardingBody2}
                          name="awardingBody2"
                          sx={{ width: isMobile ? "100%" : "20%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <FormControl sx={{ width: isMobile ? "100%" : "25%", mb:"30px", mr: isMobile ? "0%" : "2%" }}>
                          <InputLabel id="awardingBody2Qualification-id">Qualification 2</InputLabel>
                          <Select
                            labelId="awardingBody2Qualification-id"
                            variant="outlined"
                            name="awardingBody2Qualification"
                            value={awardingBody2Qualification}
                            disabled
                          >
                            {options3}
                          </Select>
                        </FormControl>

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 1, max: 5 } }}
                          label="Level"
                          disabled
                          value={awardingBody2Level}
                          name="awardingBody2Level"
                          sx={{ width: isMobile ? "100%" : "10%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            variant="outlined"
                            label={'Date Approved'}
                            disabled
                            value={dayjs(awardingBody2DateApproved)}
                            name="awardingBody2DateApproved"
                            sx={{ width: isMobile ? "100%" : "17%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                          />
                        </LocalizationProvider>

                        <FormControl sx={{ width: isMobile ? "100%" : "20%", mb:"30px"}}>
                          <InputLabel id="approvalStatus-id">Approval Status</InputLabel>
                          <Select
                            labelId="approvalStatus-id"
                            variant="outlined"
                            name="awardingBody2ApprovalStatus"
                            value={awardingBody2ApprovalStatus}
                            disabled
                          >
                              <MenuItem value={"Done"}>Done</MenuItem>
                              <MenuItem value={"Pending"}>Pending</MenuItem>
                          </Select>
                        </FormControl>

                        <Stack direction="row" gap={2}>
                            <Button
                                sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                onClick={() => {setValue(1)}}
                                variant="contained"
                            >Previous</Button>
                            <Button
                                sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                onClick={() => {setValue(3)}}
                                variant="contained"
                            >Next</Button>
                        </Stack>
                    </Box>
                </TabPanel>

                {/*CENTRE ORGANISATIONAL STAFFING*/}
                <TabPanel value={value} index={3} sx={{ width: isMobile ? "100%" : "90%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: isMobile ? "" : "470px",
                        overflowY: isMobile ? "none" : "scroll",
                        pr: 2
                      }}
                    >
                        <Typography sx={{color:'#295C21', fontSize:12, mb:3}}>CENTRE ORGANISATIONAL STAFFING</Typography>

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Centre Manager's FullName"
                          disabled
                          value={centerManagerName}
                          name="centerManagerName"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Centre Manager's Qualification"
                          disabled
                          value={centerManagerQualification}
                          name="centerManagerQualification"
                          helperText="e.g: BSc. Building, HND Marketing, etc."
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Assessor 1's FullName"
                          disabled
                          value={assessor1Name}
                          name="assessor1Name"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Assessor 1's Qualification"
                          disabled
                          value={assessor1Qualification}
                          name="assessor1Qualification"
                          helperText="e.g: BSc. Building, HND Marketing, etc."
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Assessor 2's FullName"
                          disabled
                          value={(assessor2Name === "") ? "N/A" : assessor2Name}
                          name="assessor2Name"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Assessor 2's Qualification"
                          disabled
                          value={(assessor2Qualification === "") ? "N/A" : assessor2Qualification}
                          name="assessor2Qualification"
                          helperText="e.g: BSc. Building, HND Marketing, etc."
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Internal Verifier's FullName"
                          disabled
                          value={internalVerifierName}
                          name="internalVerifierName"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Internal Verifier's Qualification"
                          disabled
                          value={internalVerifierQualification}
                          name="internalVerifierQualification"
                          helperText="e.g: BSc. Building, HND Marketing, etc."
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Industry/Workplace Liaison Officer's FullName"
                          disabled
                          value={(liaisonOfficerName === "") ? "N/A" : liaisonOfficerName}
                          name="liaisonOfficerName"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Industry/Workplace Liaison Officer's Qualification"
                          disabled
                          value={(liaisonOfficerQualification === "") ? "N/A" : liaisonOfficerQualification}
                          name="liaisonOfficerQualification"
                          helperText="e.g: BSc. Building, HND Marketing, etc."
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Resource/Data Officer's FullName"
                          disabled
                          value={(resourceOfficerName === "") ? "N/A" : resourceOfficerName}
                          name="resourceOfficerName"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Resource/Data Officer's Qualification"
                          disabled
                          value={(resourceOfficerQualification === "") ? "N/A" : resourceOfficerQualification}
                          name="resourceOfficerQualification"
                          helperText="e.g: BSc. Building, HND Marketing, etc."
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Other Staff's FullName"
                          disabled
                          value={(otherStaffName === "") ? "N/A" : otherStaffName}
                          name="otherStaffName"
                          sx={{ width: isMobile ? "100%" : "32%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Other Staff's Qualification"
                          disabled
                          value={(otherStaffQualification === "") ? "N/A" : otherStaffQualification}
                          name="otherStaffQualification"
                          helperText="e.g: BSc. Building, HND Marketing, etc."
                          sx={{ width: isMobile ? "100%" : "32%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Other Staff's Designation"
                          disabled
                          value={(otherStaffDesignation === "") ? "N/A" : otherStaffDesignation}
                          name="otherStaffDesignation"
                          helperText="e.g: Program Officer, HOD Admin, etc."
                          sx={{ width: isMobile ? "100%" : "32%", mb:"10px" }}
                        />

                        <Stack direction="row" gap={2}>
                            <Button
                                sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                onClick={() => {setValue(2)}}
                                variant="contained"
                            >Previous</Button>
                            <Button
                                sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                onClick={() => {setValue(4)}}
                                variant="contained"
                            >Next</Button>
                        </Stack>
                    </Box>
                </TabPanel>

                {/*CENTRE PHYSICAL FACILITIES*/}
                <TabPanel value={value} index={4} sx={{ width: isMobile ? "100%" : "90%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: isMobile ? "" : "470px",
                        overflowY: isMobile ? "none" : "scroll",
                        pr: 2
                      }}
                    >
                        <Typography sx={{color:'#295C21', fontSize:12, mb: 3}}>CENTRE PHYSICAL FACILITIES</Typography>

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Classrooms/Training Rooms - Quantity"
                          disabled
                          value={trainingRoomQuantity}
                          name="trainingRoomQuantity"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Classrooms/Training Rooms - Total Floor Area"
                          disabled
                          value={trainingRoomFloorArea}
                          name="trainingRoomFloorArea"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Workshops - Quantity"
                          disabled
                          value={workshopQuantity}
                          name="workshopQuantity"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Workshops - Total Floor Area"
                          disabled
                          value={workshopFloorArea}
                          name="workshopFloorArea"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Practical Training Space for each Trade - Quantity"
                          disabled
                          value={practicalTrainingSpaceQuantity}
                          name="practicalTrainingSpaceQuantity"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Practical Training Space for each Trade - Total Floor Area"
                          disabled
                          value={practicalTrainingSpaceFloorArea}
                          name="practicalTrainingSpaceFloorArea"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Technical Laboratories - Quantity"
                          disabled
                          value={technicalLabQuantity}
                          name="technicalLabQuantity"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Technical Laboratories - Total Floor Area"
                          disabled
                          value={technicalLabFloorArea}
                          name="technicalLabFloorArea"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Data/Records Room - Quantity"
                          disabled
                          value={recordsRoomQuantity}
                          name="recordsRoomQuantity"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Data/Records Room - Total Floor Area"
                          disabled
                          value={recordsRoomFloorArea}
                          name="recordsRoomFloorArea"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Auditorium - Quantity"
                          disabled
                          value={auditoriumQuantity}
                          name="auditoriumQuantity"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Auditorium - Total Floor Area"
                          disabled
                          value={auditoriumFloorArea}
                          name="auditoriumFloorArea"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Library & Study Rooms - Quantity"
                          disabled
                          value={libraryQuantity}
                          name="libraryQuantity"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Library & Study Rooms - Total Floor Area"
                          disabled
                          value={libraryFloorArea}
                          name="libraryFloorArea"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Water & Power Supply (Public/Private) - Quantity"
                          disabled
                          value={utilityQuantity}
                          name="utilityQuantity"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Water & Power Supply (Public/Private) - Total Floor Area"
                          disabled
                          value={utilityFloorArea}
                          name="utilityFloorArea"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Management Offices - Quantity"
                          disabled
                          value={mgtOfficesQuantity}
                          name="mgtOfficesQuantity"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Management Offices - Total Floor Area"
                          disabled
                          value={mgtOfficesFloorArea}
                          name="mgtOfficesFloorArea"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Staff Rooms - Quantity"
                          disabled
                          value={staffRoomQuantity}
                          name="staffRoomQuantity"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Staff Rooms - Total Floor Area"
                          disabled
                          value={staffRoomFloorArea}
                          name="staffRoomFloorArea"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Toilets - Quantity"
                          disabled
                          value={toiletQuantity}
                          name="toiletQuantity"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Toilets - Total Floor Area"
                          disabled
                          value={toiletFloorArea}
                          name="toiletFloorArea"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="First Aid/Firefighting Facility - Quantity"
                          disabled
                          value={firstAidFireFacilityQuantity}
                          name="firstAidFireFacilityQuantity"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="First Aid/Firefighting Facility - Total Floor Area"
                          disabled
                          value={firstAidFireFacilityFloorArea}
                          name="firstAidFireFacilityFloorArea"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Hostel - Quantity"
                          disabled
                          value={hostelQuantity}
                          name="hostelQuantity"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Hostel - Total Floor Area"
                          disabled
                          value={hostelFloorArea}
                          name="hostelFloorArea"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Tools & Equipment for relevant Trades - Quantity"
                          disabled
                          value={tradeToolsQuantity}
                          name="tradeToolsQuantity"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Tools & Equipment for relevant Trades - Total Floor Area"
                          disabled
                          value={tradeToolsFloorArea}
                          name="tradeToolsFloorArea"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Other Facility Name"
                          disabled
                          value={(otherFacilityName === "") ? "N/A" : otherFacilityName}
                          name="otherFacilityName"
                          sx={{ width: isMobile ? "100%" : "32%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Others - Quantity"
                          disabled
                          value={otherFacilityQuantity}
                          name="otherFacilityQuantity"
                          sx={{ width: isMobile ? "100%" : "32%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Others - Total Floor Area"
                          disabled
                          value={otherFacilityFloorArea}
                          name="otherFacilityFloorArea"
                          sx={{ width: isMobile ? "100%" : "32%", mb:"10px" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                          }}
                        />

                        <Stack direction="row" gap={2}>
                            <Button
                                sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                onClick={() => {setValue(3)}}
                                variant="contained"
                            >Previous</Button>
                            <Button
                                sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                onClick={() => {setValue(5)}}
                                variant="contained"
                            >Next</Button>
                        </Stack>
                    </Box>
                </TabPanel>

                {/*CENTRE TRAINING STAFF*/}
                <TabPanel value={value} index={5} sx={{ width: isMobile ? "100%" : "90%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: isMobile ? "" : "470px",
                        overflowY: isMobile ? "none" : "scroll",
                        pr: 2
                      }}
                    >
                        <Typography sx={{color:'#295C21', fontSize:12, mb: 3}}>CENTRE TRAINING STAFF</Typography>

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Full Name of Staff 1"
                          disabled
                          value={(trainingStaff1Name === "") ? "N/A" : trainingStaff1Name}
                          name="trainingStaff1Name"
                          sx={{ width: isMobile ? "100%" : "25%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Staff's Qualification"
                          disabled
                          value={(trainingStaff1Qualification === "") ? "N/A" : trainingStaff1Qualification}
                          name="trainingStaff1Qualification"
                          helperText="e.g: BSc. Building, HND Marketing, etc."
                          sx={{ width: isMobile ? "100%" : "25%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <FormControl sx={{ width: isMobile ? "49%" : "10%", mb:"10px", mr: isMobile ? "2%" : "2%"}}>
                          <InputLabel id="approvalStatus-id">Gender</InputLabel>
                          <Select
                            labelId="approvalStatus-id"
                            variant="outlined"
                            name="trainingStaff1Gender"
                            value={trainingStaff1Gender}
                            disabled
                          >
                              <MenuItem value={"Female"}>Female</MenuItem>
                              <MenuItem value={"Male"}>Male</MenuItem>
                          </Select>
                        </FormControl>

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Practical Experience"
                          disabled
                          value={trainingStaff1Experience}
                          name="trainingStaff1Experience"
                          sx={{ width: isMobile ? "49%" : "10%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">yrs</InputAdornment>,
                          }}
                        />

                        <FormControl sx={{ width: isMobile ? "100%" : "20%", mb: isMobile ? "50px" : "10px" }}>
                          <InputLabel id="qualifications-id">Trade Specialization</InputLabel>
                          <Select
                            labelId="qualifications-id"
                            variant="outlined"
                            name="trainingStaff1Specialization"
                            value={trainingStaff1Specialization}
                            disabled
                          >
                            {options4}
                          </Select>
                        </FormControl>


                        <TextField
                          variant="outlined"
                          type="text"
                          label="Full Name of Staff 2"
                          disabled
                          value={(trainingStaff2Name === "") ? "N/A" : trainingStaff2Name}
                          name="trainingStaff2Name"
                          sx={{ width: isMobile ? "100%" : "25%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Staff's Qualification"
                          disabled
                          value={(trainingStaff2Qualification === "") ? "N/A" : trainingStaff2Qualification}
                          name="trainingStaff2Qualification"
                          helperText="e.g: BSc. Building, HND Marketing, etc."
                          sx={{ width: isMobile ? "100%" : "25%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <FormControl sx={{ width: isMobile ? "49%" : "10%", mb:"10px", mr: isMobile ? "2%" : "2%"}}>
                          <InputLabel id="approvalStatus-id">Gender</InputLabel>
                          <Select
                            labelId="approvalStatus-id"
                            variant="outlined"
                            name="trainingStaff2Gender"
                            value={trainingStaff2Gender}
                            disabled
                          >
                              <MenuItem value={"Female"}>Female</MenuItem>
                              <MenuItem value={"Male"}>Male</MenuItem>
                          </Select>
                        </FormControl>

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Practical Experience"
                          disabled
                          value={trainingStaff2Experience}
                          name="trainingStaff2Experience"
                          sx={{ width: isMobile ? "49%" : "10%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">yrs</InputAdornment>,
                          }}
                        />

                        <FormControl sx={{ width: isMobile ? "100%" : "20%", mb: isMobile ? "50px" : "10px" }}>
                          <InputLabel id="qualifications-id">Trade Specialization</InputLabel>
                          <Select
                            labelId="qualifications-id"
                            variant="outlined"
                            name="trainingStaff2Specialization"
                            value={trainingStaff2Specialization}
                            disabled
                          >
                            {options4}
                          </Select>
                        </FormControl>


                        <TextField
                          variant="outlined"
                          type="text"
                          label="Full Name of Staff 3"
                          disabled
                          value={(trainingStaff3Name === "") ? "N/A" : trainingStaff3Name}
                          name="trainingStaff3Name"
                          sx={{ width: isMobile ? "100%" : "25%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Staff's Qualification"
                          disabled
                          value={(trainingStaff3Qualification === "") ? "N/A" : trainingStaff3Qualification}
                          name="trainingStaff3Qualification"
                          helperText="e.g: BSc. Building, HND Marketing, etc."
                          sx={{ width: isMobile ? "100%" : "25%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <FormControl sx={{ width: isMobile ? "49%" : "10%", mb:"10px", mr: isMobile ? "2%" : "2%"}}>
                          <InputLabel id="approvalStatus-id">Gender</InputLabel>
                          <Select
                            labelId="approvalStatus-id"
                            variant="outlined"
                            name="trainingStaff3Gender"
                            value={trainingStaff3Gender}
                            disabled
                          >
                              <MenuItem value={"Female"}>Female</MenuItem>
                              <MenuItem value={"Male"}>Male</MenuItem>
                          </Select>
                        </FormControl>

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Practical Experience"
                          disabled
                          value={trainingStaff3Experience}
                          name="trainingStaff3Experience"
                          sx={{ width: isMobile ? "49%" : "10%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">yrs</InputAdornment>,
                          }}
                        />

                        <FormControl sx={{ width: isMobile ? "100%" : "20%", mb: isMobile ? "50px" : "10px" }}>
                          <InputLabel id="qualifications-id">Trade Specialization</InputLabel>
                          <Select
                            labelId="qualifications-id"
                            variant="outlined"
                            name="trainingStaff3Specialization"
                            value={trainingStaff3Specialization}
                            disabled
                          >
                            {options4}
                          </Select>
                        </FormControl>

                        <Stack direction="row" gap={2}>
                            <Button
                                sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                onClick={() => {setValue(4)}}
                                variant="contained"
                            >Previous</Button>
                            <Button
                                sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                onClick={() => {setValue(6)}}
                                variant="contained"
                            >Next</Button>
                        </Stack>
                    </Box>
                </TabPanel>

                {/*EQA FEEDBACK 1*/}
                <TabPanel value={value} index={6} sx={{ width: isMobile ? "100%" : "90%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: isMobile ? "" : "470px",
                        overflowY: isMobile ? "none" : "scroll",
                        pr: 2
                      }}
                    >
                        <Typography sx={{color:'#295C21', fontSize:12, mb:3}}>CENTRE APPROVAL VISIT REPORT & FEEDBACK - Human & Financial Resource</Typography>

                        <Box sx={{minWidth:isMobile ? "100%" : "960px"}}>
                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    i.{"\u00A0"} Are there NSQ certified Assessors at the Centre?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="nsqCertifiedAssessor-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="nsqCertifiedAssessor-id"
                                    variant="outlined"
                                    value={nsqCertifiedAssessors}
                                    name="nsqCertifiedAssessors"
                                    disabled
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px"}}>
                                  <InputLabel id="nsqCertifiedAssessorEQAView-id">EQA Response</InputLabel>
                                  <Select
                                    labelId="nsqCertifiedAssessorEQAView-id"
                                    variant="outlined"
                                    value={nsqCertifiedAssessorsEQAView}
                                    name="nsqCertifiedAssessorsEQAView"
                                    onChange={(e) => {setNsqCertifiedAssessorsEQAView(e.target.value)}}
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    ii.{"\u00A0"} How many Assessors are available at the Centre?
                                </Typography>
                                <TextField
                                  variant="outlined"
                                  type="number"
                                  InputProps={{ inputProps: { min: 0 } }}
                                  label="Assessors Count"
                                  value={assessorsCount}
                                  name="assessorsCount"
                                  disabled
                                  sx={{ width: isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}
                                />
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px"}}>
                                  <InputLabel id="assessorsCountEQAView-id">EQA Response</InputLabel>
                                  <Select
                                    labelId="assessorsCountEQAView-id"
                                    variant="outlined"
                                    value={assessorsCountEQAView}
                                    name="assessorsCountEQAView"
                                    onChange={(e) => {setAssessorsCountEQAView(e.target.value)}}
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    iii.{"\u00A0"} Are there NSQ certified Verifiers at the Centre?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="nsqCertifiedVerifier-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="nsqCertifiedVerifier-id"
                                    variant="outlined"
                                    value={nsqCertifiedVerifiers}
                                    name="nsqCertifiedVerifiers"
                                    disabled
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px"}}>
                                  <InputLabel id="nsqCertifiedVerifierEQAView-id">EQA Response</InputLabel>
                                  <Select
                                    labelId="nsqCertifiedVerifierEQAView-id"
                                    variant="outlined"
                                    value={nsqCertifiedVerifiersEQAView}
                                    name="nsqCertifiedVerifiersEQAView"
                                    onChange={(e) => {setNsqCertifiedVerifiersEQAView(e.target.value)}}
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    iv.{"\u00A0"} How many Verifiers are available at Centre?
                                </Typography>
                                <TextField
                                  variant="outlined"
                                  type="number"
                                  InputProps={{ inputProps: { min: 0 } }}
                                  label="Verifiers Count"
                                  value={verifiersCount}
                                  name="verifiersCount"
                                  disabled
                                  sx={{ width: isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}
                                />
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px"}}>
                                  <InputLabel id="verifiersCountEQAView-id">EQA Response</InputLabel>
                                  <Select
                                    labelId="verifiersCountEQAView-id"
                                    variant="outlined"
                                    value={verifiersCountEQAView}
                                    name="verifiersCountEQAView"
                                    onChange={(e) => {setVerifiersCountEQAView(e.target.value)}}
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    v.{"\u00A0"} Is the Centre Manager NSQ certified?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="nsqCertifiedCenterManager-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="nsqCertifiedCenterManager-id"
                                    variant="outlined"
                                    value={nsqCertifiedCenterManager}
                                    name="nsqCertifiedCenterManager"
                                    disabled
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px"}}>
                                  <InputLabel id="nsqCertifiedCenterManagerEQAView-id">EQA Response</InputLabel>
                                  <Select
                                    labelId="nsqCertifiedCenterManagerEQAView-id"
                                    variant="outlined"
                                    value={nsqCertifiedCenterManagerEQAView}
                                    name="nsqCertifiedCenterManagerEQAView"
                                    onChange={(e) => {setNsqCertifiedCenterManagerEQAView(e.target.value)}}
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    vi.{"\u00A0"} Is there a Liaison Officer with the Industry at the Centre?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="liaisonOfficerPresent-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="liaisonOfficerPresent-id"
                                    variant="outlined"
                                    value={liaisonOfficerPresent}
                                    name="liaisonOfficerPresent"
                                    disabled
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px"}}>
                                  <InputLabel id="liaisonOfficerPresentEQAView-id">EQA Response</InputLabel>
                                  <Select
                                    labelId="liaisonOfficerPresentEQAView-id"
                                    variant="outlined"
                                    value={liaisonOfficerPresentEQAView}
                                    name="liaisonOfficerPresentEQAView"
                                    onChange={(e) => {setLiaisonOfficerPresentEQAView(e.target.value)}}
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    vii.{"\u00A0"} Is there a Resource/Data Officer at the Centre?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="resourceOfficerPresent-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="resourceOfficerPresent-id"
                                    variant="outlined"
                                    value={resourceOfficerPresent}
                                    name="resourceOfficerPresent"
                                    disabled
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px"}}>
                                  <InputLabel id="resourceOfficerPresentEQAView-id">EQA Response</InputLabel>
                                  <Select
                                    labelId="resourceOfficerPresentEQAView-id"
                                    variant="outlined"
                                    value={resourceOfficerPresentEQAView}
                                    name="resourceOfficerPresentEQAView"
                                    onChange={(e) => {setResourceOfficerPresentEQAView(e.target.value)}}
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    viii.{"\u00A0"} Are there evidences/records of site-banks for apprenticeship training at the Centre?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="siteBankPresent-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="siteBankPresent-id"
                                    variant="outlined"
                                    value={siteBankPresent}
                                    name="siteBankPresent"
                                    disabled
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px"}}>
                                  <InputLabel id="siteBankPresentEQAView-id">EQA Response</InputLabel>
                                  <Select
                                    labelId="siteBankPresentEQAView-id"
                                    variant="outlined"
                                    value={siteBankPresentEQAView}
                                    name="siteBankPresentEQAView"
                                    onChange={(e) => {setSiteBankPresentEQAView(e.target.value)}}
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <TextField
                              variant="outlined"
                              type="text"
                              multiline
                              rows={5}
                              label="EQA General Feedback on Centre Human Resource"
                              value={eqaFeedbackOnCenterHumanResource}
                              name="eqaFeedbackOnCenterHumanResource"
                              onChange={onEqaFeedbackOnCenterHumanResourceChanged}
                              sx={{ width: "100%", mb:"30px" }}
                            />

                            <Stack direction="row" gap={2}>
                                <Button
                                    sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                    onClick={() => {setValue(5)}}
                                    variant="contained"
                                >Previous</Button>
                                <Button
                                    sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                    onClick={() => {setValue(7)}}
                                    variant="contained"
                                >Next</Button>
                            </Stack>
                        </Box>
                    </Box>
                </TabPanel>

                {/*EQA FEEDBACK 2*/}
                <TabPanel value={value} index={7} sx={{ width: isMobile ? "100%" : "90%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: isMobile ? "" : "470px",
                        overflowY: isMobile ? "none" : "scroll",
                        pr: 2
                      }}
                    >
                        <Typography sx={{color:'#295C21', fontSize:12, mb:3}}>CENTRE APPROVAL VISIT REPORT & FEEDBACK - Physical Infrastructure</Typography>

                        <Box sx={{minWidth:isMobile ? "100%" : "960px"}}>
                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    ix.{"\u00A0"} Are the Classrooms/Training Rooms adequate in terms of space, lighting, ventilation & traffic?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="trainingRoomAdequate-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="trainingRoomAdequate-id"
                                    variant="outlined"
                                    value={trainingRoomAdequate}
                                    name="trainingRoomAdequate"
                                    disabled
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px"}}>
                                  <InputLabel id="trainingRoomAdequateEQAView-id">EQA Response</InputLabel>
                                  <Select
                                    labelId="trainingRoomAdequateEQAView-id"
                                    variant="outlined"
                                    value={trainingRoomAdequateEQAView}
                                    name="trainingRoomAdequateEQAView"
                                    onChange={(e) => {setTrainingRoomAdequateEQAView(e.target.value)}}
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    x.{"\u00A0"} Is there a Data/Records room available and adequate?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="dataRoomAdequate-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="dataRoomAdequate-id"
                                    variant="outlined"
                                    value={dataRoomAdequate}
                                    name="dataRoomAdequate"
                                    disabled
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px"}}>
                                  <InputLabel id="dataRoomAdequateEQAView-id">EQA Response</InputLabel>
                                  <Select
                                    labelId="dataRoomAdequateEQAView-id"
                                    variant="outlined"
                                    value={dataRoomAdequateEQAView}
                                    name="dataRoomAdequateEQAView"
                                    onChange={(e) => {setDataRoomAdequateEQAView(e.target.value)}}
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xi.{"\u00A0"} Are Workshop spaces adequate and fit for purpose?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="workshopAdequate-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="workshopAdequate-id"
                                    variant="outlined"
                                    value={workshopAdequate}
                                    name="workshopAdequate"
                                    disabled
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px"}}>
                                  <InputLabel id="workshopAdequateEQAView-id">EQA Response</InputLabel>
                                  <Select
                                    labelId="workshopAdequateEQAView-id"
                                    variant="outlined"
                                    value={workshopAdequateEQAView}
                                    name="workshopAdequateEQAView"
                                    onChange={(e) => {setWorkshopAdequateEQAView(e.target.value)}}
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xii.{"\u00A0"} Are Practical training spaces available and suitable for each trade?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="practicalSpaceAdequate-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="practicalSpaceAdequate-id"
                                    variant="outlined"
                                    value={practicalSpaceAdequate}
                                    name="practicalSpaceAdequate"
                                    disabled
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px"}}>
                                  <InputLabel id="practicalSpaceAdequateEQAView-id">EQA Response</InputLabel>
                                  <Select
                                    labelId="practicalSpaceAdequateEQAView-id"
                                    variant="outlined"
                                    value={practicalSpaceAdequateEQAView}
                                    name="practicalSpaceAdequateEQAView"
                                    onChange={(e) => {setPracticalSpaceAdequateEQAView(e.target.value)}}
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xiii.{"\u00A0"} Are Technical laboratories AVAILABLE or HIRED?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="techLabAdequate-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="techLabAdequate-id"
                                    variant="outlined"
                                    value={techLabAdequate}
                                    name="techLabAdequate"
                                    disabled
                                  >
                                      <MenuItem value={"AVAILABLE"}>AVAILABLE</MenuItem>
                                      <MenuItem value={"HIRED"}>HIRED</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px"}}>
                                  <InputLabel id="techLabAdequateEQAView-id">EQA Response</InputLabel>
                                  <Select
                                    labelId="techLabAdequateEQAView-id"
                                    variant="outlined"
                                    value={techLabAdequateEQAView}
                                    name="techLabAdequateEQAView"
                                    onChange={(e) => {setTechLabAdequateEQAView(e.target.value)}}
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xiv.{"\u00A0"} Are Staff rooms adequate and fit for purpose?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="staffRoomAdequate-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="staffRoomAdequate-id"
                                    variant="outlined"
                                    value={staffRoomAdequate}
                                    name="staffRoomAdequate"
                                    disabled
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px"}}>
                                  <InputLabel id="staffRoomAdequateEQAView-id">EQA Response</InputLabel>
                                  <Select
                                    labelId="staffRoomAdequateEQAView-id"
                                    variant="outlined"
                                    value={staffRoomAdequateEQAView}
                                    name="staffRoomAdequateEQAView"
                                    onChange={(e) => {setStaffRoomAdequateEQAView(e.target.value)}}
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xv.{"\u00A0"} Are Toilets available and functional?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="toiletAdequate-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="toiletAdequate-id"
                                    variant="outlined"
                                    value={toiletAdequate}
                                    name="toiletAdequate"
                                    disabled
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px"}}>
                                  <InputLabel id="toiletAdequateEQAView-id">EQA Response</InputLabel>
                                  <Select
                                    labelId="toiletAdequateEQAView-id"
                                    variant="outlined"
                                    value={toiletAdequateEQAView}
                                    name="toiletAdequateEQAView"
                                    onChange={(e) => {setToiletAdequateEQAView(e.target.value)}}
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xvi.{"\u00A0"} Are First Aid/Firefighting facilities available and functional?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="firstAidFireAdequate-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="firstAidFireAdequate-id"
                                    variant="outlined"
                                    value={firstAidFireAdequate}
                                    name="firstAidFireAdequate"
                                    disabled
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px"}}>
                                  <InputLabel id="firstAidFireAdequateEQAView-id">EQA Response</InputLabel>
                                  <Select
                                    labelId="firstAidFireAdequateEQAView-id"
                                    variant="outlined"
                                    value={firstAidFireAdequateEQAView}
                                    name="firstAidFireAdequateEQAView"
                                    onChange={(e) => {setFirstAidFireAdequateEQAView(e.target.value)}}
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xvii.{"\u00A0"} Are Hostels available and fit for purpose?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="hostelsAdequate-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="hostelsAdequate-id"
                                    variant="outlined"
                                    value={hostelsAdequate}
                                    name="hostelsAdequate"
                                    disabled
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px"}}>
                                  <InputLabel id="hostelsAdequateEQAView-id">EQA Response</InputLabel>
                                  <Select
                                    labelId="hostelsAdequateEQAView-id"
                                    variant="outlined"
                                    value={hostelsAdequateEQAView}
                                    name="hostelsAdequateEQAView"
                                    onChange={(e) => {setHostelsAdequateEQAView(e.target.value)}}
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xviii.{"\u00A0"} Are there Tools and Equipment for relevant trades at your Centre?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="tradeToolsAdequate-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="tradeToolsAdequate-id"
                                    variant="outlined"
                                    value={tradeToolsAdequate}
                                    name="tradeToolsAdequate"
                                    disabled
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px"}}>
                                  <InputLabel id="tradeToolsAdequateEQAView-id">EQA Response</InputLabel>
                                  <Select
                                    labelId="tradeToolsAdequateEQAView-id"
                                    variant="outlined"
                                    value={tradeToolsAdequateEQAView}
                                    name="tradeToolsAdequateEQAView"
                                    onChange={(e) => {setTradeToolsAdequateEQAView(e.target.value)}}
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xix.{"\u00A0"} Is Auditorium AVAILABLE or HIRED?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="auditoriumAvailable-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="auditoriumAvailable-id"
                                    variant="outlined"
                                    value={auditoriumAvailable}
                                    name="auditoriumAvailable"
                                    disabled
                                  >
                                      <MenuItem value={"AVAILABLE"}>AVAILABLE</MenuItem>
                                      <MenuItem value={"HIRED"}>HIRED</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px"}}>
                                  <InputLabel id="auditoriumAvailableEQAView-id">EQA Response</InputLabel>
                                  <Select
                                    labelId="auditoriumAvailableEQAView-id"
                                    variant="outlined"
                                    value={auditoriumAvailableEQAView}
                                    name="auditoriumAvailableEQAView"
                                    onChange={(e) => {setAuditoriumAvailableEQAView(e.target.value)}}
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xx.{"\u00A0"} Are Library and Study rooms adequate?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="libraryAdequate-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="libraryAdequate-id"
                                    variant="outlined"
                                    value={libraryAdequate}
                                    name="libraryAdequate"
                                    disabled
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px"}}>
                                  <InputLabel id="libraryAdequateEQAView-id">EQA Response</InputLabel>
                                  <Select
                                    labelId="libraryAdequateEQAView-id"
                                    variant="outlined"
                                    value={libraryAdequateEQAView}
                                    name="libraryAdequateEQAView"
                                    onChange={(e) => {setLibraryAdequateEQAView(e.target.value)}}
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xxi.{"\u00A0"} Is Water and Power supply adequate?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="waterAndPowerAdequate-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="waterAndPowerAdequate-id"
                                    variant="outlined"
                                    value={waterAndPowerAdequate}
                                    name="waterAndPowerAdequate"
                                    disabled
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px"}}>
                                  <InputLabel id="waterAndPowerAdequateEQAView-id">EQA Response</InputLabel>
                                  <Select
                                    labelId="waterAndPowerAdequateEQAView-id"
                                    variant="outlined"
                                    value={waterAndPowerAdequateEQAView}
                                    name="waterAndPowerAdequateEQAView"
                                    onChange={(e) => {setWaterAndPowerAdequateEQAView(e.target.value)}}
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xxii.{"\u00A0"} Are Management Offices adequate and fit for purpose?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="mgtOfficesAdequate-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="mgtOfficesAdequate-id"
                                    variant="outlined"
                                    value={mgtOfficesAdequate}
                                    name="mgtOfficesAdequate"
                                    disabled
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px"}}>
                                  <InputLabel id="mgtOfficesAdequateEQAView-id">EQA Response</InputLabel>
                                  <Select
                                    labelId="mgtOfficesAdequateEQAView-id"
                                    variant="outlined"
                                    value={mgtOfficesAdequateEQAView}
                                    name="mgtOfficesAdequateEQAView"
                                    onChange={(e) => {setMgtOfficesAdequateEQAView(e.target.value)}}
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <TextField
                              variant="outlined"
                              type="text"
                              multiline
                              rows={5}
                              label="EQA General Feedback on Centre Facilities"
                              value={eqaFeedbackOnCenterFacilities}
                              name="eqaFeedbackOnCenterFacilities"
                              onChange={onEqaFeedbackOnCenterFacilitiesChanged}
                              sx={{ width: "100%", mb:"30px" }}
                            />

                            <Stack direction="row" gap={2}>
                                <Button
                                    sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                    onClick={() => {setValue(6)}}
                                    variant="contained"
                                >Previous</Button>
                                <Button
                                    sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                    onClick={() => {setValue(8)}}
                                    variant="contained"
                                >Next</Button>
                            </Stack>
                        </Box>
                    </Box>
                </TabPanel>

                {/*EQA FEEDBACK 3*/}
                <TabPanel value={value} index={8} sx={{ width: isMobile ? "100%" : "90%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: isMobile ? "" : "470px",
                        overflowY: isMobile ? "none" : "scroll",
                        pr: 2
                      }}
                    >
                        <Typography sx={{color:'#295C21', fontSize:12, mb:3}}>CENTRE APPROVAL VISIT REPORT & FEEDBACK - Code of Practice</Typography>

                        <Box sx={{minWidth:isMobile ? "100%" : "960px"}}>
                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xxiii.{"\u00A0"} Is NOS available at the Centre?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="nosAvailable-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="nosAvailable-id"
                                    variant="outlined"
                                    value={nosAvailable}
                                    name="nosAvailable"
                                    disabled
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px"}}>
                                  <InputLabel id="nosAvailableEQAView-id">EQA Response</InputLabel>
                                  <Select
                                    labelId="nosAvailableEQAView-id"
                                    variant="outlined"
                                    value={nosAvailableEQAView}
                                    name="nosAvailableEQAView"
                                    onChange={(e) => {setNosAvailableEQAView(e.target.value)}}
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xxiv.{"\u00A0"} Are there Training Manuals at your Centre?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="trainingManualAvailable-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="trainingManualAvailable-id"
                                    variant="outlined"
                                    value={trainingManualAvailable}
                                    name="trainingManualAvailable"
                                    disabled
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px"}}>
                                  <InputLabel id="trainingManualAvailableEQAView-id">EQA Response</InputLabel>
                                  <Select
                                    labelId="trainingManualAvailableEQAView-id"
                                    variant="outlined"
                                    value={trainingManualAvailableEQAView}
                                    name="trainingManualAvailableEQAView"
                                    onChange={(e) => {setTrainingManualAvailableEQAView(e.target.value)}}
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xxv.{"\u00A0"} Are the Manuals aligned to the NOS?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="nosAndManualAlignment-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="nosAndManualAlignment-id"
                                    variant="outlined"
                                    value={nosAndManualAlignment}
                                    name="nosAndManualAlignment"
                                    disabled
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px"}}>
                                  <InputLabel id="nosAndManualAlignedEQAView-id">EQA Response</InputLabel>
                                  <Select
                                    labelId="nosAndManualAlignedEQAView-id"
                                    variant="outlined"
                                    value={nosAndManualAlignedEQAView}
                                    name="nosAndManualAlignedEQAView"
                                    onChange={(e) => {setNosAndManualAlignedEQAView(e.target.value)}}
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xxvi.{"\u00A0"} Are there other Training materials that are relevant to the training needs?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="otherTrainingMaterialsAvailable-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="otherTrainingMaterialsAvailable-id"
                                    variant="outlined"
                                    value={otherTrainingMaterialsAvailable}
                                    name="otherTrainingMaterialsAvailable"
                                    disabled
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px"}}>
                                  <InputLabel id="otherTrainingMaterialsAvailableEQAView-id">EQA Response</InputLabel>
                                  <Select
                                    labelId="otherTrainingMaterialsAvailableEQAView-id"
                                    variant="outlined"
                                    value={otherTrainingMaterialsAvailableEQAView}
                                    name="otherTrainingMaterialsAvailableEQAView"
                                    onChange={(e) => {setOtherTrainingMaterialsAvailableEQAView(e.target.value)}}
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xxvii.{"\u00A0"} Is there an NSQ Code of Practice and Implementation Guideline at your Centre?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="nsqGuidelineAvailable-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="nsqGuidelineAvailable-id"
                                    variant="outlined"
                                    value={nsqGuidelineAvailable}
                                    name="nsqGuidelineAvailable"
                                    disabled
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px"}}>
                                  <InputLabel id="nsqGuidelineAvailableEQAView-id">EQA Response</InputLabel>
                                  <Select
                                    labelId="nsqGuidelineAvailableEQAView-id"
                                    variant="outlined"
                                    value={nsqGuidelineAvailableEQAView}
                                    name="nsqGuidelineAvailableEQAView"
                                    onChange={(e) => {setNsqGuidelineAvailableEQAView(e.target.value)}}
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xxviii.{"\u00A0"} Are the Trainers and Staff conversant/comply with these documents?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="staffCompliantWithStandards-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="staffCompliantWithStandards-id"
                                    variant="outlined"
                                    value={staffCompliantWithStandards}
                                    name="staffCompliantWithStandards"
                                    disabled
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px"}}>
                                  <InputLabel id="staffCompliantWithStandardsEQAView-id">EQA Response</InputLabel>
                                  <Select
                                    labelId="staffCompliantWithStandardsEQAView-id"
                                    variant="outlined"
                                    value={staffCompliantWithStandardsEQAView}
                                    name="staffCompliantWithStandardsEQAView"
                                    onChange={(e) => {setStaffCompliantWithStandardsEQAView(e.target.value)}}
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xxix.{"\u00A0"} Are the Objectives of the training clearly defined?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="objectivesClear-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="objectivesClear-id"
                                    variant="outlined"
                                    value={objectivesClear}
                                    name="objectivesClear"
                                    disabled
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px"}}>
                                  <InputLabel id="objectivesClearEQAView-id">EQA Response</InputLabel>
                                  <Select
                                    labelId="objectivesClearEQAView-id"
                                    variant="outlined"
                                    value={objectivesClearEQAView}
                                    name="objectivesClearEQAView"
                                    onChange={(e) => {setObjectivesClearEQAView(e.target.value)}}
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xxx.{"\u00A0"} Is Class participation through questioning encouraged?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="classParticipation-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="classParticipation-id"
                                    variant="outlined"
                                    value={classParticipation}
                                    name="classParticipation"
                                    disabled
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px"}}>
                                  <InputLabel id="classParticipationEQAView-id">EQA Response</InputLabel>
                                  <Select
                                    labelId="classParticipationEQAView-id"
                                    variant="outlined"
                                    value={classParticipationEQAView}
                                    name="classParticipationEQAView"
                                    onChange={(e) => {setClassParticipationEQAView(e.target.value)}}
                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <TextField
                              variant="outlined"
                              type="text"
                              multiline
                              rows={5}
                              label="EQA General Feedback on Centre NOS & NSQ"
                              value={eqaFeedbackOnCenterNOSNSQ}
                              name="eqaFeedbackOnCenterNOSNSQ"
                              onChange={onEqaFeedbackOnCenterNOSNSQChanged}
                              sx={{ width: "100%", mb:"30px" }}
                            />

                            <Stack direction="row" gap={2}>
                                <Button
                                    sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                    onClick={() => {setValue(7)}}
                                    variant="contained"
                                >Previous</Button>
                                <Button
                                    sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                    onClick={() => {setValue(9)}}
                                    variant="contained"
                                >Next</Button>
                            </Stack>
                        </Box>
                    </Box>
                </TabPanel>

                {/*EQA FINAL REPORT*/}
                <TabPanel value={value} index={9} sx={{ width: isMobile ? "100%" : "90%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: isMobile ? "" : "470px",
                        overflowY: isMobile ? "none" : "scroll",
                        pr: 2
                      }}
                    >
                        <Typography sx={{color:'#295C21', fontSize:12, mb:3}}>EQA FINAL REPORT</Typography>

                        <Box sx={{minWidth:isMobile ? "100%" : "960px"}}>
                            <TextField
                              variant="outlined"
                              type="text"
                              multiline
                              rows={12}
                              label="EQA End of Visit Report"
                              value={eqaFinalFeedbackOnCenter}
                              name="eqaFinalFeedbackOnCenter"
                              onChange={onEqaFinalFeedbackOnCenterChanged}
                              sx={{ width: "100%", mb:"30px" }}
                            />

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    I.{"\u00A0"} Conducive Learning Environment:
                                </Typography>
                                <FormControl sx={{width:isMobile ? "50%" : "40%", mb:"30px"}}>
                                  <InputLabel id="conduciveLearningEnvironment-id">Select as appropriate</InputLabel>
                                  <Select
                                    labelId="conduciveLearningEnvironment-id"
                                    variant="outlined"
                                    value={conduciveLearningEnvironment}
                                    name="conduciveLearningEnvironment"
                                    onChange={(e) => {setConduciveLearningEnvironment(e.target.value)}}
                                  >
                                      <MenuItem value={"APPROVED"}>APPROVED</MenuItem>
                                      <MenuItem value={"TEMPORAL APPROVAL"}>TEMPORAL APPROVAL</MenuItem>
                                      <MenuItem value={"NEED MORE INFORMATION"}>NEED MORE INFORMATION</MenuItem>
                                      <MenuItem value={"NOT APPROVED"}>NOT APPROVED</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    II.{"\u00A0"} Policy and Procedure:
                                </Typography>
                                <FormControl sx={{width:isMobile ? "50%" : "40%", mb:"30px"}}>
                                  <InputLabel id="policyAndProcedure-id">Select as appropriate</InputLabel>
                                  <Select
                                    labelId="policyAndProcedure-id"
                                    variant="outlined"
                                    value={policyAndProcedure}
                                    name="policyAndProcedure"
                                    onChange={(e) => {setPolicyAndProcedure(e.target.value)}}
                                  >
                                      <MenuItem value={"APPROVED"}>APPROVED</MenuItem>
                                      <MenuItem value={"TEMPORAL APPROVAL"}>TEMPORAL APPROVAL</MenuItem>
                                      <MenuItem value={"NEED MORE INFORMATION"}>NEED MORE INFORMATION</MenuItem>
                                      <MenuItem value={"NOT APPROVED"}>NOT APPROVED</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    III.{"\u00A0"} Practical Work Placement Arrangement:
                                </Typography>
                                <FormControl sx={{width:isMobile ? "50%" : "40%", mb:"30px"}}>
                                  <InputLabel id="practicalWorkPlacementArrangement-id">Select as appropriate</InputLabel>
                                  <Select
                                    labelId="practicalWorkPlacementArrangement-id"
                                    variant="outlined"
                                    value={practicalWorkPlacementArrangement}
                                    name="practicalWorkPlacementArrangement"
                                    onChange={(e) => {setPracticalWorkPlacementArrangement(e.target.value)}}
                                  >
                                      <MenuItem value={"APPROVED"}>APPROVED</MenuItem>
                                      <MenuItem value={"TEMPORAL APPROVAL"}>TEMPORAL APPROVAL</MenuItem>
                                      <MenuItem value={"NEED MORE INFORMATION"}>NEED MORE INFORMATION</MenuItem>
                                      <MenuItem value={"NOT APPROVED"}>NOT APPROVED</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    IV.{"\u00A0"} Sufficient Quality Assurance Team:
                                </Typography>
                                <FormControl sx={{width:isMobile ? "50%" : "40%", mb:"30px"}}>
                                  <InputLabel id="sufficientQualityAssuranceTeam-id">Select as appropriate</InputLabel>
                                  <Select
                                    labelId="sufficientQualityAssuranceTeam-id"
                                    variant="outlined"
                                    value={sufficientQualityAssuranceTeam}
                                    name="sufficientQualityAssuranceTeam"
                                    onChange={(e) => {setSufficientQualityAssuranceTeam(e.target.value)}}
                                  >
                                      <MenuItem value={"APPROVED"}>APPROVED</MenuItem>
                                      <MenuItem value={"TEMPORAL APPROVAL"}>TEMPORAL APPROVAL</MenuItem>
                                      <MenuItem value={"NEED MORE INFORMATION"}>NEED MORE INFORMATION</MenuItem>
                                      <MenuItem value={"NOT APPROVED"}>NOT APPROVED</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    V.{"\u00A0"} Course Structure / Learners' Support System:
                                </Typography>
                                <FormControl sx={{width:isMobile ? "50%" : "40%", mb:"30px"}}>
                                  <InputLabel id="learnersSupportSystem-id">Select as appropriate</InputLabel>
                                  <Select
                                    labelId="learnersSupportSystem-id"
                                    variant="outlined"
                                    value={learnersSupportSystem}
                                    name="learnersSupportSystem"
                                    onChange={(e) => {setLearnersSupportSystem(e.target.value)}}
                                  >
                                      <MenuItem value={"APPROVED"}>APPROVED</MenuItem>
                                      <MenuItem value={"TEMPORAL APPROVAL"}>TEMPORAL APPROVAL</MenuItem>
                                      <MenuItem value={"NEED MORE INFORMATION"}>NEED MORE INFORMATION</MenuItem>
                                      <MenuItem value={"NOT APPROVED"}>NOT APPROVED</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction="row" gap={2}>
                                <Button
                                    sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                    onClick={() => {setValue(8)}}
                                    variant="contained"
                                >Previous</Button>
                                <Button
                                    sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                    onClick={() => {setValue(10)}}
                                    variant="contained"
                                >Next</Button>
                            </Stack>
                        </Box>
                    </Box>
                </TabPanel>

                {/*EQA SUPPORTING DOCUMENTS*/}
                <TabPanel value={value} index={10} sx={{ width: isMobile ? "100%" : "90%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: isMobile ? "" : "470px",
                        overflowY: isMobile ? "none" : "scroll",
                        pr: 2
                      }}
                    >
                        <Typography sx={{color:'#295C21', fontSize:12}}>EQA SUPPORTING DOCUMENT(s)</Typography>

                        <Collapse in={alert} sx={{ width: "100%" }}>
                          <Alert
                            variant="outlined"
                            sx={{ color: "#00ffff" }}
                            severity="success"
                            aria-live="assertive"
                            onClose={() => {setAlert(null)}}
                          >
                              {alert}
                          </Alert>
                        </Collapse>

                        <Stack direction="column" gap={2} sx={{width:isMobile ? "100%" : "960px"}}>
                            <Stack direction="row" gap={1}>
                                {eqaReports &&  <Typography sx={{width:"50%"}}>{eqaReports.name}</Typography> }
                            </Stack>

                            <Stack direction="row" gap={1} sx={{mb:3}}>
                                <input
                                  accept=".zip, .pdf"
                                  style={{ display: 'none' }}
                                  id="eqaReports-file"
                                  type="file"
                                  name="eqaReports"
                                  onChange={onSelectEqaReports}
                                />
                                <label htmlFor="eqaReports-file" style={{width:"50%"}}>
                                    <Button
                                        variant="filled"
                                        component="span"
                                        startIcon={<CloudUploadIcon />}
                                        sx={{ backgroundColor: '#80B373', color: 'rgba(0, 0, 0, 0.87)', width:"100%", mb:7 }}
                                    >
                                      Upload EQA Reports
                                    </Button>
                                </label>
                            </Stack>
                        </Stack>

                        <Box display="flex" mt="30px">
                          <LoadingButton
                              type="submit"
                              loading={isLoading}
                              disabled={!canSave}
                              variant="contained"
                              sx={{width:isMobile ? "100%" : "50%", color:"#fff"}}
                          >
                            Submit EQA Report
                          </LoadingButton>
                        </Box>

                        <Stack direction="row" gap={2}>
                            <Button
                                sx={{mb:3, mt:5, width:"150px", color:"#fff"}}
                                onClick={() => {setValue(9)}}
                                variant="contained"
                            >Previous</Button>
                        </Stack>

                    </Box>
                </TabPanel>
              </form>
          </Box>
        </Box>
      </>
    )

    if(!centerapplication) {
      return <PulseLoader color={"#0b1c08"} className="center" />
    } else {
      return content
    }
}

export default CenterQualityCheck
