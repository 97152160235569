export const STATECODES = {
  "ABUJA": "AB",
  "ABIA": "AI",
  "ADAMAWA": "AD",
  "AKWA IBOM": "AK",
  "ANAMBRA": "AN",
  "BAUCHI": "BA",
  "BAYELSA": "BY",
  "BENUE": "BE",
  "BORNO": "BO",
  "CROSS RIVER": "CR",
  "DELTA": "DE",
  "EBONYI": "EB",
  "EDO": "ED",
  "EKITI": "EK",
  "ENUGU": "EN",
  "GOMBE": "GO",
  "IMO": "IM",
  "JIGAWA": "JI",
  "KADUNA": "KA",
  "KANO": "KN",
  "KATSINA": "KT",
  "KEBBI": "KE",
  "KOGI": "KO",
  "KWARA": "KW",
  "LAGOS": "LA",
  "NASARAWA": "NA",
  "NIGER": "NI",
  "OGUN": "OG",
  "ONDO": "ON",
  "OSUN": "OS",
  "OYO": "OY",
  "PLATEAU": "PL",
  "RIVERS": "RI",
  "SOKOTO": "SO",
  "TARABA": "TA",
  "YOBE": "YO",
  "ZAMFARA": "ZA"
}
