import { Link } from 'react-router-dom'
import { useTheme } from "@mui/material"
import { tokens } from "../theme"
import DashHeader from "./DashHeader2"
import { Typography, Button } from "@mui/material"
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload'
import ApprovalIcon from '@mui/icons-material/Approval'
import AssessmentIcon from '@mui/icons-material/Assessment'

const Public = () => {

    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const content = (
      <>
        <DashHeader />
        <section id="home" data-parallax="scroll" data-image-src="carpenter.jpg" data-natural-width={3000} data-natural-height={2000}>
            {/*<main className="public__main">*/}
                <div className="home-scrolldown">
                    <a href="#about" className="scroll-icon smoothscroll">
                        <span>Scroll Down</span>
                        <i className="icon-arrow-right" aria-hidden="true"></i>
                    </a>
                </div>
                <div className="overlay"></div>

                <div className="home-content">
                    <div className="row contents">
                        <div className="home-content-left">
                            <h3 data-aos="fade-up">Welcome to</h3>

                            <h1 data-aos="fade-up">
                                NIOB Skill CaMP
                            </h1>
                            <h5>
                                The Skill Certification and Management Portal for the <br/>
                                Nigerian Institute of Building (NIOB)
                            </h5>

                            <div className="buttons" data-aos="fade-up">
                                <Button
                                    variant="outlined"
                                    startIcon={<PlayArrowIcon />}
                                    size="large"
                                    sx={{color:"#fff", borderColor: "#fff", p: "20px 50px"}}
                                >
                                  Watch Video
                                </Button>
                            </div>
                        </div>

                    </div>

                    <div className="home-image-right">
                        <img src="iphone-app-470.png"
                            srcSet="iphone-app-2-470.png 1x, iphone-app-2-940.png 2x"
                            data-aos="fade-up"/>
                    </div>
                </div>

            {/*</main>*/}
        </section>

        {/* about
        ==================================================*/}
        <section id="about">

            <div className="row about-intro">

                <div className="col-four">
                    <h3 className="intro-header" data-aos="fade-up">About</h3>
                    <h1 className="intro-header" data-aos="fade-up">Skill CaMP</h1>
                </div>
                <div className="col-eight">
                    <p className="lead" data-aos="fade-up">
                      A Skill Certification and Management Portal that provides certification information on National Skill Qualification Framework.
                    </p>
                </div>

            </div>

            <div className="row about-features">

                <div className="features-list block-1-3 block-m-1-2 block-mob-full group">

                    <div className="bgrid feature" data-aos="fade-up">

                        <PlayArrowIcon className="icon icon-image" size="large"/>

                        <div className="service-content">

                            <h3>Centre Applications</h3>

                            <p style={{textAlign:"justify"}}>Centres can now apply online for NSQ certification. Approved Centres get a portal where they can manage their Staff and Trainees.</p>

                        </div>

                        </div> {/*-- /bgrid --*/}

                        <div className="bgrid feature" data-aos="fade-up">

                            <AssuredWorkloadIcon className="icon icon-image" size="large"/>

                        <div className="service-content">
                            <h3>Quality Assurance</h3>

                            <p style={{textAlign:"justify"}}>Maintaining high standards is at the heart of what we do. Hence, well-trained and certified EQAs are deployed to ensure the highest quality in Centres</p>


                        </div>

                    </div> {/*-- /bgrid --*/}

                    <div className="bgrid feature" data-aos="fade-up">

                        <AssessmentIcon className="icon icon-image" size="large"/>

                        <div className="service-content">
                            <h3>QA Reporting</h3>

                            <p style={{textAlign:"justify"}}>All our quality assurance processes and outcomes are well documented and reported. Thereby ensuring transparency across board.</p>

                        </div>

                    </div> {/*-- /bgrid --*/}

                </div> {/*-- end features-list --*/}

            </div> {/*-- end about-features --*/}

            <div className="row about-how">

                <h1 className="intro-header" data-aos="fade-up">How The App Works?</h1>

                <div className="about-how-content" data-aos="fade-up">
                    <div className="about-how-steps block-1-2 block-tab-full group">

                        <div className="bgrid step" data-item="1">
                            <h3>Apply</h3>
                            <p>A Centre fills &amp; submits this &nbsp;
                            <a href="/center-application" style={{ color: "#49a139", textDecoration: "underline" }}>Online Form</a>.</p>
                        </div>

                        <div className="bgrid step" data-item="2">
                            <h3>EQA Assigned</h3>
                            <p>An EQA is assigned to the Centre for quality assurance.</p>
                        </div>

                        <div className="bgrid step" data-item="3">
                            <h3>Board Review</h3>
                            <p>The Board reviews the Centres submission with the EQA's report.</p>
                        </div>

                        <div className="bgrid step" data-item="4">
                            <h3>Approval</h3>
                            <p>Having completed the review phase, the Board approves/disapproves a Centre's application.</p>
                        </div>

                    </div>
               </div> {/*-- end about-how-content --*/}

            </div> {/*-- end about-how --*/}

        </section>

        <footer>
            <Link to="/login">Login</Link>
        </footer>
      </>
    )

    return content
}

export default Public
