import {
    createSelector,
    createEntityAdapter
} from '@reduxjs/toolkit'
import { apiSlice } from  '../../app/api/apiSlice'

const qualificationsAdapter = createEntityAdapter({})

const initialState = qualificationsAdapter.getInitialState()

export const qualificationsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getQualifications: builder.query({
            query: () => ({
                url: '/qualifications',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
              const loadedQualifications = responseData.map(qualification => {
                  qualification.id = qualification._id
                  return qualification
              });
              return qualificationsAdapter.setAll(initialState, loadedQualifications)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Qualification', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'Qualification', id }))
                    ]
                } else return [{ type: 'Qualification', id: 'LIST' }]
            }
        }),
        addNewQualification: builder.mutation({
            query: initialQualificationData => ({
                url: '/qualifications',
                method: 'POST',
                body: {
                    ...initialQualificationData,
                }
            }),
            invalidatesTags: [
                { type: 'Qualification', id: "LIST" }
            ]
        }),
        updateQualification: builder.mutation({
            query: initialQualificationData => ({
                url: '/qualifications',
                method: 'PATCH',
                body: {
                    ...initialQualificationData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Qualification', id: arg.id }
            ]
        }),
        deleteQualification: builder.mutation({
            query: ({ id }) => ({
                url: `/qualifications`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Qualification', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetQualificationsQuery,
    useAddNewQualificationMutation,
    useUpdateQualificationMutation,
    useDeleteQualificationMutation,
} = qualificationsApiSlice

// return the query result object
export const selectQualificationsResult = qualificationsApiSlice.endpoints.getQualifications.select()

// creates memoized selector
const selectQualificationsData = createSelector(
    selectQualificationsResult,
    qualificationsResult => qualificationsResult.data // normalized state object with ids  & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllQualifications,
    selectById: selectQualificationById,
    selectIds: selectQualificationIds
    // Pass in a selector that returns the qualifications slice of state
} = qualificationsAdapter.getSelectors(state => selectQualificationsData(state) ?? initialState)
