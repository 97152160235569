import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse } from '@fortawesome/free-solid-svg-icons'
import { useNavigate, useLocation } from 'react-router-dom'
import useAuth from "../hooks/useAuth"

const DashFooter = () => {

    const { uln, firstName, lastName, status } = useAuth()

    const navigate = useNavigate()
    const { pathname } = useLocation()

    const onGoHomeClicked = () => navigate('/dash')

    let goHomeButton = null

    if (pathname !== '/dash'){
        goHomeButton = (
            <button
              className="dash-footer__button icon-button"
              title="Home"
              onClick={onGoHomeClicked}
            >
                <FontAwesomeIcon icon={faHouse} />
            </button>
        )
    }

    const content = (
        <footer className="dash-footer">
            {uln ? <>
            {goHomeButton}
            <p>Current User: {firstName+" "+lastName}</p>
            <p>Status: {status}</p>
            </> : <p>NIOB 2024 Copyright &copy;</p>}
        </footer>
    )

    return content
}

export default DashFooter
