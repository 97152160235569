import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetCenterApplicationsQuery } from './centerapplicationApiSlice';
import useAuth from "../../hooks/useAuth";
import PulseLoader from 'react-spinners/PulseLoader';
import useTitle from '../../hooks/useTitle';

import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter, GridToolbarExport, GridToolbar } from '@mui/x-data-grid';
import { Box, Button, Stack, IconButton, Tooltip, Paper } from "@mui/material";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { styled } from '@mui/material/styles'

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .no-rows-primary': {
    fill: theme.palette.mode === 'light' ? '#AEB8C2' : '#3D4751',
  },
  '& .no-rows-secondary': {
    fill: theme.palette.mode === 'light' ? '#E8EAED' : '#1D2126',
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width={96}
        viewBox="0 0 452 257"
        aria-hidden
        focusable="false"
      >
        <path
          className="no-rows-primary"
          d="M348 69c-46.392 0-84 37.608-84 84s37.608 84 84 84 84-37.608 84-84-37.608-84-84-84Zm-104 84c0-57.438 46.562-104 104-104s104 46.562 104 104-46.562 104-104 104-104-46.562-104-104Z"
        />
        <path
          className="no-rows-primary"
          d="M308.929 113.929c3.905-3.905 10.237-3.905 14.142 0l63.64 63.64c3.905 3.905 3.905 10.236 0 14.142-3.906 3.905-10.237 3.905-14.142 0l-63.64-63.64c-3.905-3.905-3.905-10.237 0-14.142Z"
        />
        <path
          className="no-rows-primary"
          d="M308.929 191.711c-3.905-3.906-3.905-10.237 0-14.142l63.64-63.64c3.905-3.905 10.236-3.905 14.142 0 3.905 3.905 3.905 10.237 0 14.142l-63.64 63.64c-3.905 3.905-10.237 3.905-14.142 0Z"
        />
        <path
          className="no-rows-secondary"
          d="M0 10C0 4.477 4.477 0 10 0h380c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 20 0 15.523 0 10ZM0 59c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 69 0 64.523 0 59ZM0 106c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 153c0-5.523 4.477-10 10-10h195.5c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 200c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 247c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10Z"
        />
      </svg>
      <Box sx={{ mt: 2, color: '#0b1c08' }}>No record</Box>
    </StyledGridOverlay>
  );
}

const CenterApplicationsList = () => {
    useTitle('NIOB Skill CaMP: All Center Applications');
    const isMobile = useMediaQuery("(max-width:600px)");
    const { isSuperAdmin } = useAuth();
    const navigate = useNavigate();

    const {
        data: centerapplications,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetCenterApplicationsQuery('centerapplicationsList', {
        pollingInterval: 30000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });

    const [pageSize, setPageSize] = useState(5);

    const handleAssignment = (event, id) => navigate(`/dash/center-application/${id}`);
    const handleView = (event, id) => navigate(`/dash/center-application/view/${id}`);

    const rows = useMemo(() => {
        if (!centerapplications || !centerapplications.entities) return [];
        return Object.values(centerapplications.entities).map(application => ({
            ...application,
            id: application.id
        }));
    }, [centerapplications]);

    const columns = [
        { field: 'cacNumber', headerName: 'CAC NUMBER', width: 150 },
        { field: 'centerName', headerName: 'CENTER NAME', width: 200 },
        {
            field: 'centerApplicationApproved',
            headerName: 'is APPROVED?',
            width: 150,
            renderCell: (params) => params.value ? "APPROVED" : "NOT YET"
        },
        {
            field: 'eqaAssigned',
            headerName: 'is ASSIGNED?',
            width: 150,
            renderCell: (params) => params.value ? <DoneIcon sx={{mt:2}} /> : <CloseIcon sx={{color: '#ff0000', mt:2}} />
        },
        { field: 'eqaAssignedTo', headerName: 'EQA ASSIGNED', width: 200 },
        {
            field: 'eqaCenterReportComplete',
            headerName: 'EQA REPORT COMPLETED?',
            width: 200,
            renderCell: (params) => params.value ? <DoneIcon sx={{mt:2}} /> : <CloseIcon sx={{color: '#ff0000', mt:2}} />
        },
        { field: 'stateOfRez', headerName: 'STATE', width: 150 },
        {
            field: 'createdAt',
            headerName: 'APPLIED ON',
            width: 150,
            renderCell: (params) => new Date(params.value).toLocaleDateString()
        },
        {
            field: 'actions',
            headerName: 'ACTIONS',
            width: 200,
            renderCell: (params) => (
                <Stack direction="row" spacing={1} sx={{ display: "inline-flex", alignItems: "center", justifyContent: "center" }}>
                    <Tooltip title="View Full Center Application">
                        <IconButton onClick={(event) => handleView(event, params.row.id)}>
                            <VisibilityIcon sx={{color: '#0b1c08', mt:1}}/>
                        </IconButton>
                    </Tooltip>
                    {params.row.eqaAssignedTo === 'Unassigned' && (
                        <Tooltip title="Assign to EQA">
                            <IconButton onClick={(event) => handleAssignment(event, params.row.id)}>
                                <AssignmentIndIcon sx={{color: '#0b1c08', mt:1}}/>
                            </IconButton>
                        </Tooltip>
                    )}
                </Stack>
            )
        }
    ];

    let content;

    if (isLoading) {
        content = <PulseLoader color={"#0b1c08"} className="center" />;
    }
    if (isError) {
        content = <p className='errmsg'>{error?.data?.message}</p>;
    }
    //if (isSuccess) {
    return content = (
            <>
                <Header title="CENTER APPLICATIONS LIST" subtitle="Record of All Center Applications" />
                <Box sx={{
                    width: '100%',
                    mb: '80px',
                    mt: isMobile ? "10%" : "",
                    "& .MuiPaper-root": { backgroundColor: '#030702' },
                    "& .MuiDataGrid-row:nth-of-type(odd)": {
                        backgroundColor: '#fff'
                    },
                    "& .MuiDataGrid-row:nth-of-type(odd):hover": {
                        backgroundColor: '#dfdfdf',
                    },
                    "& .MuiDataGrid-row:nth-of-type(even)": {
                        backgroundColor: '#efefef'
                    },
                    "& .MuiDataGrid-row:nth-of-type(even):hover": {
                        backgroundColor: '#dfdfdf',
                    },
                    "& .MuiDataGrid-footerContainer": {
                        backgroundColor: '#0b1c08'
                    },
                    "& .MuiDataGrid-filterForm": {
                        backgroundColor: '#9CD08F'
                    },
                    "& .MuiDataGrid-cell": { color: '#0b1c08' },
                    "& .MuiDataGrid-toolbarContainer": {backgroundColor: '#fff !important'},
                    "& .MuiDataGrid-scrollbar--horizontal": {height: '5px !important', zIndex:1},
                    p: "3% 2% 5% 2%"
                }}>
                    <Stack direction="row" gap={1} sx={{ mb: 2 }}>
                        <Link to="/dash">
                            <Button variant="outlined" sx={{ color: "#0b1c08" }} size="small" startIcon={<HomeIcon />}>
                                Home
                            </Button>
                        </Link>
                    </Stack>
                    <div style={{ height: 450, width: '100%' }}>

                            <DataGrid
                                rows={rows}
                                columns={columns}
                                pageSize={pageSize}
                                rowsPerPageOptions={[5, 10, 25]}
                                disableSelectionOnClick
                                disableColumnFilter
                                disableColumnSelector
                                slots={{
                                  toolbar: GridToolbar,
                                  noRowsOverlay: CustomNoRowsOverlay
                                }}
                                slotProps={{
                                  toolbar: {
                                    showQuickFilter: true,
                                  },
                                }}
                                components={{
                                    Toolbar: () => (
                                        <GridToolbarContainer>
                                            <GridToolbarQuickFilter />
                                            <GridToolbarExport />
                                        </GridToolbarContainer>
                                    )
                                }}
                                sx={{ '--DataGrid-overlayHeight': '450px' }}
                            />
                    </div>
                </Box>
            </>
        );
    //}

    //return content;
};

const MyCenterApplicationsList = () => {
  useTitle('NIOB Skill CaMP: My Center Assignments')
  const isMobile = useMediaQuery('(max-width:600px)')
  const { isSuperAdmin, id, firstName, lastName } = useAuth()
  const navigate = useNavigate()

  const {
      data: centerapplications,
      isLoading,
      isSuccess,
      isError,
      error
  } = useGetCenterApplicationsQuery('centerapplicationsList', {
      pollingInterval: 30000,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true
  });

  const [pageSize, setPageSize] = useState(5);

  const handleQualityCheck = (id) => navigate(`/dash/my-center-assignments/${id}`)

  const rows = useMemo(() => {
      if (!centerapplications || !centerapplications.entities) return [];
      return Object.values(centerapplications.entities)
                    .filter(item => item.eqaAssignedTo === `${firstName} ${lastName}`)
                    .map(application => ({
                        ...application,
                        id: application.id
                    }));
  }, [centerapplications]);

  const columns = [
      { field: 'cacNumber', headerName: 'CAC NUMBER', width: 150 },
      { field: 'centerName', headerName: 'CENTER NAME', width: 200 },
      { field: 'centerType', headerName: 'CENTER TYPE', width: 200 },
      { field: 'eqaAssignedDate', headerName: 'DATE ASSIGNED', width: 150 },
      {
          field: 'eqaCenterReportComplete',
          headerName: 'REPORT COMPLETE?',
          width: 150,
          renderCell: (params) => params.value ? <DoneIcon sx={{mt:2}} /> : <CloseIcon sx={{color: '#ff0000', mt:2}} />
      },
      { field: 'stateOfRez', headerName: 'STATE', width: 150 },
      {
          field: 'actions',
          headerName: 'ACTIONS',
          width: 200,
          renderCell: (params) => (
            params.row.eqaCenterReportComplete ?
              <Tooltip title="Quality Checked">
                <IconButton aria-label="quality-check" className="icon-button table__button">
                  <DoneAllIcon color='success'/>
                </IconButton>
              </Tooltip>
              :
              <Tooltip title="Complete Quality Check">
                <IconButton
                  aria-label="quality-check"
                  className="icon-button table__button"
                  onClick={() => handleQualityCheck(params.row.id)}
                >
                  <AppRegistrationIcon sx={{color:'#0b1c08'}} />
                </IconButton>
              </Tooltip>
          )
      }
  ];

  let content;

  if (isLoading) {
      content = <PulseLoader color={"#0b1c08"} className="center" />;
  }
  if (isError) {
      content = <p className='errmsg'>{error?.data?.message}</p>;
  }
  //if (isSuccess) {
  return content = (
          <>
              {/*<Header title="MY CENTER ASSIGNMENTS" subtitle={`Record of All Centers assigned to ${firstName} ${lastName.toUpperCase()}`} />*/}
              <Box sx={{
                  width: '100%',
                  mb: '80px',
                  mt: isMobile ? "10%" : "",
                  "& .MuiPaper-root": { backgroundColor: '#030702' },
                  "& .MuiDataGrid-row:nth-of-type(odd)": {
                      backgroundColor: '#fff'
                  },
                  "& .MuiDataGrid-row:nth-of-type(odd):hover": {
                      backgroundColor: '#dfdfdf',
                  },
                  "& .MuiDataGrid-row:nth-of-type(even)": {
                      backgroundColor: '#efefef'
                  },
                  "& .MuiDataGrid-row:nth-of-type(even):hover": {
                      backgroundColor: '#dfdfdf',
                  },
                  "& .MuiDataGrid-footerContainer": {
                      backgroundColor: '#0b1c08'
                  },
                  "& .MuiDataGrid-filterForm": {
                      backgroundColor: '#9CD08F'
                  },
                  "& .MuiDataGrid-cell": { color: '#0b1c08' },
                  "& .MuiDataGrid-toolbarContainer": {backgroundColor: '#fff !important'},
                  "& .MuiDataGrid-scrollbar--horizontal": {height: '5px !important', zIndex:1},
                  "& .MuiDataGrid-scrollbar--vertical": {zIndex:1},
                  p: "3% 2% 5% 2%"
              }}>
                  {/*<Stack direction="row" gap={1} sx={{ mb: 2 }}>
                      <Link to="/dash">
                          <Button variant="outlined" sx={{ color: "#0b1c08" }} size="small" startIcon={<HomeIcon />}>
                              Home
                          </Button>
                      </Link>
                  </Stack>*/}
                  <div style={{ height: 450, width: '100%' }}>

                          <DataGrid
                              rows={rows}
                              columns={columns}
                              pageSize={pageSize}
                              rowsPerPageOptions={[5, 10, 25]}
                              disableSelectionOnClick
                              disableColumnFilter
                              disableColumnSelector
                              slots={{
                                toolbar: GridToolbar,
                                noRowsOverlay: CustomNoRowsOverlay
                              }}
                              slotProps={{
                                toolbar: {
                                  showQuickFilter: true,
                                },
                              }}
                              components={{
                                  Toolbar: () => (
                                      <GridToolbarContainer>
                                          <GridToolbarQuickFilter />
                                          <GridToolbarExport />
                                      </GridToolbarContainer>
                                  )
                              }}
                              sx={{ '--DataGrid-overlayHeight': '450px' }}
                          />
                  </div>
              </Box>
          </>
      );
  //}

  //return content;
}

const MyCenterApplicationsListOnDash = () => {}


export {
    CenterApplicationsList,
    MyCenterApplicationsList,
    MyCenterApplicationsListOnDash
}
