import { useParams } from 'react-router-dom'
import EditQualificationForm from './EditQualificationForm'
import { useGetQualificationsQuery } from './qualificationsApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'

const EditQualification = () => {
    useTitle('NIOB Skill CaMP: Edit Qualification')

    const { id } = useParams()

    const { qualification } = useGetQualificationsQuery("qualificationsList", {
        selectFromResult: ({ data }) => ({
            qualification: data?.entities[id]
        }),
    })

    if (!qualification) return <PulseLoader color={"#0b1c08"} className="center" />

    const content = <EditQualificationForm qualification={qualification} />

    return content
}

export default EditQualification
