import { useParams, useNavigate, Link } from 'react-router-dom'
import { useGetCenterApplicationsQuery, useUpdateCenterApplicationMutation } from './centerapplicationApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'
import Header from "../../components/Header"

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import LoadingButton from '@mui/lab/LoadingButton'
import DashHeader from '../../components/DashHeader2'
import { Typography, Box, Card, Divider, InputAdornment, MenuItem, IconButton, CardContent, Grid, FormControl, Button, Tabs, Tab } from "@mui/material"
import { useTheme, TextField, Stack, InputLabel, Select, CardHeader, CardActions, Radio, RadioGroup, FormLabel, FormControlLabel } from "@mui/material"
import { FormGroup, Checkbox, List, ListItem, Alert, Collapse } from "@mui/material"
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import useMediaQuery from "@mui/material/useMediaQuery"
import React, { useState, useEffect } from "react"
import { VisibilityOff, Visibility } from "@mui/icons-material"
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { tokens } from "../../theme"
import { STATES } from '../../config/states'
import { CENTERTYPES } from '../../config/centertypes'
import { QUALIFICATIONS } from '../../config/qualifications'
import { useGetQualificationsQuery } from '../qualifications/qualificationsApiSlice'
import dayjs from 'dayjs'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const EditCenterApplicationForm = ({centerapplication}) => {
    useTitle('NIOB Skill CaMP: Update Centre Application')
    const isMobile = useMediaQuery("(max-width:600px)")
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const maxYear = new Date().getFullYear().toString()
    const today = new Date()

    const [updateCenterApplication, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateCenterApplicationMutation()

    const navigate = useNavigate()

    const { id } = useParams()
    //const { data: qualificationsData } = useGetQualificationsQuery()


    //CENTER DETAILS INITIALIZE
    const [centerName, setCenterName] = useState(centerapplication?.centerName)
    const [yearOfInc, setYearOfInc] = useState(centerapplication?.yearOfInc)
    const [cacNumber, setCACNumber] = useState(centerapplication?.cacNumber)
    const [permanentCenterAddress, setPermanentCenterAddress] = useState(centerapplication?.permanentCenterAddress)
    const [trainingCenterAddress, setTrainingCenterAddress] = useState(centerapplication?.trainingCenterAddress)
    const [phone, setPhone] = useState(centerapplication?.phone)
    const [email, setEmail] = useState(centerapplication?.email)
    const [website, setWebsite] = useState(centerapplication?.website)
    const [centerHead, setCenterHead] = useState(centerapplication?.centerHead)
    const [centerHeadPhone, setCenterHeadPhone] = useState(centerapplication?.centerHeadPhone)
    const [centerHeadEmail, setCenterHeadEmail] = useState(centerapplication?.centerHeadEmail)
    const [qualifications, setQualifications] = useState(centerapplication?.qualifications)
    const [stateOfRez, setStateOfRez] = useState(centerapplication?.stateOfRez)
    const [value, setValue] = React.useState(0)

    //CENTER ORGANISATION INITIALIZE
    const [centerType, setCenterType] = useState(centerapplication?.centerType)
    const [satelliteCenterName1, setSatelliteCenterName1] = useState(centerapplication?.satelliteCenterName1)
    const [satelliteCenterAddress1, setSatelliteCenterAddress1] = useState(centerapplication?.satelliteCenterAddress1)
    const [satelliteCenterName2, setSatelliteCenterName2] = useState(centerapplication?.satelliteCenterName2)
    const [satelliteCenterAddress2, setSatelliteCenterAddress2] = useState(centerapplication?.satelliteCenterAddress2)
    const [otherApprovals, setOtherApprovals] = React.useState(centerapplication?.otherApprovals[0])
    const [awardingBody1, setAwardingBody1] = useState(centerapplication?.awardingBody1)
    const [awardingBody1Qualification, setAwardingBody1Qualification] = useState(centerapplication?.awardingBody1Qualification)
    const [awardingBody1Level, setAwardingBody1Level] = useState(centerapplication?.awardingBody1Level)
    const [awardingBody1DateApproved, setAwardingBody1DateApproved] = useState(centerapplication?.awardingBody1DateApproved)
    const [awardingBody1ApprovalStatus, setAwardingBody1ApprovalStatus] = useState(centerapplication?.awardingBody1ApprovalStatus)
    const [awardingBody2, setAwardingBody2] = useState(centerapplication?.awardingBody2)
    const [awardingBody2Qualification, setAwardingBody2Qualification] = useState(centerapplication?.awardingBody2Qualification)
    const [awardingBody2Level, setAwardingBody2Level] = useState(centerapplication?.awardingBody2Level)
    const [awardingBody2DateApproved, setAwardingBody2DateApproved] = useState(centerapplication?.awardingBody2DateApproved)
    const [awardingBody2ApprovalStatus, setAwardingBody2ApprovalStatus] = useState(centerapplication?.awardingBody2ApprovalStatus)


    //ORGANISATION STAFFING INITIALIZE
    const [centerManagerName, setCenterManagerName] = useState(centerapplication?.centerManagerName)
    const [centerManagerQualification, setCenterManagerQualification] = useState(centerapplication?.centerManagerQualification)
    const [assessor1Name, setAssessor1Name] = useState(centerapplication?.assessor1Name)
    const [assessor1Qualification, setAssessor1Qualification] = useState(centerapplication?.assessor1Qualification)
    const [assessor2Name, setAssessor2Name] = useState(centerapplication?.assessor2Name)
    const [assessor2Qualification, setAssessor2Qualification] = useState(centerapplication?.assessor2Qualification)
    const [internalVerifierName, setInternalVerifierName] = useState(centerapplication?.internalVerifierName)
    const [internalVerifierQualification, setInternalVerifierQualification] = useState(centerapplication?.internalVerifierQualification)
    const [liaisonOfficerName, setLiaisonOfficerName] = useState(centerapplication?.liaisonOfficerName)
    const [liaisonOfficerQualification, setLiaisonOfficerQualification] = useState(centerapplication?.liaisonOfficerQualification)
    const [resourceOfficerName, setResourceOfficerName] = useState(centerapplication?.resourceOfficerName)
    const [resourceOfficerQualification, setResourceOfficerQualification] = useState(centerapplication?.resourceOfficerQualification)
    const [otherStaffName, setOtherStaffName] = useState(centerapplication?.otherStaffName)
    const [otherStaffQualification, setOtherStaffQualification] = useState(centerapplication?.otherStaffQualification)
    const [otherStaffDesignation, setOtherStaffDesignation] = useState(centerapplication?.otherStaffDesignation)


    //PHYSICAL FACILITIES INITIALIZE
    const [trainingRoomQuantity, setTrainingRoomQuantity] = useState(centerapplication?.trainingRoomQuantity)
    const [trainingRoomFloorArea, setTrainingRoomFloorArea] = useState(centerapplication?.trainingRoomFloorArea)
    const [workshopQuantity, setWorkshopQuantity] = useState(centerapplication?.workshopQuantity)
    const [workshopFloorArea, setWorkshopFloorArea] = useState(centerapplication?.workshopFloorArea)
    const [practicalTrainingSpaceQuantity, setPracticalTrainingSpaceQuantity] = useState(centerapplication?.practicalTrainingSpaceQuantity)
    const [practicalTrainingSpaceFloorArea, setPracticalTrainingSpaceFloorArea] = useState(centerapplication?.practicalTrainingSpaceFloorArea)
    const [technicalLabQuantity, setTechnicalLabQuantity] = useState(centerapplication?.technicalLabQuantity)
    const [technicalLabFloorArea, setTechnicalLabFloorArea] = useState(centerapplication?.technicalLabFloorArea)
    const [recordsRoomQuantity, setRecordsRoomQuantity] = useState(centerapplication?.recordsRoomQuantity)
    const [recordsRoomFloorArea, setRecordsRoomFloorArea] = useState(centerapplication?.recordsRoomFloorArea)
    const [auditoriumQuantity, setAuditoriumQuantity] = useState(centerapplication?.auditoriumQuantity)
    const [auditoriumFloorArea, setAuditoriumFloorArea] = useState(centerapplication?.auditoriumFloorArea)
    const [libraryQuantity, setLibraryQuantity] = useState(centerapplication?.libraryQuantity)
    const [libraryFloorArea, setLibraryFloorArea] = useState(centerapplication?.libraryFloorArea)
    const [utilityQuantity, setUtilityQuantity] = useState(centerapplication?.utilityQuantity)
    const [utilityFloorArea, setUtilityFloorArea] = useState(centerapplication?.utilityFloorArea)
    const [mgtOfficesQuantity, setMgtOfficesQuantity] = useState(centerapplication?.mgtOfficesQuantity)
    const [mgtOfficesFloorArea, setMgtOfficesFloorArea] = useState(centerapplication?.mgtOfficesFloorArea)
    const [staffRoomQuantity, setStaffRoomQuantity] = useState(centerapplication?.staffRoomQuantity)
    const [staffRoomFloorArea, setStaffRoomFloorArea] = useState(centerapplication?.staffRoomFloorArea)
    const [toiletQuantity, setToiletQuantity] = useState(centerapplication?.toiletQuantity)
    const [toiletFloorArea, setToiletFloorArea] = useState(centerapplication?.toiletFloorArea)
    const [firstAidFireFacilityQuantity, setFirstAidFireFacilityQuantity] = useState(centerapplication?.firstAidFireFacilityQuantity)
    const [firstAidFireFacilityFloorArea, setFirstAidFireFacilityFloorArea] = useState(centerapplication?.firstAidFireFacilityFloorArea)
    const [hostelQuantity, setHostelQuantity] = useState(centerapplication?.hostelQuantity)
    const [hostelFloorArea, setHostelFloorArea] = useState(centerapplication?.hostelFloorArea)
    const [tradeToolsQuantity, setTradeToolsQuantity] = useState(centerapplication?.tradeToolsQuantity)
    const [tradeToolsFloorArea, setTradeToolsFloorArea] = useState(centerapplication?.tradeToolsFloorArea)
    const [otherFacilityName, setOtherFacilityName] = useState(centerapplication?.otherFacilityName)
    const [otherFacilityQuantity, setOtherFacilityQuantity] = useState(centerapplication?.otherFacilityQuantity)
    const [otherFacilityFloorArea, setOtherFacilityFloorArea] = useState(centerapplication?.otherFacilityFloorArea)


    //TRAINING STAFF INITIALIZE
    const [trainingStaff1Name] = useState(centerapplication?.trainingStaff1Name)
    const [trainingStaff1Gender] = useState(centerapplication?.trainingStaff1Gender)
    const [trainingStaff1Qualification] = useState(centerapplication?.trainingStaff1Qualification)
    const [trainingStaff1Experience] = useState(centerapplication?.trainingStaff1Experience)
    const [trainingStaff1Specialization] = useState(centerapplication?.trainingStaff1Specialization)
    const [trainingStaff2Name] = useState(centerapplication?.trainingStaff2Name)
    const [trainingStaff2Gender] = useState(centerapplication?.trainingStaff2Gender)
    const [trainingStaff2Qualification] = useState(centerapplication?.trainingStaff2Qualification)
    const [trainingStaff2Experience] = useState(centerapplication?.trainingStaff2Experience)
    const [trainingStaff2Specialization] = useState(centerapplication?.trainingStaff2Specialization)
    const [trainingStaff3Name] = useState(centerapplication?.trainingStaff3Name)
    const [trainingStaff3Gender] = useState(centerapplication?.trainingStaff3Gender)
    const [trainingStaff3Qualification] = useState(centerapplication?.trainingStaff3Qualification)
    const [trainingStaff3Experience] = useState(centerapplication?.trainingStaff3Experience)
    const [trainingStaff3Specialization] = useState(centerapplication?.trainingStaff3Specialization)


    //EQA FEEDBACK 1 INITIALIZE
    const [nsqCertifiedAssessors] = useState(centerapplication?.nsqCertifiedAssessors)
    const [assessorsCount] = useState(centerapplication?.assessorsCount)
    const [nsqCertifiedVerifiers] = useState(centerapplication?.nsqCertifiedVerifiers)
    const [verifiersCount] = useState(centerapplication?.verifiersCount)
    const [nsqCertifiedCenterManager] = useState(centerapplication?.nsqCertifiedCenterManager)
    const [liaisonOfficerPresent] = useState(centerapplication?.liaisonOfficerPresent)
    const [resourceOfficerPresent] = useState(centerapplication?.resourceOfficerPresent)
    const [siteBankPresent] = useState(centerapplication?.siteBankPresent)


    //EQA FEEDBACK 2 INITIALIZE
    const [trainingRoomAdequate] = useState(centerapplication?.trainingRoomAdequate)
    const [dataRoomAdequate] = useState(centerapplication?.dataRoomAdequate)
    const [workshopAdequate] = useState(centerapplication?.workshopAdequate)
    const [practicalSpaceAdequate] = useState(centerapplication?.practicalSpaceAdequate)
    const [techLabAdequate] = useState(centerapplication?.techLabAdequate)
    const [staffRoomAdequate] = useState(centerapplication?.staffRoomAdequate)
    const [toiletAdequate] = useState(centerapplication?.toiletAdequate)
    const [firstAidFireAdequate] = useState(centerapplication?.firstAidFireAdequate)
    const [hostelsAdequate] = useState(centerapplication?.hostelsAdequate)
    const [tradeToolsAdequate] = useState(centerapplication?.tradeToolsAdequate)
    const [auditoriumAvailable] = useState(centerapplication?.auditoriumAvailable)
    const [libraryAdequate] = useState(centerapplication?.libraryAdequate)
    const [waterAndPowerAdequate] = useState(centerapplication?.waterAndPowerAdequate)
    const [mgtOfficesAdequate] = useState(centerapplication?.mgtOfficesAdequate)


    //EQA FEEDBACK 3 INITIALIZE
    const [nosAvailable] = useState(centerapplication?.nosAvailable)
    const [trainingManualAvailable] = useState(centerapplication?.trainingManualAvailable)
    const [nosAndManualAlignment] = useState(centerapplication?.nosAndManualAlignment)
    const [otherTrainingMaterialsAvailable] = useState(centerapplication?.otherTrainingMaterialsAvailable)
    const [nsqGuidelineAvailable] = useState(centerapplication?.nsqGuidelineAvailable)
    const [staffCompliantWithStandards] = useState(centerapplication?.staffCompliantWithStandards)
    const [objectivesClear] = useState(centerapplication?.objectivesClear)
    const [classParticipation] = useState(centerapplication?.classParticipation)


    //SUPPORTING DOCS & DECLARATION INITIALIZE
    const [applicantsName, setApplicantsName] = useState(centerapplication.applicantsName)
    const [applicantsPosition, setApplicantsPosition] = useState(centerapplication.applicantsPosition)

    //INITIALIZE UPLOAD VARIABLES
    const [centerCredentials, setCenterCredentials] = useState("")
    const [centerCredentialsURL, setCenterCredentialsURL] = useState(centerapplication.centerCredentialsURL)
    const [previewCredentials, setPreviewCredentials] = useState(centerapplication.centerCredentialsURL)


    const [alert, setAlert] = useState(null)


    //CENTER DETAILS HANDLE CHANGE
    const onCenterNameChanged = e => setCenterName(e.target.value)
    const onCACChanged = e => setCACNumber(e.target.value)
    const onPermanentCenterAddressChanged = e => setPermanentCenterAddress(e.target.value)
    const onTrainingCenterAddressChanged = e => setTrainingCenterAddress(e.target.value)
    const onPhoneChanged = e => setPhone(e.target.value)
    const onEmailChanged = e => setEmail(e.target.value)
    const onWebsiteChanged = e => setWebsite(e.target.value)
    const onCenterHeadChanged = e => setCenterHead(e.target.value)
    const onCenterHeadPhoneChanged = e => setCenterHeadPhone(e.target.value)
    const onCenterHeadEmailChanged = e => setCenterHeadEmail(e.target.value)

    //CENTER ORGANIZATION HANDLE CHANGE
    const onCenterTypeChanged = e => setCenterType(e.target.value)
    const onSatelliteCenterName1Changed = e => setSatelliteCenterName1(e.target.value)
    const onSatelliteCenterAddress1Changed = e => setSatelliteCenterAddress1(e.target.value)
    const onSatelliteCenterName2Changed = e => setSatelliteCenterName2(e.target.value)
    const onSatelliteCenterAddress2Changed = e => setSatelliteCenterAddress2(e.target.value)
    const onOtherApprovalsChange = (event) => {
      setOtherApprovals({ ...otherApprovals, [event.target.name]: event.target.checked })
    }
    const { itp, naddc, nabteb, cityGuild } = otherApprovals
    const onAwardingBody1Changed = e => setAwardingBody1(e.target.value)
    const onAwardingBody1LevelChanged = e => setAwardingBody1Level(e.target.value)
    const onAwardingBody2Changed = e => setAwardingBody2(e.target.value)
    const onAwardingBody2LevelChanged = e => setAwardingBody2Level(e.target.value)

    //HUMAN & FINANCIAL RESOURCES HANDLE CHANGE
    const onAssessorsCountChanged = e => setAssessorsCount(e.target.value)
    const onVerifiersCountChanged = e => setVerifiersCount(e.target.value)

    //ORGANIZATIONAL STAFFING HANDLE CHANGE
    const onCenterManagerNameChanged = e => setCenterManagerName(e.target.value)
    const onCenterManagerQualificationChanged = e => setCenterManagerQualification(e.target.value)
    const onAssessor1NameChanged = e => setAssessor1Name(e.target.value)
    const onAssessor1QualificationChanged = e => setAssessor1Qualification(e.target.value)
    const onAssessor2NameChanged = e => setAssessor2Name(e.target.value)
    const onAssessor2QualificationChanged = e => setAssessor2Qualification(e.target.value)
    const onInternalVerifierNameChanged = e => setInternalVerifierName(e.target.value)
    const onInternalVerifierQualificationChanged = e => setInternalVerifierQualification(e.target.value)
    const onLiaisonOfficerNameChanged = e => setLiaisonOfficerName(e.target.value)
    const onLiaisonOfficerQualificationChanged = e => setLiaisonOfficerQualification(e.target.value)
    const onResourceOfficerNameChanged = e => setResourceOfficerName(e.target.value)
    const onResourceOfficerQualificationChanged = e => setResourceOfficerQualification(e.target.value)
    const onOtherStaffNameChanged = e => setOtherStaffName(e.target.value)
    const onOtherStaffQualificationChanged = e => setOtherStaffQualification(e.target.value)
    const onOtherStaffDesignationChanged = e => setOtherStaffDesignation(e.target.value)

    //PHYSICAL FACILITIES HANDLE CHANGE
    const onTrainingRoomQuantityChanged = e => setTrainingRoomQuantity(e.target.value)
    const onTrainingRoomFloorAreaChanged = e => setTrainingRoomFloorArea(e.target.value)
    const onWorkshopQuantityChanged = e => setWorkshopQuantity(e.target.value)
    const onWorkshopFloorAreaChanged = e => setWorkshopFloorArea(e.target.value)
    const onPracticalTrainingSpaceQuantityChanged = e => setPracticalTrainingSpaceQuantity(e.target.value)
    const onPracticalTrainingSpaceFloorAreaChanged = e => setPracticalTrainingSpaceFloorArea(e.target.value)
    const onTechnicalLabQuantityChanged = e => setTechnicalLabQuantity(e.target.value)
    const onTechnicalLabFloorAreaChanged = e => setTechnicalLabFloorArea(e.target.value)
    const onRecordsRoomQuantityChanged = e => setRecordsRoomQuantity(e.target.value)
    const onRecordsRoomFloorAreaChanged = e => setRecordsRoomFloorArea(e.target.value)
    const onAuditoriumQuantityChanged = e => setAuditoriumQuantity(e.target.value)
    const onAuditoriumFloorAreaChanged = e => setAuditoriumFloorArea(e.target.value)
    const onLibraryQuantityChanged = e => setLibraryQuantity(e.target.value)
    const onLibraryFloorAreaChanged = e => setLibraryFloorArea(e.target.value)
    const onUtilityQuantityChanged = e => setUtilityQuantity(e.target.value)
    const onUtilityFloorAreaChanged = e => setUtilityFloorArea(e.target.value)
    const onMgtOfficesQuantityChanged = e => setMgtOfficesQuantity(e.target.value)
    const onMgtOfficesFloorAreaChanged = e => setMgtOfficesFloorArea(e.target.value)
    const onStaffRoomQuantityChanged = e => setStaffRoomQuantity(e.target.value)
    const onStaffRoomFloorAreaChanged = e => setStaffRoomFloorArea(e.target.value)
    const onToiletQuantityChanged = e => setToiletQuantity(e.target.value)
    const onToiletFloorAreaChanged = e => setToiletFloorArea(e.target.value)
    const onFirstAidFireFacilityQuantityChanged = e => setFirstAidFireFacilityQuantity(e.target.value)
    const onFirstAidFireFacilityFloorAreaChanged = e => setFirstAidFireFacilityFloorArea(e.target.value)
    const onHostelQuantityChanged = e => setHostelQuantity(e.target.value)
    const onHostelFloorAreaChanged = e => setHostelFloorArea(e.target.value)
    const onTradeToolsQuantityChanged = e => setTradeToolsQuantity(e.target.value)
    const onTradeToolsFloorAreaChanged = e => setTradeToolsFloorArea(e.target.value)
    const onOtherFacilityNameChanged = e => setOtherFacilityName(e.target.value)
    const onOtherFacilityQuantityChanged = e => setOtherFacilityQuantity(e.target.value)
    const onOtherFacilityFloorAreaChanged = e => setOtherFacilityFloorArea(e.target.value)

    //TRAINING STAFF HANDLE CHANGE
    const onTrainingStaff1NameChanged = e => setTrainingStaff1Name(e.target.value)
    const onTrainingStaff1QualificationChanged = e => setTrainingStaff1Qualification(e.target.value)
    const onTrainingStaff1ExperienceChanged = e => setTrainingStaff1Experience(e.target.value)
    const onTrainingStaff2NameChanged = e => setTrainingStaff2Name(e.target.value)
    const onTrainingStaff2QualificationChanged = e => setTrainingStaff2Qualification(e.target.value)
    const onTrainingStaff2ExperienceChanged = e => setTrainingStaff2Experience(e.target.value)
    const onTrainingStaff3NameChanged = e => setTrainingStaff3Name(e.target.value)
    const onTrainingStaff3QualificationChanged = e => setTrainingStaff3Qualification(e.target.value)
    const onTrainingStaff3ExperienceChanged = e => setTrainingStaff3Experience(e.target.value)

    //SUPPORTING DOCS & DECLARATION HANDLE CHANGE
    const onApplicantsNameChanged = e => setApplicantsName(e.target.value)
    const onApplicantsPositionChanged = e => setApplicantsPosition(e.target.value)
    const onSelectCredentials = e => {
        if (e.target.files && e.target.files.length > 0) {
            setCenterCredentials(e.target.files[0])
        }
    }


    const canSave = [
        nsqCertifiedAssessors !== "",
        assessorsCount !== "",
        nsqCertifiedVerifiers !== "",
        verifiersCount !== "",
        nsqCertifiedCenterManager !== "",
        liaisonOfficerPresent !== "",
        resourceOfficerPresent !== "",
        siteBankPresent !== "",
        trainingRoomAdequate !== "",
        dataRoomAdequate !== "",
        workshopAdequate !== "",
        practicalSpaceAdequate !== "",
        techLabAdequate !== "",
        staffRoomAdequate !== "",
        toiletAdequate !== "",
        firstAidFireAdequate !== "",
        hostelsAdequate !== "",
        tradeToolsAdequate !== "",
        auditoriumAvailable !== "",
        libraryAdequate !== "",
        waterAndPowerAdequate !== "",
        mgtOfficesAdequate !== "",
        nosAvailable !== "",
        trainingManualAvailable !== "",
        nosAndManualAlignment !== "",
        otherTrainingMaterialsAvailable !== "",
        nsqGuidelineAvailable !== "",
        staffCompliantWithStandards !== "",
        objectivesClear !== "",
        classParticipation !== ""
    ].every(Boolean) && !isLoading


    const onSaveCenterApplicationClicked = async e => {
        e.preventDefault()

        // Split qualifications string into an array
        //const qualificationsArray = qualifications ? qualifications.split(',') : []

        const qualification_ids = qualifications
          .map(qualification => qualification.trim()) // Trim any extra whitespace
          .map(qualification => {
            // Find the corresponding qualification object in QUALIFICATIONS
            const qualificationObj = Object.entries(QUALIFICATIONS).find(
              ([key, value]) => value === qualification // Compare the value part of the entry
            )
            return qualificationObj ? qualificationObj[0] : null // Return the qualification id or null if not found
          })
          .filter(qualificationId => qualificationId !== null) // Remove any null values



        await updateCenterApplication({
            id: centerapplication.id,
            centerName,
            'yearOfInc': yearOfInc.$y,
            cacNumber,
            permanentCenterAddress,
            trainingCenterAddress,
            phone,
            email,
            website,
            centerHead,
            centerHeadPhone,
            centerHeadEmail,
            qualifications: qualification_ids,
            stateOfRez,
            centerType,
            satelliteCenterName1,
            satelliteCenterAddress1,
            satelliteCenterName2,
            satelliteCenterAddress2,
            otherApprovals,
            awardingBody1,
            awardingBody1Qualification,
            awardingBody1Level,
            awardingBody1DateApproved,
            awardingBody1ApprovalStatus,
            awardingBody2,
            awardingBody2Qualification,
            awardingBody2Level,
            awardingBody2DateApproved,
            awardingBody2ApprovalStatus,
            nsqCertifiedAssessors,
            assessorsCount,
            nsqCertifiedVerifiers,
            verifiersCount,
            nsqCertifiedCenterManager,
            liaisonOfficerPresent,
            resourceOfficerPresent,
            siteBankPresent,
            trainingRoomAdequate,
            dataRoomAdequate,
            workshopAdequate,
            practicalSpaceAdequate,
            techLabAdequate,
            staffRoomAdequate,
            toiletAdequate,
            firstAidFireAdequate,
            hostelsAdequate,
            tradeToolsAdequate,
            auditoriumAvailable,
            libraryAdequate,
            waterAndPowerAdequate,
            mgtOfficesAdequate,
            nosAvailable,
            trainingManualAvailable,
            nosAndManualAlignment,
            otherTrainingMaterialsAvailable,
            nsqGuidelineAvailable,
            staffCompliantWithStandards,
            objectivesClear,
            classParticipation,
            centerManagerName,
            centerManagerQualification,
            assessor1Name,
            assessor1Qualification,
            assessor2Name,
            assessor2Qualification,
            internalVerifierName,
            internalVerifierQualification,
            liaisonOfficerName,
            liaisonOfficerQualification,
            resourceOfficerName,
            resourceOfficerQualification,
            otherStaffName,
            otherStaffQualification,
            otherStaffDesignation,
            trainingRoomQuantity,
            trainingRoomFloorArea,
            workshopQuantity,
            workshopFloorArea,
            practicalTrainingSpaceQuantity,
            practicalTrainingSpaceFloorArea,
            technicalLabQuantity,
            technicalLabFloorArea,
            recordsRoomQuantity,
            recordsRoomFloorArea,
            auditoriumQuantity,
            auditoriumFloorArea,
            libraryQuantity,
            libraryFloorArea,
            utilityQuantity,
            utilityFloorArea,
            mgtOfficesQuantity,
            mgtOfficesFloorArea,
            staffRoomQuantity,
            staffRoomFloorArea,
            toiletQuantity,
            toiletFloorArea,
            firstAidFireFacilityQuantity,
            firstAidFireFacilityFloorArea,
            hostelQuantity,
            hostelFloorArea,
            tradeToolsQuantity,
            tradeToolsFloorArea,
            otherFacilityName,
            otherFacilityQuantity,
            otherFacilityFloorArea,
            trainingStaff1Name,
            trainingStaff1Gender,
            trainingStaff1Qualification,
            trainingStaff1Experience,
            trainingStaff1Specialization,
            trainingStaff2Name,
            trainingStaff2Gender,
            trainingStaff2Qualification,
            trainingStaff2Experience,
            trainingStaff2Specialization,
            trainingStaff3Name,
            trainingStaff3Gender,
            trainingStaff3Qualification,
            trainingStaff3Experience,
            trainingStaff3Specialization,
            applicantsName,
            applicantsPosition,
            centerCredentialsURL
        })
    }

    useEffect(() => {
        if (isSuccess) {
            setAlert("Centre Application successfully Updated!")
            setTimeout(() => navigate("/", { replace: true }), 7000)
        }
    }, [isSuccess, navigate])


    const options1 = Object.values(STATES).map(_state => (
        <MenuItem key={_state} value={_state}>{_state}</MenuItem>
    ))

    const options2 = Object.values(CENTERTYPES).map(centerType => (
        <MenuItem key={centerType} value={centerType}>
            {centerType}
        </MenuItem>
    ))

    /*const options3 = qualificationsData?.entities ? Object.entries(qualificationsData.entities).map(([id, qualification]) => (
        <MenuItem key={id} value={qualification.qualificationType}>{qualification.qualificationType}</MenuItem>
    )) : null*/

    const options4 = Object.entries(QUALIFICATIONS)
    .filter(([key, value]) => centerapplication.qualifications.includes(value)) // Filter to include only qualifications present in centerapplication
    .map(([key, value]) => (
      <MenuItem key={key} value={value}>{value}</MenuItem>
    ))

    const errContent = error?.data?.message ?? ''


    const handleChange = (event, newValue) => {
      setValue(newValue)
    }


    const content = (
      <>
      <DashHeader />
      <Header title="EDIT CENTRE APPLICATION" subtitle={`Updating Application Record for ${centerapplication.centerName.toUpperCase()}`} />
      <Box sx={{ width: '100%', mb: '70px', "& .MuiPaper-root": {backgroundColor: '#030702'}, p: "2% 2%" }}>

          <Box
            sx={{
              flexGrow: 1,
              bgcolor: '#F1F3F1',
              display: isMobile ? 'block' : 'flex',
              "& .MuiTab-root.Mui-selected": {
                  color: '#0b1c08',
                  fontWeight: 700
              },
              "& .MuiTab-root": {
                  alignItems: 'flex-start',
                  color: '#295C21',
                  borderBottom: '1px solid #0b1c081a'
              },
              "& .MuiTabs-indicator": {
                  backgroundColor: '#49a139'
              },
              "& .MuiFormHelperText-root": {
                  color: '#49a139'
              }
            }}
          >
              <Tabs
                orientation={isMobile ? "horizontal" : "vertical"}
                variant="scrollable"
                allowScrollButtonsMobile
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs"
                sx={{ borderRight: "1px solid #D7D9D7", minWidth: isMobile ? '100%' : '280px', color:"#49a139" }}
              >
                    <Tab label="Instructions" {...a11yProps(0)} />
                    <Tab label="A. CENTRE DETAILS" {...a11yProps(1)} />
                    <Tab label="B. YOUR ORGANISATION" {...a11yProps(2)} />
                    <Tab label="C. ORGANISATIONAL STAFFING" {...a11yProps(3)} />
                    <Tab label="D. PHYSICAL FACILITIES" {...a11yProps(4)} />
                    <Tab label="E. CENTRE TRAINING STAFF" {...a11yProps(5)} />
                    <Tab label="F. HUMAN & FINANCIAL RESOURCE" {...a11yProps(6)} />
                    <Tab label="G. PHYSICAL INFRASTRUCTURE" {...a11yProps(7)} />
                    <Tab label="H. CODE OF PRACTICE" {...a11yProps(8)} />
                    <Tab label="I. SUPPORTING DOCUMENTS" {...a11yProps(9)} />
              </Tabs>

              <form className="form" onSubmit={onSaveCenterApplicationClicked}>
                {/*Instructions*/}
                <TabPanel value={value} index={0} sx={{ width: isMobile ? "100%" : "90%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: isMobile ? "" : "470px",
                        overflowY: isMobile ? "none" : "scroll",
                        pr: 2
                      }}
                    >
                        <Typography sx={{color:'#295C21', fontSize:17, mb:3}}>
                            APPLICATION / APPROVAL FORM INSTRUCTIONS
                        </Typography>

                        <Box sx={{maxWidth: isMobile ? "90%" : "78%", mb:5}}>
                            <Typography sx={{color:'#0b1c08', fontSize:16, wordWrap: "break-word", mb:3}}>
                                Please use this form to request approval as Training/Assessment Centre for NSQs
                                Complete all sections in BLOCK LETTERS and give your details as you want them to
                                appear in NIOB records.
                            </Typography>
                            <Typography sx={{color:'#295C21', fontSize:16, wordWrap: "break-word"}}>
                                Read the following conditions which apply to this approval request, before you complete
                                this form:
                            </Typography>
                            <List sx={{fontSize:14, wordWrap: "break-word"}}>
                                <ListItem>
                                    1. You will be required to pay an inspection fee which will be invoiced to your Account.
                                </ListItem>
                                <ListItem>
                                    2. Payment for an inspection visit fee is required per venue/satellite Centre.
                                </ListItem>
                                <ListItem>
                                    3. Your Centre and Qualification(s) approval will lapse if there is no activity within one year
                                    of approval being granted.
                                </ListItem>
                                <ListItem>
                                    4. Failure to provide accurate information may result in withdrawal of any Approval granted earlier.
                                </ListItem>
                            </List>
                        </Box>

                        <Button
                            onClick={() => {setValue(1)}}
                            sx={{color:"#fff"}}
                            variant="contained"
                        >Proceed to Apply</Button>

                    </Box>
                </TabPanel>

                {/*CENTRE DETAILS*/}
                <TabPanel value={value} index={1} sx={{ width: isMobile ? "100%" : "90%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: isMobile ? "" : "470px",
                        overflowY: isMobile ? "none" : "scroll",
                        pr: 2
                      }}
                    >
                        <Typography sx={{color:'#295C21', fontSize:12, mb:3}}>CENTRE DETAILS</Typography>
                        <TextField
                          variant="outlined"
                          type="text"
                          label={<span>Centre Name <span style={{ color: 'red' }}>*</span></span>}
                          onChange={onCenterNameChanged}
                          value={centerName}
                          name="centerName"
                          sx={{ width: isMobile ? "100%" : "32%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            variant="outlined"
                            label={'Year of Incorporation'}
                            openTo="year"
                            onChange={(newValue) => setYearOfInc(newValue)}
                            value={dayjs(yearOfInc)}
                            views={['year']}
                            name="yearOfInc"
                            sx={{ width: isMobile ? "100%" : "32%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                          />
                        </LocalizationProvider>

                        <TextField
                          variant="outlined"
                          type="text"
                          label={<span>CAC Number <span style={{ color: 'red' }}>*</span></span>}
                          onChange={onCACChanged}
                          value={cacNumber}
                          name="cacNumber"
                          sx={{ width: isMobile ? "100%" : "32%", mb:"30px" }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">RC</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          multiline
                          rows={3}
                          type="text"
                          label={<span>Permanent Centre Address <span style={{ color: 'red' }}>*</span></span>}
                          onChange={onPermanentCenterAddressChanged}
                          value={permanentCenterAddress}
                          name="permanentCenterAddress"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          multiline
                          rows={3}
                          type="text"
                          label="Training Centre Address"
                          onChange={onTrainingCenterAddressChanged}
                          value={trainingCenterAddress}
                          name="trainingCenterAddress"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"30px" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label={<span>Phone Number <span style={{ color: 'red' }}>*</span></span>}
                          onChange={onPhoneChanged}
                          value={phone}
                          name="phone"
                          sx={{ width: isMobile ? "100%" : "32%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">+234</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="email"
                          label={<span>Email <span style={{ color: 'red' }}>*</span></span>}
                          onChange={onEmailChanged}
                          value={email}
                          name="email"
                          sx={{ width: isMobile ? "100%" : "32%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Website"
                          onChange={onWebsiteChanged}
                          value={website}
                          name="website"
                          sx={{ width: isMobile ? "100%" : "32%", mb:"30px" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label={<span>Centre Head Fullname <span style={{ color: 'red' }}>*</span></span>}
                          onChange={onCenterHeadChanged}
                          value={centerHead}
                          name="centerHead"
                          sx={{ width: isMobile ? "100%" : "32%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label={<span>Centre Head Phone Number <span style={{ color: 'red' }}>*</span></span>}
                          onChange={onCenterHeadPhoneChanged}
                          value={centerHeadPhone}
                          name="centerHeadPhone"
                          sx={{ width: isMobile ? "100%" : "32%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">+234</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="email"
                          label="Centre Head Email"
                          onChange={onCenterHeadEmailChanged}
                          value={centerHeadEmail}
                          name="centerHeadEmail"
                          sx={{ width: isMobile ? "100%" : "32%", mb:"30px" }}
                        />

                        <FormControl sx={{ width: isMobile ? "100%" : "49%", mr: isMobile ? "0%" : "2%" }}>
                          <InputLabel id="qualifications-id">Qualifications <span style={{ color: 'red' }}>*</span></InputLabel>
                          <Select
                            labelId="qualifications-id"
                            variant="outlined"
                            name="qualifications"
                            value={qualifications}
                            onChange={(e) => setQualifications(e.target.value)}
                            multiple
                          >
                            {options4}
                          </Select>
                        </FormControl>

                        <FormControl sx={{ width: isMobile ? "100%" : "49%" }}>
                          <InputLabel id="state-id">State <span style={{ color: 'red' }}>*</span></InputLabel>
                          <Select
                            labelId="state-id"
                            variant="outlined"
                            name="stateOfRez"
                            value={stateOfRez}
                            onChange={(e) => setStateOfRez(e.target.value)}
                          >
                            {options1}
                          </Select>
                        </FormControl>

                        <Stack direction="row" gap={2}>
                            <Button
                                sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                onClick={() => {setValue(0)}}
                                variant="contained"
                            >Previous</Button>
                            <Button
                                sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                onClick={() => {setValue(2)}}
                                variant="contained"
                            >Next</Button>
                        </Stack>
                    </Box>

                </TabPanel>

                {/*YOUR ORGANISATION*/}
                <TabPanel value={value} index={2} sx={{ width: isMobile ? "100%" : "90%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: isMobile ? "" : "470px",
                        overflowY: isMobile ? "none" : "scroll",
                        pr: 2
                      }}
                    >
                        <Typography sx={{color:'#295C21', fontSize:12, mb:3}}>CENTRE ORGANISATION</Typography>

                        <FormControl sx={{width:"100%", mb:"30px"}}>
                          <InputLabel id="center-type-id">Centre Type</InputLabel>
                          <Select
                            labelId="center-type-id"
                            variant="outlined"
                            value={centerType}
                            name="centerType"
                            onChange={(e) => setCenterType(e.target.value)}
                          >
                            {options2}
                          </Select>
                        </FormControl>


                        <Typography sx={{width:"100%", mb:"10px"}}>Satellite Centres where Training is provided other than your Main Campus/Centre</Typography>
                        <TextField
                          variant="outlined"
                          type="text"
                          label="Satellite Centre Name 1"
                          onChange={onSatelliteCenterName1Changed}
                          value={satelliteCenterName1}
                          name="satelliteCenterName1"
                          sx={{ width: isMobile ? "100%" : "40%", mb:"15px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          multiline
                          type="text"
                          label="Satellite Centre Address 1"
                          onChange={onSatelliteCenterAddress1Changed}
                          value={satelliteCenterAddress1}
                          name="satelliteCenterAddress1"
                          sx={{ width: isMobile ? "100%" : "58%", mb:"15px" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Satellite Centre Name 2"
                          onChange={onSatelliteCenterName2Changed}
                          value={satelliteCenterName2}
                          name="satelliteCenterName2"
                          sx={{ width: isMobile ? "100%" : "40%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          multiline
                          type="text"
                          label="Satellite Centre Address 2"
                          onChange={onSatelliteCenterAddress2Changed}
                          value={satelliteCenterAddress2}
                          name="satelliteCenterAddress2"
                          sx={{ width: isMobile ? "100%" : "58%", mb:"30px" }}
                        />


                        <FormControl fullWidth sx={{ width: '100%', mb:"30px" }}>
                          <FormLabel component="legend" sx={{color:"#49a139"}}>Indicate if you are currently an approved Centre of another Awarding Body</FormLabel>
                          <FormGroup row>
                            <FormControlLabel
                              control={<Checkbox checked={itp} onChange={onOtherApprovalsChange} name="itp" sx={{color:"#49a139"}} />}
                              label="ITP"
                            />
                            <FormControlLabel
                              control={<Checkbox checked={naddc} onChange={onOtherApprovalsChange} name="naddc" sx={{color:"#49a139"}} />}
                              label="NADDC"
                            />
                            <FormControlLabel
                              control={<Checkbox checked={nabteb} onChange={onOtherApprovalsChange} name="nabteb" sx={{color:"#49a139"}} />}
                              label="NABTEB"
                            />
                            <FormControlLabel
                              control={<Checkbox checked={cityGuild} onChange={onOtherApprovalsChange} name="cityGuild" sx={{color:"#49a139"}} />}
                              label="City & Guilds"
                            />
                          </FormGroup>
                        </FormControl>


                        <Typography sx={{width:"100%", mb:"10px"}}>If your Centre is currently approved as a Centre for NSQ by another Awarding Body, please give details below:</Typography>
                        <TextField
                          variant="outlined"
                          type="text"
                          label="Awarding Body 1"
                          onChange={onAwardingBody1Changed}
                          value={awardingBody1}
                          name="awardingBody1"
                          sx={{ width: isMobile ? "100%" : "20%", mb:"15px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <FormControl sx={{ width: isMobile ? "100%" : "25%", mb:"15px", mr: isMobile ? "0%" : "2%" }}>
                          <InputLabel id="awardingBody1Qualification-id">Qualification 1</InputLabel>
                          <Select
                            labelId="awardingBody1Qualification-id"
                            variant="outlined"
                            name="awardingBody1Qualification"
                            value={awardingBody1Qualification}
                            onChange={(e) => setAwardingBody1Qualification(e.target.value)}
                          >
                            {options4}
                          </Select>
                        </FormControl>

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 1, max: 5 } }}
                          label="Level"
                          onChange={onAwardingBody1LevelChanged}
                          value={awardingBody1Level}
                          name="awardingBody1Level"
                          sx={{ width: isMobile ? "100%" : "10%", mb:"15px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            variant="outlined"
                            label={'Date Approved'}
                            onChange={(newValue) => setAwardingBody1DateApproved(newValue)}
                            value={awardingBody1DateApproved}
                            maxDate={dayjs(today)}
                            name="awardingBody1DateApproved"
                            sx={{ width: isMobile ? "100%" : "17%", mb:"15px", mr: isMobile ? "0%" : "2%" }}
                          />
                        </LocalizationProvider>

                        <FormControl sx={{ width: isMobile ? "100%" : "20%", mb:"15px"}}>
                          <InputLabel id="approvalStatus-id">Approval Status</InputLabel>
                          <Select
                            labelId="approvalStatus-id"
                            variant="outlined"
                            name="awardingBody1ApprovalStatus"
                            value={awardingBody1ApprovalStatus}
                            onChange={(e) => setAwardingBody1ApprovalStatus(e.target.value)}
                          >
                              <MenuItem value={"Done"}>Done</MenuItem>
                              <MenuItem value={"Pending"}>Pending</MenuItem>
                          </Select>
                        </FormControl>

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Awarding Body 2"
                          onChange={onAwardingBody2Changed}
                          value={awardingBody2}
                          name="awardingBody2"
                          sx={{ width: isMobile ? "100%" : "20%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <FormControl sx={{ width: isMobile ? "100%" : "25%", mb:"30px", mr: isMobile ? "0%" : "2%" }}>
                          <InputLabel id="awardingBody2Qualification-id">Qualification 2</InputLabel>
                          <Select
                            labelId="awardingBody2Qualification-id"
                            variant="outlined"
                            name="awardingBody2Qualification"
                            value={awardingBody2Qualification}
                            onChange={(e) => setAwardingBody2Qualification(e.target.value)}
                          >
                            {options4}
                          </Select>
                        </FormControl>

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 1, max: 5 } }}
                          label="Level"
                          onChange={onAwardingBody2LevelChanged}
                          value={awardingBody2Level}
                          name="awardingBody2Level"
                          sx={{ width: isMobile ? "100%" : "10%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            variant="outlined"
                            label={'Date Approved'}
                            onChange={(newValue) => setAwardingBody2DateApproved(newValue)}
                            value={awardingBody2DateApproved}
                            maxDate={dayjs(today)}
                            name="awardingBody2DateApproved"
                            sx={{ width: isMobile ? "100%" : "17%", mb:"30px", mr: isMobile ? "0%" : "2%" }}
                          />
                        </LocalizationProvider>

                        <FormControl sx={{ width: isMobile ? "100%" : "20%", mb:"30px"}}>
                          <InputLabel id="approvalStatus-id">Approval Status</InputLabel>
                          <Select
                            labelId="approvalStatus-id"
                            variant="outlined"
                            name="awardingBody2ApprovalStatus"
                            value={awardingBody2ApprovalStatus}
                            onChange={(e) => setAwardingBody2ApprovalStatus(e.target.value)}
                          >
                              <MenuItem value={"Done"}>Done</MenuItem>
                              <MenuItem value={"Pending"}>Pending</MenuItem>
                          </Select>
                        </FormControl>

                        <Stack direction="row" gap={2}>
                            <Button
                                sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                onClick={() => {setValue(1)}}
                                variant="contained"
                            >Previous</Button>
                            <Button
                                sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                onClick={() => {setValue(3)}}
                                variant="contained"
                            >Next</Button>
                        </Stack>
                    </Box>
                </TabPanel>

                {/*CENTRE ORGANISATIONAL STAFFING*/}
                <TabPanel value={value} index={3} sx={{ width: isMobile ? "100%" : "90%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: isMobile ? "" : "470px",
                        overflowY: isMobile ? "none" : "scroll",
                        pr: 2
                      }}
                    >
                        <Typography sx={{color:'#295C21', fontSize:12, mb:3}}>CENTRE ORGANISATIONAL STAFFING</Typography>

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Centre Manager's FullName"
                          onChange={onCenterManagerNameChanged}
                          value={centerManagerName}
                          name="centerManagerName"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Centre Manager's Qualification"
                          onChange={onCenterManagerQualificationChanged}
                          value={centerManagerQualification}
                          name="centerManagerQualification"
                          helperText="e.g: BSc. Building, HND Marketing, etc."
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Assessor 1's FullName"
                          onChange={onAssessor1NameChanged}
                          value={assessor1Name}
                          name="assessor1Name"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Assessor 1's Qualification"
                          onChange={onAssessor1QualificationChanged}
                          value={assessor1Qualification}
                          name="assessor1Qualification"
                          helperText="e.g: BSc. Building, HND Marketing, etc."
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Assessor 2's FullName"
                          onChange={onAssessor2NameChanged}
                          value={(assessor2Name === "") ? "N/A" : assessor2Name}
                          name="assessor2Name"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Assessor 2's Qualification"
                          onChange={onAssessor2QualificationChanged}
                          value={(assessor2Qualification === "") ? "N/A" : assessor2Qualification}
                          name="assessor2Qualification"
                          helperText="e.g: BSc. Building, HND Marketing, etc."
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Internal Verifier's FullName"
                          onChange={onInternalVerifierNameChanged}
                          value={internalVerifierName}
                          name="internalVerifierName"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Internal Verifier's Qualification"
                          onChange={onInternalVerifierQualificationChanged}
                          value={internalVerifierQualification}
                          name="internalVerifierQualification"
                          helperText="e.g: BSc. Building, HND Marketing, etc."
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Industry/Workplace Liaison Officer's FullName"
                          onChange={onLiaisonOfficerNameChanged}
                          value={(liaisonOfficerName === "") ? "N/A" : liaisonOfficerName}
                          name="liaisonOfficerName"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Industry/Workplace Liaison Officer's Qualification"
                          onChange={onLiaisonOfficerQualificationChanged}
                          value={(liaisonOfficerQualification === "") ? "N/A" : liaisonOfficerQualification}
                          name="liaisonOfficerQualification"
                          helperText="e.g: BSc. Building, HND Marketing, etc."
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Resource/Data Officer's FullName"
                          onChange={onResourceOfficerNameChanged}
                          value={(resourceOfficerName === "") ? "N/A" : resourceOfficerName}
                          name="resourceOfficerName"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Resource/Data Officer's Qualification"
                          onChange={onResourceOfficerQualificationChanged}
                          value={(resourceOfficerQualification === "") ? "N/A" : resourceOfficerQualification}
                          name="resourceOfficerQualification"
                          helperText="e.g: BSc. Building, HND Marketing, etc."
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Other Staff's FullName"
                          onChange={onOtherStaffNameChanged}
                          value={(otherStaffName === "") ? "N/A" : otherStaffName}
                          name="otherStaffName"
                          sx={{ width: isMobile ? "100%" : "32%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Other Staff's Qualification"
                          onChange={onOtherStaffQualificationChanged}
                          value={(otherStaffQualification === "") ? "N/A" : otherStaffQualification}
                          name="otherStaffQualification"
                          helperText="e.g: BSc. Building, HND Marketing, etc."
                          sx={{ width: isMobile ? "100%" : "32%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Other Staff's Designation"
                          onChange={onOtherStaffQualificationChanged}
                          value={(otherStaffDesignation === "") ? "N/A" : otherStaffDesignation}
                          name="otherStaffDesignation"
                          helperText="e.g: Program Officer, HOD Admin, etc."
                          sx={{ width: isMobile ? "100%" : "32%", mb:"10px" }}
                        />

                        <Stack direction="row" gap={2}>
                            <Button
                                sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                onClick={() => {setValue(2)}}
                                variant="contained"
                            >Previous</Button>
                            <Button
                                sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                onClick={() => {setValue(4)}}
                                variant="contained"
                            >Next</Button>
                        </Stack>
                    </Box>
                </TabPanel>

                {/*CENTRE PHYSICAL FACILITIES*/}
                <TabPanel value={value} index={4} sx={{ width: isMobile ? "100%" : "90%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: isMobile ? "" : "470px",
                        overflowY: isMobile ? "none" : "scroll",
                        pr: 2
                      }}
                    >
                        <Typography sx={{color:'#295C21', fontSize:12, mb: 3}}>CENTRE PHYSICAL FACILITIES</Typography>

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Classrooms/Training Rooms - Quantity"

                          value={trainingRoomQuantity}
                          name="trainingRoomQuantity"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Classrooms/Training Rooms - Total Floor Area"

                          value={trainingRoomFloorArea}
                          name="trainingRoomFloorArea"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Workshops - Quantity"

                          value={workshopQuantity}
                          name="workshopQuantity"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Workshops - Total Floor Area"

                          value={workshopFloorArea}
                          name="workshopFloorArea"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Practical Training Space for each Trade - Quantity"

                          value={practicalTrainingSpaceQuantity}
                          name="practicalTrainingSpaceQuantity"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Practical Training Space for each Trade - Total Floor Area"

                          value={practicalTrainingSpaceFloorArea}
                          name="practicalTrainingSpaceFloorArea"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Technical Laboratories - Quantity"

                          value={technicalLabQuantity}
                          name="technicalLabQuantity"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Technical Laboratories - Total Floor Area"

                          value={technicalLabFloorArea}
                          name="technicalLabFloorArea"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Data/Records Room - Quantity"

                          value={recordsRoomQuantity}
                          name="recordsRoomQuantity"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Data/Records Room - Total Floor Area"

                          value={recordsRoomFloorArea}
                          name="recordsRoomFloorArea"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Auditorium - Quantity"

                          value={auditoriumQuantity}
                          name="auditoriumQuantity"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Auditorium - Total Floor Area"

                          value={auditoriumFloorArea}
                          name="auditoriumFloorArea"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Library & Study Rooms - Quantity"

                          value={libraryQuantity}
                          name="libraryQuantity"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Library & Study Rooms - Total Floor Area"

                          value={libraryFloorArea}
                          name="libraryFloorArea"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Water & Power Supply (Public/Private) - Quantity"

                          value={utilityQuantity}
                          name="utilityQuantity"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Water & Power Supply (Public/Private) - Total Floor Area"

                          value={utilityFloorArea}
                          name="utilityFloorArea"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Management Offices - Quantity"

                          value={mgtOfficesQuantity}
                          name="mgtOfficesQuantity"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Management Offices - Total Floor Area"

                          value={mgtOfficesFloorArea}
                          name="mgtOfficesFloorArea"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Staff Rooms - Quantity"

                          value={staffRoomQuantity}
                          name="staffRoomQuantity"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Staff Rooms - Total Floor Area"

                          value={staffRoomFloorArea}
                          name="staffRoomFloorArea"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Toilets - Quantity"

                          value={toiletQuantity}
                          name="toiletQuantity"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Toilets - Total Floor Area"

                          value={toiletFloorArea}
                          name="toiletFloorArea"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="First Aid/Firefighting Facility - Quantity"

                          value={firstAidFireFacilityQuantity}
                          name="firstAidFireFacilityQuantity"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="First Aid/Firefighting Facility - Total Floor Area"

                          value={firstAidFireFacilityFloorArea}
                          name="firstAidFireFacilityFloorArea"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Hostel - Quantity"

                          value={hostelQuantity}
                          name="hostelQuantity"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Hostel - Total Floor Area"

                          value={hostelFloorArea}
                          name="hostelFloorArea"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Tools & Equipment for relevant Trades - Quantity"

                          value={tradeToolsQuantity}
                          name="tradeToolsQuantity"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Tools & Equipment for relevant Trades - Total Floor Area"

                          value={tradeToolsFloorArea}
                          name="tradeToolsFloorArea"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"10px" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                          }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Other Facility Name"

                          value={(otherFacilityName === "") ? "N/A" : otherFacilityName}
                          name="otherFacilityName"
                          sx={{ width: isMobile ? "100%" : "32%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Others - Quantity"

                          value={otherFacilityQuantity}
                          name="otherFacilityQuantity"
                          sx={{ width: isMobile ? "100%" : "32%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Others - Total Floor Area"

                          value={otherFacilityFloorArea}
                          name="otherFacilityFloorArea"
                          sx={{ width: isMobile ? "100%" : "32%", mb:"10px" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
                          }}
                        />

                        <Stack direction="row" gap={2}>
                            <Button
                                sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                onClick={() => {setValue(3)}}
                                variant="contained"
                            >Previous</Button>
                            <Button
                                sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                onClick={() => {setValue(5)}}
                                variant="contained"
                            >Next</Button>
                        </Stack>
                    </Box>
                </TabPanel>

                {/*CENTRE TRAINING STAFF*/}
                <TabPanel value={value} index={5} sx={{ width: isMobile ? "100%" : "90%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: isMobile ? "" : "470px",
                        overflowY: isMobile ? "none" : "scroll",
                        pr: 2
                      }}
                    >
                        <Typography sx={{color:'#295C21', fontSize:12, mb: 3}}>CENTRE TRAINING STAFF</Typography>

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Full Name of Staff 1"

                          value={(trainingStaff1Name === "") ? "N/A" : trainingStaff1Name}
                          name="trainingStaff1Name"
                          sx={{ width: isMobile ? "100%" : "25%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Staff's Qualification"

                          value={(trainingStaff1Qualification === "") ? "N/A" : trainingStaff1Qualification}
                          name="trainingStaff1Qualification"
                          helperText="e.g: BSc. Building, HND Marketing, etc."
                          sx={{ width: isMobile ? "100%" : "25%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <FormControl sx={{ width: isMobile ? "49%" : "10%", mb:"10px", mr: isMobile ? "2%" : "2%"}}>
                          <InputLabel id="approvalStatus-id">Gender</InputLabel>
                          <Select
                            labelId="approvalStatus-id"
                            variant="outlined"
                            name="trainingStaff1Gender"
                            value={trainingStaff1Gender}

                          >
                              <MenuItem value={"Female"}>Female</MenuItem>
                              <MenuItem value={"Male"}>Male</MenuItem>
                          </Select>
                        </FormControl>

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Practical Experience"

                          value={trainingStaff1Experience}
                          name="trainingStaff1Experience"
                          sx={{ width: isMobile ? "49%" : "10%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">yrs</InputAdornment>,
                          }}
                        />

                        <FormControl sx={{ width: isMobile ? "100%" : "20%", mb: isMobile ? "50px" : "10px" }}>
                          <InputLabel id="qualifications-id">Trade Specialization</InputLabel>
                          <Select
                            labelId="qualifications-id"
                            variant="outlined"
                            name="trainingStaff1Specialization"
                            value={trainingStaff1Specialization}

                          >
                            {options4}
                          </Select>
                        </FormControl>


                        <TextField
                          variant="outlined"
                          type="text"
                          label="Full Name of Staff 2"

                          value={(trainingStaff2Name === "") ? "N/A" : trainingStaff2Name}
                          name="trainingStaff2Name"
                          sx={{ width: isMobile ? "100%" : "25%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Staff's Qualification"

                          value={(trainingStaff2Qualification === "") ? "N/A" : trainingStaff2Qualification}
                          name="trainingStaff2Qualification"
                          helperText="e.g: BSc. Building, HND Marketing, etc."
                          sx={{ width: isMobile ? "100%" : "25%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <FormControl sx={{ width: isMobile ? "49%" : "10%", mb:"10px", mr: isMobile ? "2%" : "2%"}}>
                          <InputLabel id="approvalStatus-id">Gender</InputLabel>
                          <Select
                            labelId="approvalStatus-id"
                            variant="outlined"
                            name="trainingStaff2Gender"
                            value={trainingStaff2Gender}

                          >
                              <MenuItem value={"Female"}>Female</MenuItem>
                              <MenuItem value={"Male"}>Male</MenuItem>
                          </Select>
                        </FormControl>

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Practical Experience"

                          value={trainingStaff2Experience}
                          name="trainingStaff2Experience"
                          sx={{ width: isMobile ? "49%" : "10%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">yrs</InputAdornment>,
                          }}
                        />

                        <FormControl sx={{ width: isMobile ? "100%" : "20%", mb: isMobile ? "50px" : "10px" }}>
                          <InputLabel id="qualifications-id">Trade Specialization</InputLabel>
                          <Select
                            labelId="qualifications-id"
                            variant="outlined"
                            name="trainingStaff2Specialization"
                            value={trainingStaff2Specialization}

                          >
                            {options4}
                          </Select>
                        </FormControl>


                        <TextField
                          variant="outlined"
                          type="text"
                          label="Full Name of Staff 3"

                          value={(trainingStaff3Name === "") ? "N/A" : trainingStaff3Name}
                          name="trainingStaff3Name"
                          sx={{ width: isMobile ? "100%" : "25%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Staff's Qualification"

                          value={(trainingStaff3Qualification === "") ? "N/A" : trainingStaff3Qualification}
                          name="trainingStaff3Qualification"
                          helperText="e.g: BSc. Building, HND Marketing, etc."
                          sx={{ width: isMobile ? "100%" : "25%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                        />

                        <FormControl sx={{ width: isMobile ? "49%" : "10%", mb:"10px", mr: isMobile ? "2%" : "2%"}}>
                          <InputLabel id="approvalStatus-id">Gender</InputLabel>
                          <Select
                            labelId="approvalStatus-id"
                            variant="outlined"
                            name="trainingStaff3Gender"
                            value={trainingStaff3Gender}

                          >
                              <MenuItem value={"Female"}>Female</MenuItem>
                              <MenuItem value={"Male"}>Male</MenuItem>
                          </Select>
                        </FormControl>

                        <TextField
                          variant="outlined"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          label="Practical Experience"

                          value={trainingStaff3Experience}
                          name="trainingStaff3Experience"
                          sx={{ width: isMobile ? "49%" : "10%", mb:"10px", mr: isMobile ? "0%" : "2%" }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">yrs</InputAdornment>,
                          }}
                        />

                        <FormControl sx={{ width: isMobile ? "100%" : "20%", mb: isMobile ? "50px" : "10px" }}>
                          <InputLabel id="qualifications-id">Trade Specialization</InputLabel>
                          <Select
                            labelId="qualifications-id"
                            variant="outlined"
                            name="trainingStaff3Specialization"
                            value={trainingStaff3Specialization}

                          >
                            {options4}
                          </Select>
                        </FormControl>

                        <Stack direction="row" gap={2}>
                            <Button
                                sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                onClick={() => {setValue(4)}}
                                variant="contained"
                            >Previous</Button>
                            <Button
                                sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                onClick={() => {setValue(6)}}
                                variant="contained"
                            >Next</Button>
                        </Stack>
                    </Box>
                </TabPanel>

                {/*HUMAN & FINANCIAL RESOURCE*/}
                <TabPanel value={value} index={6} sx={{ width: isMobile ? "100%" : "90%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: isMobile ? "" : "470px",
                        overflowY: isMobile ? "none" : "scroll",
                        pr: 2
                      }}
                    >
                        <Typography sx={{color:'#295C21', fontSize:12, mb:3}}>HUMAN & FINANCIAL RESOURCE</Typography>

                        <Box sx={{minWidth:isMobile ? "100%" : "960px"}}>
                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    i.{"\u00A0"} Are there NSQ certified Assessors at the Centre?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="nsqCertifiedAssessor-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="nsqCertifiedAssessor-id"
                                    variant="outlined"
                                    value={nsqCertifiedAssessors}
                                    name="nsqCertifiedAssessors"

                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    ii.{"\u00A0"} How many Assessors are available at the Centre?
                                </Typography>
                                <TextField
                                  variant="outlined"
                                  type="number"
                                  InputProps={{ inputProps: { min: 0 } }}
                                  label="Assessors Count"
                                  value={assessorsCount}
                                  name="assessorsCount"

                                  sx={{ width: isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}
                                />
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    iii.{"\u00A0"} Are there NSQ certified Verifiers at the Centre?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="nsqCertifiedVerifier-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="nsqCertifiedVerifier-id"
                                    variant="outlined"
                                    value={nsqCertifiedVerifiers}
                                    name="nsqCertifiedVerifiers"

                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    iv.{"\u00A0"} How many Verifiers are available at Centre?
                                </Typography>
                                <TextField
                                  variant="outlined"
                                  type="number"
                                  InputProps={{ inputProps: { min: 0 } }}
                                  label="Verifiers Count"
                                  value={verifiersCount}
                                  name="verifiersCount"

                                  sx={{ width: isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}
                                />
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    v.{"\u00A0"} Is the Centre Manager NSQ certified?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="nsqCertifiedCenterManager-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="nsqCertifiedCenterManager-id"
                                    variant="outlined"
                                    value={nsqCertifiedCenterManager}
                                    name="nsqCertifiedCenterManager"

                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    vi.{"\u00A0"} Is there a Liaison Officer with the Industry at the Centre?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="liaisonOfficerPresent-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="liaisonOfficerPresent-id"
                                    variant="outlined"
                                    value={liaisonOfficerPresent}
                                    name="liaisonOfficerPresent"

                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    vii.{"\u00A0"} Is there a Resource/Data Officer at the Centre?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="resourceOfficerPresent-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="resourceOfficerPresent-id"
                                    variant="outlined"
                                    value={resourceOfficerPresent}
                                    name="resourceOfficerPresent"

                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    viii.{"\u00A0"} Are there evidences/records of site-banks for apprenticeship training at the Centre?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="siteBankPresent-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="siteBankPresent-id"
                                    variant="outlined"
                                    value={siteBankPresent}
                                    name="siteBankPresent"

                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction="row" gap={2}>
                                <Button
                                    sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                    onClick={() => {setValue(5)}}
                                    variant="contained"
                                >Previous</Button>
                                <Button
                                    sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                    onClick={() => {setValue(7)}}
                                    variant="contained"
                                >Next</Button>
                            </Stack>
                        </Box>
                    </Box>
                </TabPanel>

                {/*PHYSICAL INFRASTRUCTURE*/}
                <TabPanel value={value} index={7} sx={{ width: isMobile ? "100%" : "90%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: isMobile ? "" : "470px",
                        overflowY: isMobile ? "none" : "scroll",
                        pr: 2
                      }}
                    >
                        <Typography sx={{color:'#295C21', fontSize:12, mb:3}}>PHYSICAL INFRASTRUCTURE</Typography>

                        <Box sx={{minWidth:isMobile ? "100%" : "960px"}}>
                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    ix.{"\u00A0"} Are the Classrooms/Training Rooms adequate in terms of space, lighting, ventilation & traffic?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="trainingRoomAdequate-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="trainingRoomAdequate-id"
                                    variant="outlined"
                                    value={trainingRoomAdequate}
                                    name="trainingRoomAdequate"

                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    x.{"\u00A0"} Is there a Data/Records room available and adequate?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="dataRoomAdequate-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="dataRoomAdequate-id"
                                    variant="outlined"
                                    value={dataRoomAdequate}
                                    name="dataRoomAdequate"

                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "Centre"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xi.{"\u00A0"} Are Workshop spaces adequate and fit for purpose?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="workshopAdequate-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="workshopAdequate-id"
                                    variant="outlined"
                                    value={workshopAdequate}
                                    name="workshopAdequate"

                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xii.{"\u00A0"} Are Practical training spaces available and suitable for each trade?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="practicalSpaceAdequate-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="practicalSpaceAdequate-id"
                                    variant="outlined"
                                    value={practicalSpaceAdequate}
                                    name="practicalSpaceAdequate"

                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xiii.{"\u00A0"} Are Technical laboratories AVAILABLE or HIRED?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="techLabAdequate-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="techLabAdequate-id"
                                    variant="outlined"
                                    value={techLabAdequate}
                                    name="techLabAdequate"

                                  >
                                      <MenuItem value={"AVAILABLE"}>AVAILABLE</MenuItem>
                                      <MenuItem value={"HIRED"}>HIRED</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xiv.{"\u00A0"} Are Staff rooms adequate and fit for purpose?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="staffRoomAdequate-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="staffRoomAdequate-id"
                                    variant="outlined"
                                    value={staffRoomAdequate}
                                    name="staffRoomAdequate"

                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xv.{"\u00A0"} Are Toilets available and functional?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="toiletAdequate-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="toiletAdequate-id"
                                    variant="outlined"
                                    value={toiletAdequate}
                                    name="toiletAdequate"

                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xvi.{"\u00A0"} Are First Aid/Firefighting facilities available and functional?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="firstAidFireAdequate-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="firstAidFireAdequate-id"
                                    variant="outlined"
                                    value={firstAidFireAdequate}
                                    name="firstAidFireAdequate"

                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xvii.{"\u00A0"} Are Hostels available and fit for purpose?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="hostelsAdequate-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="hostelsAdequate-id"
                                    variant="outlined"
                                    value={hostelsAdequate}
                                    name="hostelsAdequate"

                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xviii.{"\u00A0"} Are there Tools and Equipment for relevant trades at your Centre?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="tradeToolsAdequate-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="tradeToolsAdequate-id"
                                    variant="outlined"
                                    value={tradeToolsAdequate}
                                    name="tradeToolsAdequate"

                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xix.{"\u00A0"} Is Auditorium AVAILABLE or HIRED?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="auditoriumAvailable-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="auditoriumAvailable-id"
                                    variant="outlined"
                                    value={auditoriumAvailable}
                                    name="auditoriumAvailable"

                                  >
                                      <MenuItem value={"AVAILABLE"}>AVAILABLE</MenuItem>
                                      <MenuItem value={"HIRED"}>HIRED</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xx.{"\u00A0"} Are Library and Study rooms adequate?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="libraryAdequate-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="libraryAdequate-id"
                                    variant="outlined"
                                    value={libraryAdequate}
                                    name="libraryAdequate"

                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xxi.{"\u00A0"} Is Water and Power supply adequate?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="waterAndPowerAdequate-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="waterAndPowerAdequate-id"
                                    variant="outlined"
                                    value={waterAndPowerAdequate}
                                    name="waterAndPowerAdequate"

                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xxii.{"\u00A0"} Are Management Offices adequate and fit for purpose?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="mgtOfficesAdequate-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="mgtOfficesAdequate-id"
                                    variant="outlined"
                                    value={mgtOfficesAdequate}
                                    name="mgtOfficesAdequate"

                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction="row" gap={2}>
                                <Button
                                    sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                    onClick={() => {setValue(6)}}
                                    variant="contained"
                                >Previous</Button>
                                <Button
                                    sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                    onClick={() => {setValue(8)}}
                                    variant="contained"
                                >Next</Button>
                            </Stack>
                        </Box>
                    </Box>
                </TabPanel>

                {/*CODE OF PRACTICE*/}
                <TabPanel value={value} index={8} sx={{ width: isMobile ? "100%" : "90%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: isMobile ? "" : "470px",
                        overflowY: isMobile ? "none" : "scroll",
                        pr: 2
                      }}
                    >
                        <Typography sx={{color:'#295C21', fontSize:12, mb:3}}>CODE OF PRACTICE</Typography>

                        <Box sx={{minWidth:isMobile ? "100%" : "960px"}}>
                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xxiii.{"\u00A0"} Is NOS available at the Centre?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="nosAvailable-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="nosAvailable-id"
                                    variant="outlined"
                                    value={nosAvailable}
                                    name="nosAvailable"

                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xxiv.{"\u00A0"} Are there Training Manuals at your Centre?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="trainingManualAvailable-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="trainingManualAvailable-id"
                                    variant="outlined"
                                    value={trainingManualAvailable}
                                    name="trainingManualAvailable"

                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xxv.{"\u00A0"} Are the Manuals aligned to the NOS?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="nosAndManualAlignment-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="nosAndManualAlignment-id"
                                    variant="outlined"
                                    value={nosAndManualAlignment}
                                    name="nosAndManualAlignment"

                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xxvi.{"\u00A0"} Are there other Training materials that are relevant to the training needs?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="otherTrainingMaterialsAvailable-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="otherTrainingMaterialsAvailable-id"
                                    variant="outlined"
                                    value={otherTrainingMaterialsAvailable}
                                    name="otherTrainingMaterialsAvailable"

                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xxvii.{"\u00A0"} Is there an NSQ Code of Practice and Implementation Guideline at your Centre?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="nsqGuidelineAvailable-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="nsqGuidelineAvailable-id"
                                    variant="outlined"
                                    value={nsqGuidelineAvailable}
                                    name="nsqGuidelineAvailable"

                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xxviii.{"\u00A0"} Are the Trainers and Staff conversant/comply with these documents?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="staffCompliantWithStandards-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="staffCompliantWithStandards-id"
                                    variant="outlined"
                                    value={staffCompliantWithStandards}
                                    name="staffCompliantWithStandards"

                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xxix.{"\u00A0"} Are the Objectives of the training clearly defined?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="objectivesClear-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="objectivesClear-id"
                                    variant="outlined"
                                    value={objectivesClear}
                                    name="objectivesClear"

                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction={isMobile ? "column" : "row"} sx={{alignItems:isMobile ? "left" : "center"}}>
                                <Typography sx={{width:isMobile ? "100%" : "58%", mb:isMobile ? "10px" : "30px", mr:isMobile ? "0" : "2%"}}>
                                    xxx.{"\u00A0"} Is Class participation through questioning encouraged?
                                </Typography>
                                <FormControl sx={{width:isMobile ? "49%" : "20%", mb:"30px", mr:"2%"}}>
                                  <InputLabel id="classParticipation-id">Centre Response</InputLabel>
                                  <Select
                                    labelId="classParticipation-id"
                                    variant="outlined"
                                    value={classParticipation}
                                    name="classParticipation"

                                  >
                                      <MenuItem value={"YES"}>YES</MenuItem>
                                      <MenuItem value={"NO"}>NO</MenuItem>
                                  </Select>
                                </FormControl>
                            </Stack>

                            <Stack direction="row" gap={2}>
                                <Button
                                    sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                    onClick={() => {setValue(7)}}
                                    variant="contained"
                                >Previous</Button>
                                <Button
                                    sx={{mb:3, mt:2, width:"150px", color:"#fff"}}
                                    onClick={() => {setValue(9)}}
                                    variant="contained"
                                >Next</Button>
                            </Stack>
                        </Box>
                    </Box>
                </TabPanel>

                {/*SUPPORTING DOCUMENTS*/}
                <TabPanel value={value} index={9} sx={{ width: isMobile ? "100%" : "90%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: isMobile ? "" : "470px",
                        overflowY: isMobile ? "none" : "scroll",
                        pr: 2
                      }}
                    >
                        <Typography sx={{color:'#295C21', fontSize:12}}>SUPPORTING DOCUMENTS</Typography>

                        <Stack direction="column" gap={2} sx={{width:isMobile ? "100%" : "960px"}}>
                            <Stack direction="row" gap={1}>
                                {centerCredentials &&  <Typography sx={{width:"50%"}}>{centerCredentials.name}</Typography> }
                            </Stack>

                            <Stack direction="row" gap={1} sx={{mb:3}}>
                                <input
                                  accept=".zip, .pdf"
                                  style={{ display: 'none' }}
                                  id="credentials-file"
                                  type="file"
                                  name="centerCredentials"
                                  onChange={onSelectCredentials}
                                />
                                <label htmlFor="credentials-file" style={{width:"50%"}}>
                                    <Button
                                        variant="filled"
                                        component="span"
                                        startIcon={<CloudUploadIcon />}
                                        sx={{ backgroundColor: '#80B373', color: 'rgba(0, 0, 0, 0.87)', width:"100%", mb:7 }}
                                    >
                                      Upload Centre Credentials
                                    </Button>
                                </label>
                            </Stack>
                        </Stack>

                        <TextField
                          variant="outlined"
                          type="text"
                          label="Full Name of Applicant"
                          onChange={onApplicantsNameChanged}
                          value={applicantsName}
                          name="applicantsName"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"60px", mr: isMobile ? "0%" : "2%" }}
                        />
                        <TextField
                          variant="outlined"
                          type="text"
                          label="Position/Designation of Applicant"
                          onChange={onApplicantsPositionChanged}
                          value={applicantsPosition}
                          name="applicantsPosition"
                          sx={{ width: isMobile ? "100%" : "49%", mb:"60px" }}
                        />

                        <Collapse in={alert} sx={{ width: "100%" }}>
                          <Alert
                            variant="outlined"
                            sx={{ color: "#00ffff" }}
                            severity="success"
                            aria-live="assertive"
                            onClose={() => {setAlert(null)}}
                          >
                              {alert}
                          </Alert>
                        </Collapse>

                        <Box display="flex" mt="30px">
                          <LoadingButton
                              type="submit"
                              loading={isLoading}
                              disabled={!canSave}
                              variant="contained"
                              sx={{width:isMobile ? "100%" : "50%", color:"#fff"}}
                          >
                            Update Centre Application
                          </LoadingButton>
                        </Box>

                        <Stack direction="row" gap={2}>
                            <Button
                                sx={{mb:3, mt:5, width:"150px", color:"#fff"}}
                                onClick={() => {setValue(8)}}
                                variant="contained"
                            >Previous</Button>
                        </Stack>

                    </Box>
                </TabPanel>
              </form>
          </Box>
        </Box>
      </>
    )

    if(!centerapplication) {
      return <PulseLoader color={"#0b1c08"} className="center" />
    } else if(centerapplication.eqaCenterReportComplete) {
      return (
        <>
            <DashHeader />
            <Typography variant="h1" color="info" sx={{position:"absolute", top:"50%", left:"20%"}}>
                Unfortunately, you can no longer update this Application.
            </Typography>
            <Link to="/">
                <Typography variant="h3" sx={{position:"absolute", top:"58%", left:"20%"}}>
                    Go Back Home
                </Typography>
            </Link>
        </>
      )
    } else {
      return content
    }
}

export default EditCenterApplicationForm
