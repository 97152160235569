import * as React from 'react'
import { useEffect } from 'react'
import { styled, alpha } from '@mui/material/styles'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import { Box, Divider, Stack } from '@mui/material'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import InputBase from '@mui/material/InputBase'
import Badge from '@mui/material/Badge'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import SearchIcon from '@mui/icons-material/Search'
import AccountCircle from '@mui/icons-material/AccountCircle'
import MailIcon from '@mui/icons-material/Mail'
import NotificationsIcon from '@mui/icons-material/Notifications'
import MoreIcon from '@mui/icons-material/MoreVert'
import CloseIcon from '@mui/icons-material/Close'
import useAuth from "../hooks/useAuth"
import { useSendLogoutMutation } from '../features/auth/authApiSlice'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#fafafa",
  border: "1px solid #0b1c0890",
  color: "#0b1c08",
  '&:hover': {
    backgroundColor: "#2B682B",
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}))

export default function PrimarySearchAppBar() {
  const { uln } = useAuth()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [sendLogout, {
    isLoading,
    isSuccess,
    isError,
    error
  }] = useSendLogoutMutation()

  useEffect(() => {
    if (isSuccess) {
      navigate('/login', { replace: true }) // Navigate to the login page after successful logout
    }
  }, [isSuccess, navigate])

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)
  const [profileAnchorEl, setProfileAnchorEl] = React.useState(null)

  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)
  const isProfileMenuOpen = Boolean(profileAnchorEl)

  const handleProfileMenuOpen = (event) => {
    setProfileAnchorEl(event.currentTarget)
  }

  const handleProfileMenuClose = () => {
    setProfileAnchorEl(null)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    handleMobileMenuClose()
  }

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleLogout = async () => {
    await sendLogout()
    handleMenuClose()
  }

  const menuId = 'primary-search-account-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      sx={{ color: "#0b1c08" }}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>Settings</MenuItem>
      <Divider inset="full" />
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  )

  const profileMenuId = 'primary-profile-menu'
  const renderProfileMenu = (
    <Menu
      anchorEl={profileAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={profileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isProfileMenuOpen}
      onClose={handleProfileMenuClose}
      sx={{ color: "#0b1c08" }}
    >
      <MenuItem onClick={handleProfileMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleProfileMenuClose}>Settings</MenuItem>
      <Divider inset="full" />
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  )

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      PaperProps={{
        sx: {
          backgroundColor: "#fafafa",
          opacity: 0.8,
          width: '100%',
          height: '100%',
        }
      }}
      sx={{
        color: "#0b1c08"
      }}
    >
      <IconButton
        size="large"
        aria-label="close menu"
        onClick={handleCloseMenu}
        sx={{ position: 'absolute', top: 8, right: 8, color: "#0b1c08", zIndex: 1300 }} // Ensure the icon is visible
      >
        <CloseIcon />
      </IconButton>
      {uln ? [
        <MenuItem key="messages">
          <IconButton size="large" aria-label="show 4 new mails" color="inherit">
            {/*<Badge badgeContent={4} color="error">*/}
              <MailIcon />
            {/*</Badge>*/}
          </IconButton>
          <p>Messages</p>
        </MenuItem>,
        <MenuItem key="notifications">
          <IconButton
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
          >
            {/*<Badge badgeContent={17} color="error">*/}
              <NotificationsIcon />
            {/*</Badge>*/}
          </IconButton>
          <p>Notifications</p>
        </MenuItem>,
        <MenuItem key="profile" onClick={handleProfileMenuOpen}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls={profileMenuId}
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      ] : [
        <MenuItem key="home">
          <Link to="/">
            Home
          </Link>
        </MenuItem>,
        <MenuItem key="login">
          <Link to="/login">
            Login
          </Link>
        </MenuItem>,
        <MenuItem key="center-application">
          <Link to="/center-application">
            Centre Application
          </Link>
        </MenuItem>
      ]}
      {!uln && <MenuItem key="search" sx={{ width: '100%', mt: 2 }}>
        <Search sx={{ width: '100%' }}>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
          />
        </Search>
      </MenuItem>}
    </Menu>
  )

  return (
    <Box sx={{
      flexGrow: 1,
      "& .MuiAppBar-root": { backgroundColor: "#fafafa", position: "fixed" },
      "& .MuiStack-root a": { color: "#0b1c08", fontSize: 15 },
      "& .MuiStack-root a:hover": { color: "#2B682B" },
      "& .MuiIconButton-root": { color: "#0b1c08" }
    }}>
      <AppBar position="sticky">
        <Toolbar>
          <Toolbar>
            <Link to="/dash">
              <img src="./logo.png" alt="logo" width="90" height="60" />
            </Link>
          </Toolbar>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          {uln ? (
            <Box sx={{ display: { xs: 'none', md: 'flex' }, color: "#0b1c08" }}>
              <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                {/*<Badge badgeContent={4} color="error">*/}
                  <MailIcon />
                {/*</Badge>*/}
              </IconButton>
              <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
              >
                {/*<Badge badgeContent={17} color="error">*/}
                  <NotificationsIcon />
                {/*</Badge>*/}
              </IconButton>
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </Box>
          ) : (
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                <Link to="/">Home</Link>
                <Link to="/login">Login</Link>
                <Link to="/center-application">Centre Application</Link>
              </Stack>
            </Box>
          )}
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {renderProfileMenu}
    </Box>
  )
}
