import { useParams } from 'react-router-dom'
import EditCenterForm from './EditCenterForm'
import { useGetCentersQuery } from './centersApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'

const EditCenter = () => {
    useTitle('NIOB Skill CaMP: Edit Center')

    const { id } = useParams()

    const { center } = useGetCentersQuery("centersList", {
        selectFromResult: ({ data }) => ({
            center: data?.entities[id]
        }),
    })

    if (!center) return <PulseLoader color={"#0b1c08"} className="center" />

    const content = <EditCenterForm center={center} />

    return content
}

export default EditCenter
