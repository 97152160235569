import { Typography, Box, useTheme } from "@mui/material"
import { tokens } from "../theme"

const Header = ({ title, subtitle }) => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  return (
    <Box sx={{ m: "65px 0 0 0", p: "20px 3%", backgroundColor: "#0B1C08", width: "100%" }}>
      <Typography
        variant="h2"
        color={colors.primary[800]}
        fontWeight="bold"
        sx={{ m: "0 0 5px 0", textShadow: "1px 1px #000000" }}
      >
        {title}
      </Typography>
      <Typography variant="h5" color={colors.greenAccent[100]}>
        {subtitle}
      </Typography>
    </Box>
  )
}

export default Header
