import {
    createSelector,
    createEntityAdapter
} from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const centerapplicationAdapter = createEntityAdapter({})

const initialState = centerapplicationAdapter.getInitialState()

export const centerapplicationApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCenterApplications: builder.query({
            query: () => ({
                url: '/center-application', // Ensure this matches your server endpoint
                validateStatus: (response, result) => response.status === 200 && !result.isError,
            }),
            transformResponse: responseData => {
                const loadedCenterApplications = responseData.centerapplicationsWithQualifications.map(centerapplication => ({
                    ...centerapplication,
                    id: centerapplication._id // Ensure id assignment is correct
                }))
                return centerapplicationAdapter.setAll(initialState, loadedCenterApplications)
            },
            providesTags: result => (result?.ids ? [{ type: 'CenterApplication', id: 'LIST' }, ...result.ids.map(id => ({ type: 'CenterApplication', id }))] : [{ type: 'CenterApplication', id: 'LIST' }])
        }),
        addNewCenterApplication: builder.mutation({
            query: initialCenterApplicationData => ({
                url: '/center-application',
                method: 'POST',
                body: initialCenterApplicationData
            }),
            invalidatesTags: [{ type: 'CenterApplication', id: 'LIST' }]
        }),
        updateCenterApplication: builder.mutation({
            query: initialCenterApplicationData => ({
                url: '/center-application',
                method: 'PATCH',
                body: initialCenterApplicationData
            }),
            invalidatesTags: (_, __, arg) => [{ type: 'CenterApplication', id: arg.id }]
        }),
        deleteCenterApplication: builder.mutation({
            query: ({ id }) => ({
                url: `/center-application`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (_, __, arg) => [{ type: 'CenterApplication', id: arg.id }]
        }),
    }),
})

export const {
    useGetCenterApplicationsQuery,
    useAddNewCenterApplicationMutation,
    useUpdateCenterApplicationMutation,
    useDeleteCenterApplicationMutation,
} = centerapplicationApiSlice

export const selectCenterApplicationResult = centerapplicationApiSlice.endpoints.getCenterApplications.select()

const selectCenterApplicationData = createSelector(
    selectCenterApplicationResult,
    centerapplicationResult => centerapplicationResult.data // Ensure this selector is correctly extracting data
)

export const {
    selectAll: selectAllCenterApplication,
    selectById: selectCenterApplicationById,
    selectIds: selectCenterApplicationIds
} = centerapplicationAdapter.getSelectors(state => selectCenterApplicationData(state) ?? initialState)
