import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetAssessorTraineeAssignmentsQuery } from './assessortraineeassignmentApiSlice'
import { useGetUsersQuery } from '../users/usersApiSlice'
import AssessorTraineeAssignment from './AssessorTraineeAssignment'
import useAuth from "../../hooks/useAuth"
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from '@mui/material/styles'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, TableSortLabel, Toolbar,
          Typography, Checkbox, IconButton, Tooltip, FormControlLabel, Switch, Box, Stack, Button, List, ListItem, ListItemText,
          ListSubheader, ListItemAvatar, Avatar, Divider, Rating } from '@mui/material'
import { tableCellClasses } from '@mui/material/TableCell'
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import AddIcon from '@mui/icons-material/Add'
import HomeIcon from '@mui/icons-material/Home'
import { Link } from "react-router-dom"
import useMediaQuery from "@mui/material/useMediaQuery"
import Header from "../../components/Header"

import * as React from 'react'
import PropTypes from 'prop-types'
import { alpha } from '@mui/material/styles'
import DeleteIcon from '@mui/icons-material/Delete'
import FilterListIcon from '@mui/icons-material/FilterList'
import { visuallyHidden } from '@mui/utils'


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#030702',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#0b1c08',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#091506',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
const stableSort = (array, comparator) => {
  // Convert entities to an array of [id, entity] pairs
  const stabilizedThis = Object.entries(array).map(([id, entity]) => [entity, id])

  // Sort the array using the comparator function
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    // If entities are equal, sort by their original index (ID)
    return parseInt(a[1]) - parseInt(b[1])
  })

  // Return the sorted entities
  return stabilizedThis.map((pair) => pair[0])
}


const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'assessor',
    numeric: false,
    disablePadding: true,
    label: 'ASSESSOR',
  },
  {
    id: 'trainee',
    numeric: false,
    disablePadding: false,
    label: 'TRAINEE',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'ASSIGNMENT DATE',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: true,
    label: 'ACTIONS',
  },
]

const assessorHeadCells = [
  {
    id: 'trainee',
    numeric: false,
    disablePadding: true,
    label: 'TRAINEE',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'DATE ASSIGNED',
  },
]

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  if (rowCount > 0) {
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all assessor-trainee assignments',
              }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'center' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

function EnhancedTableToolbar(props, isCenter) {
  const { numSelected } = props

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
        "& .MuiStack-root .MuiButton-root": {color:'#fff', border:'1px solid #fff'},
        "& .MuiStack-root .MuiButton-root:hover": {backgroundColor:'#7fd47010'}
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Stack direction="row" gap={1} sx={{ flex: '1 1 100%' }}>
          <Link to="/dash">
            <Button variant="outlined" color="info" size="small" startIcon={<HomeIcon />} sx={{ mb: 0 }}>
              Home
            </Button>
          </Link>
          <Link to="/dash/assessor-trainee-assignments/new">
            <Button variant="outlined" color="info" size="small" startIcon={<AddIcon />} sx={{ mb: 0 }}>
              Assign Assessor to Trainee
            </Button>
          </Link>
        </Stack>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
}

function AssessorEnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  if (rowCount > 0) {
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all centerapplications',
              }}
            />
          </TableCell>
          {assessorHeadCells.map((assessorHeadCell) => (
            <TableCell
              key={assessorHeadCell.id}
              align={assessorHeadCell.numeric ? 'center' : 'left'}
              padding={assessorHeadCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === assessorHeadCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === assessorHeadCell.id}
                direction={orderBy === assessorHeadCell.id ? order : 'asc'}
                onClick={createSortHandler(assessorHeadCell.id)}
              >
                {assessorHeadCell.label}
                {orderBy === assessorHeadCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }
}

AssessorEnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const AssessorTraineeAssignmentsList = () => {
    useTitle('NIOB Skill CaMP: All AssessorTraineeAssignments')
    const isMobile = useMediaQuery("(max-width:600px)")

    const { isSuperAdmin } = useAuth()

    const navigate = useNavigate()

    const {
        data: assessorTraineeAssignments,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetAssessorTraineeAssignmentsQuery('assessorTraineeAssignmentsList', {
        pollingInterval: 30000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('calories')
    const [selected, setSelected] = React.useState([])
    const [page, setPage] = React.useState(0)
    const [dense, setDense] = React.useState(true)
    const [rowsPerPage, setRowsPerPage] = React.useState(5)

    const handleEdit = (event, id) => navigate(`/dash/assessor-trainee-assignments/${id}`)

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(property)
    }

    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelected = Object.values(assessorTraineeAssignments.entities).map((n) => n.id)
        setSelected(newSelected)
        return
      }
      setSelected([])
    }

    const handleClick = (event, id) => {
      const selectedIndex = selected.indexOf(id)
      let newSelected = []

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1))
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        )
      }
      setSelected(newSelected)
    }

    const handleChangePage = (event, newPage) => {
      setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
    }

    const handleChangeDense = (event) => {
      setDense(event.target.checked)
    }

    const isSelected = (id) => selected.indexOf(id) !== -1

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - assessorTraineeAssignments.length) : 0

    const visibleRows = useMemo(() => {
        //if (!assessorTraineeAssignments) return [] // Return an empty array if assessorTraineeAssignments is not yet populated
        const assessorTraineeAssignmentsArray = assessorTraineeAssignments?.entities ? Object.values(assessorTraineeAssignments.entities) : []

        return stableSort(assessorTraineeAssignmentsArray, getComparator(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        );
      }, [assessorTraineeAssignments, order, orderBy, page, rowsPerPage])

    let content

    if (isLoading) content = <PulseLoader color={"#0b1c08"} className="center" />

    if (isError) {
        content = <p className='errmsg'>{error?.data?.message}</p>
    }

    if (isSuccess) {

        content = (
          <>
          <Header title="ASSESSOR-TRAINEE ASSIGNMENT LIST" subtitle="Record of All Assesser-to-Trainee Assignments" />
          <Box sx={{ width: '100%', "& .MuiPaper-root": {backgroundColor: '#030702'}, p:"2%", mb: "150px", mt:isMobile ? "10%" : "" }}>

            <Paper sx={{ width: '100%', mb: 2 }}>
              <EnhancedTableToolbar numSelected={selected.length} />
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={Object.entries(assessorTraineeAssignments.entities).length}
                  />
                  <TableBody>
                    {visibleRows.map((assessorTraineeAssignment) => {
                      const isItemSelected = isSelected(assessorTraineeAssignment.id);
                      const labelId = `enhanced-table-checkbox-${assessorTraineeAssignment.id}`;

                      return (
                        <StyledTableRow
                          hover
                          onClick={(event) => handleClick(event, assessorTraineeAssignment.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={assessorTraineeAssignment.id}
                          selected={isItemSelected}
                          sx={{ cursor: 'pointer' }}
                        >
                          <StyledTableCell padding="checkbox">
                            <Checkbox
                              sx={{color:"#fff"}}
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {assessorTraineeAssignment.id}
                          </StyledTableCell>
                          <StyledTableCell align="left">{assessorTraineeAssignment.assessor}</StyledTableCell>
                          <StyledTableCell align="left">{assessorTraineeAssignment.trainee}</StyledTableCell>
                          <StyledTableCell align="left">{assessorTraineeAssignment.createdAt.split('T')[0]}</StyledTableCell>
                          <StyledTableCell align="center">
                              <button
                                  className="icon-button table__button"
                                  onClick={(event) => handleEdit(event, assessorTraineeAssignment.id)}
                              >
                                  <FontAwesomeIcon icon={faPenToSquare} />
                              </button>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <StyledTableRow
                        style={{
                          height: (dense ? 13 : 23) * emptyRows,
                        }}
                      >
                        <StyledTableCell colSpan={6} />
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={assessorTraineeAssignments?.ids.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} color={'success'} onChange={handleChangeDense} />}
              label="Dense padding"
            />
          </Box>
          </>
        )
    }

    return content
}


const AssessorTraineeAssignmentsListOnDash = () => {
    useTitle('NIOB Skill CaMP: All AssessorTraineeAssignments')
    const isMobile = useMediaQuery("(max-width:600px)")

    const { isSuperAdmin } = useAuth()

    const navigate = useNavigate()

    const {
        data: assessorTraineeAssignments,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetAssessorTraineeAssignmentsQuery('assessorTraineeAssignmentsList', {
        pollingInterval: 30000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('calories')
    const [selected, setSelected] = React.useState([])
    const [page, setPage] = React.useState(0)
    const [dense, setDense] = React.useState(true)
    const [rowsPerPage, setRowsPerPage] = React.useState(5)

    const handleEdit = (event, id) => navigate(`/dash/assessor-trainee-assignments/${id}`)

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(property)
    }

    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelected = Object.values(assessorTraineeAssignments.entities).map((n) => n.id)
        setSelected(newSelected)
        return
      }
      setSelected([])
    }

    const handleClick = (event, id) => {
      const selectedIndex = selected.indexOf(id)
      let newSelected = []

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1))
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        )
      }
      setSelected(newSelected)
    }

    const handleChangePage = (event, newPage) => {
      setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
    }

    const handleChangeDense = (event) => {
      setDense(event.target.checked)
    }

    const isSelected = (id) => selected.indexOf(id) !== -1

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - assessorTraineeAssignments.length) : 0

    const visibleRows = useMemo(() => {
        //if (!assessorTraineeAssignments) return [] // Return an empty array if assessorTraineeAssignments is not yet populated
        const assessorTraineeAssignmentsArray = assessorTraineeAssignments?.entities ? Object.values(assessorTraineeAssignments.entities) : []

        return stableSort(assessorTraineeAssignmentsArray, getComparator(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        );
      }, [assessorTraineeAssignments, order, orderBy, page, rowsPerPage])

    let content

    if (isLoading) content = <PulseLoader color={"#0b1c08"} className="center" />

    if (isError) {
        content = <p className='errmsg'>{error?.data?.message}</p>
    }

    if (isSuccess) {

        content = (
          <>
          <Box sx={{ width: '100%', "& .MuiPaper-root": {backgroundColor: '#030702'}, p:"2%", mb: "150px", mt:isMobile ? "10%" : "" }}>

            <Paper sx={{ width: '100%', mb: 2 }}>
              <EnhancedTableToolbar numSelected={selected.length} />
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={Object.entries(assessorTraineeAssignments.entities).length}
                  />
                  <TableBody>
                    {visibleRows.map((assessorTraineeAssignment) => {
                      const isItemSelected = isSelected(assessorTraineeAssignment.id);
                      const labelId = `enhanced-table-checkbox-${assessorTraineeAssignment.id}`;

                      return (
                        <StyledTableRow
                          hover
                          onClick={(event) => handleClick(event, assessorTraineeAssignment.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={assessorTraineeAssignment.id}
                          selected={isItemSelected}
                          sx={{ cursor: 'pointer' }}
                        >
                          <StyledTableCell padding="checkbox">
                            <Checkbox
                              sx={{color:"#fff"}}
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {assessorTraineeAssignment.id}
                          </StyledTableCell>
                          <StyledTableCell align="left">{assessorTraineeAssignment.assessor}</StyledTableCell>
                          <StyledTableCell align="left">{assessorTraineeAssignment.trainee}</StyledTableCell>
                          <StyledTableCell align="left">{assessorTraineeAssignment.createdAt.split('T')[0]}</StyledTableCell>
                          <StyledTableCell align="center">
                              <button
                                  className="icon-button table__button"
                                  onClick={(event) => handleEdit(event, assessorTraineeAssignment.id)}
                              >
                                  <FontAwesomeIcon icon={faPenToSquare} />
                              </button>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <StyledTableRow
                        style={{
                          height: (dense ? 13 : 23) * emptyRows,
                        }}
                      >
                        <StyledTableCell colSpan={6} />
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={assessorTraineeAssignments?.ids.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} color={'success'} onChange={handleChangeDense} />}
              label="Dense padding"
            />
          </Box>
          </>
        )
    }

    return content
}


const MyAssessorTraineeAssignmentsListOnDash = () => {
    const isMobile = useMediaQuery("(max-width:600px)")

    const { isAssessor, isTrainee } = useAuth()

    const navigate = useNavigate()
    const { id, firstName, lastName } = useAuth()

    const { data: usersData } = useGetUsersQuery()

    const {
        data: assessorTraineeAssignments,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetAssessorTraineeAssignmentsQuery('assessorTraineeAssignmentsList', {
        pollingInterval: 30000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('calories')
    const [selected, setSelected] = React.useState([])
    const [page, setPage] = React.useState(0)
    const [dense, setDense] = React.useState(true)
    const [rowsPerPage, setRowsPerPage] = React.useState(5)

    //const handleQualityCheck = (event, id) => navigate(`/dash/my-center-assignments/${id}`)

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(property)
    }

    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelected = Object.values(assessorTraineeAssignments.entities).map((n) => n.id)
        setSelected(newSelected)
        return
      }
      setSelected([])
    }

    const handleClick = (event, id) => {
      const selectedIndex = selected.indexOf(id)
      let newSelected = []

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1))
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        )
      }
      setSelected(newSelected)
    }

    const handleChangePage = (event, newPage) => {
      setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
    }

    const handleChangeDense = (event) => {
      setDense(event.target.checked)
    }

    const isSelected = (id) => selected.indexOf(id) !== -1

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - assessorTraineeAssignments.length) : 0

    const visibleRows = useMemo(() => {
        //if (!assessorTraineeAssignments) return [] // Return an empty array if assessorTraineeAssignments is not yet populated
        const assessorTraineeAssignmentsArray = assessorTraineeAssignments?.entities
        ? Object.values(assessorTraineeAssignments.entities)
            .filter(item => item.assessor === firstName.concat(" ",lastName))
        : []

        return stableSort(assessorTraineeAssignmentsArray, getComparator(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        );
      }, [assessorTraineeAssignments, order, orderBy, page, rowsPerPage])

    let content

    if (isLoading) content = <PulseLoader color={"#0b1c08"} className="center" />

    if (isError) {
        content = <p className='errmsg'>{error?.data?.message}</p>
    }

    if (isSuccess && isAssessor) {

        content = (
          <>
          <Box sx={{ width: '100%',"& .MuiPaper-root": {backgroundColor: '#030702'} }}>

            <Paper sx={{ width: '100%', mb: 2 }}>
              <EnhancedTableToolbar numSelected={selected.length} />
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                >
                  <AssessorEnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={Object.entries(assessorTraineeAssignments.entities).length}
                  />
                  <TableBody>
                    {visibleRows.map((assessorTraineeAssignment) => {
                      const isItemSelected = isSelected(assessorTraineeAssignment.id);
                      const labelId = `enhanced-table-checkbox-${assessorTraineeAssignment.id}`;

                      return (
                        <StyledTableRow
                          hover
                          onClick={(event) => handleClick(event, assessorTraineeAssignment.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={assessorTraineeAssignment.id}
                          selected={isItemSelected}
                          sx={{ cursor: 'pointer' }}
                        >
                          <StyledTableCell padding="checkbox">
                            <Checkbox
                              sx={{color:"#fff"}}
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell align="left" sx={{pl:0}}>{assessorTraineeAssignment.trainee}</StyledTableCell>
                          <StyledTableCell align="left">{assessorTraineeAssignment.createdAt.split('T')[0]}</StyledTableCell>
                        </StyledTableRow>
                      )
                    })}
                    {emptyRows > 0 && (
                      <StyledTableRow
                        style={{
                          height: (dense ? 13 : 23) * emptyRows,
                        }}
                      >
                        <StyledTableCell colSpan={6} />
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={Object.values(assessorTraineeAssignments.entities).filter(item => item.assessor === firstName.concat(" ",lastName)).length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} color={'success'} onChange={handleChangeDense} />}
              label="Dense padding"
            />
          </Box>
          </>
        )
    } else if (isSuccess && isTrainee) {
        const currentUserFullName = firstName.concat(" ", lastName) // Trainee's full name

        const assessorNames = Object.values(assessorTraineeAssignments.entities)
          .filter(assignment => assignment.trainee === currentUserFullName)
          .map(assignment => assignment.assessor)

        const assessorDetails = usersData?.entities
          ? Object.values(usersData.entities).filter(user =>
              assessorNames.includes(user.firstName.concat(" ", user.lastName)) &&
              user.roles.includes("ASSESSOR")
            )
          : []

        const ivDetails = []

        content = (
          <Box sx={{ width: '100%' }}>
              <List
                sx={{ width: '100%', bgcolor: '#0b1c08' }}
                aria-labelledby="assigned-qa-team"
                subheader={
                  <ListSubheader component="div" id="assigned-qa-team" sx={{pl:2, pt:2}}>
                    ASSIGNED QUALITY ASSURANCE TEAM
                  </ListSubheader>
                }
              >
                {assessorDetails.length > 0 ? (
                  assessorDetails.map((assessor, index) => (
                    <>
                    <ListItem key={index} alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt={assessor?.firstName} src="/static/images/avatar/1.jpg" />
                      </ListItemAvatar>
                      <ListItemText
                        sx={{ color: "#fff" }}
                        primary={assessor?.firstName.concat(" ", assessor?.lastName)}
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: 'inline' }}
                              component="span"
                              variant="body2"
                              color="#80B373"
                            >
                              ASSESSOR
                            </Typography>
                            {` — ${assessor?.uln}${"\u00A0"}  /  ${"\u00A0"}`}
                            {assessor?.rating ? (
                              <>
                                <Rating name="read-only" value={assessor.rating} size="small" readOnly />
                                {`${"\u00A0"}  /  ${"\u00A0"}`}
                              </>
                            ) : (
                              ''
                            )}
                            link to assessor profile
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    </>
                  ))
                ) : (
                  "You have not been assigned to any Assessor"
                )}

                {ivDetails.length > 0 ? (
                  ivDetails.map((iv, index) => (
                    <>
                    <ListItem key={index} alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt={iv?.firstName} src="/static/images/avatar/1.jpg" />
                      </ListItemAvatar>
                      <ListItemText
                        sx={{ color: "#fff" }}
                        primary={iv?.firstName.concat(" ", iv?.lastName)}
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: 'inline' }}
                              component="span"
                              variant="body2"
                              color="#80B373"
                            >
                              INTERNAL VERIFIER
                            </Typography>
                            {` — ${iv?.uln}${"\u00A0"}  /  ${"\u00A0"}`}
                            {iv?.rating ? (
                              <>
                                <Rating name="read-only" value={iv.rating} size="small" readOnly />
                                {`${"\u00A0"}  /  ${"\u00A0"}`}
                              </>
                            ) : (
                              ''
                            )}
                            link to internal verifier profile
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    </>
                  ))
                ) : (
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      
                    </ListItemAvatar>
                    <ListItemText
                      sx={{ color: "#fff" }}
                      primary="You have not been assigned to any Internal Verifier"/>
                  </ListItem>
                )}
              </List>
          </Box>
        )
    }

    return content
}


export {
  AssessorTraineeAssignmentsList,
  AssessorTraineeAssignmentsListOnDash,
  MyAssessorTraineeAssignmentsListOnDash
}
