import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useAuth from "../../hooks/useAuth"
import { useGetUsersQuery } from '../users/usersApiSlice'
import { useGetCenterApplicationsQuery } from '../centerapplication/centerapplicationApiSlice'
import { useGetCentersQuery } from "../centers/centersApiSlice"
import { memo } from 'react'
import PulseLoader from 'react-spinners/PulseLoader'

import * as React from 'react'
import { Paper, Box, useTheme, TextField, Typography, Stack, Button, FormControl, InputLabel, Select,
  MenuItem, Grid, Card, CardHeader, CardActions, CardContent, Rating, Tabs, Tab, Divider, IconButton,
  Avatar, List, ListItem, ListItemText, ListSubheader, ListItemAvatar, Badge, CardMedia} from "@mui/material"
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera"
import { Link, useNavigate } from "react-router-dom"
import useTitle from '../../hooks/useTitle'
import { tokens } from "../../theme"
import useMediaQuery from "@mui/material/useMediaQuery"
import Header from "../../components/Header"
import EventCalendar from "../../components/Calendar"
import { MyProfileAvatarBoxDash } from "../../components/MyProfileAvatarBox"
import { BasicInfoBoxDash } from "../../components/BasicInfoBox"
import { MyCenterApplicationsList } from "../../features/centerapplication/CenterApplicationsList"
import { AssessorTraineeAssignmentsListOnDash, MyAssessorTraineeAssignmentsListOnDash } from "../../features/assessortraineeassignment/AssessorTraineeAssignmentsList"
import { MyUsersList } from "../../features/users/UsersList"
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import HomeIcon from '@mui/icons-material/Home'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import FingerprintIcon from '@mui/icons-material/Fingerprint'
import HomeWorkIcon from '@mui/icons-material/HomeWork'
import HandymanIcon from '@mui/icons-material/Handyman'
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark'
import WidgetsIcon from '@mui/icons-material/Widgets'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import SchoolIcon from '@mui/icons-material/School'
import EngineeringIcon from '@mui/icons-material/Engineering'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import HistoryEduIcon from '@mui/icons-material/HistoryEdu'
import PeopleIcon from '@mui/icons-material/People'
import ComputerIcon from '@mui/icons-material/Computer'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles'
import PropTypes from 'prop-types'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  display: "flex",
  alignItems: "center",
  justifyContent: "center", // Center horizontally
  backgroundColor: "#80B37390",
  color: "#0b1c08",
  fontSize: "15px",
  height: 80,
  transition: "box-shadow 0.3s", // Add transition for smoother elevation change
  "&:hover": {
    boxShadow: theme.shadows[8], // Increase elevation on hover
  },
}))

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#80B373' : '#80B373',
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props
  const isMobile = useMediaQuery("(max-width:600px)")

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pl: isMobile ? 0 : "290px" }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}



const MyDashboard = () => {
    useTitle('NIOB Skill CaMP: User Dashboard')
    const isMobile = useMediaQuery("(max-width:600px)")
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const { isSuperAdmin, isCenter, isCenterManager, isTrainee, isEV, isIV, isAssessor, id, firstName, lastName } = useAuth()

    const { user } = useGetUsersQuery("usersList", {
        selectFromResult: ({ data }) => ({
            user: data?.entities[id]
        }),
    })

    //Get all Users
    const { data: usersData } = useGetUsersQuery()
    let usersCount = 0

    if (usersData) {
      usersCount = usersData?.ids.length
    }

    //Get Centres
    const { data: centersData } = useGetCentersQuery()
    let centresCount = 0

    if (centersData) {
      centresCount = centersData?.ids.length
    }

    //Get Centre Applications
    const { data: centerApplicationsData } = useGetCenterApplicationsQuery()
    let centreApplicationsCount = 0

    if (centerApplicationsData) {
      centreApplicationsCount = centerApplicationsData?.ids.length
    }

    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
      setValue(newValue)
    }


    const navigate = useNavigate()

    if (user && isTrainee) {

        const content = (
            <>
            <Header title="DASHBOARD" subtitle={"Welcome "+user.firstName.concat(" ",user.lastName)} sx={{m: "65px 0 0 0"}} />
            <Box
                sx={{
                  "& .MuiStack-root .MuiButton-outlined": {color:'#fff', border:'1px solid #fff'},
                  "& .MuiStack-root .MuiButton-outlined:hover": {backgroundColor:'#7fd47010'},
                  p: "4% 4% 10% 4%",
                  bgcolor: '#F1F3F1'
                }}
            >
                <Box
                  sx={{
                    flexGrow: 1,
                    bgcolor: '#F1F3F1',
                    display: isMobile ? 'block' : 'flex',
                    "& .MuiTab-root.Mui-selected": {
                        color: '#0b1c08',
                        fontWeight: 700
                    },
                    "& .MuiTab-root": {
                        alignItems: 'flex-start',
                        color: '#295C21',
                        borderBottom: '1px solid #0b1c081a'
                    },
                    "& .MuiTabs-indicator": {
                        backgroundColor: '#49a139'
                    },
                    "& .MuiFormHelperText-root": {
                        color: '#49a139'
                    },
                    "& .MuiTab-wrapper": {
                        flexDirection: "row"
                    }
                  }}
                >
                    <Tabs
                      orientation={isMobile ? "horizontal" : "vertical"}
                      variant="scrollable"
                      allowScrollButtonsMobile
                      value={value}
                      onChange={handleChange}
                      aria-label="Vertical tabs"
                      sx={{
                          minWidth: isMobile ? '100%' : '280px',
                          color:"#49a139",
                          position:isMobile ? "" : "fixed",
                          borderBottomWidth: "150px"
                      }}
                    >
                      <Tab label={<div style={{display:"flex"}}><FingerprintIcon sx={{mr:"5px", mt:"-5px"}}/> BIODATA</div>} {...a11yProps(0)} />
                      <Tab label={<div style={{display:"flex"}}><HomeWorkIcon sx={{mr:"5px", mt:"-5px"}}/> CENTER INFORMATION</div>} {...a11yProps(1)} />
                      <Tab label={<div style={{display:"flex"}}><HandymanIcon sx={{mr:"5px", mt:"-5px"}}/> QUALIFICATIONS</div>} {...a11yProps(2)} />
                      <Tab label={<div style={{display:"flex"}}><CollectionsBookmarkIcon sx={{mr:"5px", mt:"-5px"}}/> E-PORTFOLIO</div>} {...a11yProps(3)} />
                      <Tab label={<div style={{display:"flex"}}><WidgetsIcon sx={{mr:"5px", mt:"-5px"}}/> MENU</div>} {...a11yProps(4)} />
                    </Tabs>

                    {/**Actions OR Functions**/}
                    <TabPanel value={value} index={4} sx={{ width: isMobile ? "100%" : "90%" }}>
                        <Box
                          sx={{
                            width: isMobile ? "100%" : "1060px",
                            bgcolor: "#E6EAE5",
                            p: "3% 3% 10% 3%",
                            boxShadow: "0 10px 6px -3px #777",
                            display: 'grid',
                            gridTemplateColumns: { md: '1fr 1fr 1fr' },
                            gap: 2,
                          }}
                        >
                            {<Link to="/dash/settings/updatepassword">
                                <Item elevation={1}>
                                  Update Password
                                </Item>
                            </Link>}
                        </Box>
                    </TabPanel>

                    {/**BIODATA**/}
                    <TabPanel value={value} index={0} sx={{ width: isMobile ? "100%" : "90%" }}>
                        <Box sx={{
                              width: isMobile ? "100%" : "1060px",
                              bgcolor: "#E6EAE5",
                              p: "3% 3% 10% 3%",
                              boxShadow: "0 10px 6px -3px #777"
                            }}
                        >
                            <Stack direction={isMobile ? "column" : "row"} gap={5}>
                                <MyProfileAvatarBoxDash
                                  fullName={user.firstName.concat(" ",user.lastName)}
                                  rating={user.rating}
                                  roles={user.roles.join(" / ")}
                                  id={user.id}
                                  sx={{width: "30%"}}
                                />
                                {/* CARD HEADER END */}

                                <BasicInfoBoxDash
                                  uln={user.uln}
                                  email={user.email}
                                  phone={user.phone}
                                  centers={user.centers.join(", ")}
                                  qualifications={user.qualifications.join(", ")}
                                  sx={{width: "70%"}}
                                />
                            </Stack>
                        </Box>
                    </TabPanel>

                    {/**CENTER INFO**/}
                    <TabPanel value={value} index={1} sx={{ width: isMobile ? "100%" : "90%" }}>
                        <Box sx={{
                          width: isMobile ? "100%" : "1060px",
                          bgcolor: "#E6EAE5",
                          p: "3% 3% 10% 3%",
                          boxShadow: "0 10px 6px -3px #777",
                          "& .MuiStack-root .MuiListSubheader-root": {
                              backgroundColor: colors.primary[400],
                              color: colors.greenAccent[400],
                              position: 'static',
                              fontSize: 15
                          }
                        }}>
                            <Stack direction="column" gap={3}>
                                <MyAssessorTraineeAssignmentsListOnDash/>
                            </Stack>
                        </Box>
                    </TabPanel>

                    {/**QUALIFICATIONS**/}
                    <TabPanel value={value} index={2} sx={{ width: isMobile ? "100%" : "90%" }}>
                        <Box sx={{
                            width: isMobile ? "100%" : "1060px",
                            bgcolor: "#E6EAE5",
                            p: "3% 3% 10% 3%",
                            boxShadow: "0 10px 6px -3px #777",
                            "& .MuiStack-root .MuiListSubheader-root": {
                                backgroundColor: colors.primary[400],
                                color: colors.greenAccent[400],
                                position: 'static',
                                fontSize: 15
                            }
                        }}>
                            <Stack direction="column" gap={3}>
                                <Grid sx={{ bgcolor: colors.primary[400], width: isMobile ? "100%" : "50%", p: 2 }} >
                                    <List
                                        sx={{ width: '100%' }}
                                        aria-labelledby="qualifications"
                                        subheader={
                                            <ListSubheader component="div" id="qualifications" sx={{pl:0}}>
                                                QUALIFICATIONS
                                            </ListSubheader>
                                        }
                                    >
                                        {user.qualifications.map((qualification, key) => (
                                            <Box key={key}>
                                                <Typography sx={{ mb:1, mt:1, color: "#fff" }}>
                                                    {qualification}
                                                </Typography>
                                                <Stack direction="row" sx={{ mb:2 }}>
                                                    <BorderLinearProgress variant="determinate" value={100} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={100} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={100} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={80} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={0} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={0} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={0} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={0} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={0} sx={{width:"11%"}} />
                                                </Stack>
                                            </Box>
                                        ))}
                                    </List>
                                </Grid>
                            </Stack>
                        </Box>
                    </TabPanel>

                    {/**E-PORTFOLIO**/}
                    <TabPanel value={value} index={3} sx={{ width: isMobile ? "100%" : "90%" }}>
                        <Box sx={{
                          width: isMobile ? "100%" : "1060px",
                          bgcolor: "#E6EAE5",
                          p: "3% 3% 10% 3%",
                          boxShadow: "0 10px 6px -3px #777",
                          "& .MuiStack-root .MuiListSubheader-root": {
                              backgroundColor: colors.primary[400],
                              color: colors.greenAccent[400],
                              position: 'static',
                              fontSize: 15
                          }
                        }}>
                            <Grid sx={{ bgcolor: colors.primary[400], width: "60%", p: 2 }} >
                                <EventCalendar />
                            </Grid>
                        </Box>
                    </TabPanel>
                </Box>
            </Box>
            </>
        )

        return content

    } else if (user && isSuperAdmin) {
        const handleEdit = () => navigate(`/dash/users/${id}`)

        const date = new Date()
        const today = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'long'}).format(date)

        const content = (
            <Box className="welcome">
                <Grid container spacing={2} sx={{mb:isMobile ? "" : "300px !important"}}>
                    <Grid item xs={12} sx={{mt:"80px", height: "80vh"}}>
                        <Box sx={{pl:"2%"}}>
                            <p>{today}</p>

                            <h1 style={{fontSize: "2rem", mb: 0}}>Welcome {firstName.toUpperCase()+" "+lastName.toUpperCase()}!</h1>
                        </Box>

                        <Box
                          sx={{
                            p: "0 2% 4% 2%",
                            borderRadius: 2,
                            display: 'grid',
                            gridTemplateColumns: { md: '1fr' },
                            gap: 2
                          }}
                        >
                            <Card sx={{ p: 2, borderRadius: '12px', boxShadow: 1, border: '1px solid #ddd', backgroundColor: "#fafafa", color: "#0b1c08" }}>
                                <CardContent>
                                  <Typography variant="h6" component="div">
                                    Admin Stats
                                  </Typography>
                                  <Typography variant="subtitle2" color="#0b1c0890" sx={{ mt: 1 }}>
                                    Total 48.5% Growth <span role="img" aria-label="sunglasses">😎</span> this month
                                  </Typography>
                                  <Grid container spacing={2} sx={{ mt: 2 }}>
                                    <Grid item xs={12} sm={2.4}>
                                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Paper sx={{ p: 1, backgroundColor: '#673AB7', display: 'flex', alignItems: 'center' }}>
                                          <HistoryEduIcon sx={{ fontSize: 36, color: '#EDE7F6' }} />
                                        </Paper>
                                        <Box sx={{ ml: 2 }}>
                                          <Typography variant="subtitle2" color="#0b1c0890">
                                            Certificates Given
                                          </Typography>
                                          <Typography variant="h5">
                                            245k
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={2.4}>
                                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Paper sx={{ p: 1, backgroundColor: '#8E3B46', display: 'flex', alignItems: 'center' }}>
                                          <AccountBalanceIcon sx={{ fontSize: 36, color: '#EDE7F6' }} />
                                        </Paper>
                                        <Box sx={{ ml: 2 }}>
                                          <Typography variant="subtitle2" color="#0b1c0890">
                                            Approved Centres
                                          </Typography>
                                          <Typography variant="h5">
                                            {centresCount}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={2.4}>
                                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Paper sx={{ p: 1, backgroundColor: '#4CAF50', display: 'flex', alignItems: 'center' }}>
                                          <PeopleIcon sx={{ fontSize: 36, color: '#E8F5E9' }} />
                                        </Paper>
                                        <Box sx={{ ml: 2 }}>
                                          <Typography variant="subtitle2" color="#0b1c0890">
                                            Total Users
                                          </Typography>
                                          <Typography variant="h5">
                                            {usersCount}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={2.4}>
                                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Paper sx={{ p: 1, backgroundColor: '#FF9800', display: 'flex', alignItems: 'center' }}>
                                          <ComputerIcon sx={{ fontSize: 36, color: '#FFF3E0' }} />
                                        </Paper>
                                        <Box sx={{ ml: 2 }}>
                                          <Typography variant="subtitle2" color="#0b1c0890">
                                            Centre Applications
                                          </Typography>
                                          <Typography variant="h5">
                                            {centreApplicationsCount}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={2.4}>
                                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Paper sx={{ p: 1, backgroundColor: '#00BCD4', display: 'flex', alignItems: 'center' }}>
                                          <AccountBalanceWalletIcon sx={{ fontSize: 36, color: '#E0F7FA' }} />
                                        </Paper>
                                        <Box sx={{ ml: 2 }}>
                                          <Typography variant="subtitle2" color="#0b1c0890">
                                            Revenue Generated
                                          </Typography>
                                          <Typography variant="h5">
                                            ₦88k
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </CardContent>
                            </Card>
                        </Box>

                        <Box
                          sx={{
                            p: "0 2% 4% 2%",
                            borderRadius: 2,
                            display: 'grid',
                            gridTemplateColumns: { md: '1fr 1fr 1fr' },
                            gap: 2
                          }}
                        >
                            {(isSuperAdmin || isCenterManager) &&
                            <Link to="/dash/users">
                                <Item elevation={1}>
                                  <VisibilityIcon />
                                  {"\u00A0"} View All Users
                                </Item>
                            </Link>}

                            {(isSuperAdmin || isCenterManager) &&
                            <Link to="/dash/users/new">
                                <Item elevation={1}>
                                  <AddIcon />
                                  {"\u00A0"} Add New User
                                </Item>
                            </Link>}

                            {isSuperAdmin &&
                            <Link to="/dash/centers">
                                <Item elevation={1}>
                                  <VisibilityIcon />
                                  {"\u00A0"} View All Centres
                                </Item>
                            </Link>}

                            {/*isSuperAdmin &&
                            <Link to="/dash/centers/new">
                                <Item elevation={1}>
                                  <AddIcon />
                                  {"\u00A0"} Add New Centre
                                </Item>
                            </Link>*/}

                            {isSuperAdmin &&
                            <Link to="/dash/center-application">
                                <Item elevation={1}>
                                  <VisibilityIcon />
                                  {"\u00A0"} View All Centre Applications
                                </Item>
                            </Link>}

                            {isSuperAdmin &&
                            <Link to="/dash/roles">
                                <Item elevation={1}>
                                  <VisibilityIcon />
                                  {"\u00A0"} View All Roles
                                </Item>
                            </Link>}

                            {isSuperAdmin &&
                            <Link to="/dash/roles/new">
                                <Item elevation={1}>
                                  <AddIcon />
                                  {"\u00A0"} Add New Role
                                </Item>
                            </Link>}

                            {isSuperAdmin &&
                            <Link to="/dash/qualifications">
                                <Item elevation={1}>
                                  <VisibilityIcon />
                                  {"\u00A0"} View Qualifications
                                </Item>
                            </Link>}

                            {isSuperAdmin &&
                                <Link to="/dash/qualifications/new">
                                <Item elevation={1}>
                                  <AddIcon />
                                  {"\u00A0"} Add New Qualification
                                </Item>
                            </Link>}

                            {<Link to="/dash/settings/updatepassword">
                                <Item elevation={1}>
                                  Update Password
                                </Item>
                            </Link>}
                        </Box>
                    </Grid>
                </Grid>

            </Box>
        )

        return content

    } else if (user && isEV) {
        const handleEdit = () => navigate(`/dash/users/${id}`)

        const date = new Date()
        const today = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'long'}).format(date)

        const content = (
            <>
            <Header title="DASHBOARD" subtitle={"Welcome "+user.firstName.concat(" ",user.lastName)} sx={{m: "65px 0 0 0"}} />
            <Box
                sx={{
                  "& .MuiStack-root .MuiButton-outlined": {color:'#fff', border:'1px solid #fff'},
                  "& .MuiStack-root .MuiButton-outlined:hover": {backgroundColor:'#7fd47010'},
                  p: "4% 4% 10% 4%",
                  bgcolor: '#F1F3F1'
                }}
            >
                <Box
                  sx={{
                    flexGrow: 1,
                    bgcolor: '#F1F3F1',
                    display: isMobile ? 'block' : 'flex',
                    "& .MuiTab-root.Mui-selected": {
                        color: '#0b1c08',
                        fontWeight: 700
                    },
                    "& .MuiTab-root": {
                        alignItems: 'flex-start',
                        color: '#295C21',
                        borderBottom: '1px solid #0b1c081a'
                    },
                    "& .MuiTabs-indicator": {
                        backgroundColor: '#49a139'
                    },
                    "& .MuiFormHelperText-root": {
                        color: '#49a139'
                    },
                    "& .MuiTab-wrapper": {
                        flexDirection: "row"
                    }
                  }}
                >
                    <Tabs
                      orientation={isMobile ? "horizontal" : "vertical"}
                      variant="scrollable"
                      allowScrollButtonsMobile
                      value={value}
                      onChange={handleChange}
                      aria-label="Vertical tabs"
                      sx={{
                          minWidth: isMobile ? '100%' : '280px',
                          color:"#49a139",
                          position:isMobile ? "" : "fixed",
                          borderBottomWidth: "150px"
                      }}
                    >
                      <Tab label={<div style={{display:"flex"}}><FingerprintIcon sx={{mr:"5px", mt:"-5px"}}/> BIODATA</div>} {...a11yProps(0)} />
                      <Tab label={<div style={{display:"flex"}}><HomeWorkIcon sx={{mr:"5px", mt:"-5px"}}/> MY CENTRES</div>} {...a11yProps(1)} />
                      <Tab label={<div style={{display:"flex"}}><HandymanIcon sx={{mr:"5px", mt:"-5px"}}/> QUALIFICATIONS</div>} {...a11yProps(2)} />
                      <Tab label={<div style={{display:"flex"}}><CollectionsBookmarkIcon sx={{mr:"5px", mt:"-5px"}}/> E-PORTFOLIO</div>} {...a11yProps(3)} />
                      <Tab label={<div style={{display:"flex"}}><WidgetsIcon sx={{mr:"5px", mt:"-5px"}}/> MENU</div>} {...a11yProps(4)} />
                    </Tabs>

                    {/**Actions OR Functions**/}
                    <TabPanel value={value} index={4}>
                        <Box
                          sx={{
                            width: isMobile ? "100%" : "960px",
                            bgcolor: "#E6EAE5",
                            p: "3% 3% 10% 3%",
                            boxShadow: "0 10px 6px -3px #777",
                            display: 'grid',
                            gridTemplateColumns: { md: '1fr 1fr 1fr' },
                            gap: 2,
                          }}
                        >
                            {/*(isSuperAdmin || isEV) &&
                            <Link to="/dash/my-center-assignments">
                                <Item elevation={1}>
                                  <VisibilityIcon />
                                  View All Assignments
                                </Item>
                            </Link>*/}

                            {<Link to="/dash/settings/updatepassword">
                                <Item elevation={1}>
                                  Update Password
                                </Item>
                            </Link>}
                        </Box>
                    </TabPanel>

                    {/**BIODATA**/}
                    <TabPanel value={value} index={0}>
                        <Box sx={{
                              width: isMobile ? "100%" : "960px",
                              bgcolor: "#E6EAE5",
                              p: "3% 3% 10% 3%",
                              boxShadow: "0 10px 6px -3px #777"
                            }}
                        >
                            <Stack direction={isMobile ? "column" : "row"} gap={5}>
                                <MyProfileAvatarBoxDash
                                  fullName={user.firstName.concat(" ",user.lastName)}
                                  rating={user.rating}
                                  roles={user.roles.join(" / ")}
                                  id={user.id}
                                  sx={{width: "30%"}}
                                />
                                {/* CARD HEADER END */}

                                <BasicInfoBoxDash
                                  uln={user.uln}
                                  email={user.email}
                                  phone={user.phone}
                                  centers={user.centers.join(", ")}
                                  qualifications={user.qualifications.join(", ")}
                                  sx={{width: "70%"}}
                                />
                            </Stack>
                        </Box>
                    </TabPanel>

                    {/**CENTER INFO**/}
                    <TabPanel value={value} index={1}>
                        <Box sx={{
                          width: isMobile ? "100%" : "960px",
                          bgcolor: "#E6EAE5",
                          p: "3% 3% 10% 3%",
                          boxShadow: "0 10px 6px -3px #777",
                          "& .MuiStack-root .MuiListSubheader-root": {
                              backgroundColor: colors.primary[400],
                              color: colors.greenAccent[400],
                              position: 'static',
                              fontSize: 15
                          }
                        }}>
                            {/*<Stack direction="column" gap={3}>
                                <List
                                  sx={{ width: '100%', bgcolor: '#0b1c08' }}
                                  aria-labelledby="assigned-qa-team"
                                  subheader={
                                    <ListSubheader component="div" id="assigned-qa-team" sx={{pl:2, pt:2}}>
                                      ASSIGNED QUALITY ASSURANCE TEAM
                                    </ListSubheader>
                                  }
                                >
                                  <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                      <Avatar alt="Kunle" src="/static/images/avatar/1.jpg" />
                                    </ListItemAvatar>
                                    <ListItemText
                                      sx={{ color: "#fff" }}
                                      primary="Kunle Bola" /*name of assigned Assessor
                                      secondary={
                                        <React.Fragment>
                                          <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="#80B373"
                                          >
                                            ASSESSOR
                                          </Typography>
                                          {" — assessor uln  /  assessor rating  /  link to assessor profile"}
                                            </React.Fragment>
                                          }
                                        />
                                  </ListItem>
                                  <Divider variant="inset" component="li" />
                                  <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                      <Avatar alt="Mahmood" src="/static/images/avatar/2.jpg" />
                                    </ListItemAvatar>
                                    <ListItemText
                                      sx={{ color: "#fff" }}
                                      primary="Mahmood Khumalo"
                                      secondary={
                                        <React.Fragment>
                                          <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="#80B373"
                                          >
                                            INTERNAL QUALITY ASSURANCE
                                          </Typography>
                                          {" — iqa uln  /  iqa rating  /  link to iqa profile"}
                                            </React.Fragment>
                                          }
                                        />
                                  </ListItem>
                                  <Divider variant="inset" component="li" />
                                  <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                      <Avatar alt="Chinasa" src="/static/images/avatar/3.jpg" />
                                    </ListItemAvatar>
                                    <ListItemText
                                      sx={{ color: "#fff" }}
                                      primary="Chinasa Oluchi"
                                      secondary={
                                        <React.Fragment>
                                          <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="#80B373"
                                          >
                                            EXTERNAL QUALITY ASSURANCE
                                          </Typography>
                                          {' — eqa uln  /  eqa rating  /  link to eqa profile'}
                                            </React.Fragment>
                                          }
                                        />
                                  </ListItem>
                                </List>
                            </Stack>*/}
                            <MyCenterApplicationsList />
                        </Box>
                    </TabPanel>

                    {/**QUALIFICATIONS**/}
                    <TabPanel value={value} index={2}>
                        <Box sx={{
                            width: isMobile ? "100%" : "960px",
                            bgcolor: "#E6EAE5",
                            p: "3% 3% 10% 3%",
                            boxShadow: "0 10px 6px -3px #777",
                            "& .MuiStack-root .MuiListSubheader-root": {
                                backgroundColor: colors.primary[400],
                                color: colors.greenAccent[400],
                                position: 'static',
                                fontSize: 15
                            }
                        }}>
                            <Stack direction="column" gap={3}>
                                <Grid sx={{ bgcolor: colors.primary[400], width: isMobile ? "100%" : "50%", p: 2 }} >
                                    <List
                                        sx={{ width: '100%' }}
                                        aria-labelledby="qualifications"
                                        subheader={
                                            <ListSubheader component="div" id="qualifications" sx={{pl:0}}>
                                                QUALIFICATIONS
                                            </ListSubheader>
                                        }
                                    >
                                        {user.qualifications.map((qualification, key) => (
                                            <Box key={key}>
                                                <Typography sx={{ mb:1, mt:1, color: "#fff" }}>
                                                    {qualification}
                                                </Typography>
                                                <Stack direction="row" sx={{ mb:2 }}>
                                                    <BorderLinearProgress variant="determinate" value={100} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={100} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={100} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={80} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={0} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={0} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={0} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={0} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={0} sx={{width:"11%"}} />
                                                </Stack>
                                            </Box>
                                        ))}
                                    </List>
                                </Grid>
                            </Stack>
                        </Box>
                    </TabPanel>

                    {/**E-PORTFOLIO**/}
                    <TabPanel value={value} index={3} sx={{ width: isMobile ? "100%" : "90%" }}>
                        <Box sx={{
                          width: isMobile ? "100%" : "1060px",
                          bgcolor: "#E6EAE5",
                          p: "3% 3% 10% 3%",
                          boxShadow: "0 10px 6px -3px #777",
                          "& .MuiStack-root .MuiListSubheader-root": {
                              backgroundColor: colors.primary[400],
                              color: colors.greenAccent[400],
                              position: 'static',
                              fontSize: 15
                          }
                        }}>
                            <Grid sx={{ bgcolor: colors.primary[400], width: "60%", p: 2 }} >
                                <EventCalendar />
                            </Grid>
                        </Box>
                    </TabPanel>
                </Box>
            </Box>
            </>
        )

        return content

    } else if (user && isCenter) {
        const handleEdit = () => navigate(`/dash/users/${id}`)

        const date = new Date()
        const today = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'long'}).format(date)

        const content = (
            <>
            <Header title="DASHBOARD" subtitle={"Welcome "+user.firstName} sx={{m: "65px 0 0 0"}} />
            <Box
                sx={{
                  "& .MuiStack-root .MuiButton-outlined": {color:'#fff', border:'1px solid #fff'},
                  "& .MuiStack-root .MuiButton-outlined:hover": {backgroundColor:'#7fd47010'},
                  p: "4% 4% 10% 4%",
                  bgcolor: '#F1F3F1'
                }}
            >
                <Box
                  sx={{
                    flexGrow: 1,
                    bgcolor: '#F1F3F1',
                    display: isMobile ? 'block' : 'flex',
                    "& .MuiTab-root.Mui-selected": {
                        color: '#0b1c08',
                        fontWeight: 700
                    },
                    "& .MuiTab-root": {
                        alignItems: 'flex-start',
                        color: '#295C21',
                        borderBottom: '1px solid #0b1c081a'
                    },
                    "& .MuiTabs-indicator": {
                        backgroundColor: '#49a139'
                    },
                    "& .MuiFormHelperText-root": {
                        color: '#49a139'
                    },
                    "& .MuiTab-wrapper": {
                        flexDirection: "row"
                    }
                  }}
                >
                    <Tabs
                      orientation={isMobile ? "horizontal" : "vertical"}
                      variant="scrollable"
                      allowScrollButtonsMobile
                      value={value}
                      onChange={handleChange}
                      aria-label="Vertical tabs"
                      sx={{
                          minWidth: isMobile ? '100%' : '280px',
                          color:"#49a139",
                          position:isMobile ? "" : "fixed",
                          borderBottomWidth: "150px"
                      }}
                    >
                      <Tab label={<div style={{display:"flex"}}><HomeWorkIcon sx={{mr:"5px", mt:"-5px"}}/> CENTRE INFO</div>} {...a11yProps(0)} />
                      <Tab label={<div style={{display:"flex"}}><EngineeringIcon sx={{mr:"5px", mt:"-5px"}}/> ASSESSORS</div>} {...a11yProps(1)} />
                      <Tab label={<div style={{display:"flex"}}><HistoryEduIcon sx={{mr:"5px", mt:"-5px"}}/> INTERNAL VERIFIERS</div>} {...a11yProps(2)} />
                      <Tab label={<div style={{display:"flex"}}><SupervisorAccountIcon sx={{mr:"5px", mt:"-5px"}}/> OTHER STAFF</div>} {...a11yProps(3)} />
                      <Tab label={<div style={{display:"flex"}}><SchoolIcon sx={{mr:"5px", mt:"-5px"}}/> TRAINEES</div>} {...a11yProps(4)} />
                      <Tab label={<div style={{display:"flex"}}><AppRegistrationIcon sx={{mr:"5px", mt:"-5px"}}/> ASSIGNMENTS</div>} {...a11yProps(5)} />
                      <Tab label={<div style={{display:"flex"}}><WidgetsIcon sx={{mr:"5px", mt:"-5px"}}/> MENU</div>} {...a11yProps(6)} />
                      <Tab label={<div style={{display:"flex"}}><HandymanIcon sx={{mr:"5px", mt:"-5px"}}/> QUALIFICATIONS</div>} {...a11yProps(7)} />
                      <Tab label={<div style={{display:"flex"}}><CollectionsBookmarkIcon sx={{mr:"5px", mt:"-5px"}}/> E-PORTFOLIO</div>} {...a11yProps(8)} />
                    </Tabs>

                    {/**Actions OR Functions**/}
                    <TabPanel value={value} index={6} sx={{ width: isMobile ? "100%" : "90%" }}>
                        <Box
                          sx={{
                            width: isMobile ? "100%" : "960px",
                            bgcolor: "#E6EAE5",
                            p: "3% 3% 10% 3%",
                            boxShadow: "0 10px 6px -3px #777",
                            display: 'grid',
                            gridTemplateColumns: { md: '1fr 1fr 1fr' },
                            gap: 2,
                          }}
                        >
                            <Link to="/dash/users/new">
                                <Item elevation={1}>
                                  <AddIcon />
                                  Add New User
                                </Item>
                            </Link>

                            {/*<Link to="/dash/assessor-trainee-assignments">
                                <Item elevation={1}>
                                  <VisibilityIcon />
                                  View All Assessor-Trainee Assignments
                                </Item>
                            </Link>*/}

                            <Link to="/dash/assessor-trainee-assignments/new">
                                <Item elevation={1}>
                                  <AddIcon />
                                  Assign Assessor to Trainee
                                </Item>
                            </Link>

                            <Link to="/dash/settings/updatepassword">
                                <Item elevation={1}>
                                  Update Password
                                </Item>
                            </Link>
                        </Box>
                    </TabPanel>

                    {/**CENTRE INFO**/}
                    <TabPanel value={value} index={0} sx={{ width: isMobile ? "100%" : "90%" }}>
                        <Box sx={{
                              width: isMobile ? "100%" : "960px",
                              bgcolor: "#E6EAE5",
                              p: "3% 3% 10% 3%",
                              boxShadow: "0 10px 6px -3px #777"
                            }}
                        >
                            <Stack direction={isMobile ? "column" : "row"} gap={5}>
                                <MyProfileAvatarBoxDash
                                  fullName={user.firstName}
                                  rating={user.rating}
                                  roles={user.roles}
                                  id={user.id}
                                  sx={{width: "30%"}}
                                />
                                {/* CARD HEADER END */}

                                <BasicInfoBoxDash
                                  uln={user.uln}
                                  email={user.email}
                                  phone={user.phone}
                                  centers={user.centers}
                                  qualifications={user.qualifications.join(", ")}
                                  sx={{width: "70%"}}
                                />
                            </Stack>
                        </Box>
                    </TabPanel>

                    {/**ASSESSORS**/}
                    <TabPanel value={value} index={1} sx={{ width: isMobile ? "100%" : "90%" }}>
                        <Box sx={{
                          width: isMobile ? "100%" : "960px",
                          bgcolor: "#E6EAE5",
                          p: "3% 3% 10% 3%",
                          boxShadow: "0 10px 6px -3px #777",
                          "& .MuiStack-root .MuiListSubheader-root": {
                              backgroundColor: colors.primary[400],
                              color: colors.greenAccent[400],
                              position: 'static',
                              fontSize: 15
                          }
                        }}>
                            <MyUsersList roletype="ASSESSOR" center={user.firstName} />
                        </Box>
                    </TabPanel>

                    {/**INTERNAL VERIFIERS**/}
                    <TabPanel value={value} index={2} sx={{ width: isMobile ? "100%" : "90%" }}>
                        <Box sx={{
                          width: isMobile ? "100%" : "960px",
                          bgcolor: "#E6EAE5",
                          p: "3% 3% 10% 3%",
                          boxShadow: "0 10px 6px -3px #777",
                          "& .MuiStack-root .MuiListSubheader-root": {
                              backgroundColor: colors.primary[400],
                              color: colors.greenAccent[400],
                              position: 'static',
                              fontSize: 15
                          }
                        }}>
                            <MyUsersList roletype="INTERNAL VERIFIER" center={user.firstName} />
                        </Box>
                    </TabPanel>

                    {/**OTHER STAFF**/}
                    <TabPanel value={value} index={3} sx={{ width: isMobile ? "100%" : "90%" }}>
                        <Box sx={{
                          width: isMobile ? "100%" : "960px",
                          bgcolor: "#E6EAE5",
                          p: "3% 3% 10% 3%",
                          boxShadow: "0 10px 6px -3px #777",
                          "& .MuiStack-root .MuiListSubheader-root": {
                              backgroundColor: colors.primary[400],
                              color: colors.greenAccent[400],
                              position: 'static',
                              fontSize: 15
                          }
                        }}>
                            <MyUsersList roletype="OTHER STAFF" center={user.firstName} />
                        </Box>
                    </TabPanel>

                    {/**TRAINEES**/}
                    <TabPanel value={value} index={4} sx={{ width: isMobile ? "100%" : "90%" }}>
                        <Box sx={{
                          width: isMobile ? "100%" : "960px",
                          bgcolor: "#E6EAE5",
                          p: "3% 3% 10% 3%",
                          boxShadow: "0 10px 6px -3px #777",
                          "& .MuiStack-root .MuiListSubheader-root": {
                              backgroundColor: colors.primary[400],
                              color: colors.greenAccent[400],
                              position: 'static',
                              fontSize: 15
                          }
                        }}>
                            <MyUsersList roletype="TRAINEE" center={user.firstName} />
                        </Box>
                    </TabPanel>

                    {/**ASSIGNMENTS**/}
                    <TabPanel value={value} index={5} sx={{ width: isMobile ? "100%" : "90%" }}>
                        <Box sx={{
                          width: isMobile ? "100%" : "960px",
                          bgcolor: "#E6EAE5",
                          p: "3% 3% 10% 3%",
                          boxShadow: "0 10px 6px -3px #777",
                          "& .MuiStack-root .MuiListSubheader-root": {
                              backgroundColor: colors.primary[400],
                              color: colors.greenAccent[400],
                              position: 'static',
                              fontSize: 15
                          }
                        }}>
                            <AssessorTraineeAssignmentsListOnDash />
                        </Box>
                    </TabPanel>

                    {/**QUALIFICATIONS**/}
                    <TabPanel value={value} index={7} sx={{ width: isMobile ? "100%" : "90%" }}>
                        <Box sx={{
                            width: isMobile ? "100%" : "960px",
                            bgcolor: "#E6EAE5",
                            p: "3% 3% 10% 3%",
                            boxShadow: "0 10px 6px -3px #777",
                            "& .MuiStack-root .MuiListSubheader-root": {
                                backgroundColor: colors.primary[400],
                                color: colors.greenAccent[400],
                                position: 'static',
                                fontSize: 15
                            }
                        }}>
                            <Stack direction="column" gap={3}>
                                <Grid sx={{ bgcolor: colors.primary[400], width: isMobile ? "100%" : "50%", p: 2 }} >
                                    <List
                                        sx={{ width: '100%' }}
                                        aria-labelledby="qualifications"
                                        subheader={
                                            <ListSubheader component="div" id="qualifications" sx={{pl:0}}>
                                                QUALIFICATIONS
                                            </ListSubheader>
                                        }
                                    >
                                        {user.qualifications.map((qualification, key) => (
                                            <Box key={key}>
                                                <Typography sx={{ mb:1, mt:1, color: "#fff" }}>
                                                    {qualification}
                                                </Typography>
                                                <Stack direction="row" sx={{ mb:2 }}>
                                                    <BorderLinearProgress variant="determinate" value={100} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={100} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={100} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={80} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={0} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={0} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={0} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={0} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={0} sx={{width:"11%"}} />
                                                </Stack>
                                            </Box>
                                        ))}
                                    </List>
                                </Grid>
                            </Stack>
                        </Box>
                    </TabPanel>

                    {/**E-PORTFOLIO**/}
                    <TabPanel value={value} index={8} sx={{ width: isMobile ? "100%" : "90%" }}>
                        <Box sx={{
                          width: isMobile ? "100%" : "960px",
                          bgcolor: "#E6EAE5",
                          p: "3% 3% 10% 3%",
                          boxShadow: "0 10px 6px -3px #777",
                          "& .MuiStack-root .MuiListSubheader-root": {
                              backgroundColor: colors.primary[400],
                              color: colors.greenAccent[400],
                              position: 'static',
                              fontSize: 15
                          }
                        }}>
                            <Grid sx={{ bgcolor: colors.primary[400], width: "60%", p: 2 }} >
                                <EventCalendar />
                            </Grid>
                        </Box>
                    </TabPanel>
                </Box>
            </Box>
            </>
        )

        return content

    } else if (user && isAssessor) {
        const handleEdit = () => navigate(`/dash/users/${id}`)

        const date = new Date()
        const today = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'long'}).format(date)

        const content = (
            <>
            <Header title="DASHBOARD" subtitle={"Welcome "+user.firstName.concat(" ",user.lastName)} sx={{m: "65px 0 0 0"}} />
            <Box
                sx={{
                  "& .MuiStack-root .MuiButton-outlined": {color:'#fff', border:'1px solid #fff'},
                  "& .MuiStack-root .MuiButton-outlined:hover": {backgroundColor:'#7fd47010'},
                  p: "4% 4% 10% 4%",
                  bgcolor: '#F1F3F1'
                }}
            >
                <Box
                  sx={{
                    flexGrow: 1,
                    bgcolor: '#F1F3F1',
                    display: isMobile ? 'block' : 'flex',
                    "& .MuiTab-root.Mui-selected": {
                        color: '#0b1c08',
                        fontWeight: 700
                    },
                    "& .MuiTab-root": {
                        alignItems: 'flex-start',
                        color: '#295C21',
                        borderBottom: '1px solid #0b1c081a'
                    },
                    "& .MuiTabs-indicator": {
                        backgroundColor: '#49a139'
                    },
                    "& .MuiFormHelperText-root": {
                        color: '#49a139'
                    },
                    "& .MuiTab-wrapper": {
                        flexDirection: "row"
                    }
                  }}
                >
                    <Tabs
                      orientation={isMobile ? "horizontal" : "vertical"}
                      variant="scrollable"
                      allowScrollButtonsMobile
                      value={value}
                      onChange={handleChange}
                      aria-label="Vertical tabs"
                      sx={{
                          minWidth: isMobile ? '100%' : '280px',
                          color:"#49a139",
                          position:isMobile ? "" : "fixed",
                          borderBottomWidth: "150px"
                      }}
                    >
                      <Tab label={<div style={{display:"flex"}}><FingerprintIcon sx={{mr:"5px", mt:"-5px"}}/> BIODATA</div>} {...a11yProps(0)} />
                      <Tab label={<div style={{display:"flex"}}><HomeWorkIcon sx={{mr:"5px", mt:"-5px"}}/> MY CENTRE ASSIGNMENTS</div>} {...a11yProps(1)} />
                      <Tab label={<div style={{display:"flex"}}><HandymanIcon sx={{mr:"5px", mt:"-5px"}}/> QUALIFICATIONS</div>} {...a11yProps(2)} />
                      <Tab label={<div style={{display:"flex"}}><CollectionsBookmarkIcon sx={{mr:"5px", mt:"-5px"}}/> E-PORTFOLIO</div>} {...a11yProps(3)} />
                      <Tab label={<div style={{display:"flex"}}><WidgetsIcon sx={{mr:"5px", mt:"-5px"}}/> MENU</div>} {...a11yProps(4)} />
                    </Tabs>

                    {/**Actions OR Functions**/}
                    <TabPanel value={value} index={4} sx={{ width: isMobile ? "100%" : "90%" }}>
                        <Box
                          sx={{
                            width: isMobile ? "100%" : "1060px",
                            bgcolor: "#E6EAE5",
                            p: "3% 3% 10% 3%",
                            boxShadow: "0 10px 6px -3px #777",
                            display: 'grid',
                            gridTemplateColumns: { md: '1fr 1fr 1fr' },
                            gap: 2,
                          }}
                        >
                            {(isSuperAdmin || isEV) &&
                            <Link to="/dash/my-center-assignments">
                                <Item elevation={1}>
                                  <VisibilityIcon />
                                  View All Assignments
                                </Item>
                            </Link>}

                            {<Link to="/dash/settings/updatepassword">
                                <Item elevation={1}>
                                  Update Password
                                </Item>
                            </Link>}
                        </Box>
                    </TabPanel>

                    {/**BIODATA**/}
                    <TabPanel value={value} index={0} sx={{ width: isMobile ? "100%" : "90%" }}>
                        <Box sx={{
                              width: isMobile ? "100%" : "1060px",
                              bgcolor: "#E6EAE5",
                              p: "3% 3% 10% 3%",
                              boxShadow: "0 10px 6px -3px #777"
                            }}
                        >
                            <Stack direction={isMobile ? "column" : "row"} gap={5}>
                                <MyProfileAvatarBoxDash
                                  fullName={user.firstName.concat(" ",user.lastName)}
                                  rating={user.rating}
                                  roles={user.roles.join(" / ")}
                                  id={user.id}
                                  sx={{width: "30%"}}
                                />
                                {/* CARD HEADER END */}

                                <BasicInfoBoxDash
                                  uln={user.uln}
                                  email={user.email}
                                  phone={user.phone}
                                  centers={user.centers.join(", ")}
                                  qualifications={user.qualifications.join(", ")}
                                  sx={{width: "70%"}}
                                />
                            </Stack>
                        </Box>
                    </TabPanel>

                    {/**CENTER INFO**/}
                    <TabPanel value={value} index={1} sx={{ width: isMobile ? "100%" : "90%" }}>
                        <Box sx={{
                          width: isMobile ? "100%" : "1060px",
                          bgcolor: "#E6EAE5",
                          p: "3% 3% 10% 3%",
                          boxShadow: "0 10px 6px -3px #777",
                          "& .MuiStack-root .MuiListSubheader-root": {
                              backgroundColor: colors.primary[400],
                              color: colors.greenAccent[400],
                              position: 'static',
                              fontSize: 15
                          }
                        }}>
                            <MyAssessorTraineeAssignmentsListOnDash />
                        </Box>
                    </TabPanel>

                    {/**QUALIFICATIONS**/}
                    <TabPanel value={value} index={2} sx={{ width: isMobile ? "100%" : "90%" }}>
                        <Box sx={{
                            width: isMobile ? "100%" : "1060px",
                            bgcolor: "#E6EAE5",
                            p: "3% 3% 10% 3%",
                            boxShadow: "0 10px 6px -3px #777",
                            "& .MuiStack-root .MuiListSubheader-root": {
                                backgroundColor: colors.primary[400],
                                color: colors.greenAccent[400],
                                position: 'static',
                                fontSize: 15
                            }
                        }}>
                            <Stack direction="column" gap={3}>
                                <Grid sx={{ bgcolor: colors.primary[400], width: isMobile ? "100%" : "50%", p: 2 }} >
                                    <List
                                        sx={{ width: '100%' }}
                                        aria-labelledby="qualifications"
                                        subheader={
                                            <ListSubheader component="div" id="qualifications" sx={{pl:0}}>
                                                QUALIFICATIONS
                                            </ListSubheader>
                                        }
                                    >
                                        {user.qualifications.map((qualification, key) => (
                                            <Box key={key}>
                                                <Typography sx={{ mb:1, mt:1, color: "#fff" }}>
                                                    {qualification}
                                                </Typography>
                                                <Stack direction="row" sx={{ mb:2 }}>
                                                    <BorderLinearProgress variant="determinate" value={100} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={100} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={100} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={80} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={0} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={0} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={0} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={0} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={0} sx={{width:"11%"}} />
                                                </Stack>
                                            </Box>
                                        ))}
                                    </List>
                                </Grid>
                            </Stack>
                        </Box>
                    </TabPanel>

                    {/**E-PORTFOLIO**/}
                    <TabPanel value={value} index={3} sx={{ width: isMobile ? "100%" : "90%" }}>
                        <Box sx={{
                          width: isMobile ? "100%" : "1060px",
                          bgcolor: "#E6EAE5",
                          p: "3% 3% 10% 3%",
                          boxShadow: "0 10px 6px -3px #777",
                          "& .MuiStack-root .MuiListSubheader-root": {
                              backgroundColor: colors.primary[400],
                              color: colors.greenAccent[400],
                              position: 'static',
                              fontSize: 15
                          }
                        }}>
                            <Grid sx={{ bgcolor: colors.primary[400], width: "60%", p: 2 }} >
                                <EventCalendar />
                            </Grid>
                        </Box>
                    </TabPanel>
                </Box>
            </Box>
            </>
        )

        return content

    } else if (user && isIV) {
        const handleEdit = () => navigate(`/dash/users/${id}`)

        const date = new Date()
        const today = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'long'}).format(date)

        const content = (
            <>
            <Header title="DASHBOARD" subtitle={"Welcome "+user.firstName.concat(" ",user.lastName)} sx={{m: "65px 0 0 0"}} />
            <Box
                sx={{
                  "& .MuiStack-root .MuiButton-outlined": {color:'#fff', border:'1px solid #fff'},
                  "& .MuiStack-root .MuiButton-outlined:hover": {backgroundColor:'#7fd47010'},
                  p: "4% 4% 10% 4%",
                  bgcolor: '#F1F3F1'
                }}
            >
                <Box
                  sx={{
                    flexGrow: 1,
                    bgcolor: '#F1F3F1',
                    display: isMobile ? 'block' : 'flex',
                    "& .MuiTab-root.Mui-selected": {
                        color: '#0b1c08',
                        fontWeight: 700
                    },
                    "& .MuiTab-root": {
                        alignItems: 'flex-start',
                        color: '#295C21',
                        borderBottom: '1px solid #0b1c081a'
                    },
                    "& .MuiTabs-indicator": {
                        backgroundColor: '#49a139'
                    },
                    "& .MuiFormHelperText-root": {
                        color: '#49a139'
                    },
                    "& .MuiTab-wrapper": {
                        flexDirection: "row"
                    }
                  }}
                >
                    <Tabs
                      orientation={isMobile ? "horizontal" : "vertical"}
                      variant="scrollable"
                      allowScrollButtonsMobile
                      value={value}
                      onChange={handleChange}
                      aria-label="Vertical tabs"
                      sx={{
                          minWidth: isMobile ? '100%' : '280px',
                          color:"#49a139",
                          position:isMobile ? "" : "fixed",
                          borderBottomWidth: "150px"
                      }}
                    >
                      <Tab label={<div style={{display:"flex"}}><FingerprintIcon sx={{mr:"5px", mt:"-5px"}}/> BIODATA</div>} {...a11yProps(0)} />
                      <Tab label={<div style={{display:"flex"}}><HomeWorkIcon sx={{mr:"5px", mt:"-5px"}}/> MY CENTRE ASSIGNMENTS</div>} {...a11yProps(1)} />
                      <Tab label={<div style={{display:"flex"}}><HandymanIcon sx={{mr:"5px", mt:"-5px"}}/> QUALIFICATIONS</div>} {...a11yProps(2)} />
                      <Tab label={<div style={{display:"flex"}}><CollectionsBookmarkIcon sx={{mr:"5px", mt:"-5px"}}/> E-PORTFOLIO</div>} {...a11yProps(3)} />
                      <Tab label={<div style={{display:"flex"}}><WidgetsIcon sx={{mr:"5px", mt:"-5px"}}/> MENU</div>} {...a11yProps(4)} />
                    </Tabs>

                    {/**Actions OR Functions**/}
                    <TabPanel value={value} index={4} sx={{ width: isMobile ? "100%" : "90%" }}>
                        <Box
                          sx={{
                            width: isMobile ? "100%" : "1060px",
                            bgcolor: "#E6EAE5",
                            p: "3% 3% 10% 3%",
                            boxShadow: "0 10px 6px -3px #777",
                            display: 'grid',
                            gridTemplateColumns: { md: '1fr 1fr 1fr' },
                            gap: 2,
                          }}
                        >
                            {(isSuperAdmin || isEV) &&
                            <Link to="/dash/my-center-assignments">
                                <Item elevation={1}>
                                  <VisibilityIcon />
                                  View All Assignments
                                </Item>
                            </Link>}

                            {<Link to="/dash/settings/updatepassword">
                                <Item elevation={1}>
                                  Update Password
                                </Item>
                            </Link>}
                        </Box>
                    </TabPanel>

                    {/**BIODATA**/}
                    <TabPanel value={value} index={0} sx={{ width: isMobile ? "100%" : "90%" }}>
                        <Box sx={{
                              width: isMobile ? "100%" : "1060px",
                              bgcolor: "#E6EAE5",
                              p: "3% 3% 10% 3%",
                              boxShadow: "0 10px 6px -3px #777"
                            }}
                        >
                            <Stack direction={isMobile ? "column" : "row"} gap={5}>
                                <MyProfileAvatarBoxDash
                                  fullName={user.firstName.concat(" ",user.lastName)}
                                  rating={user.rating}
                                  roles={user.roles.join(" / ")}
                                  id={user.id}
                                  sx={{width: "30%"}}
                                />
                                {/* CARD HEADER END */}

                                <BasicInfoBoxDash
                                  uln={user.uln}
                                  email={user.email}
                                  phone={user.phone}
                                  centers={user.centers.join(", ")}
                                  qualifications={user.qualifications.join(", ")}
                                  sx={{width: "70%"}}
                                />
                            </Stack>
                        </Box>
                    </TabPanel>

                    {/**CENTER INFO**/}
                    <TabPanel value={value} index={1} sx={{ width: isMobile ? "100%" : "90%" }}>
                        <Box sx={{
                          width: isMobile ? "100%" : "1060px",
                          bgcolor: "#E6EAE5",
                          p: "3% 3% 10% 3%",
                          boxShadow: "0 10px 6px -3px #777",
                          "& .MuiStack-root .MuiListSubheader-root": {
                              backgroundColor: colors.primary[400],
                              color: colors.greenAccent[400],
                              position: 'static',
                              fontSize: 15
                          }
                        }}>
                            <>
                                <CloudUploadIcon sx={{ fill: "0b1c08a9", fontSize: 120 }} />
                                <Typography variant="h3" sx={{ color: "efefef" }}>
                                    No Assessor has been assigned to you.
                                </Typography>
                            </>
                        </Box>
                    </TabPanel>

                    {/**QUALIFICATIONS**/}
                    <TabPanel value={value} index={2} sx={{ width: isMobile ? "100%" : "90%" }}>
                        <Box sx={{
                            width: isMobile ? "100%" : "1060px",
                            bgcolor: "#E6EAE5",
                            p: "3% 3% 10% 3%",
                            boxShadow: "0 10px 6px -3px #777",
                            "& .MuiStack-root .MuiListSubheader-root": {
                                backgroundColor: colors.primary[400],
                                color: colors.greenAccent[400],
                                position: 'static',
                                fontSize: 15
                            }
                        }}>
                            <Stack direction="column" gap={3}>
                                <Grid sx={{ bgcolor: colors.primary[400], width: isMobile ? "100%" : "50%", p: 2 }} >
                                    <List
                                        sx={{ width: '100%' }}
                                        aria-labelledby="qualifications"
                                        subheader={
                                            <ListSubheader component="div" id="qualifications" sx={{pl:0}}>
                                                QUALIFICATIONS
                                            </ListSubheader>
                                        }
                                    >
                                        {user.qualifications.map((qualification, key) => (
                                            <Box key={key}>
                                                <Typography sx={{ mb:1, mt:1, color: "#fff" }}>
                                                    {qualification}
                                                </Typography>
                                                <Stack direction="row" sx={{ mb:2 }}>
                                                    <BorderLinearProgress variant="determinate" value={100} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={100} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={100} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={80} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={0} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={0} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={0} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={0} sx={{width:"11%"}} />
                                                    <BorderLinearProgress variant="determinate" value={0} sx={{width:"11%"}} />
                                                </Stack>
                                            </Box>
                                        ))}
                                    </List>
                                </Grid>
                            </Stack>
                        </Box>
                    </TabPanel>

                    {/**E-PORTFOLIO**/}
                    <TabPanel value={value} index={3} sx={{ width: isMobile ? "100%" : "90%" }}>
                        <Box sx={{
                          width: isMobile ? "100%" : "1060px",
                          bgcolor: "#E6EAE5",
                          p: "3% 3% 10% 3%",
                          boxShadow: "0 10px 6px -3px #777",
                          "& .MuiStack-root .MuiListSubheader-root": {
                              backgroundColor: colors.primary[400],
                              color: colors.greenAccent[400],
                              position: 'static',
                              fontSize: 15
                          }
                        }}>
                            <Grid sx={{ bgcolor: colors.primary[400], width: "60%", p: 2 }} >
                                <EventCalendar />
                            </Grid>
                        </Box>
                    </TabPanel>
                </Box>
            </Box>
            </>
        )

        return content

    } else return null
}

const memoizedDashboard = memo(MyDashboard)

export default memoizedDashboard
