import { useSelector } from 'react-redux'
import { selectCurrentToken } from "../features/auth/authSlice"
import { jwtDecode } from 'jwt-decode'

const useAuth = () => {
    const token = useSelector(selectCurrentToken)
    let isSuperAdmin = false
    let isCenter = false
    let isCenterManager = false
    let isAssessor = false
    let isIV = false
    let isEV = false
    let isTrainee = false
    let status = "User"

    if (token) {
        const decoded = jwtDecode(token)
        const { uln, firstName, lastName, roles, id } = decoded.UserInfo

        isSuperAdmin = roles.includes('SUPER ADMIN')
        isCenter = roles.includes('CENTRE')
        isCenterManager = roles.includes('CENTRE MANAGER')
        isAssessor = roles.includes('ASSESSOR')
        isIV = roles.includes('INTERNAL VERIFIER')
        isEV = roles.includes('EXTERNAL VERIFIER')
        isTrainee = roles.includes('TRAINEE')

        if (isSuperAdmin) status = "SUPER ADMIN"
        if (isCenter) status = "CENTRE"
        if (isCenterManager) status = "CENTRE MANAGER"
        if (isAssessor) status = "ASSESSOR"
        if (isIV) status = "INTERNAL VERIFIER"
        if (isEV) status = "EXTERNAL VERIFIER"
        if (isTrainee) status = "TRAINEE"

        return { id, uln, firstName, lastName, roles, status, isSuperAdmin, isCenter, isCenterManager, isAssessor, isIV, isEV, isTrainee }
    }

    return { id: '', uln: '', firstName: '', lastName: '', roles: [], isSuperAdmin, isCenter, isCenterManager, isAssessor, isIV, isEV, isTrainee, status }
}
export default useAuth
