import { Routes, Route } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from "@mui/material"
import { ColorModeContext, useMode } from "./theme"
import Layout from './components/Layout'
import Public from './components/Public'
import Login from './features/auth/Login2'
import DashLayout from './components/DashLayout'
import MyDashboard from './features/auth/Dashboards'
import QualificationsList from './features/qualifications/QualificationsList'
import { UsersList, MyUsersList } from './features/users/UsersList'
import CentersList from './features/centers/CentersList'
import { AssessorTraineeAssignmentsList } from './features/assessortraineeassignment/AssessorTraineeAssignmentsList'
import CenterApplication from './features/centerapplication/CenterApplication'
import CenterQuality from './features/centerapplication/CenterQuality'
import { AssignCenter, ViewCenterApplication } from './features/centerapplication/AssignCenter'
import EditCenterApplication from './features/centerapplication/EditCenterApplication'
import { CenterApplicationsList, MyCenterApplicationsList } from './features/centerapplication/CenterApplicationsList'
import RolesList from './features/roles/RolesList'
import EditUser from './features/users/EditUser'
import NewUserForm from './features/users/NewUserForm'
import EditCenter from './features/centers/EditCenter'
import NewCenterForm from './features/centers/NewCenterForm'
import NewAssessorTraineeAssignmentForm from './features/assessortraineeassignment/NewAssessorTraineeAssignmentForm'
import EditRole from './features/roles/EditRole'
import NewRoleForm from './features/roles/NewRoleForm'
import EditQualification from './features/qualifications/EditQualification'
import NewQualificationForm from './features/qualifications/NewQualificationForm'
import UpdatePassword from './features/settings/UpdatePassword'
import Profile from './features/settings/Profile'
import Prefetch from './features/auth/Prefetch'
import PersistLogin from './features/auth/PersistLogin'
import RequireAuth from './features/auth/RequireAuth'
import { ROLES } from './config/roles'
import useTitle from './hooks/useTitle'

function App() {
  useTitle('NIOB Skill CaMP')
  const [theme, colorMode] = useMode()

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<Layout />}>
            {/* Public Routes */}
            <Route index element={<Public />} />
            <Route path="login" element={<Login />} />
            <Route path="profile" element={<Profile />} />
            <Route path="center-application" element={<CenterApplication />} />
            <Route path="center-application/edit/:id" element={<EditCenterApplication />} />

            {/* Protected Routes */}
            <Route element={<PersistLogin />}>
              <Route element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />}>
                <Route element={<Prefetch />}>
                  <Route path="dash" element={<DashLayout />}>
                    <Route index element={<MyDashboard />} />

                    <Route path="settings">
                      <Route path=":id" element={<UpdatePassword />} />
                    </Route>

                    <Route element={<RequireAuth allowedRoles={[ROLES.SUPERADMIN, ROLES.CENTERMANAGER, ROLES.CENTER]} />}>
                      <Route path="users">
                        <Route index element={<UsersList />} />
                        <Route path=":id" element={<EditUser />} />
                        <Route path="new" element={<NewUserForm />} />
                      </Route>
                    </Route>

                    <Route element={<RequireAuth allowedRoles={[ROLES.CENTERMANAGER, ROLES.CENTER]} />}>
                      <Route path="my-center-users">
                        <Route index element={<MyUsersList />} />
                      </Route>
                    </Route>

                    <Route element={<RequireAuth allowedRoles={[ROLES.SUPERADMIN]} />}>
                      <Route path="centers">
                        <Route index element={<CentersList />} />
                        <Route path=":id" element={<EditCenter />} />
                        <Route path="new" element={<NewCenterForm />} />
                      </Route>
                    </Route>

                    <Route element={<RequireAuth allowedRoles={[ROLES.SUPERADMIN]} />}>
                      <Route path="center-application">
                        <Route index element={<CenterApplicationsList />} />
                        <Route path="view/:id" element={<ViewCenterApplication />} />
                        <Route path=":id" element={<AssignCenter />} />
                      </Route>
                    </Route>

                    <Route element={<RequireAuth allowedRoles={[ROLES.CENTER]} />}>
                      <Route path="assessor-trainee-assignments">
                        <Route index element={<AssessorTraineeAssignmentsList />} />
                        <Route path="new" element={<NewAssessorTraineeAssignmentForm />} />
                      </Route>
                    </Route>

                    <Route element={<RequireAuth allowedRoles={[ROLES.EXTERNALVERIFIER]} />}>
                      <Route path="my-center-assignments">
                        <Route index element={<MyCenterApplicationsList />} />
                        <Route path=":id" element={<CenterQuality />} />
                      </Route>
                    </Route>

                    <Route element={<RequireAuth allowedRoles={[ROLES.SUPERADMIN]} />}>
                      <Route path="roles">
                        <Route index element={<RolesList />} />
                        <Route path=":id" element={<EditRole />} />
                        <Route path="new" element={<NewRoleForm />} />
                      </Route>
                    </Route>

                    <Route element={<RequireAuth allowedRoles={[ROLES.SUPERADMIN]} />}>
                      <Route path="qualifications">
                        <Route index element={<QualificationsList />} />
                        <Route path=":id" element={<EditQualification />} />
                        <Route path="new" element={<NewQualificationForm />} />
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Routes>
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
}

export default App
