import {
    createSelector,
    createEntityAdapter
} from '@reduxjs/toolkit'
import { apiSlice } from  '../../app/api/apiSlice'

const centersAdapter = createEntityAdapter({})

const initialState = centersAdapter.getInitialState()

export const centersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCenters: builder.query({
            query: () => ({
                url: '/centers',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
              const loadedCenters = responseData.map(center => {
                  center.id = center._id
                  return center
              });
              return centersAdapter.setAll(initialState, loadedCenters)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Center', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'Center', id }))
                    ]
                } else return [{ type: 'Center', id: 'LIST' }]
            }
        }),
        addNewCenter: builder.mutation({
            query: initialCenterData => ({
                url: '/centers',
                method: 'POST',
                body: {
                    ...initialCenterData,
                }
            }),
            invalidatesTags: [
                { type: 'Center', id: "LIST" }
            ]
        }),
        updateCenter: builder.mutation({
            query: initialCenterData => ({
                url: '/centers',
                method: 'PATCH',
                body: {
                    ...initialCenterData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Center', id: arg.id }
            ]
        }),
        deleteCenter: builder.mutation({
            query: ({ id }) => ({
                url: `/centers`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Center', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetCentersQuery,
    useAddNewCenterMutation,
    useUpdateCenterMutation,
    useDeleteCenterMutation,
} = centersApiSlice

// return the query result object
export const selectCentersResult = centersApiSlice.endpoints.getCenters.select()

// creates memoized selector
const selectCentersData = createSelector(
    selectCentersResult,
    centersResult => centersResult.data // normalized state object with ids  & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllCenters,
    selectById: selectCenterById,
    selectIds: selectCenterIds
    // Pass in a selector that returns the Centers slice of state
} = centersAdapter.getSelectors(state => selectCentersData(state) ?? initialState)
