import { useState, useEffect } from "react"
import useAuth from "../../hooks/useAuth"
import { useAddNewAssessorTraineeAssignmentMutation } from "./assessortraineeassignmentApiSlice"
import { useGetUsersQuery } from '../users/usersApiSlice'
import { Link, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from "@fortawesome/free-solid-svg-icons"
import useTitle from '../../hooks/useTitle'

import LoadingButton from '@mui/lab/LoadingButton'
import { Box, useTheme, TextField, Typography, Stack, Button, FormControl, InputLabel, Select, MenuItem, Grid, Card, CardHeader, CardActions, CardContent, Collapse } from "@mui/material"
import Alert from '@mui/material/Alert'
import HomeIcon from '@mui/icons-material/Home'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { styled } from '@mui/material/styles'
import { tokens } from "../../theme"
import useMediaQuery from "@mui/material/useMediaQuery"
import Header from "../../components/Header"

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const NewAssessorTraineeAssignmentForm = () => {
    useTitle('NIOB Skill CaMP: New Assessor-Trainee Assignment')
    const isMobile = useMediaQuery("(max-width:600px)")
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const { data: usersData } = useGetUsersQuery()

    const { isCenter, firstName, lastName } = useAuth()

    const [addNewAssessorTraineeAssignment, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewAssessorTraineeAssignmentMutation()

    const navigate = useNavigate()

    const [assessor, setAssessor] = useState('')
    const [trainee, setTrainee] = useState('')

    useEffect(() => {
        if (isSuccess) {
            setAssessor('')
            setTrainee('')
            navigate('/dash')
        }
    }, [isSuccess, navigate])

    /*const onQualificationsChanged = e => {
        const values = Array.from(
            e.target.selectedOptions,
            option => option.value
        )
        setQualifications(values)
    }*/

    const canSave = [
        assessor,
        trainee
    ].every(Boolean) && !isLoading

    const onSaveAssignmentClicked = async e => {
        e.preventDefault()

        await addNewAssessorTraineeAssignment({
            assessor,
            trainee
        })

    }


    const options1 = usersData?.entities ? Object.entries(usersData.entities)
    .filter(([id, user]) => (user.centers.includes(firstName) && user.roles.includes("ASSESSOR")))
    .map(([id, user]) => (
        <MenuItem key={id} value={user.id}>{user.firstName.concat(" ", user.lastName)}</MenuItem>
    )) : null

    const options2 = usersData?.entities ? Object.entries(usersData.entities)
    .filter(([id, user]) => (user.centers.includes(firstName) && user.roles.includes("TRAINEE")))
    .map(([id, user]) => (
        <MenuItem key={id} value={user.id}>{user.firstName.concat(" ", user.lastName)}</MenuItem>
    )) : null

    const errContent = error?.data?.message ?? ''

    return (
      <>
      <Header title="NEW ASSESSOR-TRAINEE ASSIGNMENT" subtitle="Assign Assessor to Trainee" />
      <Box sx={{
        p: "2% 2% 5% 2%",
        m: isMobile ? "10% 2% 150px 2%" : "2% 2% 10% 2%",
        "& .MuiStack-root .MuiButton-outlined": {color:'#0b1c08', border:'1px solid #0b1c08'},
        "& .MuiStack-root .MuiButton-outlined:hover": {backgroundColor:'#7fd47010'}
      }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', width: "95%" }}>

              <Stack direction="row" gap={1} mt="20px" mb="20px">
                <Link to="/dash">
                  <Button variant="outlined" color="info" size="small" startIcon={<HomeIcon />} sx={{ mb: 0 }}>
                    Home
                  </Button>
                </Link>
                <Link to="/dash/assessor-trainee-assignments">
                  <Button variant="outlined" color="info" size="small" startIcon={<VisibilityIcon />} sx={{ mb: 0 }}>
                    View All Assessor-Trainee Assignments
                  </Button>
                </Link>
              </Stack>
          </Box>
          <Stack direction={isMobile ? "column" : "row"} gap={5}>
              <Box sx={{
                width: isMobile ? "95%" : '60%'
              }}>
                <form className="form" onSubmit={onSaveAssignmentClicked}>
                    <Box
                      display="grid"
                      gap="30px"
                      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                      sx={{
                        "& > div": { gridColumn: isMobile ? "span 4" : undefined },
                      }}
                    >
                        {/*Alert Messages to be displayed here*/}
                        {/*isError &&
                          <Collapse in={error} sx={{ width: "100%" }}>
                            <Alert
                              variant="outlined"
                              severity="error"
                              aria-live="assertive"
                              onClose={() => {setError(null)}}
                            >
                                {error}
                            </Alert>
                          </Collapse>*/}
                        {/*isSuccess &&
                          <Collapse in={successMsg} sx={{ width: "100%" }}>
                            <Alert
                              variant="outlined"
                              sx={{ color: "#00ffff" }}
                              severity="success"
                              aria-live="assertive"
                              onClose={() => {setSuccessMsg(null)}}
                            >
                                {successMsg}
                            </Alert>
                          </Collapse>*/}

                        <FormControl sx={{ gridColumn: 'span 2' }}>
                          <InputLabel id="assessor-id">Assessor</InputLabel>
                          <Select
                            fullWidth
                            labelId="assessor-id"
                            variant="outlined"
                            value={assessor}
                            name="assessor"
                            onChange={(e) => setAssessor(e.target.value)}
                          >
                            {options1}
                          </Select>
                        </FormControl>

                        <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
                          <InputLabel id="trainee-id">Trainee</InputLabel>
                          <Select
                            labelId="trainee-id"
                            variant="outlined"
                            value={trainee}
                            name="trainee"
                            onChange={(e) => setTrainee(e.target.value)}
                          >
                            {options2}
                          </Select>
                        </FormControl>
                    </Box>
                    <Box display="flex" mt="30px">
                      <LoadingButton type="submit" loading={isLoading} disabled={!canSave} sx={{color:"#fff", width:"50%"}} variant="contained">
                        Assign Assessor to Trainee
                      </LoadingButton>
                    </Box>
                </form>
            </Box>

            <Grid gap={4} sx={{ width: isMobile ? "95%" : "40%" }}>
              <Grid item xs={12} md={4}>
                <Card sx={{ bgcolor: colors.primary[400] }} >
                  <CardHeader
                    title={"BULK ASSIGN ASSESSORS TO TRAINEES"}
                    subheader={"UPLOAD .csv FILE"}
                  />
                  <CardContent>
                      <Typography variant="body2" color="text.secondary">
                        This impressive paella is a perfect party dish and a fun meal to cook
                        together with your guests. Add 1 cup of frozen peas along with the mussels,
                        if you like.
                      </Typography>
                  </CardContent>
                  <CardActions>
                      <Button component="label" variant="contained" startIcon={""}>
                          Upload file
                          <VisuallyHiddenInput type="file" />
                      </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Stack>
      </Box>
      </>
    )
}

export default NewAssessorTraineeAssignmentForm
