import { useReducer, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useUpdateUserMutation } from '../users/usersApiSlice'
import useAuth from '../../hooks/useAuth'

import LoadingButton from '@mui/lab/LoadingButton'
import { Box, TextField, Button, Stack, Collapse, Alert } from "@mui/material"
import HomeIcon from '@mui/icons-material/Home'
import useMediaQuery from "@mui/material/useMediaQuery"
import Header from "../../components/Header"

const PASSWORD_REGEX = /^[A-z,0-9]{8,20}$/

// Define action types
const SET_PASSWORD = 'SET_PASSWORD'
const SET_CONFIRM_PASSWORD = 'SET_CONFIRM_PASSWORD'
const SET_VALID_PASSWORD = 'SET_VALID_PASSWORD'
const SET_VALID_CONFIRM_PASSWORD = 'SET_VALID_CONFIRM_PASSWORD'

// Reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case SET_PASSWORD:
      return { ...state, password: action.payload }
    case SET_CONFIRM_PASSWORD:
      return { ...state, confirmPassword: action.payload }
    case SET_VALID_PASSWORD:
      return { ...state, validPassword: action.payload }
    case SET_VALID_CONFIRM_PASSWORD:
      return { ...state, validConfirmPassword: action.payload }
    default:
      return state
  }
};

const UpdatePassword = () => {
  const isMobile = useMediaQuery("(max-width:600px)")
  const navigate = useNavigate()
  const { id } = useAuth()
  const userId = id

  const [{ password, confirmPassword, validPassword, validConfirmPassword }, dispatch] = useReducer(reducer, {
    password: '',
    confirmPassword: '',
    validPassword: false,
    validConfirmPassword: false,
  })

  const [updateUser, {
      isLoading,
      isSuccess,
      isError,
      error
  }] = useUpdateUserMutation()

  const [alert, setAlert] = useState(null)

  useEffect(() => {
    dispatch({ type: SET_VALID_PASSWORD, payload: PASSWORD_REGEX.test(password) })
  }, [password])

  useEffect(() => {
    dispatch({ type: SET_VALID_CONFIRM_PASSWORD, payload: password === confirmPassword })
  }, [password, confirmPassword])

  useEffect(() => {
    if (isSuccess) {
      dispatch({ type: SET_PASSWORD, payload: '' })
      dispatch({ type: SET_CONFIRM_PASSWORD, payload: '' })
      navigate('/dash');
    }
  }, [isSuccess, navigate])

  const handlePwdChange = e => dispatch({ type: SET_PASSWORD, payload: e.target.value })
  const handleConfirmPwdChange = e => dispatch({ type: SET_CONFIRM_PASSWORD, payload: e.target.value })

  const onUpdatePasswordClicked = async (e) => {
      e.preventDefault();
      await updateUser({ id: userId, password })
  }

  const canSave = [validPassword, validConfirmPassword].every(Boolean) && !isLoading;

  const errContent = error?.data?.message ?? ''

  return (
    <>
    <Header title="UPDATE PASSWORD" subtitle="Change your Password" />

    <Box sx={{
      p: "2% 2% 5% 2%",
      "& .MuiStack-root .MuiButton-outlined": {color:'#0b1c08', border:'1px solid #0b1c08'},
      "& .MuiStack-root .MuiButton-outlined:hover": {backgroundColor:'#7fd47010'},
      height: "60vh"
    }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: "100%" }}>
            <Stack direction="row" gap={1} mt="20px" mb="20px">
              <Link to="/dash">
                <Button variant="outlined" color="info" size="small" startIcon={<HomeIcon />} sx={{ mb: 0 }}>
                  Home
                </Button>
              </Link>
            </Stack>
        </Box>

        <form onSubmit={onUpdatePasswordClicked}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isMobile ? "span 4" : undefined },
                width: "60%"
              }}
            >
              {/*Alert Messages to be displayed here*/}
              {errContent &&
              <Alert
                variant="outlined"
                sx={{ gridColumn: "span 4" }}
                severity="error"
                aria-live="assertive">
                  {errContent}
              </Alert>}

              <TextField
                fullWidth
                variant="outlined"
                type="password"
                label="Password"
                onChange={handlePwdChange}
                value={password}
                name="password"
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="outlined"
                type="password"
                label="Confirm Password"
                onChange={handleConfirmPwdChange}
                value={confirmPassword}
                name="confirmPassword"
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            <Box display="flex" mt="30px">
                <LoadingButton type="submit" loading={isLoading} disabled={!canSave} sx={{color:"#fff", width:"30%"}} fullWidth variant="contained">
                  <span>Update Password</span>
                </LoadingButton>
            </Box>
        </form>
    </Box>
    </>
  );
};

export default UpdatePassword
