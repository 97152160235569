import { useState, useEffect } from "react"
import { useAddNewCenterMutation } from "./centersApiSlice"
import { Link, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from "@fortawesome/free-solid-svg-icons"
import { STATES } from '../../config/states'
import { CENTERTYPES } from '../../config/centertypes'
import { useGetQualificationsQuery } from '../qualifications/qualificationsApiSlice'
import useTitle from '../../hooks/useTitle'

import LoadingButton from '@mui/lab/LoadingButton'
import { Box, useTheme, TextField, Typography, Stack, Button, FormControl, InputLabel, Select, MenuItem, Grid, Card, CardHeader, CardActions, CardContent } from "@mui/material"
import Alert from '@mui/material/Alert'
import HomeIcon from '@mui/icons-material/Home'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { styled } from '@mui/material/styles'
import { tokens } from "../../theme"
import useMediaQuery from "@mui/material/useMediaQuery"
import Header from "../../components/Header"
import Papa from 'papaparse'

const CENTER_CODE_REGEX = /^NB\/\d{2,3}[A-Z]{3}$/
const CENTER_REGEX = /^[a-zA-Z \.,()-]{8,50}$/
const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
const PHONE_REGEX = /^[0-9]{11}$/

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const NewCenterForm = () => {
    useTitle('NIOB Skill CaMP: New Centre')
    const isMobile = useMediaQuery("(max-width:600px)")
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const { data: qualificationsData } = useGetQualificationsQuery()

    const [addNewCenter, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewCenterMutation()

    const navigate = useNavigate()

    const [centerCode, setCenterCode] = useState('')
    const [validCenterCode, setValidCenterCode] = useState(false)
    const [centerName, setCenterName] = useState('')
    const [validCenterName, setValidCenterName] = useState(false)
    const [centerManager, setCenterManager] = useState('')
    const [validCenterManager, setValidCenterManager] = useState(false)
    const [email, setEmail] = useState('')
    const [validEmail, setValidEmail] = useState(false)
    const [stateOfRez, setStateOfRez] = useState('')
    const [centerType, setCenterType] = useState('')
    const [qualifications, setQualifications] = useState([])

    useEffect(() => {
        setValidCenterCode(CENTER_CODE_REGEX.test(centerCode))
    }, [centerCode])

    useEffect(() => {
        setValidCenterName(CENTER_REGEX.test(centerName))
    }, [centerName])

    useEffect(() => {
        setValidCenterManager(CENTER_REGEX.test(centerManager))
    }, [centerManager])

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email))
    }, [email])

    useEffect(() => {
        if (isSuccess) {
            setCenterCode('')
            setCenterName('')
            setCenterManager('')
            setEmail('')
            setStateOfRez('')
            setCenterType('')
            setQualifications([])
            navigate('/dash/centers')
        }
    }, [isSuccess, navigate])

    const onCenterCodeChanged = e => setCenterCode(e.target.value)
    const onCenterNameChanged = e => setCenterName(e.target.value)
    const onCenterManagerChanged = e => setCenterManager(e.target.value)
    const onEmailChanged = e => setEmail(e.target.value)
    const onCenterTypeChanged = e => setCenterType(e.target.value)
    const onStateOfRezChanged = e => setStateOfRez(e.target.value)

    /*const onQualificationsChanged = e => {
        const values = Array.from(
            e.target.selectedOptions,
            option => option.value
        )
        setQualifications(values)
    }*/

    const canSave = [
        centerType,
        stateOfRez,
        qualifications.length,
        validCenterName,
        validCenterManager,
        validEmail,
        validCenterCode
    ].every(Boolean) && !isLoading

    const onSaveCenterClicked = async e => {
        await addNewCenter({
            centerCode,
            centerName,
            centerManager,
            email,
            centerType,
            stateOfRez,
            qualifications,
            'verified': false
        });
    }

    const [file, setFile] = useState(null)
    const [data, setData] = useState()

    const handleBulkUploadFileChange = (event) => {
      const selectedFile = event.target.files[0]
      setFile(selectedFile)
    }

    const handleBulkUpload = async (event) => {
      const file = event.target.files[0]
      if (!file) {
        console.log("No file selected")
      } else {
        Papa.parse(file, {
          header: true,
          worker: true,
          skipEmptyLines: true,
          complete: async function (result) {
            try {
              result.data.map( async (row) => {
                // Split qualifications string into an array
                const qualificationsArray = row.qualifications ? row.qualifications.split(',') : []

                // Get the id of each qualification
                const qualification_ids = qualificationsArray
                  .map(qualification => qualification.trim()) // Trim any extra whitespace
                  .map(qualification => {
                    // Find the corresponding qualification object in qualificationData.entities
                    const qualificationObj = Object.values(qualificationsData.entities).find(
                      entity => entity.qualificationType === qualification
                    )
                    return qualificationObj ? qualificationObj.id : null // Return the qualification id or null if not found
                  })
                  .filter(qualificationId => qualificationId !== null) // Remove any null values

                const response = await addNewUser({
                    "centerCode":row.centerCode,
                    "centerName":row.centerName,
                    "email":row.email,
                    "centerManager":row.centerManager,
                    "stateOfRez":row.stateOfRez.toUpperCase(),
                    "centerType":row.centerType,
                    "qualifications":qualification_ids
                })

                console.log("RESPONSE: ",response)
              })

            } catch (err) {
              // Handle error response from the server
              console.error('File upload failed:', err.message)
              if (!err?.response) {
                setErrMsg2('No Server Response')
              } else if (err.response?.status === 400) {
                setErrMsg2(err.response?.data.data)
              } else if (err.response?.status === 401) {
                setErrMsg2(err.response?.data.data)
              } else {
                setErrMsg2(err.response?.data.data)
              }
              errRef2.current.focus()
            }
          }
        })
      }
    }


    const options1 = Object.values(STATES).map(_state => (
        <MenuItem key={_state} value={_state}>{_state}</MenuItem>
    ))

    const options2 = Object.values(CENTERTYPES).map(centerType => (
        <MenuItem key={centerType} value={centerType}>
            {centerType}
        </MenuItem>
    ))

    const options3 = qualificationsData?.entities ? Object.entries(qualificationsData.entities).map(([id, qualification]) => (
        <MenuItem key={id} value={id}>{qualification.qualificationType}</MenuItem>
    )) : null

    const errContent = error?.data?.message ?? ''

    return (
      <>
      <Header title="NEW CENTRE" subtitle="Register a New Centre" />
      <Box sx={{
        p: "2% 2% 5% 2%",
        m: isMobile ? "10% 2% 150px 2%" : "2% 2% 10% 2%",
        "& .MuiStack-root .MuiButton-outlined": {color:'#0b1c08', border:'1px solid #0b1c08'},
        "& .MuiStack-root .MuiButton-outlined:hover": {backgroundColor:'#7fd47010'}
      }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', width: "95%" }}>

              <Stack direction="row" gap={1} mt="20px" mb="20px">
                <Link to="/dash">
                  <Button variant="outlined" color="info" size="small" startIcon={<HomeIcon />} sx={{ mb: 0 }}>
                    Home
                  </Button>
                </Link>
                <Link to="/dash/centers">
                  <Button variant="outlined" color="info" size="small" startIcon={<VisibilityIcon />} sx={{ mb: 0 }}>
                    View All Centres
                  </Button>
                </Link>
              </Stack>
          </Box>
          <Stack direction={isMobile ? "column" : "row"} gap={5}>
              <Box sx={{
                width: isMobile ? "95%" : '60%'
              }}>
                <form className="form" onSubmit={onSaveCenterClicked}>
                    <Box
                      display="grid"
                      gap="30px"
                      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                      sx={{
                        "& > div": { gridColumn: isMobile ? "span 4" : undefined },
                      }}
                    >
                        {/*Alert Messages to be displayed here*/}
                        {/*errMsg ?
                          <Alert ref={errRef}
                            variant="outlined"
                            sx={{ gridColumn: "span 4" }}
                            severity="error"
                            aria-live="assertive">
                            {errMsg}
                          </Alert> : ""*/}
                        {/*successMsg ?
                          <Alert ref={successRef}
                            variant="outlined"
                            sx={{ gridColumn: "span 4" }}
                            severity="success"
                            aria-live="assertive">
                              {successMsg}
                          </Alert> : ""*/}

                        <TextField
                          fullWidth
                          variant="outlined"
                          type="text"
                          label="Centre Code"
                          onChange={(e) => setCenterCode(e.target.value)}
                          value={centerCode}
                          name="centerCode"
                          sx={{ gridColumn: "span 4" }}
                        />

                        <TextField
                          fullWidth
                          variant="outlined"
                          type="text"
                          label="Centre Name"
                          onChange={onCenterNameChanged}
                          value={centerName}
                          name="centerName"
                          sx={{ gridColumn: "span 2" }}
                        />

                        <TextField
                          fullWidth
                          variant="outlined"
                          type="text"
                          label="Centre Manager"
                          onChange={onCenterManagerChanged}
                          value={centerManager}
                          name="centerManager"
                          sx={{ gridColumn: "span 2" }}
                        />

                        <TextField
                          fullWidth
                          variant="outlined"
                          type="email"
                          label="Email"
                          onChange={onEmailChanged}
                          value={email}
                          name="email"
                          sx={{ gridColumn: "span 4" }}
                        />

                        <FormControl sx={{ gridColumn: 'span 2' }}>
                          <InputLabel id="state-id">State</InputLabel>
                          <Select
                            fullWidth
                            labelId="state-id"
                            variant="outlined"
                            value={stateOfRez}
                            name="stateOfRez"
                            onChange={(e) => setStateOfRez(e.target.value)}
                          >
                            {options1}
                          </Select>
                        </FormControl>

                        <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
                          <InputLabel id="center-type-id">Centre Type</InputLabel>
                          <Select
                            labelId="center-type-id"
                            variant="outlined"
                            value={centerType}
                            name="centerType"
                            onChange={(e) => setCenterType(e.target.value)}
                          >
                            {options2}
                          </Select>
                        </FormControl>

                        <FormControl fullWidth sx={{ gridColumn: 'span 4' }}>
                          <InputLabel id="qualifications-id">Qualifications</InputLabel>
                          <Select
                            labelId="qualifications-id"
                            variant="outlined"
                            name="qualifications"
                            value={qualifications}
                            onChange={(e) => setQualifications(e.target.value)}
                            multiple
                          >
                            {options3}
                          </Select>
                        </FormControl>
                    </Box>
                    <Box display="flex" mt="30px">
                      <LoadingButton type="submit" loading={isLoading} disabled={!canSave} sx={{color:"#fff", width:"50%"}} variant="contained">
                        Add New Centre
                      </LoadingButton>
                    </Box>
                </form>
            </Box>

            <Grid gap={4} sx={{ width: isMobile ? "95%" : "40%" }}>
              <Grid item xs={12} md={4}>
                <Card sx={{ bgcolor: colors.primary[400] }} >
                  <CardHeader
                    title={"BULK REGISTER CENTRES"}
                    subheader={"UPLOAD .csv FILE"}
                  />
                  <CardContent>
                      <Typography variant="body2" color="text.secondary">
                        Register 2 or more Centres at once by filling and uploading <a
                          href="/bulk_centres.csv" // Adjusted path
                          style={{ color: "#49a139", textDecoration: "underline" }}
                          target="_blank"
                          download="bulk_centres.csv" // Added download attribute
                        >
                          <u>this .csv file</u>
                        </a>.
                      </Typography>
                  </CardContent>
                  <CardActions>
                      <Button component="label" variant="contained" startIcon={""}>
                          Upload file
                          <VisuallyHiddenInput
                            type="file"
                            name="file"
                            accept=".csv"
                            onChange={(event) => {
                              handleBulkUploadFileChange(event)
                              handleBulkUpload(event)
                            }}
                          />
                      </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Stack>
      </Box>
      </>
    )
}

export default NewCenterForm
