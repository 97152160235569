import { useUpdateCenterApplicationMutation } from "./centerapplicationApiSlice"
import { useState, useEffect } from "react"
import { useParams, Link, useNavigate } from 'react-router-dom'
import { useGetCenterApplicationsQuery } from './centerapplicationApiSlice'
import { useAddNewCenterMutation, useGetCentersQuery } from "../centers/centersApiSlice"
import { useGetUsersQuery } from '../users/usersApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'
import axios from 'axios'
import { saveAs } from 'file-saver'

import * as React from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, useTheme, TextField, Stack, Button, FormControl, InputLabel, Select, MenuItem, List, ListItem, ListItemText, ListSubheader,
  FormGroup, FormControlLabel, Typography, Divider, Grid, ListItemAvatar, Avatar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Modal } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home'
import VisibilityIcon from '@mui/icons-material/Visibility'
import PersonIcon from '@mui/icons-material/Person'
import { tokens } from "../../theme"
import useMediaQuery from "@mui/material/useMediaQuery"
import Header from "../../components/Header"
import { STATECODES } from "../../config/statecodes"
import { QUALIFICATIONS } from "../../config/qualifications"


const AssignCenter = () => {
    useTitle('NIOB Skill CaMP: Assign Center to EQA')
    const isMobile = useMediaQuery("(max-width:600px)")
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const navigate = useNavigate()
    const { id } = useParams()

    const { centerapplication } = useGetCenterApplicationsQuery("centerapplicationsList", {
        selectFromResult: ({ data }) => ({
            centerapplication: data?.entities[id]
        }),
    })

    const { data: usersData } = useGetUsersQuery()

    const [updateCenterApplication, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateCenterApplicationMutation()

    const [eqa, setEQA] = useState('')

    useEffect(() => {
        if (isSuccess) {
            setEQA('')
            navigate('/dash/center-application')
        }
    })

    if (!centerapplication) return <PulseLoader color={"#0b1c08"} className="center" />

    const options = usersData?.entities ? Object.entries(usersData.entities)
    .filter(([id, user]) => user.roles.includes("EXTERNAL VERIFIER"))
    .map(([id, user]) => (
        <MenuItem key={id} value={user.id}>{user.firstName.concat(" ", user.lastName)}</MenuItem>
    )) : null

    const canSave = [
        eqa.length
    ].every(Boolean) && !isLoading

    const onAssignCenterApplicationClicked = async e => {
        e.preventDefault()

        await updateCenterApplication({
            id: centerapplication.id, eqa
        })
    }

    //const content = <EditCenterForm center={center} />
    const content = (
      <>
      <Header title="EQA ASSIGNMENT" subtitle={"Assigning EQA for Centre Quality Assurance"} />
      <Box sx={{
          m: isMobile ? "10% 8% 2% 8%" : "2% 2% 50px 2%",
          "& .MuiStack-root .MuiButton-outlined": {color:'#0b1c08', border:'1px solid #0b1c08'},
          "& .MuiStack-root .MuiButton-outlined:hover": {backgroundColor:'#7fd47010'}
        }}>
            <Box mb="100px">

                <Stack direction="row" gap={1} mt="20px" mb="10px">
                  <Link to="/dash">
                    <Button variant="outlined" color="info" size="small" startIcon={<HomeIcon />} sx={{ mb: 0 }}>
                      Home
                    </Button>
                  </Link>
                  <Link to="/dash/center-application">
                    <Button variant="outlined" color="info" size="small" startIcon={<VisibilityIcon />} sx={{ mb: 0 }}>
                      View All Centre Applications
                    </Button>
                  </Link>
                </Stack>
                <Stack direction={isMobile ? "column" : "row"} gap={5}>
                    <Box sx={{
                      width:"70%",
                      "& .MuiStack-root .MuiListSubheader-root": {
                          backgroundColor: colors.primary[400],
                          color: colors.greenAccent[400],
                          position: 'static',
                          fontSize: 15
                      },
                      "& .MuiListItemText-root": {
                          minWidth: '250px'
                      }
                    }}>
                        <Grid sx={{ bgcolor: colors.primary[400] }} >
                            <List sx={{ width: '100%' }}>
                              <ListItem sx={{ mb:1, mt:1 }}>
                                { isMobile ?
                                <ListItemText
                                    sx={{ maxWidth: "300px" }}
                                    primary={
                                      <React.Fragment>
                                        <Typography
                                          variant="h6"
                                          sx={{ fontSize: "10px" }}
                                          color={colors.greenAccent[400]}
                                        >
                                          CENTRE NAME:
                                        </Typography>
                                        <Typography
                                          variant="h4"
                                          sx={{color:"#fff"}}
                                        >
                                          {centerapplication.centerName}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                />  :
                                <>
                                <ListItemText
                                  sx={{ maxWidth: "250px" }}
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        variant={ "h5" }
                                        color={colors.greenAccent[400]}
                                      >
                                        CENTRE NAME:
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                                <ListItemText
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        variant={ "h6" }
                                        align="left"
                                        sx={{color:"#fff"}}
                                      >
                                        {centerapplication.centerName}
                                      </Typography>
                                    </React.Fragment>
                                  }
                                /></> }
                              </ListItem>

                              <Divider variant="middle" component="li" />

                              <ListItem sx={{ mb:1, mt:1 }}>
                                { isMobile ?
                                <ListItemText
                                    sx={{ maxWidth: "300px" }}
                                    primary={
                                      <React.Fragment>
                                        <Typography
                                          variant="h6"
                                          sx={{ fontSize: "10px" }}
                                          color={colors.greenAccent[400]}
                                        >
                                          CENTRE EMAIL:
                                        </Typography>
                                        <Typography
                                          variant="h4"
                                          sx={{color:"#fff"}}
                                        >
                                          {centerapplication.email}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                />  :
                                <>
                                <ListItemText
                                  sx={{ maxWidth: "250px" }}
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        variant={ "h5" }
                                        color={colors.greenAccent[400]}
                                      >
                                        CENTRE EMAIL:
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                                <ListItemText
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        variant={ "h6" }
                                        sx={{color:"#fff"}}
                                      >
                                        {centerapplication.email}
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                                </> }
                              </ListItem>

                              <Divider variant="middle" component="li" />

                              <ListItem sx={{ mb:1, mt:1 }}>
                                { isMobile ?
                                <ListItemText
                                    sx={{ maxWidth: "300px" }}
                                    primary={
                                      <React.Fragment>
                                        <Typography
                                          variant="h6"
                                          sx={{ fontSize: "10px" }}
                                          color={colors.greenAccent[400]}
                                        >
                                          CENTRE TYPE:
                                        </Typography>
                                        <Typography
                                          variant="h4"
                                          sx={{color:"#fff"}}

                                        >
                                          {centerapplication.centerType}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                />  :
                                <>
                                <ListItemText
                                  sx={{ maxWidth: "250px" }}
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        variant={ "h5" }
                                        color={colors.greenAccent[400]}
                                      >
                                        CENTRE TYPE:
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                                <ListItemText
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        variant={ "h6" }
                                        sx={{color:"#fff"}}

                                      >
                                        {centerapplication.centerType}
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                                </> }
                              </ListItem>

                              <Divider variant="middle" component="li" />

                              <ListItem sx={{ mb:1, mt:1 }}>
                                { isMobile ?
                                <ListItemText
                                    sx={{ maxWidth: "300px" }}
                                    primary={
                                      <React.Fragment>
                                        <Typography
                                          variant="h6"
                                          sx={{ fontSize: "10px" }}
                                          color={colors.greenAccent[400]}
                                        >
                                          CENTRE ADDRESS:
                                        </Typography>
                                        <Typography
                                          variant="h4"
                                          sx={{color:"#fff"}}
                                        >
                                          {centerapplication.permanentCenterAddress}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                />  :
                                <>
                                <ListItemText
                                  sx={{ maxWidth: "250px" }}
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        variant={ "h5" }
                                        color={colors.greenAccent[400]}
                                      >
                                        CENTRE ADDRESS:
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                                <ListItemText
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        variant={ "h6" }
                                        sx={{color:"#fff"}}
                                      >
                                        {centerapplication.permanentCenterAddress}
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                                </> }
                              </ListItem>

                              <Divider variant="middle" component="li" />

                              <ListItem sx={{ mb:1, mt:1 }}>
                                { isMobile ?
                                <ListItemText
                                    sx={{ maxWidth: "300px" }}
                                    primary={
                                      <React.Fragment>
                                        <Typography
                                          variant="h6"
                                          sx={{ fontSize: "10px" }}
                                          color={colors.greenAccent[400]}
                                        >
                                          STATE:
                                        </Typography>
                                        <Typography
                                          variant="h4"
                                          sx={{color:"#fff"}}
                                        >
                                          {centerapplication.stateOfRez}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                />  :
                                <>
                                <ListItemText
                                  sx={{ maxWidth: "250px" }}
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        variant={ "h5" }
                                        color={colors.greenAccent[400]}
                                      >
                                        STATE:
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                                <ListItemText
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        variant={ "h6" }
                                        sx={{color:"#fff"}}
                                      >
                                        {centerapplication.stateOfRez}
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                                </> }
                              </ListItem>
                            </List>
                        </Grid>
                    </Box>
                    <Box sx={{
                      width:"70%"
                    }}>
                        <form onSubmit={onAssignCenterApplicationClicked}>
                            <FormControl fullWidth sx={{ gridColumn: 'span 3', mb:'20px' }}>
                              <InputLabel id="eqa-id">Select EQA for Assignment</InputLabel>
                              <Select
                                labelId="eqa-id"
                                variant="outlined"
                                name="eqa"
                                value={eqa}
                                onChange={(e) => setEQA(e.target.value)}
                              >
                                {options}
                              </Select>
                            </FormControl>

                            <LoadingButton
                                type="submit"
                                loading={isLoading}
                                disabled={!canSave}
                                variant="contained"
                                sx={{width:isMobile ? "100%" : "50%", color:"#fff"}}
                            >
                              Submit EQA Assignment
                            </LoadingButton>
                        </form>
                    </Box>
                </Stack>
            </Box>
      </Box>
      </>
    )

    return content
}


function createData(unitType, quantity, floorArea) {
  return { unitType, quantity, floorArea }
}


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: '#efefef',
  border: '2px solid #efefef',
  boxShadow: 24,
  p: 4,
}

const generateCenterCode = (count, stateOfRez, centerName) => {
    const stateTrim = STATECODES[stateOfRez]
    const centerNameTrim = centerName.charAt(0).toUpperCase()

    return `NB/${count}${centerNameTrim}${stateTrim}`
}

const ViewCenterApplication = () => {
    useTitle('NIOB Skill CaMP: View CENTRE Application')
    const isMobile = useMediaQuery("(max-width:600px)")
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const navigate = useNavigate()
    const { id } = useParams()
    const [open, setOpen] = React.useState(false)
    const [open2, setOpen2] = React.useState(false)
    const [emailMessage, setEmailMessage] = useState("")


    const { data: centers } = useGetCentersQuery()

    const { centerapplication } = useGetCenterApplicationsQuery("centerapplicationsList", {
        selectFromResult: ({ data }) => ({
            centerapplication: data?.entities[id]
        }),
    })

    const [addNewCenter, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewCenterMutation()

    const [ applicationApproval, setApplicationApproval ] = useState(false)

    if (!centerapplication) return <PulseLoader color={"#0b1c08"} className="center" />

    const rows = [
        createData('Classrooms/Training Rooms', centerapplication.trainingRoomQuantity, centerapplication.trainingRoomFloorArea),
        createData('Workshops', centerapplication.workshopQuantity, centerapplication.workshopFloorArea),
        createData('Practical Training Space', centerapplication.practicalTrainingSpaceQuantity, centerapplication.practicalTrainingSpaceFloorArea),
        createData('Technical Laboratories', centerapplication.technicalLabQuantity, centerapplication.technicalLabFloorArea)
    ]

    const handleDownload = (centerId) => {
        axios({
            //url: `http://localhost:3500/download/${centerId}`,
            url: `https://niob-skill-camp-api.onrender.com/download/${centerId}`, // Server endpoint
            method: 'GET',
            responseType: 'blob', // Important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${centerapplication.centerName}'s credentials.zip`) // Filename
            document.body.appendChild(link)
            link.click()
            link.remove()
        }).catch((error) => {
            console.error('Error downloading file:', error)
        })
    }

    //handle EQA Centre Visit report download
    const handleDownloadReport = (centerId) => {
        axios({
            //url: `http://localhost:3500/download-eqareport/${centerId}`,
            url: `https://niob-skill-camp-api.onrender.com/download-eqareport/${centerId}`, // Server endpoint
            method: 'GET',
            responseType: 'blob', // Important
        }).then((response) => {
            const contentDisposition = response.headers['content-disposition']
            let filename = `${centerapplication.centerName}'s EQA Centre Visit Report`

            if (contentDisposition) {
                const filenameMatch = contentDisposition.match(/filename="(.+)"/)
                if (filenameMatch.length === 2) {
                    filename = filenameMatch[1]
                }
            } else {
                // Fallback: infer the file extension from the Content-Type
                const contentType = response.headers['content-type']
                if (contentType === 'application/pdf') {
                    filename += '.pdf'
                } else if (contentType === 'application/zip') {
                    filename += '.zip'
                }
            }

            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', filename) // Filename with extension
            document.body.appendChild(link)
            link.click()
            link.remove()
        }).catch((error) => {
            console.error('Error downloading file:', error)
        })
    }


    /*const handlePDFView = () => {
        axios.post('https://niob-skill-camp-api.onrender.com/create-pdf', centerapplication)
        //axios.post('http://localhost:3500/create-pdf', centerapplication)
            .then(() => axios.get('https://niob-skill-camp-api.onrender.com/fetch-pdf', { responseType: 'blob' }))
            //.then(() => axios.get('http://localhost:3500/fetch-pdf', { responseType: 'blob' }))
            .then((res) => {
                const pdfBlob = new Blob([res.data], { type: 'application/pdf' })
                saveAs(pdfBlob, `${centerapplication.centerName}_Center_Application.pdf`)
            })
            .catch((error) => {
                console.error('Error generating or downloading PDF:', error)
            })
    }*/

    const handlePDFView = () => {
        //axios.post('http://localhost:3500/create-pdf', centerapplication)
        axios.post('https://niob-skill-camp-api.onrender.com/create-pdf', centerapplication)
            //.then(() => axios.get('http://localhost:3500/fetch-pdf', { responseType: 'blob' }))
            .then(() => axios.get('https://niob-skill-camp-api.onrender.com/fetch-pdf', { responseType: 'blob' }))
            .then((res) => {
                const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
                saveAs(pdfBlob, `${centerapplication.centerName}_Center_Application.pdf`);
            })
            .catch((error) => {
                console.error('Error generating or downloading PDF:', error);
            });
    }


    const handleApplicationApproval = async (e) => {
        e.preventDefault()

        const qualification_ids = centerapplication.qualifications
          .map(qualification => qualification.trim()) // Trim any extra whitespace
          .map(qualification => {
            // Find the corresponding qualification object in QUALIFICATIONS
            const qualificationObj = Object.entries(QUALIFICATIONS).find(
              ([key, value]) => value === qualification // Compare the value part of the entry
            )
            return qualificationObj ? qualificationObj[0] : null // Return the qualification id or null if not found
          })
          .filter(qualificationId => qualificationId !== null) // Remove any null values

        try {
            const response = await axios({
                //url: `http://localhost:3500/approve/${centerapplication.id}`,
                url: `https://niob-skill-camp-api.onrender.com/approve/${centerapplication.id}`, // Server endpoint
                method: 'PUT',
                responseType: 'json', // Important
            })

            if (response.data.status === 201) {
                // Get the Max Count for Centers
                // Function to extract numbers from matching center codes and find the maximum number
                const pattern = /^NB\/(\d+)(\w+)$/ // Regular expression to match the pattern and capture the number
                let maxNumber = -Infinity

                centers.ids.forEach((id) => {
                    const centerCode = centers.entities[id].centerCode
                    const match = centerCode.match(pattern)
                    if (match) {
                        const number = parseInt(match[1], 10)
                        if (number > maxNumber) {
                            maxNumber = number
                        }
                    }
                })

                let result = maxNumber === -Infinity ? null : maxNumber

                // Generate Center Code
                const centerCode = generateCenterCode(result+1, centerapplication.stateOfRez, centerapplication.centerName)

                const centerCreated = await addNewCenter({
                    centerCode,
                    "centerName": centerapplication.centerName,
                    "centerManager": centerapplication.centerManagerName,
                    "email": centerapplication.email,
                    "centerType": centerapplication.centerType,
                    "stateOfRez": centerapplication.stateOfRez,
                    "qualifications": qualification_ids,
                    "verified": true
                })

                if (centerCreated) {
                    setApplicationApproval(true)
                    alert(response.data.message)
                }
            } else {
                alert("Centre Application approval isn't complete!")
            }
        } catch (error) {
            console.error('Error completing Application Approval:', error)
        }
    }

    const handleApplicationDisapproval = async e => {
          e.preventDefault()
          //axios.put(`http://localhost:3500/disapprove/${centerapplication.id}`, {
          axios.put(`https://niob-skill-camp-api.onrender.com/disapprove/${centerapplication.id}`, {
              emailTo: centerapplication.email,
              subject: "Your Centre Application turned down!",
              message: ` <h4>Hello ${centerapplication.centerName},</h4>
              <p><i>Unfortunately, this message is coming to you because your Centre Application did not meet the requirements.</i></p>
              <p>You can fill and submit another application.</p>
              <p>Below is additional message from the Board to you:</p>
              <p>${emailMessage}</p>`
          }).then((response) => {
              if (response.data.status === 201) {
                setApplicationApproval(false)
                alert(response.data.message)
                setTimeout(() => navigate("/dash/center-application", { replace: true }), 7000)
              } else {
                alert("Centre Application disapproval isn't complete!")
              }
          }).catch((error) => {
              console.error('Error completing Application Disapproval:', error)
          })
    }

    //For Email Message
    const handleOpenModal = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const handleOpenModal2 = () => setOpen2(true)
    const handleClose2 = () => setOpen2(false)

    const onEmailMessageChanged = e => setEmailMessage(e.target.value)

    const onSendEmailClicked = async e => {
        e.preventDefault()

        axios.put(`https://niob-skill-camp-api.onrender.com/sendEmail/${centerapplication.id}`, {
        //axios.put(`http://localhost:3500/sendEmail/${centerapplication.id}`, {
              emailTo: centerapplication.email,
              subject: "Need to Update Your Centre Application",
              message: ` <h4>Hello ${centerapplication.centerName},</h4>
              <p><i>This message is coming to you because you need to review your Centre Application.</i></p>
              <p>In order not to have your application turned down, please <a href="https://skillcamp.niobnat.org/center-application/edit/${id}">click this link</a> to make necessary updates.</p>
              <p>Below is additional message from the Board to you:</p>
              <p>${emailMessage}</p>`
        }).then((response) => {
            if (response.data.status === 201) {
              alert(JSON.stringify(response.data.message))
              setTimeout(() => navigate("/dash/center-application", { replace: true }), 7000)
            } else {
              alert("Email to Centre not Sent!")
            }

        }).catch((error) => {
            console.error('Error sending email to Centre:', error)
        })
    }


    //const content = <EditCenterForm center={center} />
    const content = (
      <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="email-to-center"
        aria-describedby="email to center to edit their application"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            EMAIL TO CENTRE TO EDIT THEIR APPLICATION
          </Typography>
          <form className="form" onSubmit={onSendEmailClicked}>
            <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, color: "#0b1c08a1" }}>
              Type a short message to inform this Centre about the fields that need to be updated.
            </Typography>
            <TextField
              variant="outlined"
              multiline
              rows={5}
              type="text"
              label={<span>Email Message <span style={{ color: 'red' }}>*</span></span>}
              onChange={onEmailMessageChanged}
              value={emailMessage}
              name="emailMessage"
              sx={{ width: "100%", mb:"10px" }}
            />
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{width:isMobile ? "100%" : "70%", color:"#fff"}}
            >
              SEND EMAIL
            </LoadingButton>
          </form>
        </Box>
      </Modal>

      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="feedback-to-center"
        aria-describedby="feedback to center about why their application has been turned down."
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            FEEDBACK TO CENTRE ABOUT THEIR APPLICATION
          </Typography>
          <form className="form" onSubmit={handleApplicationDisapproval}>
            <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, color: "#0b1c08a1" }}>
              Type a short message to inform this Centre about why their application has been turned down.
            </Typography>
            <TextField
              variant="outlined"
              multiline
              rows={5}
              type="text"
              label={<span>Email Message <span style={{ color: 'red' }}>*</span></span>}
              onChange={onEmailMessageChanged}
              value={emailMessage}
              name="emailMessage"
              sx={{ width: "100%", mb:"10px" }}
            />
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{width:isMobile ? "100%" : "70%", color:"#fff"}}
            >
              SEND FEEDBACK
            </LoadingButton>
          </form>
        </Box>
      </Modal>

      <Header title="CENTRE APPLICATION" subtitle={`Centre Application for - ${centerapplication.centerName.toUpperCase()}`} />
      <Box sx={{
          "& .MuiStack-root .MuiButton-outlined": {color:'#0b1c08', border:'1px solid #0b1c08'},
          "& .MuiStack-root .MuiButton-outlined:hover": {backgroundColor:'#7fd47010'},
          p: "2% 2% 5% 2%",
          mb: "50px !important"
        }}>

                <Stack direction={isMobile ? "column" : "row"} gap={1} mt="20px" mb="10px">
                  <Link to="/dash">
                    <Button variant="outlined" color="info" size="small" startIcon={<HomeIcon />} sx={{ mb: 0 }}>
                      Home
                    </Button>
                  </Link>
                  <Link to="/dash/center-application">
                    <Button variant="outlined" color="info" size="small" startIcon={<VisibilityIcon />} sx={{ mb: 0 }}>
                      View All Centre Applications
                    </Button>
                  </Link>

                  <Button onClick={() => handleDownload(centerapplication.id)} variant='contained' color="info" size="small" download>
                      Download Centre Credentials
                  </Button>

                  <Button onClick={() => handleDownloadReport(centerapplication.id)} variant='contained' color="info" size="small" download>
                      Download EQA Centre Visit Report
                  </Button>

                  <Button onClick={(event) => handlePDFView(event, centerapplication.id)} variant='contained' size="small" disabled={centerapplication.eqaCenterReportComplete ? false : true}>
                      Generate Centre Application Report
                  </Button>
                </Stack>

                <Stack direction={isMobile ? "column" : "row"} gap={1} mt="10px" mb="10px">
                  <Button
                      onClick={() => handleOpenModal(centerapplication.id)}
                      variant='contained' size="small" color="info"
                      disabled={(!centerapplication.eqaCenterReportComplete && !applicationApproval) ? false : true}
                  >
                      EMAIL CENTRE TO UPDATE APPLICATION
                  </Button>

                  <Button
                      onClick={() => handleApplicationApproval(event)}
                      variant='contained' size="small" color="success"
                      disabled={(centerapplication.eqaCenterReportComplete && !applicationApproval) ? false : true}
                  >
                      APPROVE APPLICATION
                  </Button>

                  <Button
                      onClick={() => handleOpenModal2(centerapplication.id)}
                      variant='contained' size="small" color="error"
                      disabled={centerapplication.eqaCenterReportComplete ? false : true}
                  >
                      DISAPPROVE APPLICATION
                  </Button>
                </Stack>
                <Stack direction={isMobile ? "column" : "row"} gap={5}>
                    <Box sx={{
                      width:isMobile ? "100%" : "60%",
                      "& .MuiStack-root .MuiListSubheader-root": {
                          backgroundColor: colors.primary[400],
                          color: colors.greenAccent[400],
                          position: 'static',
                          fontSize: 15
                      },
                      "& .MuiListItemText-root": {
                          minWidth: '250px'
                      }
                    }}>
                        <Grid sx={{ bgcolor: colors.primary[400] }} >
                            <List
                                sx={{ width: '100%' }}
                                aria-labelledby="center-details"
                                subheader={
                                  <ListSubheader component="div" id="center-details">
                                    CENTRE DETAILS
                                  </ListSubheader>
                                }
                            >
                              <ListItem sx={{ mb:1, mt:1 }}>
                                { isMobile ?
                                <ListItemText
                                    sx={{ maxWidth: "300px" }}
                                    primary={
                                      <React.Fragment>
                                        <Typography
                                          variant="h6"
                                          sx={{ fontSize: "10px" }}
                                          color={colors.greenAccent[400]}
                                        >
                                          CENTRE NAME:
                                        </Typography>
                                        <Typography
                                          variant="h4"
                                          sx={{color:"#fff"}}
                                        >
                                          {centerapplication.centerName}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                />  :
                                <>
                                <ListItemText
                                  sx={{ maxWidth: "250px" }}
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        variant={ "h5" }
                                        color={colors.greenAccent[400]}
                                      >
                                        CENTRE NAME:
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                                <ListItemText
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        variant={ "h6" }
                                        align="left"
                                        sx={{color:"#fff"}}
                                      >
                                        {centerapplication.centerName}
                                      </Typography>
                                    </React.Fragment>
                                  }
                                /></> }
                              </ListItem>

                              <Divider variant="middle" component="li" />

                              <ListItem sx={{ mb:1, mt:1 }}>
                                { isMobile ?
                                <ListItemText
                                    sx={{ maxWidth: "300px" }}
                                    primary={
                                      <React.Fragment>
                                        <Typography
                                          variant="h6"
                                          sx={{ fontSize: "10px" }}
                                          color={colors.greenAccent[400]}
                                        >
                                          YEAR OF INCORPORATION:
                                        </Typography>
                                        <Typography
                                          variant="h4"
                                          sx={{color:"#fff"}}
                                        >
                                          {centerapplication.yearOfInc}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                />  :
                                <>
                                <ListItemText
                                  sx={{ maxWidth: "250px" }}
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        variant={ "h5" }
                                        color={colors.greenAccent[400]}
                                      >
                                        YEAR OF INCORPORATION:
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                                <ListItemText
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        variant={ "h6" }
                                        align="left"
                                        sx={{color:"#fff"}}
                                      >
                                        {centerapplication.yearOfInc}
                                      </Typography>
                                    </React.Fragment>
                                  }
                                /></> }
                              </ListItem>

                              <Divider variant="middle" component="li" />

                              <ListItem sx={{ mb:1, mt:1 }}>
                                { isMobile ?
                                <ListItemText
                                    sx={{ maxWidth: "300px" }}
                                    primary={
                                      <React.Fragment>
                                        <Typography
                                          variant="h6"
                                          sx={{ fontSize: "10px" }}
                                          color={colors.greenAccent[400]}
                                        >
                                          CAC NUMBER:
                                        </Typography>
                                        <Typography
                                          variant="h4"
                                          sx={{color:"#fff"}}
                                        >
                                          {`RC ${centerapplication.cacNumber}`}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                />  :
                                <>
                                <ListItemText
                                  sx={{ maxWidth: "250px" }}
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        variant={ "h5" }
                                        color={colors.greenAccent[400]}
                                      >
                                        CAC NUMBER:
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                                <ListItemText
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        variant={ "h6" }
                                        align="left"
                                        sx={{color:"#fff"}}
                                      >
                                        {`RC ${centerapplication.cacNumber}`}
                                      </Typography>
                                    </React.Fragment>
                                  }
                                /></> }
                              </ListItem>

                              <Divider variant="middle" component="li" />

                              <ListItem sx={{ mb:1, mt:1 }}>
                                { isMobile ?
                                <ListItemText
                                    sx={{ maxWidth: "300px" }}
                                    primary={
                                      <React.Fragment>
                                        <Typography
                                          variant="h6"
                                          sx={{ fontSize: "10px" }}
                                          color={colors.greenAccent[400]}
                                        >
                                          PERMANENT ADDRESS:
                                        </Typography>
                                        <Typography
                                          variant="h4"
                                          sx={{color:"#fff"}}
                                        >
                                          {centerapplication.permanentCenterAddress}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                />  :
                                <>
                                <ListItemText
                                  sx={{ maxWidth: "250px" }}
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        variant={ "h5" }
                                        color={colors.greenAccent[400]}
                                      >
                                        PERMANENT ADDRESS:
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                                <ListItemText
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        variant={ "h6" }
                                        sx={{color:"#fff"}}
                                      >
                                        {centerapplication.permanentCenterAddress}
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                                </> }
                              </ListItem>

                              <Divider variant="middle" component="li" />

                              <ListItem sx={{ mb:1, mt:1 }}>
                                { isMobile ?
                                <ListItemText
                                    sx={{ maxWidth: "300px" }}
                                    primary={
                                      <React.Fragment>
                                        <Typography
                                          variant="h6"
                                          sx={{ fontSize: "10px" }}
                                          color={colors.greenAccent[400]}
                                        >
                                          CENTRE EMAIL:
                                        </Typography>
                                        <Typography
                                          variant="h4"
                                          sx={{color:"#fff"}}
                                        >
                                          {centerapplication.email}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                />  :
                                <>
                                <ListItemText
                                  sx={{ maxWidth: "250px" }}
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        variant={ "h5" }
                                        color={colors.greenAccent[400]}
                                      >
                                        CENTRE EMAIL:
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                                <ListItemText
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        variant={ "h6" }
                                        sx={{color:"#fff"}}
                                      >
                                        {centerapplication.email}
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                                </> }
                              </ListItem>

                              <Divider variant="middle" component="li" />

                              <ListItem sx={{ mb:1, mt:1 }}>
                                { isMobile ?
                                <ListItemText
                                    sx={{ maxWidth: "300px" }}
                                    primary={
                                      <React.Fragment>
                                        <Typography
                                          variant="h6"
                                          sx={{ fontSize: "10px" }}
                                          color={colors.greenAccent[400]}
                                        >
                                          CENTRE HEAD:
                                        </Typography>
                                        <Typography
                                          variant="h4"
                                          sx={{color:"#fff"}}
                                        >
                                          {centerapplication.centerHead}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                />  :
                                <>
                                <ListItemText
                                  sx={{ maxWidth: "250px" }}
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        variant={ "h5" }
                                        color={colors.greenAccent[400]}
                                      >
                                        CENTRE HEAD:
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                                <ListItemText
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        variant={ "h6" }
                                        sx={{color:"#fff"}}
                                      >
                                        {centerapplication.centerHead}
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                                </> }
                              </ListItem>

                              <Divider variant="middle" component="li" />

                              <ListItem sx={{ mb:1, mt:1 }}>
                                { isMobile ?
                                <ListItemText
                                    sx={{ maxWidth: "300px" }}
                                    primary={
                                      <React.Fragment>
                                        <Typography
                                          variant="h6"
                                          sx={{ fontSize: "10px" }}
                                          color={colors.greenAccent[400]}
                                        >
                                          QUALIFICATIONS:
                                        </Typography>
                                        <Typography
                                          variant="h4"
                                          sx={{color:"#fff"}}
                                        >
                                          {centerapplication.qualifications.join(", ")}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                />  :
                                <>
                                <ListItemText
                                  sx={{ maxWidth: "250px" }}
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        variant={ "h5" }
                                        color={colors.greenAccent[400]}
                                      >
                                        QUALIFICATIONS:
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                                <ListItemText
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        variant={ "h6" }
                                        sx={{color:"#fff"}}
                                      >
                                        {centerapplication.qualifications.join(", ")}
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                                </> }
                              </ListItem>

                              <Divider variant="middle" component="li" />

                              <ListItem sx={{ mb:1, mt:1 }}>
                                { isMobile ?
                                <ListItemText
                                    sx={{ maxWidth: "300px" }}
                                    primary={
                                      <React.Fragment>
                                        <Typography
                                          variant="h6"
                                          sx={{ fontSize: "10px" }}
                                          color={colors.greenAccent[400]}
                                        >
                                          CENTRE TYPE:
                                        </Typography>
                                        <Typography
                                          variant="h4"
                                          sx={{color:"#fff"}}

                                        >
                                          {centerapplication.centerType}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                />  :
                                <>
                                <ListItemText
                                  sx={{ maxWidth: "250px" }}
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        variant={ "h5" }
                                        color={colors.greenAccent[400]}
                                      >
                                        CENTRE TYPE:
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                                <ListItemText
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        variant={ "h6" }
                                        sx={{color:"#fff"}}

                                      >
                                        {centerapplication.centerType}
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                                </> }
                              </ListItem>

                              <Divider variant="middle" component="li" />

                              <ListItem sx={{ mb:1, mt:1 }}>
                                { isMobile ?
                                <ListItemText
                                    sx={{ maxWidth: "300px" }}
                                    primary={
                                      <React.Fragment>
                                        <Typography
                                          variant="h6"
                                          sx={{ fontSize: "10px" }}
                                          color={colors.greenAccent[400]}
                                        >
                                          STATE:
                                        </Typography>
                                        <Typography
                                          variant="h4"
                                          sx={{color:"#fff"}}
                                        >
                                          {centerapplication.stateOfRez}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                />  :
                                <>
                                <ListItemText
                                  sx={{ maxWidth: "250px" }}
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        variant={ "h5" }
                                        color={colors.greenAccent[400]}
                                      >
                                        STATE:
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                                <ListItemText
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        variant={ "h6" }
                                        sx={{color:"#fff"}}
                                      >
                                        {centerapplication.stateOfRez}
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                                </> }
                              </ListItem>
                            </List>
                        </Grid>
                    </Box>

                    <Stack direction="column" gap={5} sx={{width:isMobile ? "100%" : "40%"}}>
                        <Box sx={{
                          width:"100%",
                          "& .MuiStack-root .MuiListSubheader-root": {
                              backgroundColor: colors.primary[400],
                              color: colors.greenAccent[400],
                              position: 'static',
                              fontSize: 15
                          },
                          "& .MuiListItemText-root": {
                              minWidth: '250px'
                          }
                        }}>
                            <Grid sx={{ bgcolor: colors.primary[400] }} >
                                <List
                                    sx={{ width: '100%' }}
                                    aria-labelledby="center-details"
                                    subheader={
                                      <ListSubheader component="div" id="center-details">
                                        MANAGEMENT STAFF
                                      </ListSubheader>
                                    }
                                >
                                  <ListItem sx={{ mb:1, mt:1 }}>
                                      <ListItemAvatar>
                                          <Avatar>
                                            <PersonIcon />
                                          </Avatar>
                                      </ListItemAvatar>
                                      <ListItemText
                                          sx={{color:"#fff"}}
                                          primary={centerapplication.centerManagerName}
                                          secondary={
                                              <React.Fragment>
                                                <Typography
                                                  sx={{ display: 'inline' }}
                                                  component="span"
                                                  variant="body2"
                                                  color="#80B373"
                                                >
                                                  CENTRE MANAGER
                                                </Typography>
                                                {` — ${centerapplication.centerManagerQualification}`}
                                              </React.Fragment>
                                          }
                                      />
                                  </ListItem>

                                  <Divider variant="inset" component="li" />

                                  <ListItem sx={{ mb:1, mt:1 }}>
                                      <ListItemAvatar>
                                          <Avatar>
                                            <PersonIcon />
                                          </Avatar>
                                      </ListItemAvatar>
                                      <ListItemText
                                          sx={{color:"#fff"}}
                                          primary={centerapplication.assessor1Name}
                                          secondary={
                                              <React.Fragment>
                                                <Typography
                                                  sx={{ display: 'inline' }}
                                                  component="span"
                                                  variant="body2"
                                                  color="#80B373"
                                                >
                                                  ASSESSOR 1
                                                </Typography>
                                                {` — ${centerapplication.assessor1Qualification}`}
                                              </React.Fragment>
                                          }
                                      />
                                  </ListItem>

                                  <Divider variant="inset" component="li" />

                                  {centerapplication?.assessor2Name &&
                                      <>
                                      <ListItem sx={{ mb:1, mt:1 }}>
                                          <ListItemAvatar>
                                              <Avatar>
                                                <PersonIcon />
                                              </Avatar>
                                          </ListItemAvatar>
                                          <ListItemText
                                              sx={{color:"#fff"}}
                                              primary={centerapplication.assessor2Name}
                                              secondary={
                                                  <React.Fragment>
                                                    <Typography
                                                      sx={{ display: 'inline' }}
                                                      component="span"
                                                      variant="body2"
                                                      color="#80B373"
                                                    >
                                                      ASSESSOR 2
                                                    </Typography>
                                                    {` — ${centerapplication.assessor2Qualification}`}
                                                  </React.Fragment>
                                              }
                                          />
                                      </ListItem>

                                      <Divider variant="inset" component="li" />
                                      </>
                                  }

                                  <ListItem sx={{ mb:1, mt:1 }}>
                                      <ListItemAvatar>
                                          <Avatar>
                                            <PersonIcon />
                                          </Avatar>
                                      </ListItemAvatar>
                                      <ListItemText
                                          sx={{color:"#fff"}}
                                          primary={centerapplication.internalVerifierName}
                                          secondary={
                                              <React.Fragment>
                                                <Typography
                                                  sx={{ display: 'inline' }}
                                                  component="span"
                                                  variant="body2"
                                                  color="#80B373"
                                                >
                                                  INTERNAL VERIFIER
                                                </Typography>
                                                {` — ${centerapplication.internalVerifierQualification}`}
                                              </React.Fragment>
                                          }
                                      />
                                  </ListItem>

                                  <Divider variant="inset" component="li" />

                                  {centerapplication?.liaisonOfficerName &&
                                      <>
                                      <ListItem sx={{ mb:1, mt:1 }}>
                                          <ListItemAvatar>
                                              <Avatar>
                                                <PersonIcon />
                                              </Avatar>
                                          </ListItemAvatar>
                                          <ListItemText
                                              sx={{color:"#fff"}}
                                              primary={centerapplication.liaisonOfficerName}
                                              secondary={
                                                  <React.Fragment>
                                                    <Typography
                                                      sx={{ display: 'inline' }}
                                                      component="span"
                                                      variant="body2"
                                                      color="#80B373"
                                                    >
                                                      INDUSTRY/WORKPLACE LIAISON OFFICER
                                                    </Typography>
                                                    {` — ${centerapplication.liaisonOfficerQualification}`}
                                                  </React.Fragment>
                                              }
                                          />
                                      </ListItem>

                                      <Divider variant="inset" component="li" />
                                      </>
                                  }

                                  {centerapplication?.resourceOfficerName &&
                                      <>
                                      <ListItem sx={{ mb:1, mt:1 }}>
                                          <ListItemAvatar>
                                              <Avatar>
                                                <PersonIcon />
                                              </Avatar>
                                          </ListItemAvatar>
                                          <ListItemText
                                              sx={{color:"#fff"}}
                                              primary={centerapplication.resourceOfficerName}
                                              secondary={
                                                  <React.Fragment>
                                                    <Typography
                                                      sx={{ display: 'inline' }}
                                                      component="span"
                                                      variant="body2"
                                                      color="#80B373"
                                                    >
                                                      RESOURCE/DATA OFFICER
                                                    </Typography>
                                                    {` — ${centerapplication.resourceOfficerQualification}`}
                                                  </React.Fragment>
                                              }
                                          />
                                      </ListItem>

                                      <Divider variant="inset" component="li" />
                                      </>
                                  }

                                  {centerapplication?.otherStaffName &&
                                      <>
                                      <ListItem sx={{ mb:1, mt:1 }}>
                                          <ListItemAvatar>
                                              <Avatar>
                                                <PersonIcon />
                                              </Avatar>
                                          </ListItemAvatar>
                                          <ListItemText
                                              sx={{color:"#fff"}}
                                              primary={centerapplication.otherStaffName}
                                              secondary={
                                                  <React.Fragment>
                                                    <Typography
                                                      sx={{ display: 'inline' }}
                                                      component="span"
                                                      variant="body2"
                                                      color="#80B373"
                                                    >
                                                      {centerapplication.otherStaffDesignation.toUpperCase()}
                                                    </Typography>
                                                    {` — ${centerapplication.otherStaffQualification}`}
                                                  </React.Fragment>
                                              }
                                          />
                                      </ListItem>

                                      <Divider variant="inset" component="li" />
                                      </>
                                  }
                                </List>
                            </Grid>
                        </Box>
                        <Box sx={{
                          width:"100%",
                          "& .MuiStack-root .MuiListSubheader-root": {
                              backgroundColor: colors.primary[400],
                              color: colors.greenAccent[400],
                              position: 'static',
                              fontSize: 15
                          },
                          "& .MuiListItemText-root": {
                              minWidth: '250px'
                          }
                        }}>
                            <Grid sx={{ bgcolor: colors.primary[400] }} >
                                <List
                                    sx={{ minWidth: '100%' }}
                                    aria-labelledby="center-details"
                                    subheader={
                                      <ListSubheader component="div" id="center-details">
                                        PHYSICAL FACILITIES
                                      </ListSubheader>
                                    }
                                >
                                </List>
                                <TableContainer sx={{bgcolor: colors.primary[400]}}>
                                  <Table sx={{ width: "750px"}} aria-label="physical-facilities">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>TYPE OF UNIT</TableCell>
                                        <TableCell align="center">QUANTITY</TableCell>
                                        <TableCell align="center">TOTAL FLOOR AREA&nbsp(Sqm.)</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {rows.map((row) => (
                                        <TableRow
                                          key={row.name}
                                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                          <TableCell component="th" scope="row">
                                            {row.unitType}
                                          </TableCell>
                                          <TableCell align="center">{row.quantity}</TableCell>
                                          <TableCell align="center">{row.floorArea}</TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                            </Grid>
                        </Box>
                    </Stack>
                </Stack>

        </Box>
        </>
    )

    return content
}

export {
  AssignCenter,
  ViewCenterApplication
}
