import { useGetQualificationsQuery } from './qualificationsApiSlice'
import Qualification from './Qualification'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'


import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import AddIcon from '@mui/icons-material/Add'
import HomeIcon from '@mui/icons-material/Home'
import { Box, Stack, Button } from "@mui/material"
import { Link } from "react-router-dom"
import useMediaQuery from "@mui/material/useMediaQuery"
import Header from "../../components/Header"


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#030702',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#0b1c08',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#091506',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const QualificationsList = () => {
    useTitle('NIOB Skill CaMP: All Qualifications')
    const isMobile = useMediaQuery("(max-width:600px)")

    const {
        data: qualifications,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetQualificationsQuery('qualificationsList', {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    let content

    if (isLoading) content = <PulseLoader color={"#0b1c08"} className="center" />

    if (isError) {
        content = <p className='errmsg'>{error?.data?.message}</p>
    }

    if (isSuccess) {

        const { ids } = qualifications

        const tableContent = ids?.length
            ? ids.map(qualificationId => <Qualification key={qualificationId} qualificationId={qualificationId} />)
            : null

        content = (
          <>
          <Header title="QUALIFICATIONS LIST" subtitle="Record of All Qualifications" />
          <Box sx={{
            p: "2% 2% 5% 2%",
            m: isMobile ? "10% 2% 150px 2%" : "2% 2% 10% 2%",
            "& .MuiStack-root .MuiButton-outlined": {color:'#0b1c08', border:'1px solid #0b1c08'},
            "& .MuiStack-root .MuiButton-outlined:hover": {backgroundColor:'#7fd47010'}
          }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: isMobile ? "95%" : "60%" }}>

                <Stack direction="row" gap={1} mt="20px" mb="20px">
                  <Link to="/dash">
                    <Button variant="outlined" color="info" size="small" startIcon={<HomeIcon />} sx={{ mb: 0 }}>
                      Home
                    </Button>
                  </Link>
                  <Link to="/dash/qualifications/new">
                    <Button variant="outlined" color="info" size="small" startIcon={<AddIcon />} sx={{ mb: 0 }}>
                      Add New Qualification
                    </Button>
                  </Link>
                </Stack>
            </Box>
            <TableContainer component={Paper} sx={{ maxWidth: isMobile ? "95%" : "60%" }}>
              <Table sx={{ minWidth: 650 }} aria-label="Users List">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>S/No</StyledTableCell>
                    <StyledTableCell>QUALIFICATION</StyledTableCell>
                    <StyledTableCell align="center">ACTIONS</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {tableContent}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          </>
        )
    }

    return content
}

export default QualificationsList
