import { useState, useEffect } from "react"
import { useUpdateCenterMutation, useDeleteCenterMutation } from "./centersApiSlice"
import { Link, useNavigate } from "react-router-dom"
import { STATES } from '../../config/states'
import { CENTERTYPES } from '../../config/centertypes'
import { useGetQualificationsQuery } from '../qualifications/qualificationsApiSlice'
import { useSelector } from 'react-redux'
import useTitle from '../../hooks/useTitle'

import LoadingButton from '@mui/lab/LoadingButton'
import { Box, useTheme, TextField, Stack, Button, FormControl, InputLabel, Select, MenuItem } from "@mui/material"
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Alert from '@mui/material/Alert'
import HomeIcon from '@mui/icons-material/Home'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import { styled } from '@mui/material/styles'
import { tokens } from "../../theme"
import useMediaQuery from "@mui/material/useMediaQuery"
import Header from "../../components/Header"

const CENTER_REGEX = /^[a-zA-Z \.,()-]{8,50}$/
const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

const EditCenter = ({ center }) => {
    useTitle('NIOB Skill CaMP: Edit Centre')
    const isMobile = useMediaQuery("(max-width:600px)")
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const { data: qualificationsData } = useGetQualificationsQuery()

    const [updateCenter, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateCenterMutation()

    const [deleteCenter, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteCenterMutation()

    const navigate = useNavigate()

    const [centerName, setCenterName] = useState(center.centerName)
    const [validCenterName, setValidCenterName] = useState(false)
    const [centerManager, setCenterManager] = useState(center.centerManager)
    const [validCenterManager, setValidCenterManager] = useState(false)
    const [email, setEmail] = useState(center.email)
    const [validEmail, setValidEmail] = useState(false)
    const [stateOfRez, setStateOfRez] = useState(center?.stateOfRez || '')
    const [centerType, setCenterType] = useState(center.centerType)
    const [qualifications, setQualifications] = useState(center.qualifications)
    const [verified, setVerified] = useState(center.verified)

    useEffect(() => {
        setValidCenterName(CENTER_REGEX.test(centerName))
    }, [centerName])

    useEffect(() => {
        setValidCenterManager(CENTER_REGEX.test(centerManager))
    }, [centerManager])

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email))
    }, [email])

    useEffect(() => {
        if (isSuccess || isDelSuccess) {
            setCenterName('')
            setCenterManager('')
            setEmail('')
            setStateOfRez('')
            setCenterType('')
            setVerified(false)
            setQualifications([])
            navigate('/dash/centers')
        }
    }, [isSuccess, isDelSuccess, navigate])

    const onCenterNameChanged = e => setCenterName(e.target.value)
    const onCenterManagerChanged = e => setCenterManager(e.target.value)
    const onEmailChanged = e => setEmail(e.target.value)
    const onVerifiedChanged = () => setVerified(prev => !prev)
    //const onCenterTypeChanged = e => setCenterType(e.target.value)
    //const onStateOfRezChanged = e => setStateOfRez(e.target.value)

    const onDeleteCenterClicked = async () => {
        await deleteCenter({ id: center.id })
    }

    const onSaveCenterClicked = async (e) => {
        e.preventDefault()

        const filteredQualificationIds = qualificationsData.ids
        .map(id => qualificationsData.entities[id]) // Map each id to its corresponding qualification object
        .filter(qualification => qualifications.includes(qualification.qualificationType)) // Filter by qualificationType array
        .map(qualification => qualification.id)

        await updateCenter({ id: center.id, centerName, centerManager, email, centerType, stateOfRez, verified, filteredQualificationIds })
    }

    const options1 = Object.values(STATES).map(_state => (
        <MenuItem key={_state} value={_state}>{_state}</MenuItem>
    ))

    const options2 = Object.values(CENTERTYPES).map(centerType => (
        <MenuItem key={centerType} value={centerType}>
            {centerType}
        </MenuItem>
    ))

    const options3 = qualificationsData?.entities ? Object.entries(qualificationsData.entities).map(([id, qualification]) => (
        <MenuItem key={id} value={qualification.qualificationType}>{qualification.qualificationType}</MenuItem>
    )) : null

    const canSave = [
        centerType,
        stateOfRez,
        qualifications.length,
        validCenterName,
        validCenterManager,
        validEmail
    ].every(Boolean) && !isLoading

    return (
      <>
      <Header title="EDIT CENTRE" subtitle="Update Centre record" />
      <Box sx={{
        p: "2% 2% 5% 2%",
        m: isMobile ? "10% 2% 150px 2%" : "2% 2% 10% 2%",
        "& .MuiStack-root .MuiButton-outlined": {color:'#0b1c08', border:'1px solid #0b1c08'},
        "& .MuiStack-root .MuiButton-outlined:hover": {backgroundColor:'#7fd47010'}
       }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', width: "95%" }}>

              <Stack direction={isMobile ? "column" : "row"} gap={1} mt="20px" mb="20px">
                <Link to="/dash">
                  <Button variant={isMobile ? "contained" : "outlined"} color="info" size="small" startIcon={<HomeIcon />} sx={{ mb: 0, width:isMobile ? "100%" : null }}>
                    Home
                  </Button>
                </Link>
                <Link to="/dash/centers/new">
                  <Button variant={isMobile ? "contained" : "outlined"} color="success" size="small" startIcon={<AddIcon />} sx={{ mb: 0, width:isMobile ? "100%" : null }}>
                    Add New Centre
                  </Button>
                </Link>
                <Link to="/dash/centers">
                  <Button variant={isMobile ? "contained" : "outlined"} color="success" size="small" startIcon={<VisibilityIcon />} sx={{ mb: 0, width:isMobile ? "100%" : null }}>
                    View All Centres
                  </Button>
                </Link>
                <Button
                  onClick={() => {onDeleteCenterClicked()}}
                  variant={isMobile ? "contained" : "outlined"}
                  color="error"
                  size="small"
                  startIcon={<DeleteIcon />}
                  sx={{ mb: 0, width:isMobile ? "100%" : null }}
                >
                  Delete this Centre
                </Button>
              </Stack>
          </Box>
          <Stack direction={isMobile ? "column" : "row"} gap={5}>
              <Box sx={{
                width: isMobile ? "95%" : '60%'
              }}>
                <form className="form" onSubmit={onSaveCenterClicked}>
                    <Box
                      display="grid"
                      gap="30px"
                      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                      sx={{
                        "& > div": { gridColumn: isMobile ? "span 4" : undefined },
                      }}
                    >
                        {/*Alert Messages to be displayed here*/}
                        {/*errMsg ?
                          <Alert ref={errRef}
                            variant="outlined"
                            sx={{ gridColumn: "span 4" }}
                            severity="error"
                            aria-live="assertive">
                            {errMsg}
                          </Alert> : ""*/}
                        {/*successMsg ?
                          <Alert ref={successRef}
                            variant="outlined"
                            sx={{ gridColumn: "span 4" }}
                            severity="success"
                            aria-live="assertive">
                              {successMsg}
                          </Alert> : ""*/}

                        <TextField
                          fullWidth
                          variant="outlined"
                          type="text"
                          label="Centre Name"
                          onChange={onCenterNameChanged}
                          value={centerName}
                          name="centerName"
                          sx={{ gridColumn: "span 2" }}
                        />

                        <TextField
                          fullWidth
                          variant="outlined"
                          type="text"
                          label="Centre Manager"
                          onChange={onCenterManagerChanged}
                          value={centerManager}
                          name="centerManager"
                          sx={{ gridColumn: "span 2" }}
                        />

                        <TextField
                          fullWidth
                          variant="outlined"
                          type="email"
                          label="Email"
                          onChange={onEmailChanged}
                          value={email}
                          name="email"
                          sx={{ gridColumn: "span 4" }}
                        />

                        <FormControl sx={{ gridColumn: 'span 2' }}>
                          <InputLabel id="state-id">State</InputLabel>
                          <Select
                            fullWidth
                            labelId="state-id"
                            variant="outlined"
                            value={stateOfRez}
                            name="stateOfRez"
                            onChange={(e) => setStateOfRez(e.target.value)}
                          >
                            {options1}
                          </Select>
                        </FormControl>

                        <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
                          <InputLabel id="center-type-id">Centre Type</InputLabel>
                          <Select
                            fullWidth
                            labelId="center-type-id"
                            variant="outlined"
                            value={centerType}
                            name="centerType"
                            onChange={(e) => setCenterType(e.target.value)}
                          >
                            {options2}
                          </Select>
                        </FormControl>

                        <FormGroup sx={{ gridColumn: 'span 1' }}>
                            <FormControlLabel onChange={onVerifiedChanged} checked={verified} control={<Checkbox sx={{color:"#49a139"}} />} label="Verified" />
                        </FormGroup>

                        <FormControl fullWidth sx={{ gridColumn: 'span 3' }}>
                          <InputLabel id="qualifications-id">Qualifications</InputLabel>
                          <Select
                            labelId="qualifications-id"
                            variant="outlined"
                            name="qualifications"
                            value={qualifications}
                            onChange={(e) => setQualifications(e.target.value)}
                            multiple
                          >
                            {options3}
                          </Select>
                        </FormControl>
                    </Box>
                    <Box display="flex" mt="30px">
                      <LoadingButton type="submit" loading={isLoading} disabled={!canSave} sx={{color:"#fff", width:"50%"}} variant="contained">
                        Update Centre
                      </LoadingButton>
                    </Box>
                </form>
            </Box>
          </Stack>
      </Box>
      </>
    )
}

export default EditCenter
